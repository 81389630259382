import React, { FC } from 'react';
import { FormField, FormFieldProps } from '../FormField';
import { DateRangePicker } from '../DateRangePicker';

export type FormDateRangePickerProps = FormFieldProps;

export const FormDateRangePicker: FC<FormDateRangePickerProps> = ({
	...props
}) => {
	return <FormField control={DateRangePicker} {...props} />;
};
