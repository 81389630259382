import React from "react";
import Highlight, { defaultProps } from 'prism-react-renderer'
import vsDark from 'prism-react-renderer/themes/vsDark'
import { ScreenSizeContext } from '../../context/ScreenSizeContext';

export const CodeHighlight = (props) => {
	const { size } = React.useContext(ScreenSizeContext);
	const isMobile = size.smallerThan.mobile;
	return (
		<Highlight {...defaultProps} theme={vsDark} code={props.codeString} language={props.language}>
			{({className, style, tokens, getLineProps, getTokenProps}) => (
				<pre
					className={`block m-b-4 ${className} ${props.metaData.span ? "span-" + props.metaData.span : ""}`}
					style={{
						...style,
						borderRadius: 6,
						fontSize: '14px',
						padding: '20px',
						overflow: isMobile && 'auto'
					}}
				>
					{tokens.map((line, i) => (
						<div key={i} {...getLineProps({line, key: i})}>
							{line.map((token, key) => (
								<span key={key} {...getTokenProps({token, key})} />
							))}
						</div>
					))}
				</pre>
			)}
		</Highlight>
	)
}
