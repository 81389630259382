import React, { FC, Component } from 'react';
import classnames from 'classnames';
import memoizeOne from 'memoize-one';
import {
	DropdownItemProps,
	DropdownOnSearchChangeData,
} from 'semantic-ui-react';
import { Dropdown, DropdownProps } from '../Dropdown';

interface GroupedSelectItemProps extends DropdownItemProps {
	group?: string;
	index?: number;
}

const GroupedSelectItem: FC<GroupedSelectItemProps> = (props) => {
	const content = props.content !== undefined ? props.content : props.text;
	return (
		<React.Fragment>
			{props.index === 0 && props.group && (
				<div className="group">{props.group}</div>
			)}

			<span className="text">{content}</span>
		</React.Fragment>
	);
};

interface SelectState {
	searchQuery?: SelectProps['searchQuery'];
}

export interface SelectProps extends DropdownProps {
	/** Set size of the select.<br /> Possible values: <i>'large'</i>, <i>'small'</i> */
	size?: 'small' | 'medium' | 'large';

	/** Shortcut for large-sized select */
	large?: boolean;

	/** Shortcut for small-sized select */
	small?: boolean;

	/** Array of items in Select */
	options: DropdownItemProps[];

	/** Allow for grouping of Select options */
	grouped?: boolean;
}

export class Select extends Component<SelectProps, SelectState> {
	static defaultProps = {
		selection: true,
		size: 'medium',
	};

	static displayName = 'Select';

	get searchQuery() {
		return this.props.hasOwnProperty('searchQuery')
			? this.props.searchQuery
			: this.props.grouped
			? this.state.searchQuery
			: undefined;
	}

	get options() {
		if (!this.props.grouped) {
			return this.props.options;
		}

		return this.memoizedOptions(
			this.props.options,
			this.searchQuery,
			this.props.value,
			this.props.multiple
		);
	}

	memoizedOptions = memoizeOne(
		(
			options: DropdownItemProps[],
			searchQuery?: SelectProps['searchQuery'],
			value?: SelectProps['value'],
			multiple?: boolean
		) => {
			const splitedOptions = this.splitOptions(
				options,
				searchQuery,
				value,
				multiple
			);

			return [
				...this.groupOptions(splitedOptions.available),
				...splitedOptions.selected,
				...splitedOptions.excluded,
			];
		}
	);

	constructor(props: SelectProps) {
		super(props);

		this.state = {
			searchQuery: '',
		};
	}

	handleChange = (
		event: React.SyntheticEvent<HTMLElement>,
		data: DropdownProps
	) => {
		/*
		 * search query is cleared every time Select is closed,
		 * so if this.props.multiple !== true search query will be an empty string anyway
		 * because Select will be closed after an element is selected
		 */
		if (this.searchQuery && this.props.multiple) {
			const splittedOptions = this.splitOptions(
				this.props.options,
				this.searchQuery,
				data.value,
				this.props.multiple
			);

			// Semantic UI resets filter (without event firing) when selected last filtered element
			if (splittedOptions.available.length === 0) {
				const searchData = { ...data, searchQuery: '' };

				this.handleSearchChange(event, searchData);
			}
		}

		data.value = data.value === '' ? undefined : data.value;

		if (this.props.onChange) {
			this.props.onChange(event, data);
		}
	};

	handleSearchChange = (
		event: React.SyntheticEvent<HTMLElement>,
		data: DropdownOnSearchChangeData
	) => {
		if (this.props.grouped && !this.props.hasOwnProperty('searchQuery')) {
			this.setState({
				searchQuery: data.searchQuery,
			});
		}

		if (this.props.onSearchChange) {
			this.props.onSearchChange(event, data);
		}
	};

	// Semantic UI resets filter (without event firing) on select close
	handleClose = (
		event: React.SyntheticEvent<HTMLElement>,
		data: DropdownProps
	) => {
		const searchData = { ...data, searchQuery: '' };
		this.handleSearchChange(event, searchData);

		if (this.props.onClose) {
			this.props.onClose(event, data);
		}
	};

	isOptionSuitable(
		option: DropdownItemProps,
		searchQuery: SelectProps['searchQuery']
	) {
		if (option.text == null) {
			return true;
		}

		return option.text
			.toString()
			.toLowerCase()
			.includes(searchQuery.toLowerCase());
	}

	splitOptions = memoizeOne(
		(
			options: DropdownItemProps[],
			searchQuery?: SelectProps['searchQuery'],
			value?: SelectProps['value'],
			multiple?: boolean
		) => {
			const available: DropdownItemProps[] = [];
			const selected: DropdownItemProps[] = [];
			const excluded: DropdownItemProps[] = [];

			for (const option of options) {
				if (multiple) {
					if (Array.isArray(value) && value.includes(option.value)) {
						selected.push(option);
						continue;
					}
				}

				if (
					!searchQuery ||
					this.isOptionSuitable(option, searchQuery)
				) {
					available.push(option);
				} else {
					excluded.push(option);
				}
			}

			return {
				available,
				selected,
				excluded,
			} as Record<
				'available' | 'selected' | 'excluded',
				DropdownItemProps[]
			>;
		}
	);

	groupOptions = (options: DropdownItemProps[]) => {
		const groups = options.reduce((result, current) => {
			const group: string = current.group || ('' as string);

			if (!result[group]) {
				result[group] = [];
			}

			result[group].push(current);

			return result;
		}, {} as Record<string, DropdownItemProps[]>);

		return [].concat(
			// building flat list of options
			...Object.keys(groups)
				.sort()
				.map(
					// sorting groups and converting them to the collection
					(group) =>
						groups[group].map((option, index) => ({
							...option,
							index,
							className: classnames(
								option.className,
								index === 0 && 'with-group'
							),
							content: (
								<GroupedSelectItem index={index} {...option} />
							),
						}))
				)
		) as GroupedSelectItemProps[];
	};

	render() {
		const {
			className,
			grouped,
			large,
			onChange,
			onClose,
			onSearchChange,
			options,
			size,
			small,
			value,
			...props
		} = this.props;

		let realSize = size;

		if (small) realSize = 'small';
		if (large) realSize = 'large';

		const SelectClasses = classnames('Select', className, {
			'Select--small': realSize === 'small',
			'Select--large': realSize === 'large',
			'Select--grouped': grouped,
		});

		const realValue =
			value === undefined ? (this.props.multiple ? [] : '') : value;

		return (
			<Dropdown
				{...props}
				options={this.options}
				value={realValue}
				onChange={this.handleChange}
				onSearchChange={this.handleSearchChange}
				onClose={this.handleClose}
				className={SelectClasses}
			/>
		);
	}
}
