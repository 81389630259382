import React from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import Highlighter from "react-highlight-words";
import { Link, navigate } from "gatsby";
import { Button, Input, KeyPressListener, Popover, Headline, BodyText, Eyebrow } from '@servicetitan/design-system';
import searchNotFound from '../images/searchNotFound.svg';
import { tokens } from '@servicetitan/tokens/core';
import { ScreenSizeContext } from '../context/ScreenSizeContext';

const removeMd = require('remove-markdown');

export const SearchBox = ({index, store}) => {
  const [query, setQuery] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const { size } = React.useContext(ScreenSizeContext);
  const isMobile = size.smallerThan.tablet;
  const [focused, setFocused] = React.useState(isMobile ? undefined : 0);
  const inputRef = React.useRef(null);
  const results = useFlexSearch(query, index, store);



  const onChangeHandler = (e, data) => setQuery(data.value);
  const onFocusHandler = () => results.length > 0 && setOpen(true);
  const handleClickOutside = () => setOpen(false);
  const focusInput = () => {
    const activeElement = document.activeElement;
    const inputs = ['input', 'select', 'textarea'];
    const anyInputIsFocused = inputs.includes(activeElement.tagName.toLowerCase());
    if (!anyInputIsFocused) {
      inputRef.current.focus();
    };
  };

  const handleKeyDown = (e) => {
    if (e.code === 'ArrowDown') {
      focused !== results.length -1
        && setFocused(focused + 1);
      e.preventDefault();
    }
    if (e.code === 'ArrowUp') {
      focused !== 0 && setFocused(focused - 1);
      e.preventDefault();
    }
    if (e.code === 'Enter') {
      navigate(results[focused].path);
      inputRef.current.blur();
      document.body.focus();
      setQuery('');
      setOpen(false);
      e.preventDefault();
    }
    if (e.code === 'Escape') {
      document.body.focus();
      setOpen(false);
      e.preventDefault();
    }
    if (e.code === 'Tab') {
      setOpen(false);
    }
  };

  const handleLinkClick = () => {
    setQuery('');
    setOpen(false);
    document.body.focus();
  };

  const mobileSearchClose = () => {
    setMobileSearchOpen(false);
    setQuery('');
    setFocused(0);
    setOpen(false);
  }

  const searchToc = (items = [], term = "", results = []) => {
    const lowercaseTerm = term.toLowerCase();
    let currentResults = [...results];

    for (let item of items) {
      if (item.title && item.title.toLowerCase().includes(lowercaseTerm)) {
        currentResults.push({title: item.title, url: item.url})
      }

      if (item.items && item.items.length) {
        currentResults = searchToc(item.items, term, currentResults);
      }
    }

    return currentResults;
  }

  const highlight = (text) => <Highlighter className="highlights" autoEscape={true} searchWords={[query.toString()]} textToHighlight={text} />;

  const searchPopover = (
    <Popover
        trigger={
          <Input
            fluid
            value={query}
            inputRef={inputRef}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onKeyDown={handleKeyDown}
            placeholder={isMobile ? 'Search...' : 'Press  /  to start the search...'}
            icon="search"
            iconPosition="left"
            autoFocus={isMobile}
          />
        }
        padding="s"
        open={open}
        width='100'
        className="DocsSearch__wrapper"
        direction="b"
        wrapperClassName="DocsSearch"
        popoverContentClassName="DocsSearch__popover"
        onClickOutside={handleClickOutside}
      >
        {results.length > 0
          ? (
              <React.Fragment>
                <ul className="searchList">
                  {results.map((result, index) => {
                    const description = removeMd(result.rawDescription);
                    const tocResults = searchToc(result?.toc?.items, query);

                    return (
                      <li
                        className={!isMobile && focused === index ? "selected" : null}
                        key={result.id}
                      >
                        <Link to={result.path} onClick={handleLinkClick}>
                          <Eyebrow size="small">
                            {result.category ? `${result.globalNav} / ${result.category}` : `${result.globalNav}` }
                          </Eyebrow>
                          <Headline className="m-b-half item__title" size="small">{highlight(result.title)}</Headline>
                          {description && <BodyText className="item__description" size="small">{description}</BodyText>}
                        </Link>

                        {tocResults.length > 0 ? (
                          tocResults.map((item, i) => (
                            <li key={`${item.title}${i}`}>
                              <Link to={`${result.path}${item.url}`} onClick={handleLinkClick}>
                                <BodyText className="item__header__link" size="xsmall">↪ {highlight(item.title)}</BodyText>
                              </Link>
                            </li>
                          ))
                        ) : null}
                      </li>
                    )
                  })}
                </ul>

                {!size.smallerThan.tablet && (
                  <div className="DocsSearch__footer">
                    <div>
                      <code>Enter</code>select <code>&uarr;</code>move up <code>&#8595;</code>move down
                    </div>
                  </div>
                )}
              </React.Fragment>
          ) : (
              <div className="ta-center">
                <img src={searchNotFound} alt="Search Not Found" className="m-y-3" />
                <BodyText bold>Sorry, we couldn't find any results for "<BodyText el="span" style={{color: tokens.colorBlue400}}>{query}</BodyText>"</BodyText>
                <BodyText size="small" className="m-b-3 m-t-half" subdued>Try searching for something else</BodyText>
              </div>
          )
        }
      </Popover>
  )

  React.useEffect(() => {
    if (query.length > 2) {
        setFocused(0);
        setOpen(true);
    } else {
      setOpen(false);
    }
  }, [query]);

  return (
    <React.Fragment>
      <KeyPressListener
        code="Slash"
        handler={focusInput}
      />
      {
        size.smallerThan.tablet
          ? (
            <React.Fragment>
              <Button fill="subtle" className="" iconName="search" onClick={() => setMobileSearchOpen(true)}/>
              {mobileSearchOpen &&
                <div className="mobileSearch">
                  {searchPopover}
                  <Button fill="subtle" iconName="close" onClick={mobileSearchClose} />
                </div>
              }
            </React.Fragment>
          ) : searchPopover
      }
    </React.Fragment>
  )
}
