import React, { Ref } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import {
	AnvilSelect,
	AnvilSelectOptionsProps,
	AnvilSelectProps,
	PrimitiveValueType,
} from '../AnvilSelect';

export interface FormAnvilSelectProps<TValue>
	extends FormFieldPropsStrict,
		AnvilSelectProps<TValue> {
	error?: any; // Weirdly enough, this is what Semantic is doing to solve the extending issue
}

export const FormAnvilSelect: React.FC<
	FormAnvilSelectProps<
		| AnvilSelectOptionsProps
		| AnvilSelectOptionsProps[]
		| PrimitiveValueType
		| PrimitiveValueType[]
	>
> = React.forwardRef(
	<
		TValue extends
			| AnvilSelectOptionsProps
			| AnvilSelectOptionsProps[]
			| PrimitiveValueType
			| PrimitiveValueType[]
	>(
		props: FormAnvilSelectProps<TValue>,
		ref: Ref<any>
	) => {
		return (
			<FormField
				control={AnvilSelect}
				className="a-Select--Form"
				{...props}
				ref={ref}
			/>
		);
	}
);
