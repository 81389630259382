import React, { FC } from 'react';
import { Spinner } from '../Spinner';
import { Stack } from '../Stack';
import { Portal } from '../../internal/Portal';

export interface TableLoadingPanelProps {
	portal?: HTMLElement | boolean;
}

export const TableLoadingPanel: FC<TableLoadingPanelProps> = ({ portal }) => {
	const loadingPanel = (
		<div className="k-loading-mask">
			<Stack
				alignItems="center"
				justifyContent="center"
				className="h-100"
			>
				<Spinner />
			</Stack>
			<div className="k-loading-color" />
		</div>
	);

	return (
		<Portal
			conditional={!!portal}
			target={typeof portal !== 'boolean' ? portal : undefined}
		>
			{loadingPanel}
		</Portal>
	);
};
