import React from "react";
import classnames from "classnames";
import { Link, BodyText, Eyebrow } from "@servicetitan/design-system";

export const TableOfContents = ({ headings, parent, path }) => {
  const [anchor, setAnchor] = React.useState();

  const scrollObserver = () => {
    const observer = new IntersectionObserver((entries) => {
      const newArr = entries
        .filter(entry => entry.intersectionRatio > 0)
        .filter(entry => (entry.intersectionRect.top - 100) > 0)
        .sort((a, b) => (a.intersectionRect.top > b.intersectionRect.top) ? 1 : -1);

      if (newArr.length > 0 && newArr[0])
        setAnchor(newArr[0].target.getAttribute('id'));
    });

    document.querySelectorAll('h2[id], h3[id]').forEach((section) => {
      observer.observe(section);
    });
  }

  React.useEffect(() => {
    const page = document.querySelector('.Page__page');
    page?.addEventListener('scroll', scrollObserver)

    return () => {
      page?.removeEventListener('scroll', scrollObserver)
    }
  }, [])

  const renderList = (child) => {
    return(
      <ul className="TOC__list TOC__list--sublist">
        {child.map(
          (item, index) => {
            return (
              <li key={index}>
                {item.title &&
                  <Link
                    href={`${item.url}`}
                    className={classnames({
                      'active': item.url.trim().endsWith(anchor)
                    })}
                  >
                    <BodyText el="span" size="xsmall fw-normal" subdued>{item.title}</BodyText>
                  </Link>
                }
              </li>
            )
          }
        )}
      </ul>
    )
  }
  return (
    <div className="TOC">
      <Eyebrow className="m-y-2 p-l-1">CONTENT</Eyebrow>
      <ul className="TOC__list">
        {headings.map(
          (item, index) => {
            return (
              <li key={index}>
                {item.title &&
                  <Link
                    href={`${item.url}`}
                    className={classnames({
                      'active': item.url.trim().endsWith(anchor)
                    })}
                  >
                    <BodyText el="span" size="xsmall" subdued>{item.title}</BodyText>
                  </Link>
                }
                {item.items && renderList(item.items)}
              </li>
            )
          }
        )}
      </ul>
    </div>
  )
}
