import React, { FC } from 'react';
import classnames from 'classnames';
import { Label, LabelProps } from '../Label';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export interface ToggleSwitchPropsStrict {
	/** Whether or not the toggle switch is checked */
	checked?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Whether the toggle switch is in a disabled state */
	disabled?: boolean;

	/** ID for the toggle switch */
	id?: string;

	/** Text associatged with the label of a toggle switch */
	label?: LabelProps['children'];

	/** Property options for the label */
	labelProps?: LabelProps;

	/** HTML name for the checkbox within the toggle switch */
	name?: string;

	/** Called when the toggle switch is clicked  */
	onChange?: (value: string, checked: boolean) => void;

	/** HTML value for the checkbox within the toggle switch */
	value?: string;
}

export interface ToggleSwitchProps extends ToggleSwitchPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = React.forwardRef(
	(props: ToggleSwitchProps, ref: React.RefObject<HTMLDivElement>) => {
		const {
			checked,
			className,
			disabled,
			id,
			label,
			labelProps,
			name,
			onChange,
			onClick,
			value,
			error,
			...rest
		} = props;

		const newRef = React.useRef(null);
		const itemRef = ref ? ref : newRef;

		const { isFocusVisible } = useFocusVisible(itemRef);

		const onChangeHandler = (
			e?: React.SyntheticEvent<HTMLInputElement>
		) => {
			if (!onChange) return;
			onChange(value, e.currentTarget.checked);
		};

		const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) =>
			onClick?.(e, props);

		const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
			const target = e.target as HTMLElement;
			if (target.tagName !== 'INPUT') {
				e.stopPropagation();
			}
		};

		const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') (e.target as HTMLInputElement)?.click();
		};

		const getLabel = () => {
			if (label === undefined || label === '') return false;

			const labelClassName = labelProps?.className ?? null;

			const LabelClasses = classnames(
				labelClassName,
				'ToggleSwitch__label'
			);

			return (
				<Label
					error={error}
					disabled={disabled}
					{...labelProps}
					className={LabelClasses}
				>
					{label}
				</Label>
			);
		};

		const ToggleSwitchClasses = classnames('ToggleSwitch', className, {
			'ToggleSwitch--checked': checked,
			'ToggleSwitch--disabled': disabled,
			'ToggleSwitch--focus-visible': isFocusVisible,
		});

		return (
			<div
				className={ToggleSwitchClasses}
				onClick={handleClickEvent}
				ref={itemRef}
				role="switch"
				aria-checked={checked ? true : null}
				data-anvil-component="ToggleSwitch"
				{...rest}
			>
				<label className="ToggleSwitch__html-label">
					<input
						checked={checked}
						className="ToggleSwitch__input"
						disabled={disabled}
						id={id}
						name={name}
						onKeyDown={handleKeyDown}
						onChange={onChangeHandler}
						onClick={onClickHandler}
						role="checkbox"
						type="checkbox"
					/>
					<span className="ToggleSwitch__element" />
					{getLabel()}
				</label>
			</div>
		);
	}
);

ToggleSwitch.displayName = 'ToggleSwitch';
