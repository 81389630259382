export const colonless = (str: string) => str?.replace(/:/g, '');
export const spaceless = (str: string) => str?.replace(/ /g, '');

export const isInvalidDate = (d: Date | undefined): boolean => {
	if (typeof d === 'undefined') return false;
	return d instanceof Date && isNaN(d as any);
};

export const isValidDate = (date: Date) => {
	if (date instanceof Date) return !isInvalidDate(date);
	return false;
};

// 'empty' | '1-digit' | '2-digit' | '3-digit' | '4-digit' | 'many-digits' | 'colon' | 'phrase'
export const inputCaseName = (input: string) => {
	if (input.length === 0) {
		return 'empty';
	}

	let caseName;
	const digitsOnly = /^\d+$/;

	if (digitsOnly.test(input)) {
		// Digits only
		if (input.length < 5) {
			caseName = `${input.length}-digit`; // e.g. '1-digit', '2-digit', etc.
		} else {
			// Too many digits
			caseName = 'many-digits';
		}
	} else if (input.includes(':')) {
		// Has colon
		caseName = 'colon';
	} else {
		// No colon, has letters
		caseName = 'phrase';
	}
	return caseName;
};

export const parseDigits = (input: string): null | string[] => {
	if (!isValidInput(input)) {
		return null;
	}

	const digitsOnly = input.replace(/[^\d]+/g, ''); // Keep digits only

	// Only digits allowed
	if (input !== digitsOnly) {
		return null;
	}

	const dgts = input.split('');

	switch (inputCaseName(input)) {
		case '1-digit': // "2"=02:00
			return input === '0' ? ['12', '00'] : [`0${input}`, '00'];
		case '2-digit': // "23"=23:00, "02"=02:00, "31"=03:10
			return parseTwoDigits(dgts);
		case '3-digit': // "023"=02:30, "234"=02:34, "028"=00:28 (yes)
			return parseThreeDigits(dgts);
		case '4-digit': // "0234"=02:34
		case 'many-digits': // Cutting extra digits off, "02345"=02:34
			return [`${dgts[0]}${dgts[1]}`, `${dgts[2]}${dgts[3]}`];
	}
};

// One cases of hours value, e.g. "xy:00"
const parseTwoDigits = (dgts: string[]): null | string[] => {
	const num = parseInt(dgts.join(''), 10);
	const x = parseInt(dgts[0], 10);
	const y = parseInt(dgts[1], 10);

	// Edge case of "24:00" to "12:00 AM"
	if (num === 24 || num === 0) return ['12', '00', 'AM'];

	// Case "xy:00"
	if (num > 0 && num < 24) return [`${x}${y}`, `00`];

	// Reject 25-99
	return null;
};

// Two cases: "0x:yz" or "xy:z0"
const parseThreeDigits = (dgts: string[]): null | string[] => {
	const [x, y, z] = dgts.map((i) => parseInt(i, 10));

	// Edge case, last two digits are truly minutes, e.g. "126"=01:26 or "017"=00:17
	if (z > 5) {
		if (y > 5) {
			return null; // Reject, "066"=null
		}
		return [`0${x}`, `${y}${z}`];
	}
	// Edge case, leading zero x=0, "023"=02:30
	if (x === 0 && z < 6) return [`0${y}`, `${z}0`];

	// Case 1 = "0x:yz", major
	if (y < 6) return [`0${x}`, `${y}${z}`];

	// Case 2 = "xy:z0", minor
	if (x < 3 && z < 6) return [`${x}${y}`, `${z}0`];

	// Rejection
	return null;
};

// Parses "12:45ab", return null or ['12', '45', 'AM']
export const parseColon = (input: string): null | string[] => {
	if (!isValidInput(input)) {
		return null;
	}

	const parts = spaceless(input).split(':');
	const numbersPattern = /\d+/g;
	const hours = parts[0].match(numbersPattern) || [''];
	const minutes = parts[1].match(numbersPattern) || [''];

	// Excluding "12a:34" or "12:55a2"
	if (hours?.length !== 1 || minutes?.length !== 1) {
		return null;
	}

	if (hours[0].length === 0 || hours[0].length > 2 || minutes[0].length > 2) {
		return null;
	}

	if (hours[0].length === 1) hours[0] = '0' + hours[0]; // '2' → '02:00'
	if (minutes[0].length === 0) minutes[0] = '00'; // '1:' → '1:00'
	if (minutes[0].length === 1) minutes[0] = minutes[0] + '0'; // '2:3' → '02:30'

	// Counts only first letter
	const firstLetter = parts[1]
		?.replace(/[^a-zA-Z]+/g, '') // Keep letters only
		?.toUpperCase()
		?.substring(0, 1);

	const ampm = firstLetter === 'A' ? 'AM' : firstLetter === 'P' ? 'PM' : '';

	return [hours[0], minutes[0], ampm];
};

// Parses "1245ab" in ['12', '45', 'AM'], "1234cd" in ['12', '45', '']
export const parsePhrase = (input: string): null | string[] => {
	if (!isValidInput(input)) {
		return null;
	}

	const numbersPattern = /\d+/g;
	const digits = spaceless(input)?.match(numbersPattern);

	// Digits must go in one piece and in first occurence
	if (digits?.length !== 1 || input.indexOf(digits[0]) !== 0) {
		return null;
	}

	const parsedDigits = parseDigits(digits[0]);
	if (!parsedDigits) return null; // Reject impossible digits
	const [hours, minutes] = parsedDigits;

	const firstLetter = input
		?.replace(/[^a-zA-Z]+/g, '') // Keeping letters only
		?.toUpperCase()
		?.substring(0, 1);

	const ampm = firstLetter === 'A' ? 'AM' : firstLetter === 'P' ? 'PM' : null;

	return [hours, minutes, ampm];
};

// Only digits, letters, colon and spaces are allowed
export const isValidInput = (input: string) => {
	const numbersPattern = /\d+/g;

	// Must have at least one digit
	if (!numbersPattern.test(input)) {
		return false;
	}

	// No more than 5 digits allowed (reject "123456")
	if (input.match(numbersPattern).join('').length > 5) {
		return false;
	}

	const lettersOnly = input.replace(/[^a-zA-Z]+/g, '');
	// No more than two letters allowed
	if (lettersOnly.length > 2) {
		return false;
	}

	if (input.includes(':')) {
		const colonedParts = input.split(':');
		const lettersPattern = /[a-zA-Z]+/g;
		// No letters allowed in hours part, e.g. "12a:00"
		if (lettersPattern.test(colonedParts[0])) {
			return false;
		}
	}

	// Looking for illegal characters
	const remainder = input
		.replace(/[a-zA-Z]+/g, '') // Removing all letters
		.replace(/\d+/g, '') // Removing digits
		.replace(/ /g, '') // Removing spaces
		.replace(/:/, ''); // Only one colon allowed

	return remainder.length === 0;
};
