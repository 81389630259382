import React, { FC } from 'react';
import classnames from 'classnames';
import { wrapWithComponent } from '../../utilities/wrapWithComponent';

import { CardSection } from './CardSection';

/**
 * Card properties
 */
export interface CardPropsStrict {
	/** Shorthand for status="active" */
	active?: boolean;

	/** Shorthand for elevation="base" */
	base?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Dark background, light text */
	dark?: boolean;

	/** Disabled state. Card looks unclickable with fading about 60% */
	disabled?: boolean;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Shorthand for status="error" */
	error?: boolean;

	/** Set elevation of the card */
	elevation?: 'base' | 'flat' | 'raised';

	/** If passed a href string, <Card> gets a hover state and turns into an <a> tag */
	href?: string;

	/** Adds visual effect on mouse over */
	hoverable?: boolean;

	/** Light-gray background */
	light?: boolean;

	/** Shorthand for elevation="raised" */
	raised?: boolean;

	/** Makes corners less rounded */
	sharp?: boolean;

	/** Shorthand for padding="thin" */
	thin?: boolean;

	/** Padding options for card */
	padding?: 'none' | 'thin' | 'default';

	/** Makes dashed border and light-blue background on mouse over */
	placeholder?: boolean;

	/** Status of Card */
	status?: 'default' | 'active' | 'error';
}

export interface CardProps extends CardPropsStrict {
	[propName: string]: any;
}

export interface Card extends FC<CardProps> {
	/** Subcomponents */
	Section: typeof CardSection;
}

export const Card: Card = ({
	active,
	base,
	children,
	className,
	dark,
	disabled,
	href,
	hoverable,
	el = 'div',
	elevation = 'flat',
	error,
	light,
	padding = 'default',
	placeholder,
	raised,
	thin,
	sharp,
	status,
	...rest
}) => {
	const getElevation = () => {
		if (base) return 'base';
		if (raised) return 'raised';
		return elevation;
	};

	const getPadding = () => {
		if (thin) return 'thin';
		return padding;
	};

	const getStatus = () => {
		if (error) return 'error';
		if (active) return 'active';
		return status;
	};

	const CardClasses = classnames('Card', className, {
		'Card--base': getElevation() === 'base',
		'Card--raised': getElevation() === 'raised',
		'Card--thin': getPadding() === 'thin',
		'Card--no-padding': getPadding() === 'none',
		'Card--active': getStatus() === 'active',
		'Card--error': getStatus() === 'error',
		'Card--sharp': sharp,
		'Card--hoverable': hoverable || href || el === 'a' || rest.onClick,
		'Card--disabled': disabled,
		'Card--placeholder': placeholder,
		'Card--dark': dark,
		'Card--light': light,
	});

	const CardElement = href ? 'a' : el;

	return (
		<CardElement
			className={CardClasses}
			href={href}
			data-anvil-component="Card"
			{...rest}
		>
			{wrapWithComponent(children, CardSection)}
		</CardElement>
	);
};

Card.Section = CardSection;
