import React, { FC } from 'react';
import classnames from 'classnames';

import { columnsToNumeral } from '../../utilities/SemanticUtils';
import {
	GridColumnsCount,
	ViewportWidthSegment,
	GridTextAlign,
	GridVerticalAlign,
} from './Grid';

/**
 * A row sub-component for Grid
 */
export interface GridRowPropsStrict {
	/** A row can have its columns centered. */
	centered?: boolean;

	/** Adds one or more classnames to an element */
	className?: string;

	/** Represents column count per line in the row */
	columns?: GridColumnsCount;

	/** A row can have dividers between its columns */
	divided?: boolean;

	/** Set the wrapping element's tagname */
	el?: any;

	/** A column can appear only for a specific device or screen sizes */
	only?: ViewportWidthSegment;

	/** A row can specify that its columns should reverse order per device, e.g. reversed="mobile tablet vertically" */
	reversed?: string;

	/** A row can stretch its contents to take up the entire column height */
	stretched?: boolean;

	/** Alignment of content */
	textAlign?: GridTextAlign;

	/** Vertical alignment of content */
	verticalAlign?: GridVerticalAlign;
}
export interface GridRowProps extends GridRowPropsStrict {
	[propName: string]: any;
}

export const GridRow: FC<GridRowProps> = ({
	centered,
	children,
	className,
	el: TagName = 'div',
	columns,
	divided,
	only,
	reversed,
	stretched,
	textAlign,
	verticalAlign,
	...props
}) => {
	// Composing classes out of "reversed" prop
	const reversedSet = new Set(
		typeof reversed === 'string'
			? reversed.trim().split(' ').filter(Boolean)
			: []
	);
	const reversedClasses = ['mobile', 'tablet', 'desktop']
		.filter((device) => reversedSet.has(device))
		.map((device) =>
			reversedSet.has('vertically')
				? `GridRow--${device}-vertically-reversed`
				: `GridRow--${device}-reversed`
		);

	const GridRowClasses = classnames('GridRow', className, reversedClasses, {
		'GridRow--centered': centered,
		[`GridRow--${columnsToNumeral(columns)}-columns`]:
			columnsToNumeral(columns),
		'GridRow--equal-width': columns === 'equal',
		'GridRow--divided': divided,
		[`GridRow--${only}-only`]: only,
		'GridRow--stretched': stretched,
		[`GridRow--text-align-${textAlign}`]: textAlign,
		[`GridRow--vertical-align-${verticalAlign}`]: verticalAlign,
	});

	return (
		<TagName className={GridRowClasses} {...props}>
			{children}
		</TagName>
	);
};
