import React from 'react';
import { Stack, BodyText, Tag, Tooltip, Link, Button } from '@servicetitan/design-system';
import myData from '../../package.json';
import SimpleIcons from 'simple-icons-react-component';
import { tokens } from "@servicetitan/tokens/core"
import { ScreenSizeContext } from '../context/ScreenSizeContext';

export const Footer = () => {

  const { size } = React.useContext(ScreenSizeContext);
  const largerThanDesktop = size.largerThan.desktop;

  const links = {
    codesandbox: "https://codesandbox.io/s/anvil-sandbox-30-42jrt",
    contribution: "https://servicetitan.quip.com/OhqaA1eYJVjX/Contributing-to-Anvil",
    dribbble: "https://dribbble.com/servicetitan",
    github: "https://github.com/servicetitan/anvil",
    icons: "/foundations/icons",
    medium: "https://medium.com/servicetitan-design",
    startCode: "https://github.com/servicetitan/anvil/blob/master/docs/Usage.md",
    startDesign: "https://servicetitan.quip.com/rGraAaMeZA6Q/Anvil-Design-Library",
    roadmap: "https://servicetitan.quip.com/UYuiAz3kjcj7/Anvil-ServiceTitan-Design-System",
    servicetitan: "https://servicetitan.com"
  };

  return (
    <footer>
      <Stack spacing={3} alignItems="center" className="m-w-100">
        {largerThanDesktop && <BodyText el="h3" bold>Anvil</BodyText>}
        <Tag badge className="m-l-0-i">v{myData.version}</Tag>
        <Link href={links.servicetitan} rel="noopener noreferrer" target="_blank">servicetitan.com</Link>
        <Stack.Item fill>
          </Stack.Item>
          <Stack spacing={1}>
            <Tooltip text="Dribbble">
              <Stack.Item>
                <Link href={links.dribbble} rel="noopener noreferrer" target="_blank">
                  <Button fill='subtle' icon={<SimpleIcons name="Dribbble" color={tokens.colorBlack} />} />
                </Link>
              </Stack.Item>
            </Tooltip>
            <Tooltip text="GitHub">
              <Stack.Item>
                <Link href={links.github} rel="noopener noreferrer" target="_blank">
                  <Button fill='subtle' icon={<SimpleIcons name="GitHub" color={tokens.colorBlack} />} />
                </Link>
              </Stack.Item>
            </Tooltip>
            <Tooltip text="Medium" direction="tl">
              <Stack.Item>
                <Link href={links.medium} rel="noopener noreferrer" target="_blank">
                  <Button fill='subtle' icon={<SimpleIcons name="Medium" color={tokens.colorBlack} />} />
                </Link>
              </Stack.Item>
            </Tooltip>
          </Stack>
      </Stack>
    </footer>
  )
}
