import React, { FC } from 'react';
import classnames from 'classnames';
import { TagGroupItem } from './TagGroupItem';
import { TagProps } from '../Tag';
import { wrapChildren } from '../../utilities/wrapChildren';

type TagGroupChildType =
	| React.ReactElement<TagProps>
	| boolean
	| null
	| undefined;

/**
 * TagGroup properties
 */
export interface TagGroupPropsStrict {
	/** Tag Elements */
	children: TagGroupChildType | TagGroupChildType[];

	/** Adds one or more classnames for an element */
	className?: string;
}

export interface TagGroupProps extends TagGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const TagGroup: FC<TagGroupProps> = ({
	children,
	className,
	...props
}) => {
	const TagGroupClasses = classnames('TagGroup', className);

	return (
		<div
			className={TagGroupClasses}
			data-anvil-component="TagGroup"
			{...props}
		>
			{wrapChildren(children, TagGroupItem)}
		</div>
	);
};
