import React from 'react';

export interface FormFieldContext {
	labelFor?: string;
	inputRef?: React.RefObject<HTMLElement>;
	setFormFieldContext?: React.Dispatch<
		React.SetStateAction<FormFieldContext>
	>;
}

export const FormFieldContext = React.createContext<FormFieldContext>({
	labelFor: undefined,
	inputRef: undefined,
	setFormFieldContext: () => {},
});
FormFieldContext.displayName = 'FormFieldContext';
