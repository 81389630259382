import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Link properties
 */
export interface LinkPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Text color */
	color?: 'negative' | 'red' | 'primary' | 'blue' | 'subdued' | 'grey';

	/** Hypertext reference */
	href?: string;

	/** Negative appearance, red-colored */
	negative?: boolean;

	/** Primary appearance, blue-colored */
	primary?: boolean;

	/** Subdued appearance, grey-colored */
	subdued?: boolean;

	/** Disable link, unclickable */
	disabled?: boolean;
}

export interface LinkProps extends LinkPropsStrict {
	[propName: string]: any;
}

export const Link: FC<LinkProps> = ({
	className,
	color,
	negative,
	primary,
	subdued,
	disabled,
	...props
}) => {
	const LinkElement = props.href ? 'a' : 'button';

	const LinkClasses = classnames('Link', className, {
		'Link--blue': primary || color === 'blue' || color === 'primary',
		'Link--red': negative || color === 'red' || color === 'negative',
		'Link--grey': subdued || color === 'grey' || color === 'subdued',
		'Link--disabled': disabled,
	});

	return (
		<LinkElement
			role="link"
			className={LinkClasses}
			data-anvil-component="Link"
			{...props}
		>
			{props.children}
		</LinkElement>
	);
};
