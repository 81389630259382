import React, { FC } from 'react';
import classnames from 'classnames';
import { Eyebrow } from '../Eyebrow';

/**
 * SideNavItem properties
 */
export interface SideNavGroupPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Add Title to group */
	title?: React.ReactNode;
}

export interface SideNavGroupProps extends SideNavGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const SideNavGroup: FC<SideNavGroupProps> = ({
	children,
	className,
	title,
	...props
}) => {
	const SideNavItemClasses = classnames('SideNavGroup', className);

	return (
		<li
			className={SideNavItemClasses}
			data-anvil-component="SideNavGroup"
			{...props}
		>
			{title !== undefined && (
				<Eyebrow className="SideNavGroup__title" size="medium">
					{title}
				</Eyebrow>
			)}
			<menu className="SideNavGroup__list">{children}</menu>
		</li>
	);
};
