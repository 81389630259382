import React from 'react';

export const useLatestEvent = () => {
	const latestEventRef = React.useRef<MouseEvent | KeyboardEvent | undefined>(
		undefined
	);

	React.useEffect(() => {
		const setMouseEvent = (e: MouseEvent) => {
			if (e.detail < 1) return; // Detect click events produced without pointing device, e.g. pressing Spacebar on button element
			latestEventRef.current = e;
		};

		const setKeyboardEvent = (e: KeyboardEvent) =>
			(latestEventRef.current = e);

		document.addEventListener('keydown', setKeyboardEvent);
		document.addEventListener('click', setMouseEvent);

		return () => {
			document.removeEventListener('keydown', setKeyboardEvent);
			document.removeEventListener('click', setMouseEvent);
		};
	}, []);

	return latestEventRef;
};
