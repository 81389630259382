import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { useScreenSize } from '../../hooks';

export interface ProgressTrackerStepPropsStrict {
	/** Active State */
	active?: boolean;

	/** Adds one or more classnames to an element */
	className?: string;

	/** Completed State */
	completed?: boolean;

	/** Disabled State */
	disabled?: boolean;

	/** Editting State (for previously completed flows) */
	editing?: boolean;

	/** If passed a href string, <ProgressTracker.Step> gets a hover state and turns into an <a> tag */
	href?: string;

	/** The label for the <ProgressTracker.Step> */
	label?: string;

	/** If passed an onClick function, <ProgressTracker.Step> gets a hover state */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** The step number. Automatically set when inside <ProgressTracker> */
	stepNumber?: number;

	hideLabels?: boolean;

	/** Size of the Progress Tracker step. Defaults to medium. */
	size?: 'small' | 'medium';
}

export interface ProgressTrackerStepProps
	extends ProgressTrackerStepPropsStrict {
	[propName: string]: any;
}

export const ProgressTrackerStep: FC<ProgressTrackerStepProps> = ({
	active = false,
	className,
	completed = false,
	disabled,
	editing,
	href,
	label,
	size = 'medium',
	stepNumber,
	hideLabels,
	...props
}) => {
	const screensize = useScreenSize();

	const getStepContent = () => {
		if (size === 'medium')
			return (
				<div className="ProgressTrackerStep__icon">{getBadge()}</div>
			);
		return <div className="ProgressTrackerStep__simple" />;
	};

	const getBadge = () => {
		if (size === 'small') return;
		if (completed) return <Icon name="check_circle" />;
		if (editing) return <Icon name="edit" />;
		return stepNumber;
	};

	const StepElement = href && !disabled ? 'a' : 'div';

	const getLabel = () => {
		if (!label || hideLabels || screensize.smallerThan.tablet) return;
		return <div className="ProgressTrackerStep__label">{label}</div>;
	};

	const getTooltipLabel = () => {
		if (screensize.smallerThan.tablet || hideLabels) return label;
	};

	const ProgressTrackerStepClasses = classnames(
		'ProgressTrackerStep',
		className,
		{
			'ProgressTrackerStep--active': active,
			'ProgressTrackerStep--complete': completed,
			'ProgressTrackerStep--incomplete':
				!active && !completed && !editing,
			'ProgressTrackerStep--clickable':
				href != null || props.onClick != null,
			'ProgressTrackerStep--disabled': disabled,
			'ProgressTrackerStep--editing': editing,
			'ProgressTrackerStep--hideLabels': hideLabels,
			[`ProgressTrackerStep--${size}`]: size,
		}
	);

	return (
		<StepElement
			className={ProgressTrackerStepClasses}
			tabIndex={(props.href || props.onClick) && 0}
			data-anvil-component="ProgressTrackerStep"
			href={!disabled && href}
			{...props}
		>
			<Tooltip text={getTooltipLabel()} portal direction="b" el="div">
				{getStepContent()}
			</Tooltip>
			{getLabel()}
		</StepElement>
	);
};
