import React, { FC, Children, cloneElement } from 'react';
import classnames from 'classnames';
import { BreadcrumbLink, BreadcrumbLinkType } from './BreadcrumbLink';

export type BreadcrumbChildType =
	| BreadcrumbLinkType
	| boolean
	| null
	| undefined;

export interface BreadcrumbPropsStrict {
	children: BreadcrumbChildType[];
}

export interface BreadcrumbProps extends BreadcrumbPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

interface Breadcrumb extends FC<BreadcrumbProps> {
	/** Subcomponents */
	Link: typeof BreadcrumbLink;
}

export const Breadcrumb: Breadcrumb = ({ children, className, ...props }) => {
	const BreadcrumbClasses = classnames('Breadcrumb', className);
	const links = Children.toArray(children).filter((child) => child);

	return (
		<ul
			aria-label="breadcrumbs"
			className={BreadcrumbClasses}
			data-anvil-component="Breadcrumb"
			{...props}
		>
			{links.map((child: any, i) =>
				cloneElement(
					child,
					i === links.length - 1 && !child.props?.['aria-current']
						? { 'aria-current': 'location' }
						: null
				)
			)}
		</ul>
	);
};

Breadcrumb.Link = BreadcrumbLink;
