import React from "react";
import { TagGroup, Tag, Icon } from "@servicetitan/design-system";

export const Tags = ({tags}) => {
	if (!tags) return null;

	return (
		<TagGroup>
		{ tags.map((tag, i) => {
			let color;
			if (tag === 'deprecated') color = 'warning';
			if (tag === 'new') color = 'success';
			if (tag === 'beta') color = 'info';
			if (tag === 'updated') color = 'default'

			if (tag.startsWith('icon__')) {
				const iconName = tag.split('__')[1];
				return <Icon name={iconName} size={18} />
			}

			return (
				<Tag
					className="tt-capitalize"
					key={i}
					subtle={color && color !== 'default'}
					compact
					color={color}
				>
					{tag}
				</Tag>
			);
		})}
		</TagGroup>
	);
};
