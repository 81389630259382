import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classnames from 'classnames';
import { tokens } from '@servicetitan/tokens/core';
import { Button, Headline } from '../';
import { Overlay, OverlayProps } from '../../internal/Overlay';
import { JustifyProps } from '../../utilities/justify-props';
import { useFocusTrap, UseFocusTrapProps } from '../../hooks';

interface DrawerPropsStrict extends OverlayProps {
	/** Use animation for visibility transitions */
	animate?: boolean;

	children?: React.ReactNode;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Footer Element */
	footer?: React.ReactElement;

	/** Footer button alignment */
	footerAlign?: JustifyProps;

	/** Drawer Header */
	header?: React.ReactElement | string;

	/** Visibility of Drawer */
	open?: boolean;

	/** Width of the Drawer */
	width?: 'xs' | 's' | 'm' | 'l';

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;

	/** Adds classnames to Drawer's content wrapper */
	contentClassName?: string;

	/** Adds classnames to Drawer's footer wrapper */
	footerClassName?: string;
}

export interface DrawerProps extends DrawerPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Drawer: FC<DrawerProps> = ({
	animate = true,
	children,
	className,
	contentClassName,
	footerClassName,
	footer,
	footerAlign = 'right',
	header,
	onClose,
	open,
	portal = true,
	backdrop,
	width = 's',
	focusTrapOptions,
	...rest
}) => {
	// Focus trapping
	const drawerRef = useFocusTrap({
		autoFocusContainer: true,
		disabled: !open,
		...focusTrapOptions,
	});

	const DrawerClasses = classnames('Drawer', className, {
		'Drawer--open': open,
		'Drawer--portal': portal || backdrop,
		'Drawer--close': !open,
		[`Drawer--w-${width}`]: width,
	});

	const DrawerContentClassNames = classnames(
		'Drawer__content',
		contentClassName
	);

	const DrawerFooterClassNames = classnames(
		'Drawer__footer',
		footerClassName,
		{
			'justify-content-start': footerAlign === 'left',
			'justify-content-center': footerAlign === 'center',
			'justify-content-end': footerAlign === 'right',
			'justify-content-between': footerAlign === 'space-between',
		}
	);

	const durationSlow: number = parseInt(tokens.durationSlow, 10) / 1000; // '300ms' -> 0.3
	const framerAnimation = {
		initial: { opacity: 0, x: '100%' },
		animate: { opacity: 1, x: 0 },
		exit: { opacity: 0, x: '100%' },
		transition: { type: 'easeInOut', duration: durationSlow },
	};

	const DrawerEl = animate ? motion.div : 'div';
	const DrawerProps = {
		className: DrawerClasses,
		ref: drawerRef,
		...(animate && framerAnimation),
		...rest,
	};

	return (
		<Overlay
			animate={animate}
			portal={portal}
			backdrop={backdrop}
			onClose={onClose}
			open={open}
		>
			<AnimatePresence>
				{open && (
					<DrawerEl data-anvil-component="Drawer" {...DrawerProps}>
						{header && (
							<div className="Drawer__header">
								<Headline className="Drawer__header-title">
									{header}
								</Headline>
								{onClose && (
									<Button
										aria-label="close drawer"
										fill="subtle"
										className="Drawer__header-close"
										iconName="clear"
										size="xsmall"
										onClick={onClose}
									/>
								)}
							</div>
						)}
						<div className={DrawerContentClassNames}>
							{children}
						</div>
						{footer && (
							<div className={DrawerFooterClassNames}>
								{footer}
							</div>
						)}
					</DrawerEl>
				)}
			</AnimatePresence>
		</Overlay>
	);
};
