import React, { FC } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';

export interface AnnouncementPropsStrict {
	/** Name of the action shown to users */
	actionName?: string;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Function that fires when the user clicks on the action */
	onActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks the close icon */
	onClose?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Determines the visual appearance and icon of the banner */
	status?: 'info' | 'warning' | 'critical';

	/** The message displayed to the user */
	title: string;
}

export interface AnnouncementProps extends AnnouncementPropsStrict {
	[propName: string]: any;
}

export const Announcement: FC<AnnouncementProps> = ({
	actionName,
	className,
	onActionClick,
	onClose,
	status = 'info',
	title,
	...props
}) => {
	const critical = status === 'critical';
	const warning = status === 'warning';
	const info = status === 'info';

	const AnnouncementClasses = classnames('Announcement', className, {
		'Announcement--critical': critical,
		'Announcement--warning': warning,
		'Announcement--info': info,
	});

	return (
		<div
			className={AnnouncementClasses}
			data-anvil-component="Announcement"
			role="status"
			{...props}
		>
			<div className="Announcement__body">
				<div className="Announcement__title">{title}</div>
				{actionName && onActionClick && (
					<Button
						small
						className="Announcement__action"
						onClick={onActionClick}
					>
						{actionName}
					</Button>
				)}
			</div>
			{onClose && (
				<Button
					aria-label="close announcement"
					fill={warning ? 'subtle' : 'solid'}
					color={info ? 'blue' : critical ? 'red' : null}
					className="Announcement__close"
					iconName="clear"
					size="xsmall"
					onClick={onClose}
				/>
			)}
		</div>
	);
};
