import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';

interface DropdownIconProps {
	className?: string;
}

export const DropdownIcon: FC<DropdownIconProps> = ({
	className,
	...props
}) => {
	const DropdownIconClasses = classnames('icon', 'dropdown', className);

	return (
		<Icon
			className={DropdownIconClasses}
			el="i"
			name="expand_more"
			{...props}
		/>
	);
};
