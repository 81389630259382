import React, { FC } from 'react';
import classnames from 'classnames';
import { useScreenSize } from '../../hooks/useScreenSize';

import { Headline } from '../Headline';
import { BackLink } from '../BackLink';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';

export interface TakeoverHeaderPropsStrict {
	/** Content for Action area next to close button */
	actions?: React.ReactElement;

	/** Adds one or more classnames to an element */
	backLabel: string;

	/** Adds one or more classnames to an element */
	className?: string;

	/** If passed an onBack function, <Takeover.Header> gets a Back button in the top left */
	onBack?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** If passed an onClose function, <Takeover.Header> gets a Close button in the top right */
	onClose?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Theme of Takeover */
	theme?: 'dark' | 'light';

	/** Name of Takeover */
	title: string | React.ReactElement;
}

export interface TakeoverHeaderProps extends TakeoverHeaderPropsStrict {
	[propName: string]: any;
}

export const TakeoverHeader: FC<TakeoverHeaderProps> = ({
	actions,
	backLabel,
	className,
	onBack,
	onClose,
	theme,
	title,
	...props
}) => {
	const TakeoverHeaderClasses = classnames(
		'TakeoverHeader',
		className,
		`TakeoverHeader--${theme}`
	);

	const screensize = useScreenSize();

	const getBackLabel = () => {
		if (!onBack) return;
		if (screensize.smallerThan.tablet) {
			return (
				<Tooltip
					text={backLabel}
					portal
					direction="br"
					className="c-inherit"
				>
					<Button
						fill="subtle"
						iconName="chevron_left"
						size="small"
						className="TakeoverHeader__close"
						aria-label={backLabel}
						onClick={onBack}
					/>
				</Tooltip>
			);
		}
		return (
			<BackLink
				className="TakeoverHeader__back-link"
				pageLabel={backLabel}
				onClick={onBack}
			/>
		);
	};

	return (
		<div className={TakeoverHeaderClasses} {...props}>
			<div className="TakeoverHeader__left">{getBackLabel()}</div>
			{React.isValidElement(title) ? (
				title
			) : (
				<Headline className="TakeoverHeader__title">{title}</Headline>
			)}
			<div className="TakeoverHeader__right">
				{actions && (
					<div className="TakeoverHeader__actions">{actions}</div>
				)}
				{onClose && (
					<Button
						aria-label="close takeover"
						fill="subtle"
						className="TakeoverHeader__close"
						iconName="clear"
						size="xsmall"
						onClick={onClose}
					/>
				)}
			</div>
		</div>
	);
};

TakeoverHeader.defaultProps = {
	theme: 'dark',
};
