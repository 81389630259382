import React, { FC } from 'react';
import { TagProps } from '../Tag';

export interface TagGroupItemProps {
	children: React.ReactElement<TagProps>;
}

export const TagGroupItem: FC<TagGroupItemProps> = ({ children }) => (
	<div className="TagGroup__Item" data-anvil-component="TagGroupItem">
		{children}
	</div>
);
