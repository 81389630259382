import React, { FC } from 'react';
import { focusReducer, getInitialState, FocusContext } from '../utils';
import {
	AnvilSelectSearchProps,
	AnvilSelectHeaderProps,
	AnvilSelectOptionsProps,
} from './';

interface FocusProviderProps {
	children?: React.ReactNode;
	search?: AnvilSelectSearchProps;
	header?: AnvilSelectHeaderProps;
	options?: AnvilSelectOptionsProps[];
	preventFocus?: boolean;
}

export const FocusProvider: FC<FocusProviderProps> = ({
	children,
	search,
	header,
	options,
	preventFocus,
}) => {
	const initialState = getInitialState(search, header, options, preventFocus);
	const [state, dispatch] = React.useReducer(focusReducer, initialState);

	return (
		<FocusContext.Provider
			value={{ focusState: state, focusDispatch: dispatch }}
		>
			{children}
		</FocusContext.Provider>
	);
};
