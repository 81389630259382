import React, { FC, useContext } from 'react';
import { usePopper } from 'react-popper';
import { StrictModifiers, Placement } from '@popperjs/core';
import { useFocusTrap, UseFocusTrapProps } from '../../hooks/useFocusTrap';
import {
	OverlayContext,
	calculateZindex,
} from '../../utilities/OverlayContext';
import { Portal } from '../Portal';
import classnames from 'classnames/dedupe';
import { useMergedRef } from '../../hooks/useMergedRef';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';

export interface DataAttributeProps {
	[key: `data-${string}`]: string;
}

export interface PopperPropsStrict {
	/**
	 * *Caution* This can make unexpected experiences for small screens.
	 * *Warning* Only works when portal={true}
	 * If there isn't enough space on the screen the Popover will be repositioned to the opposite side of the anchor
	 */
	autoFlipHorizontally?: boolean;

	/**
	 * *Warning* Only works when portal={true}
	 * If there isn't enough space on the screen the Popover will be repositioned to the opposite side of the anchor
	 */
	autoFlipVertically?: boolean;

	/** Adds one or more classnames for Popover's overlay element */
	className?: string;

	/** Popover direction relative to the element its apart of */
	direction?:
		| 't'
		| 'tr'
		| 'tl'
		| 'r'
		| 'rt'
		| 'rb'
		| 'b'
		| 'br'
		| 'bl'
		| 'l'
		| 'lt'
		| 'lb'
		| 'c';

	/** Change the HTML element of the Popover's source element */
	el?: 'span' | 'div';

	/** Whether the Popper is open or not */
	open?: boolean;

	/**
	 * *Warning* Only works when portal={true}
	 * A handler that is called on every click on any element outside of the anchor element and the stick node
	 */
	onClickOutside?: (event?: React.MouseEvent<HTMLElement>) => void;

	/**
	 * Renders Popper on document root through createPortal.
	 * Effectively prevents Popper from being clipped by the boundaries of any container.
	 */
	portal?: boolean;

	/** Element wrapped around Popover */
	trigger?: React.ReactNode;

	/** Define the width property of the popover */
	width?: number | '100' | 'auto';

	/** Adds one or more classnames to Popover's body element */
	bodyClassName?: string;

	/** Adds one or more classname to Popper's outer element */
	popperClassName?: string;

	/** If true, caret will be available for Popover's body */
	caret?: caretProps;

	/** props to control animation of motion component */
	animation?: MotionProps;

	/** offset ammount between popper and reference */
	offset?: offset;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;

	/** data-* attributes passed to the portaled div */
	portalDataAttributes?: DataAttributeProps;
}

export interface offset {
	/** Displaces the popper along the reference element */
	skidding?: number;

	/** Displaces the popper away from, or toward, the reference element in the direction of its placement */
	distance?: number;
}

interface caretProps {
	/** Adds caret arrow */
	show?: boolean;

	/** Adds one or more classnames to Caret element */
	className?: string;
}

export interface PopperProps extends PopperPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Popper: FC<PopperProps> = React.forwardRef(
	(
		{
			autoFlipHorizontally = false,
			autoFlipVertically = false,
			'el': PopperElement = 'div',
			width = 'auto',
			direction = 't',
			portal = false,
			children,
			className,
			onClickOutside,
			open,
			style = {},
			trigger,
			bodyClassName,
			popperClassName,
			caret = { show: false },
			animation,
			offset = { skidding: 0, distance: 0 },
			focusTrapOptions,
			portalDataAttributes,
			'data-anvil-component': dataAnvilComponent = 'Popper',
			...props
		},
		ref: React.RefObject<any>
	) => {
		const [referenceElement, setReferenceElement] = React.useState(null);
		const [popperElement, setPopperElement] = React.useState(null);
		const localRef = React.useRef(null);
		const popperRef: React.RefObject<any> = useMergedRef(ref, localRef);
		const [arrowElement, setArrowElement] = React.useState(null);
		const [scrollPosition, setScrollPosition] = React.useState(0);
		const context = useContext(OverlayContext);

		// Focus trapping
		const focusTrapRef = useFocusTrap({
			autoFocusContainer: true,
			disabled: !open,
			...focusTrapOptions,
		});

		const widthObserver = React.useMemo(
			() => ({
				name: 'widthObserver',
				enabled: width !== '100',
				phase: 'beforeWrite',
				requires: ['computeStyles'],
				fn: ({ state }: any) => {
					if (typeof width === 'string') {
						state.styles.popper.width = `${width}px`;
					}
					state.styles.popper.width = width;
				},
				effect: ({ instance }: any) => {
					Promise.resolve().then(() => instance.forceUpdate());
				},
			}),
			[width]
		);

		const sameWidth = React.useMemo(
			() => ({
				name: 'sameWidth',
				enabled: width === '100',
				phase: 'beforeWrite',
				requires: ['computeStyles'],
				fn: ({ state }: any) => {
					state.styles.popper.width = `${state.rects.reference.width}px`;
				},
				effect: ({ state }: any) => {
					state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
				},
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[width, popperRef.current?.getBoundingClientRect().width]
		);

		const observeReferenceModifier = React.useMemo(
			() => ({
				name: 'observeReferenceModifier',
				enabled: true,
				phase: 'main',
				fn: () => {},
				effect: ({ state, instance }: any) => {
					const RO_PROP = '__popperjsRO__';
					const { reference } = state.elements;

					reference[RO_PROP] = new ResizeObserver(() => {
						typeof window !== 'undefined' &&
							window.requestAnimationFrame(() => {
								instance.update();
							});
					});

					reference[RO_PROP].observe(reference);

					return () => {
						reference[RO_PROP].disconnect();
						delete reference[RO_PROP];
					};
				},
			}),
			[]
		);

		const sizeObserver = React.useMemo(
			() => ({
				name: 'sizeObserver',
				enabled:
					(autoFlipHorizontally || autoFlipVertically) &&
					direction !== 'c',
				phase: 'main',
				fn: () => {},
				effect: ({ state, instance }: any) => {
					const RO_PROP = '__popperjsPO__';
					const { popper } = state.elements;

					popper[RO_PROP] = new ResizeObserver(() =>
						// Popper resize handling
						window?.requestAnimationFrame(() => instance.update())
					);
					popper[RO_PROP].observe(popper);

					return () => {
						popper[RO_PROP].disconnect();
						delete popper[RO_PROP];
					};
				},
			}),
			[autoFlipHorizontally, autoFlipVertically, direction]
		);

		const centerPlacement = React.useMemo(
			() => ({
				name: 'offset',
				enabled: true,
				options: {
					offset: ({ reference, popper }: any) => {
						if (direction === 'c') {
							const offsetY =
								-(reference.height / 2) + -(popper.height / 2);
							return [0, offsetY];
						}
						if (caret.show) {
							return [0, 8];
						}
						return [offset.skidding ?? 0, offset.distance ?? 4];
					},
				},
			}),
			[direction, caret.show, offset.skidding, offset.distance]
		);

		const directionMapping = () => {
			switch (direction) {
				case 't':
					return 'top';
				case 'tr':
					return 'top-start';
				case 'tl':
					return 'top-end';
				case 'r':
					return 'right';
				case 'rt':
					return 'right-end';
				case 'rb':
					return 'right-start';
				case 'b':
				case 'c':
					return 'bottom';
				case 'br':
					return 'bottom-start';
				case 'bl':
					return 'bottom-end';
				case 'l':
					return 'left';
				case 'lt':
					return 'left-end';
				case 'lb':
					return 'left-start';
				default:
					return 'bottom-end';
			}
		};

		const fallbackPlacements = () => {
			if (autoFlipHorizontally) {
				switch (direction) {
					case 'r':
						return ['left'];
					case 'rb':
						return ['left-start'];
					case 'rt':
						return ['left-end'];
					case 'l':
						return ['right'];
					case 'lb':
						return ['right-start'];
					case 'lt':
						return ['right-end'];
					default:
						break;
				}
			}

			if (autoFlipVertically) {
				switch (direction) {
					case 't':
						return ['bottom'];
					case 'tr':
						return ['bottom-start'];
					case 'tl':
						return ['bottom-end'];
					case 'b':
						return ['top'];
					case 'br':
						return ['top-start'];
					case 'bl':
						return ['top-end'];
					default:
						break;
				}
			}

			return [];
		};

		const { styles, attributes, forceUpdate } = usePopper(
			referenceElement,
			popperElement,
			{
				modifiers: [
					centerPlacement as StrictModifiers,
					widthObserver as StrictModifiers,
					sameWidth as StrictModifiers,
					observeReferenceModifier as StrictModifiers,
					sizeObserver as StrictModifiers,
					{
						name: 'flip',
						enabled:
							(autoFlipHorizontally || autoFlipVertically) &&
							direction !== 'c',
						options: {
							fallbackPlacements:
								fallbackPlacements() as Placement[],
						},
					},
					{
						name: 'arrow',
						enabled: caret.show,
						options: { element: arrowElement, padding: 8 },
					},
				],
				placement: directionMapping(),
			}
		);

		if (
			typeof window !== 'undefined' &&
			window.scrollY !== 0 &&
			scrollPosition === 0
		)
			setScrollPosition(window.scrollY);

		React.useEffect(() => {
			scrollPosition !== 0 && window.scrollTo(0, scrollPosition);
		}, [scrollPosition]);

		React.useEffect(() => {
			Promise.resolve().then(forceUpdate);
		}, [forceUpdate, direction, scrollPosition]);

		React.useEffect(() => {
			function handleClickOutside(event: any) {
				if (!open) return;

				//  Target is not part of wrapper or element
				const target = event.composedPath?.()[0] ?? event.target;

				// Suppress onClickOutside() if target is inside
				if (
					popperRef?.current?.contains(target) ||
					popperElement?.contains(target)
				) {
					return;
				}

				const hasNestedPopover = popperElement?.querySelector(
					'[data-portal-popover="open"]'
				);

				/*
				 * Suppress onClickOutside() if opened & portaled Popover has been found
				 * inside Popper, so only latest Popover to react on click.
				 */
				if (hasNestedPopover) return;

				onClickOutside?.(event);
			}

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [open, onClickOutside, popperElement, popperRef, className]);

		const animations = {
			initial: { opacity: 0 },
			animate: { opacity: 1 },
			exit: { opacity: 0 },
			transition: {
				type: 'easeInOut',
				duration: 0.3,
			},
			...animation,
		};

		return (
			<PopperElement
				ref={popperRef}
				className={classnames(className)}
				style={{ ...style }}
				{...props}
			>
				<div
					style={{
						display: PopperElement === 'span' && 'inline-block',
					}}
					ref={setReferenceElement}
				>
					{trigger}
				</div>
				<Portal conditional={portal}>
					<OverlayContext.Provider value={context.concat('Popper')}>
						{open && (
							<div
								ref={setPopperElement}
								className={classnames(
									'Popper',
									popperClassName
								)}
								style={{
									...styles.popper,
									zIndex: calculateZindex(context) + 1,
								}}
								data-anvil-component={dataAnvilComponent}
								{...portalDataAttributes}
								{...attributes.popper}
							>
								<AnimatePresence>
									<motion.div
										{...animations}
										className={classnames(
											'Popper__body',
											bodyClassName
										)}
										ref={focusTrapRef}
									>
										{children}
										{caret.show && (
											<div
												ref={setArrowElement}
												className={classnames(
													'Popper__caret',
													caret.className
												)}
												style={styles.arrow}
												{...attributes.popper}
											/>
										)}
									</motion.div>
								</AnimatePresence>
							</div>
						)}
					</OverlayContext.Provider>
				</Portal>
			</PopperElement>
		);
	}
);
