import React, { forwardRef, cloneElement } from 'react';
import classNames from 'classnames';
import { GridMod } from './GridMod';
import {
	GridProps,
	GridColumn,
	GridColumnProps,
	GridRowProps,
	GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { TableLoadingPanel } from './TableLoadingPanel';
import { useTableMinWidth } from './useTableMinWidth';

function isColumnElement(
	element: React.ReactNode
): element is React.ReactElement<GridColumnProps> {
	if (!React.isValidElement(element)) {
		return false;
	}

	const elementType = (element as React.ReactElement).type;

	return (
		elementType === GridColumn ||
		(elementType as any).originalType === GridColumn
	);
}

function traverse(
	children: React.ReactNode,
	callback: (
		column: React.ReactElement<GridColumnProps>
	) => React.ReactElement<GridColumnProps>
): React.ReactNode {
	return React.Children.map(children, (child) => {
		if (!isColumnElement(child)) {
			return child;
		}

		return child.props.children
			? cloneElement(callback(child), {
					children: traverse(
						child.props.children as any,
						callback
					) as typeof child.props.children,
			  })
			: callback(child);
	});
}

export interface TableProps extends GridProps {
	loading?: boolean;
	striped?: boolean;
	borders?: { vertical?: boolean; horizontal?: boolean };
	children?: React.ReactNode;
}

export const Table = forwardRef<HTMLElement, TableProps>(
	(
		{
			loading,
			striped = true,
			borders,
			rowRender: originRowRender,
			className,
			children: originChildren,
			...props
		},
		ref
	) => {
		const [container, setContainer] = React.useState<HTMLElement>();

		const handleRef = React.useCallback(
			(loader: HTMLDivElement | null) => {
				const container =
					(loader?.previousElementSibling as HTMLElement) ?? null;

				setContainer(container ?? undefined);

				if (ref) {
					if (typeof ref === 'function') {
						ref(container);
					} else {
						Object.assign(ref, {
							current: container,
						});
					}
				}
			},
			[ref]
		);

		const rowRender = React.useCallback(
			(originRow: React.ReactElement, rowProps: GridRowProps) => {
				const overrides: Partial<typeof originRow.props> = {};

				if (props.detail && rowProps.rowType === 'groupHeader') {
					overrides.children = React.Children.map(
						originRow.props.children,
						(child) =>
							cloneElement(child, {
								columnsCount: child.props.columnsCount - 1,
							})
					);
				}

				const row =
					Object.keys(overrides).length > 0
						? cloneElement(originRow, overrides)
						: originRow;

				return originRowRender ? originRowRender(row, rowProps) : row;
			},
			[originRowRender, props.detail]
		);

		const children = React.useMemo(() => {
			return traverse(originChildren, (column) => {
				const { field, groupable, headerClassName } = column.props;

				const isFilterActive =
					field && GridColumnMenuFilter.active(field, props.filter);
				const isGroupable =
					groupable &&
					!(props.group || []).find((g) => g.field === field);

				return cloneElement(column, {
					groupable: isGroupable,
					headerClassName: classNames(
						headerClassName,
						column.props.columnMenu && 'TableColumn--has-menu',
						isFilterActive && 'TableColumn--filter-active'
					),
				});
			});
		}, [originChildren, props.filter, props.group]);

		const {
			vertical: verticalBorders,
			horizontal: horizontalBorders = !striped,
		} = borders || {};

		const TableClasses = classNames(
			'Table',
			striped && 'Table--striped',
			typeof verticalBorders === 'boolean'
				? verticalBorders && 'Table--vertical-borders'
				: 'Table--locked-borders',
			horizontalBorders && 'Table--horizontal-borders',
			props.detail && 'Table--master-detail',
			props.sortable && 'Table--sortable',
			className
		);

		// Safari-only fix
		useTableMinWidth({ tableContainer: container });

		return (
			<React.Fragment>
				<GridMod
					data-anvil-component="Table"
					{...props}
					rowRender={rowRender}
					className={TableClasses}
				>
					{children}
				</GridMod>
				<div ref={handleRef}>
					{loading && <TableLoadingPanel portal={container} />}
				</div>
			</React.Fragment>
		);
	}
);

Table.displayName = 'KendoReactGrid';
