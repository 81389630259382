import React, { FC } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { Icon } from '../Icon';

/**
 * Link properties
 */
export interface BannerPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Whether to display an icon. Icon itself determined by status. */
	icon?: boolean;

	/** Text for the primary button */
	primaryActionName?: string;

	/** Function that fires when the user clicks on the primary button/action */
	onPrimaryActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks on the secondary button/action */
	onSecondaryActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks the close icon */
	onClose?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Text for the secondary button */
	secondaryActionName?: string;

	/** Determines the visual appearance and icon of the banner */
	status?: 'success' | 'info' | 'warning' | 'critical';

	/** Top text of the banner */
	title: string;
}

export interface BannerProps extends BannerPropsStrict {
	[propName: string]: any;
}

export const Banner: FC<BannerProps> = ({
	primaryActionName,
	secondaryActionName,
	children,
	className,
	onPrimaryActionClick,
	onSecondaryActionClick,
	onClose,
	icon,
	status = 'info',
	title,
	...props
}) => {
	const getIconType = () => {
		switch (status) {
			case 'critical':
				return 'warning';
			case 'warning':
				return 'warning';
			case 'success':
				return 'check_circle';
			default:
				return 'info';
		}
	};

	const hasPrimaryAction = () => {
		return !!(primaryActionName && onPrimaryActionClick);
	};

	const hasSecondaryAction = () => {
		return !!(secondaryActionName && onSecondaryActionClick);
	};

	const BannerClasses = classnames('Banner', className, {
		'Banner--critical': status === 'critical',
		'Banner--warning': status === 'warning',
		'Banner--success': status === 'success',
		'Banner--info': status === 'info',
		'Banner--compact': children === undefined,
	});

	return (
		<div className={BannerClasses} data-anvil-component="Banner" {...props}>
			{icon && <Icon className="Banner__icon" name={getIconType()} />}
			<div className="Banner__body">
				<div className="Banner__text">
					<div className="Banner__title">{title}</div>
					{children}
				</div>
				{(primaryActionName || secondaryActionName) && (
					<div className="Banner__action">
						{hasPrimaryAction() && (
							<Button
								outline
								small
								onClick={onPrimaryActionClick}
							>
								{primaryActionName}
							</Button>
						)}
						{hasSecondaryAction() && (
							<Button
								fill="subtle"
								small
								onClick={onSecondaryActionClick}
							>
								{secondaryActionName}
							</Button>
						)}
					</div>
				)}
			</div>
			{onClose && (
				<Icon
					aria-label="close banner"
					className="Banner__close"
					onClick={onClose}
					name="clear"
				/>
			)}
		</div>
	);
};
