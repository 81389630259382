import React, { FC } from 'react';
import classnames from 'classnames';

export interface PanelProps {
	/** Content of the toolbar */
	content?: React.ReactElement;

	/** Position of the panel on the Page */
	position?: 'left' | 'right';

	/** Adds one or more classnames for an element */
	className?: string;

	/** If false, panel is hidden */
	open?: boolean;

	/** If true, it will remove initial padding of the container */
	noPadding?: boolean;

	style?: React.CSSProperties;
}

export const Panel: FC<PanelProps> = ({
	content,
	position,
	className,
	noPadding,
	style,
	open = true,
}) => {
	const contentClasses = classnames('Panel__content', className, {
		'Panel__content--noPadding': noPadding,
	});

	const panelClasses = classnames('Panel', className, {
		'Panel--left': position === 'left',
		'Panel--open': open,
	});

	return (
		<div
			style={{ width: open && (style?.width ?? '340px') }}
			className={panelClasses}
		>
			<div
				style={{ width: style?.width ?? '340px' }}
				className={contentClasses}
			>
				{content}
			</div>
		</div>
	);
};
