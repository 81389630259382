import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * LayoutSection properties
 */
export interface LayoutSectionProps {
	/** Adds one or more classnames for an element */
	className?: string;

	[propName: string]: any;
}

export const LayoutSection: FC<LayoutSectionProps> = ({
	className,
	...props
}) => {
	const LayoutSectionClasses = classnames('Layout__section', className);

	return (
		<div
			className={LayoutSectionClasses}
			data-anvil-component="LayoutSection"
			{...props}
		/>
	);
};

LayoutSection.displayName = 'LayoutSection';
