import React, { FC } from 'react';
import classnames from 'classnames';
import { BodyText } from '../BodyText';

/**
 * StatusLight properties
 */
export interface StatusLightPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Disabled StatusLight, unclickable */
	disabled?: boolean;

	/** Shortcut for full width StatusLight */
	full?: boolean;

	/** Shortcut for reverse order */
	reverse?: boolean;

	/** Shortcut for justify-content:spacebetween */
	split?: boolean;

	/** Shortcut for pulsing ripple */
	pulsing?: boolean;

	/** Making hyperlink out of StatusLight */
	href?: string;

	/** Action for when StatusLight is clicked */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Action for when StatusLight is hovered */
	onMouseOver?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Indicator color */
	color?: 'default' | 'info' | 'success' | 'warning' | 'critical' | string;
}

export interface StatusLightProps extends StatusLightPropsStrict {
	[propName: string]: any;
}

export const StatusLight: FC<StatusLightProps> = ({
	children,
	className,
	full,
	reverse,
	split,
	disabled,
	pulsing,
	href,
	color = 'default',
	...props
}) => {
	const isCustomColor = () => {
		const uniqueColors = [
			'default',
			'info',
			'success',
			'warning',
			'critical',
		];

		if (!uniqueColors.includes(color)) return true;
		return false;
	};

	const colorClasses = () => {
		if (!disabled && !isCustomColor()) {
			return `StatusLight__indicator--${color}`;
		}
		return false;
	};

	const StatusLightIndicatorClasses = classnames(
		'StatusLight__indicator',
		colorClasses(),
		{
			'StatusLight__indicator--disabled': disabled,
		}
	);

	const StatusLightClasses = classnames('StatusLight', className, {
		'StatusLight--disabled': disabled,
		'StatusLight--reverse': reverse,
		'StatusLight--full': full,
		'StatusLight--split': split,
		'StatusLight--pulsing': pulsing,
		'StatusLight--action':
			'onClick' in props || 'onMouseOver' in props || href,
	});

	const StatusLightElement = href ? 'a' : 'div';

	return (
		<StatusLightElement
			className={StatusLightClasses}
			href={href}
			data-anvil-component="StatusLight"
			{...props}
		>
			<span
				className={StatusLightIndicatorClasses}
				style={isCustomColor() ? { backgroundColor: color } : null}
			/>
			{children !== undefined && children !== null && (
				<BodyText size="small" className="StatusLight__label">
					{children}
				</BodyText>
			)}
		</StatusLightElement>
	);
};
