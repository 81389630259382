import { stringToDate } from './stringToDate';
import { isValidDate } from './utils';

export const getOptionByText = <T extends { value: Date; text: string }>(
	options: T[],
	text: string
): T => {
	if (typeof text !== 'string') return null;

	if (isValidDate(stringToDate(text)))
		return options.find((o) => o.text === text);

	return null;
};
