import React, { FC } from 'react';
import classnames from 'classnames';
import { TabGroupItem } from './TabGroupItem';
import { TabProps } from '../Tab';
import { TabAction } from './components';
import { wrapChildren } from '../../utilities/wrapChildren';
import { useArrowNav } from '../../hooks/useArrowNav';

type TabGroupChildType =
	| React.ReactElement<TabProps>
	| boolean
	| null
	| undefined;

/**
 * TabGroup properties
 */
export interface TabGroupPropsStrict {
	action?: React.ReactElement;

	/** Tab Elements */
	children: TabGroupChildType | TabGroupChildType[];

	/** Adds one or more classnames for an element */
	className?: string;

	/** Tabs behind a dark background */
	dark?: boolean;

	/** Fills the tabs to the full width of the tab group */
	fitted?: boolean;

	/** Increases the size of tabs */
	size?: 'normal' | 'large';

	/** Which visual style is used for tabs */
	type?: 'underline' | 'index';

	/** Apply a line divider to the tab group */
	divider?: boolean;

	/** Apply a max-width to individual tabs at the group level */
	maxWidth?: boolean;

	/** Apply a | divider between inactive tabs */
	verticallyDivided?: boolean;

	/** Text alignment of tabs */
	textAlign?: 'left' | 'center';
}

export interface TabGroupProps extends TabGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

interface TabGroup extends FC<TabGroupProps> {
	/** Subcomponents */
	Action: typeof TabAction;
}

export const TabGroup: TabGroup = ({
	action,
	children,
	className,
	dark,
	divider = true,
	fitted,
	size = 'normal',
	maxWidth,
	verticallyDivided,
	type = 'underline',
	textAlign = 'center',
	...props
}) => {
	const TabGroupClasses = classnames('TabGroup', className, {
		'TabGroup--fitted': fitted,
		'TabGroup--dark': dark,
		'TabGroup--index': type === 'index',
		'TabGroup--underline': type === 'underline',
		'TabGroup--leftAlign': textAlign === 'left',
		'TabGroup--large': size === 'large',
		'TabGroup--divider': divider,
		'TabGroup--maxWidth': maxWidth,
		'TabGroup--verticallyDivided': verticallyDivided,
	});

	const tabGroupRef = useArrowNav({
		handleY: false,
		focusableSelector: {
			selector: '[role=tab]:not([disabled]):not([aria-disabled])',
		},
	});

	return (
		<ul
			role="tablist"
			className={TabGroupClasses}
			data-anvil-component="TabGroup"
			ref={tabGroupRef}
			{...props}
		>
			{wrapChildren(children, TabGroupItem)}
			{action && <TabAction>{action}</TabAction>}
		</ul>
	);
};

TabGroup.Action = TabAction;
