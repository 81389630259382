import React, { FC } from 'react';
import classnames from 'classnames';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export interface TabPropsStrict {
	/** The 'active' tab in the group */
	active?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Adds the HTML disabled state */
	disabled?: boolean;

	/** Making hyperlink out of tab */
	href?: string;

	/** What happens when the user clicks the tab */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export interface TabProps extends TabPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Tab: FC<TabProps> = ({
	action,
	active,
	className,
	disabled,
	onFocus,
	onBlur,
	...props
}) => {
	let TabElement: any = 'button';

	const itemRef = React.useRef(null);

	const { isFocusVisible, focusFunction, blurFunction } = useFocusVisible(
		itemRef,
		onBlur,
		onFocus
	);

	if (props.href) TabElement = 'a';

	const TabClasses = classnames('Tab', className, {
		'Tab--active': active,
		'Tab--inactive': !active,
		'Tab--disabled': disabled,
		'Tab--focus-visible': isFocusVisible,
	});

	return (
		<TabElement
			role="tab"
			disabled={!props.href ? disabled : null}
			aria-disabled={props.href ? disabled : null}
			aria-selected={active}
			ref={itemRef}
			tabIndex={props.tabIndex || disabled ? -1 : 0}
			className={TabClasses}
			onFocus={focusFunction}
			onBlur={blurFunction}
			data-anvil-component="Tab"
			{...props}
		/>
	);
};
