import React from 'react'
import { Icon, Tooltip, BodyText, Link } from '@servicetitan/design-system'
import { tokens } from "@servicetitan/tokens/core"
import SimpleIcons from 'simple-icons-react-component';

export const ResourceLink = ({
	title,
	subtitle,
	iconName,
	externalUrl,
	download,
	customIcon,
	url
}) => {
	let fileName = url.split('/')[url.split('/').length -1]
	const icon = download ? "get_app" : externalUrl ? "open_in_new" : "arrow_forward";

	return (
		<div className={'FilePicker__File DocsFilePicker'}>
			{
				customIcon
				? <div className="a-Icon FilePicker__File__Icon" style={{ height: tokens.spacing3, width: tokens.spacing3, color: tokens.colorBlue }}><SimpleIcons name={iconName} color="inherit" /></div>
				: <Icon name={iconName ? iconName : "description"} size={24} className="FilePicker__File__Icon"/>

			}
			<div className="FilePicker__File__Name">
				{title ? <Link href={url} primary target={externalUrl ? "_blank" : null}>{title}</Link> : fileName}
				{subtitle && <BodyText size="small" subdued>{subtitle}</BodyText>}
			</div>
			{url && (
				<Tooltip text={download ? "Download File" : ""} className="FilePicker__File__ActionIcon">
						<Icon el="a" name={icon} size={24} href={url} target={externalUrl ? "_blank" : null} />
				</Tooltip>
			)}
		</div>
	)
}
