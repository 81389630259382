import React from 'react';
import { DataList } from "@servicetitan/design-system";

export const Table = (props) => {
	const header = props.children.filter(e => e.props.mdxType === "thead")[0]
	const headerItems = header.props.children.props.children.map((e, index) => <DataList.HeaderCell key={index} style={{textAlign: e.props.align}} name={e.props.children} content={e.props.children} />)
	const body = props.children.filter(e => e.props.mdxType === "tbody")[0]
	const tableRows = body.props.children
	return (
		<DataList header={headerItems} className="DocsTable">
			{tableRows.map((e, index) => {
				return (
					<DataList.Row key={index}>
						{e.props.children.map((c, index) => {
							return (
								<DataList.Cell style={{textAlign: c.props.align}} key={index}>{c.props.children}</DataList.Cell>
							)
						})}
					</DataList.Row>
				)
			})}
		</DataList>
	)
}
