import React, { FC, Ref } from 'react';
import { FormFieldContext } from '../../FormField';
import { BodyText } from '../../BodyText';
import { Tooltip } from '../../Tooltip';
import { Button } from '../../Button';
import { Stack } from '../../Stack';
import { Icon } from '../../Icon';
import classnames from 'classnames';
import { toDateFormat } from '../../../utilities/dateFormat';
import { SelectionRange } from '@progress/kendo-react-dateinputs';

export interface DateRangeTriggerProps {
	/** onKeyDown: returns event */
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;

	/** Classname for Trigger */
	className?: string;

	/** Disabled state */
	disabled?: boolean;

	/** Error state */
	error?: boolean;

	/** onClick: returns event */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** onChange function */
	onChange?: (value: SelectionRange) => void;

	/** Whether the Trigger is in open state or not */
	open?: boolean;

	/** Placeholder value */
	placeholder?: string;

	/** range value */
	value?: SelectionRange | any;

	/** display value */
	displayValue?: string;

	/** size of the Trigger */
	size?: 'small' | 'medium' | 'large';

	ref?: Ref<HTMLDivElement>;

	required?: boolean;
}

type DateRangeTriggerPropsRef = DateRangeTriggerProps & {
	ref?: Ref<HTMLDivElement>;
};

export const DateRangeTrigger: FC<DateRangeTriggerPropsRef> = React.forwardRef(
	(
		{
			onKeyDown,
			className,
			disabled,
			error,
			onClick,
			open,
			placeholder,
			value,
			displayValue,
			size,
			required,
			onChange,
			...rest
		},
		ref: Ref<HTMLDivElement>
	) => {
		const [valueRange, setValueRange] = React.useState(value);
		const TriggerClasses = classnames('Trigger', className, {
			'Trigger--open': open,
			'Trigger--error': error,
			'Trigger--disabled': disabled,
			[`Trigger--s-${size}`]: size,
		});

		const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
			onKeyDown?.(e);
		};

		const getShortLabel = () => {
			return (
				<div className="Trigger__shortLabel">
					<Icon name="event" size="20px" />
				</div>
			);
		};

		const getArrow = () =>
			open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';

		const valueString =
			displayValue ??
			(valueRange.start &&
				valueRange.end &&
				`${toDateFormat.shortMonth(
					valueRange.start
				)} - ${toDateFormat.shortMonth(valueRange.end)}`);

		const getValues = () => {
			if (valueString)
				return (
					<BodyText className="Trigger__value">
						{valueString}
					</BodyText>
				);
			return (
				<BodyText className="Trigger__placeholder">
					{placeholder}
				</BodyText>
			);
		};

		const clearClick = (e: React.SyntheticEvent) => {
			onChange({ start: null, end: null });
			setValueRange({ start: null, end: null });
			e.preventDefault();
			e.stopPropagation();
		};

		const getClearable = () => {
			if (!valueString || valueString.length === 0 || required) return;
			return (
				<Tooltip
					text={disabled ? null : 'Clear selection'}
					className="d-f"
				>
					<Button
						aria-label="clear selection"
						className="Trigger__clear"
						fill="subtle"
						disabled={disabled}
						iconName="clear"
						onClick={clearClick}
						xsmall
					/>
				</Tooltip>
			);
		};

		const handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
			return onClick === undefined ? null : onClick(e);
		};

		const { labelFor: inputId, setFormFieldContext } =
			React.useContext(FormFieldContext);

		React.useEffect(() => {
			setFormFieldContext({
				inputRef: ref as React.RefObject<HTMLDivElement>,
			});
			return () => setFormFieldContext({ inputRef: undefined });
		}, []); // eslint-disable-line

		React.useEffect(() => {
			setValueRange(value);
		}, [value]);

		return (
			<div
				aria-expanded={open}
				className={TriggerClasses}
				onClick={!disabled ? handleClick : undefined}
				tabIndex={disabled ? undefined : 0}
				onKeyDown={handleKey}
				id={inputId}
				ref={ref}
				{...rest}
			>
				<Stack>
					{getShortLabel()}
					{getValues()}
					<Stack
						className="Trigger__controls"
						spacing={1}
						alignItems="flex-start"
					>
						{getClearable()}
						<Icon
							name={getArrow()}
							size="16px"
							className="Trigger__arrow"
						/>
					</Stack>
				</Stack>
			</div>
		);
	}
);
