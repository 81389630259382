import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Text properties
 */
export interface EyebrowPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Set style `display: inline;` to nest Text components */
	inline?: boolean;

	/** Set style to italic */
	italic?: boolean;

	/** Headline Font size */
	size?: 'large' | 'medium' | 'small';

	/** Bold */
	bold?: boolean;
}

export interface EyebrowProps extends EyebrowPropsStrict {
	[propName: string]: any;
}

export const Eyebrow: FC<EyebrowProps> = React.forwardRef(
	(
		{
			children,
			className,
			el: EyebrowElement = 'span',
			inline,
			italic,
			bold,
			size = 'medium',
			...props
		},
		ref
	) => {
		const TextClasses = classnames('Eyebrow', className, {
			[`Eyebrow--${size}`]: size,
			'Eyebrow--inline': inline,
			'Eyebrow--block': !inline,
			'Eyebrow--bold': bold,
			'Eyebrow--italic': italic,
		});

		return (
			<EyebrowElement
				className={TextClasses}
				ref={ref}
				data-anvil-component="Eyebrow"
				{...props}
			>
				{children}
			</EyebrowElement>
		);
	}
);
