import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Block, List, Table as DataList, Link } from "components";
import { Divider, Eyebrow, Headline, BodyText } from "@servicetitan/design-system";
import { ScreenSizeProvider } from "../context/ScreenSizeContext";

const components = {
	pre: props => <Block {...props} />,
	inlineCode: props => <code className="DocsInlineCode" {...props} />,
	p: props => <BodyText {...props} className="DocsText m-b-2" style={{maxWidth:'40em'}} />,
	strong: props => <BodyText el="span" className="c-inherit" {...props} inline bold />,
	h1: props => <Headline size="large" {...props} el="h2" />,
	h2: props => <Headline size="medium" {...props} el="h3" />,
	h3: props => <Headline size="small" {...props} el="h4" />,
	h4: props => <Eyebrow {...props} el="h5" />,
	ul: props => <List {...props} style={{maxWidth: '35em'}} />,
	li: props => <BodyText el="li" {...props} />,
	table: props => <DataList {...props} />,
	blockquote: props => <blockquote className="DocsBlockquote" {...props} />,
	a: props => <Link {...props} />,
	hr: props => <Divider spacing="2" {...props} />
}

const Root = ({element}) => {
	return (
		<MDXProvider components={components}>
			<ScreenSizeProvider>
				{element}
			</ScreenSizeProvider>
		</MDXProvider>
	)
}

export default Root;
