import React, { FC } from 'react';
import classnames from 'classnames';
import { Headline } from '../Headline';
import { Card } from '../Card';
import { Icon } from '../Icon';

export interface FlowCardStepProps {
	/** If passed, it will replace the order number provided by the wrapper */
	orderNumber?: React.ReactElement | string | number | false;

	/** If passed, it will replace status icon */
	headerIcon?: React.ReactElement;

	/** Title of the element */
	title?: string | React.ReactElement;

	/** Step header content  */
	headerAction?: React.ReactElement;

	/** Step body content  */
	content?: React.ReactElement;

	/** Step footer content  */
	footerAction?: React.ReactElement | false;

	/** Error State */
	error?: boolean;

	/** Saved State */
	saved?: boolean;

	/** Active State */
	active?: boolean;

	/** Disabled state. Step looks unclickable with fading about 60% */
	disabled?: boolean;

	/** Adds one or more classnames to an element */
	className?: string;

	/** Adds style to an element */
	style?: React.CSSProperties;
}

export const FlowCardStep: FC<FlowCardStepProps> = ({
	orderNumber,
	headerIcon,
	title,
	headerAction,
	content,
	footerAction,
	error,
	saved,
	active,
	className,
	disabled,
	style,
	...rest
}) => {
	const statusIcon = classnames('FlowCardStep__status', {
		'FlowCardStep__status--error': error && !headerIcon,
		'FlowCardStep__status--active': active && !disabled && !headerIcon,
		'FlowCardStep__status--saved': !active && saved && !headerIcon,
	});

	const cardState = classnames('FlowCardStep', className, {
		'FlowCardStep--error': error,
		'FlowCardStep--active': active && !disabled,
		'FlowCardStep--saved': !active && saved,
		'FlowCardStep--ordered': orderNumber !== undefined,
		'FlowCardStep--headerOnly': !content,
	});

	const FlowCardStepStatus = () => {
		if (error) {
			return (
				<Icon
					name="warning"
					size="18px"
					className="FlowCardStep__indicator-error"
				/>
			);
		}
		if (orderNumber !== undefined && orderNumber !== false) {
			if (saved && !active) {
				return (
					<Icon
						name="check_circle"
						size="24px"
						className="FlowCardStep__indicator-active"
					/>
				);
			}
			return orderNumber;
		}
		if (active && !disabled)
			return (
				<Icon
					name="edit"
					size="14px"
					className="FlowCardStep__icon-active"
				/>
			);
		if (saved) {
			return (
				<Icon
					name="check_circle"
					size="24px"
					className="FlowCardStep__indicator-complete"
				/>
			);
		}
		return (
			<Icon
				name="check_circle"
				size="24px"
				className="FlowCardStep__indicator-disabled"
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={cardState}
			disabled={disabled}
			style={style}
			data-anvil-component="FlowCardStep"
			aria-current={active && 'step'}
		>
			<Card.Section className="FlowCardStep__header">
				<div className="d-f align-items-center">
					<span className="FlowCardStep__statusWrapper">
						{headerIcon ? (
							headerIcon
						) : (
							<span className={statusIcon}>
								{FlowCardStepStatus()}
							</span>
						)}
					</span>
					<Headline className="m-b-0 d-f align-items-end">
						{title}
					</Headline>
				</div>
				{headerAction && headerAction}
			</Card.Section>
			{content && <Card.Section>{content}</Card.Section>}
			{footerAction && (
				<Card.Section className="FlowCardStep__footer">
					{footerAction}
				</Card.Section>
			)}
		</Card>
	);
};
