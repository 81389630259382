import React from 'react';
import { Link as GLink } from "gatsby";
import classnames from 'classnames';
import { tokens } from '@servicetitan/tokens/core';
import * as anvil from '@servicetitan/design-system';
import { light as anvilCoreLight } from '@servicetitan/anvil-themes';
import { ContrastSvg } from '../ContrastSvg';
import { DisplayColors } from '../DisplayColors';
import { List } from '../List';
import * as images from '../../images/react-live-scope';
import { process } from '@progress/kendo-data-query';
import { State } from '../State';
import SimpleIcons from 'simple-icons-react-component';

const GatsbyLink = (props) => <GLink {...props} className="Link Link--blue" />;

export const scope = {
	classnames,
	tokens,
	ContrastSvg,
	DisplayColors,
	GatsbyLink,
	List,
	State,
	process,
	SimpleIcons,
	anvilCoreLight,
	...images,
	...anvil
}
