import { TimePickerOptionProps } from '../components/TimePickerOptionList';

import { getClosestIndexes } from './getClosestOption';

// Highlight closest option inside dropdown
export const highlightOption = (
	options: TimePickerOptionProps[],
	option: TimePickerOptionProps,
	highlightSelected?: boolean
): TimePickerOptionProps[] => {
	const indexes = option ? getClosestIndexes(options, option.value) : null;

	if (indexes) {
		if (highlightSelected) {
			// Set option selected
			options[indexes[0]].selected = true;
		} else {
			// Closest option to be highlighted
			options[indexes[0]].className = 'OptionList__option-highlighted';
		}
	}

	return options;
};
