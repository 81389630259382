import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { Checkbox, CheckboxProps } from '../Checkbox';

export interface FormCheckboxProps<T>
	extends FormFieldPropsStrict,
		Omit<CheckboxProps<T>, 'error'> {
	label: React.ReactNode;
}

type FormCheckboxType<T = any> = FC<FormCheckboxProps<T>>;

export const FormCheckbox: FormCheckboxType = (props) => (
	<FormField control={Checkbox} {...props} />
);
