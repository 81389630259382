import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon, IconPropsStrict } from '../Icon';

/**
 * ActionMenu.Item properties
 */
export interface ActionMenuItemPropsStrict {
	/** Shows up cascading menu indicator (an arrow on the right side) */
	cascading?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Disabled item, non-interactive */
	disabled?: boolean;

	/** Name of icon to render inside of item */
	icon?: IconPropsStrict['name'];

	/** Fill color of icon */
	iconColor?: string;
}

export interface ActionMenuItemProps extends ActionMenuItemPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const ActionMenuItem: FC<ActionMenuItemProps> = React.forwardRef(
	(
		{
			cascading = false,
			children,
			className,
			disabled,
			icon: iconName,
			iconColor,
			...rest
		},
		ref: React.RefObject<HTMLButtonElement>
	) => {
		const newRef = React.useRef(null);
		const itemRef = ref ? ref : newRef;

		const ActionMenuItemClasses = classnames('ActionMenuItem', className, {
			'ActionMenuItem--disabled': disabled,
			'ActionMenuItem--cascading': cascading,
		});

		return (
			<button
				className={ActionMenuItemClasses}
				disabled={disabled}
				onMouseEnter={() => itemRef.current?.focus()}
				ref={itemRef}
				role="button"
				{...rest}
			>
				{iconName ? (
					<span className="ActionMenuItem__icon">
						<Icon
							name={iconName}
							color={iconColor ?? 'currentColor'}
						/>
					</span>
				) : null}
				<span className="ActionMenuItem__content">{children}</span>
				{cascading ? (
					<span className="ActionMenuItem__icon">
						<Icon name="chevron_right" />
					</span>
				) : null}
			</button>
		);
	}
);
