import React, { FC } from 'react';
import classnames from 'classnames';

import { DataListCellType } from './DataListCell';

export type DataListRowType = React.ReactElement<DataListRowProperties>;

export interface DataListRowProperties {
	className?: string;
	children?: DataListCellType[] | DataListCellType;
}

export const DataListRow: FC<DataListRowProperties> = (props) => {
	const classes: string = classnames('DataList__Row', props.className);
	return <div className={classes}>{props.children}</div>;
};
