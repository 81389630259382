import { useState } from 'react';
import { OptionListProps } from '.';

export const useOnExpand = (
	/** Value from Option. Accepts an array of any content */
	value: OptionListProps['value']
) => {
	const getValue = Array.isArray(value) ? value : [value];

	const [expandValue, setExpandValue] = useState([...getValue]);
	const setCollapseValue = (data: OptionListProps['value']) => {
		expandValue.includes(data)
			? setExpandValue((prevState) =>
					[...prevState].filter((item) => item !== data)
			  )
			: setExpandValue((prevState) => [...prevState, data]);
		return expandValue;
	};

	return [expandValue, setCollapseValue] as const;
};
