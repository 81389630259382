import React, { FC } from 'react';
import classnames from 'classnames';
import { BodyText } from '../../BodyText';
import { Button, ButtonProps } from '../../Button';
import { Stack } from '../../Stack';
import { Link } from '../../Link';
import {
	focusLastOption,
	FocusContext,
	FooterAction,
	FocusActionType,
} from '../utils';
import { keys } from '../../../utilities/keyCodes';

export interface AnvilSelectFooterProps {
	/** Props for the action button */
	actionButtonProps?: ButtonProps;

	/** Text for the action button */
	actionName?: string;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Enables select all for the entire Select */
	selectAll?: boolean;

	/** Function that fires when the user clicks on the button */
	onActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks on the Select All button */
	onSelectAllClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks on the Select None button */
	onSelectNoneClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that closes the select when the user clicks on the action button */
	closeSelectHandler?: () => void;
}

export const AnvilSelectFooter: FC<AnvilSelectFooterProps> = React.memo(
	({
		actionButtonProps,
		actionName,
		className,
		selectAll,
		onActionClick,
		onSelectAllClick,
		onSelectNoneClick,
		closeSelectHandler,
		...props
	}) => {
		const AnvilSelectFooterClassNames = classnames(
			'Select__footer w-100',
			className
		);
		const hasAction = !!(actionName && onActionClick);

		const handleOnActionClick = (e: React.SyntheticEvent<HTMLElement>) => {
			closeSelectHandler();
			onActionClick(e);
		};

		const { focusState, focusDispatch } = React.useContext(FocusContext);
		const { actionFocus } = focusState;
		const selectAllRef = React.useRef(null);
		const selectNoneRef = React.useRef(null);

		React.useEffect(() => {
			if (actionFocus === FooterAction.SelectAll) {
				selectAllRef.current?.focus();
			}
			if (actionFocus === FooterAction.SelectNone) {
				selectNoneRef.current?.focus();
			}
		}, [actionFocus]);

		const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
			const target = e.target as HTMLElement;
			if (e.key === keys.enter) {
				if (target.classList.contains('all')) {
					onSelectAllClick(e);
					focusDispatch({
						type: FocusActionType.SetFocus,
						payload: { actionFocus: FooterAction.SelectAll },
					});
					return;
				}

				if (target.classList.contains('none')) {
					onSelectNoneClick(e);
					focusDispatch({
						type: FocusActionType.SetFocus,
						payload: { actionFocus: FooterAction.SelectNone },
					});
					return;
				}

				if (
					actionName &&
					target.textContent?.toLowerCase() === actionName
				) {
					handleOnActionClick(e);
					return;
				}
			}

			if (e.key === keys.tab && e.shiftKey) {
				if (
					(selectAll &&
						!target.parentElement.previousElementSibling &&
						target.textContent?.toLowerCase() !== actionName) ||
					(!selectAll && hasAction)
				) {
					e.preventDefault();
					focusLastOption(target, closeSelectHandler);
					return;
				}
			}

			if (e.key === keys.esc) {
				e.preventDefault();
				closeSelectHandler();
				return;
			}

			if (e.key === keys.tab && !e.shiftKey) {
				if (
					(actionName &&
						target.textContent?.toLowerCase() === actionName) ||
					(!actionName && target.classList.contains('none'))
				) {
					e.preventDefault();
					closeSelectHandler();
				}
			}
		};

		return (
			<Stack
				alignItems="center"
				justifyContent="space-between"
				className={AnvilSelectFooterClassNames}
				onKeyDown={handleKeyDown}
				{...props}
			>
				{selectAll && (
					<BodyText size="small" subdued>
						Select
						<Link
							primary
							className="m-l-1"
							onClick={onSelectAllClick}
							tabIndex={0}
						>
							<span
								ref={selectAllRef}
								className="Select__focus all"
							>
								All
							</span>
						</Link>
						<Link
							primary
							className="m-l-1"
							onClick={onSelectNoneClick}
							tabIndex={0}
						>
							<span
								ref={selectNoneRef}
								className="Select__focus none"
							>
								None
							</span>
						</Link>
					</BodyText>
				)}
				{hasAction && (
					<Button
						primary
						xsmall
						onClick={handleOnActionClick}
						className="Select__focus m-l-auto"
						tabIndex={0}
						{...actionButtonProps}
					>
						{actionName}
					</Button>
				)}
			</Stack>
		);
	}
);
