export const htmlInputProps = [
	// React
	'selected',
	'defaultValue',
	'defaultChecked',

	// HTML attributes
	'accept',
	'autoCapitalize',
	'autoComplete',
	'autoCorrect',
	'autoFocus',
	'checked',
	'disabled',
	'form',
	'id',
	'lang',
	'list',
	'max',
	'maxLength',
	'min',
	'minLength',
	'multiple',
	'name',
	'pattern',
	'placeholder',
	'readOnly',
	'required',
	'step',
	'title',
	'type',
	'value',
];

export const htmlInputEvents = [
	// Keyboard
	'onKeyDown',
	'onKeyPress',
	'onKeyUp',

	// Focus
	'onFocus',
	'onBlur',

	// Form
	'onChange',
	'onInput',

	// Mouse
	'onClick',
	'onContextMenu',
	'onDrag',
	'onDragEnd',
	'onDragEnter',
	'onDragExit',
	'onDragLeave',
	'onDragOver',
	'onDragStart',
	'onDrop',
	'onMouseDown',
	'onMouseEnter',
	'onMouseLeave',
	'onMouseMove',
	'onMouseOut',
	'onMouseOver',
	'onMouseUp',

	// Selection
	'onSelect',

	// Touch
	'onTouchCancel',
	'onTouchEnd',
	'onTouchMove',
	'onTouchStart',
];

export const htmlInputPropsArray = [...htmlInputProps, ...htmlInputEvents];

export const inputPropsArray = [
	'el',
	'action',
	'actionPosition',
	'children',
	'className',
	'disabled',
	'error',
	'fluid',
	'focus',
	'icon',
	'iconPosition',
	'input',
	'inverted',
	'large',
	'loading',
	'onChange',
	'size',
	'shortLabel',
	'shortLabelPosition',
	'small',
	'tabIndex',
	'transparent',
	'type',
	'xsmall',
];

// Returns an array of objects consisting of [props found in targetKeysArray, rest]
export const splitPropsUsingArray = (props: any, targetKeysArray: string[]) => {
	const rest = { ...props };

	const targetProps = targetKeysArray.reduce((result, propName) => {
		if (rest.hasOwnProperty(propName)) {
			(result as any)[propName] = rest[propName];
			delete (rest as any)[propName];
		}
		return result;
	}, {});
	return [targetProps, rest];
};
