import { stringToDate } from './stringToDate';

// To be replaces with checkRange()
export const checkMinMax = (
	min: Date | string,
	max: Date | string,
	value: Date | string
): boolean => {
	const v = stringToDate(value)?.getTime();
	const mi = stringToDate(min)?.getTime();
	const ma = stringToDate(max)?.getTime();

	if (mi > ma) {
		return mi >= v && v >= ma; // Reversed min-max
	}
	return mi <= v && v <= ma;
};
