import React from 'react';
import classnames from 'classnames';
import { Button, ButtonProps } from '../Button';

/**
 * ButtonToggleItem properties
 */
export interface ButtonToggleItemProps<T> {
	/** Content of the Button, i.e. the displayed text */
	text: React.ReactNode;

	/** Value of the Button */
	value: T;

	/** Returns value of option for the click event */
	onClick?: (value: T) => void;

	/** Indicates whether the Button is disabled or not. It makes the Button unselectable */
	disabled?: ButtonProps['disabled'];

	/** Sets the option's selected state */
	selected?: boolean;

	/** Selected button background color.<br /> Possible values: <i>'primary'</i>, <i>'blue'</i>, <i>'negative'</i>, <i>'red'</i> */
	color?: ButtonProps['color'];

	/** Sets size of the button.<br /> Possible values: <i>'large'</i>, <i>'small'</i> */
	size?: ButtonProps['size'];

	/** Specifies the name of the Button */
	name: string;

	/** Hide the option (not commonly used) */
	hidden?: boolean;

	/** React element to be rendered as an icon */
	icon?: ButtonProps['icon'];

	/** Name of icon to render inside of button */
	iconName?: ButtonProps['iconName'];
}

export const ButtonToggleItem: <T>(
	props: React.PropsWithoutRef<ButtonToggleItemProps<T>> &
		React.RefAttributes<HTMLDivElement>
) => React.ReactElement | null = React.forwardRef(function <T>(
	{
		color,
		disabled,
		hidden,
		icon,
		iconName,
		name,
		onClick,
		selected,
		size,
		text,
		value,
	}: ButtonToggleItemProps<T>,
	ref: React.RefObject<HTMLDivElement>
) {
	let realColor: ButtonToggleItemProps<T>['color'] = 'default';

	if (selected) realColor = color;

	const ButtonToggleItemClasses = classnames('ButtonToggle__Item', {
		'ButtonToggle__Item--hidden': hidden,
		'ButtonToggle__Item--selected': selected,
		'ButtonToggle__Item--not-selected': !selected,
		'ButtonToggle__Item--disabled': disabled,
	});

	return (
		<div className={ButtonToggleItemClasses} ref={ref}>
			<input
				name={name}
				type="radio"
				defaultChecked={selected}
				className="ButtonToggle__radio"
				tabIndex={-1} // Prevent focusing on invisible input
			/>
			<Button
				color={realColor}
				disabled={disabled}
				fill="solid"
				full
				icon={icon}
				iconName={iconName}
				onClick={onClick.bind(null, value)}
				size={size}
				aria-pressed={selected ? true : null}
			>
				{text}
			</Button>
		</div>
	);
});
