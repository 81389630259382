import React, { FC } from 'react';
import { ButtonProps } from '../Button';

export interface ButtonGroupItemProps {
	children: React.ReactElement<ButtonProps>;
}

export const ButtonGroupItem: FC<ButtonGroupItemProps> = ({ children }) => (
	<div className="ButtonGroup__Item">{children}</div>
);

ButtonGroupItem.displayName = 'ButtonGroupItem';
