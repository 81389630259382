import React from 'react';
import { LivePreview } from 'react-live';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { Icon, Spinner, Stack } from '@servicetitan/design-system';
import { Rnd } from 'react-rnd';
import classnames from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';
import { ScreenSizeContext } from '../../context/ScreenSizeContext';

const useSize = (target) => {
	const [size, setSize] = React.useState()

	React.useEffect(() => {
		setSize(target.current.getBoundingClientRect())
	}, [target])

	useResizeObserver(target, (entry) => setSize(entry.contentRect))
	return size
}

export const Preview = ({metaData, inline, fullscreen}) => {
	const { scaled, frame, noPadding, noResize, height } = metaData;
	const isFramed = scaled || frame;

	const previewRef = React.useRef(null);
	const [ratio, setRatio] = React.useState(1);
	const [render, setRender] = React.useState(false);

	const { size } = React.useContext(ScreenSizeContext);
	const isSmallerThanMobile = size.smallerThan.mobile;
	const previewSize = useSize(previewRef);

	React.useLayoutEffect(() => {
		previewSize && setRatio(previewSize.width/1280)
	}, [previewSize])

	const previewStyle = () => {
		if (fullscreen) {
			return (
				{
					width: '100%',
					height: '100%',
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 9999,
					padding: 32,
					boxSizing: 'border-box',
				}
			)
		}
		if (scaled) {
			return (
				{
					width: `1280px`,
					height: `720px`,
					transform: `scale(${ratio})`,
					transformOrigin: `0% 0%`,
				}
			)
		}
	};

	const scaleFunction = () => setRatio(previewSize.width / 1280);

	React.useEffect(() => {
		let timer = setTimeout(() => {
			setRender(true);
		}, 1)

		return () => clearTimeout(timer);
	}, []);

	return (
		<div ref={previewRef}>
			<Rnd
				disableDragging
				default={{ width: '100%' }}
				maxWidth='100%'
				minWidth={343}
				className="CodeDemo__rnd"
				style={{ position: 'relative', overflow: 'hidden', display: 'block' }}
				size={{ height: scaled ? 720 * ratio : 'auto' }}
				enableResizing={{
					top:false,
					right: inline || noResize || isSmallerThanMobile || isFramed ? false : true,
					bottom:false,
					left:false,
					topRight:false,
					bottomRight:false,
					bottomLeft:false,
					topLeft:false
				}}
				resizeHandleComponent={{
					right: <Icon name={'drag_handle'} className="dragHandle" />
				}}
				resizeHandleClasses={{ right: "dragHandle__wrapper" }}
			>
				<div
					className={classnames('CodeDemo__preview', { 'CodeDemo--background': !inline })}
					style={{ ...previewStyle() }}
				>
					{render
						? (
								isFramed ? (
									<Frame
										className={classnames('codeFrame', { 'codeFrame--scaled' : scaled })}
										style={{
											width: `100%`,
											padding: noPadding && 0,
											height: height ? `${height}px` : '100%'
										}}
										contentDidMount={scaleFunction}
									>
										<FrameContextConsumer>
											{// Callback is invoked with iframe's window and document instances
												({ document }) => {
													// Find styles in main document
													const heightStyle = document.createElement('style');
													const css = `
														html, body, .frame-root, .frame-content { height: 100%; }
													`;
													heightStyle.type = 'text/css';
													if (heightStyle.styleSheet) {
														heightStyle.styleSheet.cssText = css; // This is required for IE8 and below.
													} else {
														heightStyle.appendChild(document.createTextNode(css));
													}

													const styles = Array.from(window.document.head.querySelectorAll("link[rel=stylesheet], style")).concat(heightStyle);
													// and add it to the child
													styles.forEach(s =>
														document.head.appendChild(s.cloneNode(true))
													)
													return <LivePreview style={{ height: '100%' }}/>
												}}
										</FrameContextConsumer>
									</Frame>
								) : (
									<LivePreview
										className={classnames('livePreview', { 'scaled': scaled })}
										style={{
											padding: noPadding && 0,
											height: height ? `${height}px` : '100%'
										}}
									/>
								)
							)
						: (
								<Stack
									alignItems="center"
									justifyContent="center"
									style={{ height: 100 }}
								>
									<Spinner size="small" />
								</Stack>
							)
						}
				</div>
			</Rnd>
		</div>
	)
}
