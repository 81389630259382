import React, { FC } from 'react';
import classnames from 'classnames';
import { Card } from '../Card';

/**
 * Spinner properties
 */
export interface SpinnerPropsStrict {
	/** String of class names to add to component */
	className?: string;

	/** Option to wrap spinner in a popover style box */
	container?: boolean;

	/** Size variants */
	size?: 'large' | 'medium' | 'small' | 'tiny';
}

export interface SpinnerProps extends SpinnerPropsStrict {
	[propName: string]: any;
}

export const Spinner: FC<SpinnerProps> = ({
	className,
	container,
	size = 'medium',
	...props
}) => {
	const SpinnerClasses = classnames('Spinner', {
		[`${className}`]: className && !container,
		'Spinner--large': size === 'large',
		'Spinner--medium': size === 'medium',
		'Spinner--small': size === 'small',
		'Spinner--tiny': size === 'tiny',
	});

	const CardClasses = classnames('Card__spinner', {
		[`${className}`]: className && container,
	});

	const RadiusSize = {
		large: 46,
		medium: 45,
		small: 44,
		tiny: 43,
	};

	const CircleRadius = RadiusSize[size];

	const spinnerComponent = (
		<div
			className={SpinnerClasses}
			data-anvil-component="Spinner"
			{...props}
		>
			<svg
				className="Spinner__svg"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					className="Spinner__circle"
					cx="50"
					cy="50"
					r={CircleRadius}
				/>
			</svg>
		</div>
	);

	return container ? (
		<Card className={CardClasses}>{spinnerComponent}</Card>
	) : (
		spinnerComponent
	);
};
