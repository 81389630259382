import { useEffect, useRef } from 'react';
import { active } from './utils';

export const useRestoreFocus = ({ enabled = true }) => {
	const prevActiveRef = useRef<any>(null);

	useEffect(() => {
		if (!enabled) return;
		if (active() !== document?.body) prevActiveRef.current = active();

		// Focus previously active element on unmount
		return () => prevActiveRef.current?.focus(preventScroll);
	}, [enabled]);
};

const preventScroll = { preventScroll: true };
