import { light } from '@servicetitan/tokens/core';
import merge from 'lodash/merge';

export const createTheme = (a: theme, b?: theme) => {
	const overrides = b || a;
	const base = b ? a : light;
	return merge(base, overrides);
};

export interface theme {
	colors?: {
		app?: {
			background?: string;
		};
		border?: {
			default?: string;
			grey?: string;
			greyStrong?: string;
			primary?: string;
			primarySubdued?: string;
			critical?: string;
			criticalSubdued?: string;
			warningSubdued?: string;
			successSubdued?: string;
		};
		focus?: {
			ringPrimary?: string;
			ringCritical?: string;
		};
		active?: {
			default?: string;
			primary?: string;
			critical?: string;
			onGrey?: string;
			onPrimary?: string;
			onCritical?: string;
		};
		hover?: {
			default?: string;
			subdued?: string;
			primary?: string;
			critical?: string;
			onGrey?: string;
			onPrimary?: string;
			onCritical?: string;
		};
		status?: {
			critical?: string;
			criticalStrong?: string;
			info?: string;
			infoStrong?: string;
			success?: string;
			successStrong?: string;
			warning?: string;
			warningStrong?: string;
			default?: string;
		};
		surface?: {
			default?: string;
			inverted?: string;
			invertedStrong?: string;
			grey?: string;
			greyStrong?: string;
			greyStronger?: string;
			greySubdued?: string;
			primary?: string;
			primarySubdued?: string;
			critical?: string;
			criticalSubdued?: string;
			successSubdued?: string;
			warningSubdued?: string;
			disabled?: string;
		};
		text?: {
			default?: string;
			inverted?: string;
			subdued?: string;
			primary?: string;
			primarySubdued?: string;
			critical?: string;
			onGrey?: string;
			onGreySubdued?: string;
			onPrimary?: string;
			onWarning?: string;
			onCritical?: string;
		};
	};
}
