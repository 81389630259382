import React, { FC } from 'react';
import classnames from 'classnames';
import { ButtonGroupItem } from './ButtonGroupItem';
import { ButtonProps } from '../Button';
import { wrapChildren } from '../../utilities/wrapChildren';

type ButtonGroupChildType =
	| React.ReactElement<ButtonProps>
	| boolean
	| null
	| undefined;

/**
 * ButtonGroup properties
 */
export interface ButtonGroupPropsStrict {
	/** Join buttons together as a group */
	attached?: boolean;

	/** Button Elements */
	children: ButtonGroupChildType | ButtonGroupChildType[];

	/** String of class names to add to component */
	className?: string;

	/** When used in combination with `fullWidth`, this makes buttons take up equal amount of space */
	equalWidth?: boolean;

	/** Make buttons fill all available space */
	fullWidth?: boolean;

	/** Element to use for base component */
	el?: any;
}

export interface ButtonGroupProps extends ButtonGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
	attached,
	children,
	className,
	el: ButtonGroupElement = 'div',
	fullWidth,
	equalWidth,
	...props
}) => {
	const ButtonGroupClasses = classnames('ButtonGroup', className, {
		'ButtonGroup--fullWidth': fullWidth,
		'ButtonGroup--equalWidth': fullWidth && equalWidth,
		'ButtonGroup--attached': attached,
	});

	return (
		<ButtonGroupElement
			className={ButtonGroupClasses}
			data-anvil-component="ButtonGroup"
			{...props}
		>
			<ButtonGroupElement className="ButtonGroup__InternalWrapper">
				{wrapChildren(children, ButtonGroupItem)}
			</ButtonGroupElement>
		</ButtonGroupElement>
	);
};
