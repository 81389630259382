import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Container properties
 */
export interface ContainerPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Shorthand for primary content */
	content?: React.ReactNode;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Container has no maximum width */
	fluid?: boolean;

	/** Reduce maximum width to more naturally accommodate text */
	text?: boolean;

	/** Align container's text */
	textAlign?: 'left' | 'right' | 'center' | 'justify';
}

export interface ContainerProps extends ContainerPropsStrict {
	[propName: string]: any;
}

export const Container: FC<ContainerProps> = ({
	children,
	className,
	content,
	el: ContainerElement = 'div',
	fluid,
	text,
	textAlign = 'left',
	...props
}) => {
	const classes = classnames('semantic-anvil-theme Container', className, {
		'Container--fluid': fluid,
		'Container--text': text,
		[`ta-${textAlign}`]: textAlign,
	});

	return (
		<ContainerElement
			className={classes}
			data-anvil-component="Container"
			{...props}
		>
			{React.Children.count(children) > 0 ? children : content}
		</ContainerElement>
	);
};
