import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * CardSection properties
 */
export interface CardSectionProps {
	/** Blue background */
	blue?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Set background color */
	color?:
		| 'blue'
		| 'dark'
		| 'green'
		| 'light'
		| 'purple'
		| 'red'
		| 'lightblue';

	/** Dark background */
	dark?: boolean;

	/** Green background */
	green?: boolean;

	/** Light-gray background */
	light?: boolean;

	/** Purple background */
	purple?: boolean;

	/** Red background */
	red?: boolean;

	/** Unstrict props */
	[propName: string]: any;
}

export const CardSection: FC<CardSectionProps> = ({
	blue,
	className,
	color,
	dark,
	green,
	light,
	lightblue,
	purple,
	red,
	...props
}) => {
	const CardSectionClasses = classnames('CardSection', className, {
		'CardSection--blue': blue || color === 'blue',
		'CardSection--dark': dark || color === 'dark',
		'CardSection--green': green || color === 'green',
		'CardSection--light': light || color === 'light',
		'CardSection--purple': purple || color === 'purple',
		'CardSection--red': red || color === 'red',
		'CardSection--lightblue': lightblue || color === 'lightblue',
	});

	return (
		<div
			className={CardSectionClasses}
			data-anvil-component="CardSection"
			{...props}
		/>
	);
};

CardSection.displayName = 'CardSection';
