import React, { useEffect, useState, useCallback } from 'react';

export const useFocusVisible = (
	target: React.RefObject<any>,
	onBlur?: (event: React.FocusEvent<HTMLElement>) => void,
	onFocus?: (event: React.FocusEvent<HTMLElement>) => void
) => {
	const [isFocusVisible, setIsFocusVisible] = useState(true);
	const [keyboardUser, setKeyboardUser] = useState(true);
	const [focused, setFocused] = useState(false);

	const focusFunction = (event: React.FocusEvent<HTMLElement>) => {
		onFocus?.(event);
		setIsFocusVisible(true);
		setFocused(true);
	};

	const blurFunction = (event: React.FocusEvent<HTMLElement>) => {
		onBlur?.(event);
		setIsFocusVisible(false);
		setFocused(false);
	};

	useEffect(() => {
		setIsFocusVisible(keyboardUser);
	}, [focused, keyboardUser]);

	// KeyboardEvent
	const keyboardEventHandler = useCallback(() => {
		setKeyboardUser(true);
	}, []);

	// MouseEvent
	const mouseEventHandler = useCallback(() => {
		setKeyboardUser(false);
	}, []);

	const useEvent = (event: string, callback: () => void) => {
		useEffect(() => {
			const { current } = target;
			current?.addEventListener(event, callback);
			return () => current?.removeEventListener(event, callback);
		}, [event, callback]);
	};

	useEvent('mousedown', mouseEventHandler);
	useEvent('mouseup', mouseEventHandler);
	useEvent('keydown', keyboardEventHandler);
	useEvent('keyup', keyboardEventHandler);

	return { isFocusVisible, focusFunction, blurFunction };
};
