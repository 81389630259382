import React, { FC, ComponentType, isValidElement } from 'react';

/*
 * Checks whether `element` is a React element of type `Component` (or one of
 * the passed components, if `Component` is an array of React components).
 */
export function isElementOfType(
	element: React.ReactNode | null | undefined,
	Component: ComponentType | ComponentType[]
): boolean {
	if (
		element == null ||
		!isValidElement(element) ||
		typeof element.type === 'string'
	) {
		return false;
	}

	const { type } = element;
	const Components = Array.isArray(Component) ? Component : [Component];

	return Components.some(
		(AComponent) =>
			typeof type !== 'string' && isComponent(AComponent, type)
	);
}

/*
 * In development, we compare based on the name of the function because
 * React Hot Loader proxies React components in order to make updates. In
 * production we can simply compare the components for equality.
 */
const isComponent =
	process.env.NODE_ENV === 'development'
		? hotReloadComponentCheck
		: (Comp1: ComponentType<any>, Comp2: ComponentType<any>) =>
				Comp1 === Comp2;

function hotReloadComponentCheck(
	Comp1: ComponentType<any>,
	Comp2: ComponentType<any>
) {
	const Comp1Name = Comp1.name;
	const Comp2Name = (Comp2 as FC<any>).name;

	const Comp1DisplayName = Comp1.displayName;
	const Comp2DisplayName = (Comp2 as FC<any>).displayName;

	return (
		Comp1 === Comp2 ||
		(Boolean(Comp1Name) && Comp1Name === Comp2Name) ||
		(Boolean(Comp1DisplayName) && Comp1DisplayName === Comp2DisplayName)
	);
}
