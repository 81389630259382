export const keys = {
	backspace: 'Backspace',
	tab: 'Tab',
	enter: 'Enter',
	shift: 'Shift',
	esc: 'Escape',
	space: ' ',
	pageUp: 'PageUp',
	pageDown: 'PageDown',
	end: 'End',
	home: 'Home',
	left: 'ArrowLeft',
	up: 'ArrowUp',
	right: 'ArrowRight',
	down: 'ArrowDown',
	delete: 'Delete',
	slash: '/',
};

export enum Keys {
	Backspace = 'Backspace',
	Tab = 'Tab',
	Enter = 'Enter',
	Shift = 'Shift',
	Esc = 'Escape',
	Space = 'Space',
	PageUp = 'PageUp',
	PageDown = 'PageDown',
	End = 'End',
	Home = 'Home',
	Left = 'ArrowLeft',
	Up = 'ArrowUp',
	Right = 'ArrowRight',
	Down = 'ArrowDown',
	Delete = 'Delete',
	Slash = '/',
}
