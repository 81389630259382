import React, { ForwardRefExoticComponent } from 'react';
import classnames from 'classnames';

import { StackItem } from './StackItem';

/**
 * Stack properties
 */
export interface StackPropsStrict {
	/** Additional classes */
	className?: string;

	/** *Deprecated* Please use `wrap` prop instead. */
	nowrap?: boolean;

	/** *Deprecated* Please use `direction` prop instead. */
	vertical?: boolean;

	/** Adjust spacing between elements */
	spacing?: '0' | '1' | '2' | '3' | '4' | '5' | 0 | 1 | 2 | 3 | 4 | 5;

	/** *Deprecated* Please use `alignItems` prop instead. */
	alignment?: 'leading' | 'trailing' | 'center' | 'fill' | 'baseline';

	/** *Deprecated* Please use `justifyContent` prop instead. */
	distribution?:
		| 'equalSpacing'
		| 'leading'
		| 'trailing'
		| 'center'
		| 'fill'
		| 'fillEvenly';

	/** Flex Wrap */
	wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';

	/** Flex Direction */
	direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';

	/** Justify Content */
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'space-evenly';

	/** Align Items */
	alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';

	/** Align Content */
	alignContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'stretch';
}

export interface StackProps extends StackPropsStrict {
	[propName: string]: any;
}

export interface Stack extends ForwardRefExoticComponent<StackProps> {
	/** Subcomponents */
	Item: typeof StackItem;
}

export const Stack: Stack = React.forwardRef(
	(
		{
			children,
			className,
			vertical = false,
			spacing = 0,
			distribution = 'leading',
			alignment = 'fill',
			nowrap = false,
			wrap = 'nowrap',
			direction = 'row',
			justifyContent = 'flex-start',
			alignItems = 'stretch',
			alignContent = 'stretch',
			...rest
		},
		ref: React.RefObject<any>
	) => {
		React.useEffect(() => {
			if (nowrap) {
				console.warn(
					'@servicetitan/design-system: The `nowrap` prop of the Stack component has been deprecated. Please use `wrap` instead.'
				);
			}

			if (alignment !== 'fill') {
				console.warn(
					'@servicetitan/design-system: The `alignment` prop of the Stack component has been deprecated. Please use `alignItems` instead.'
				);
			}

			if (distribution !== 'leading') {
				console.warn(
					'@servicetitan/design-system: The `distribution` prop of the Stack component has been deprecated. Please use `justifyContent` instead.'
				);
			}

			if (vertical) {
				console.warn(
					'@servicetitan/design-system: The `vertical` prop of the Stack component has been deprecated. Please use `direction` instead.'
				);
			}
		}, [nowrap, alignment, distribution, vertical]);

		const getFlexWrap = () => {
			if (nowrap) return 'wrap';
			return wrap;
		};

		const getFlexDirection = () => {
			if (vertical) return 'column';
			return direction;
		};

		const getFlexJustifyContent = () => {
			const justificationConverter: any = {
				leading: 'flex-start',
				trailing: 'flex-end',
				center: 'center',
				equalSpacing: 'space-between',
			};

			if (distribution !== 'leading')
				return justificationConverter[distribution];
			return justifyContent;
		};

		const getFlexAlignItems = () => {
			const alignmentConverter: any = {
				leading: 'flex-start',
				trailing: 'flex-end',
				center: 'center',
				fill: 'stretch',
				baseline: 'baseline',
			};

			if (alignment !== 'fill') return alignmentConverter[alignment];
			return alignItems;
		};

		const realWrap = getFlexWrap();
		const realDirection = getFlexDirection();
		const realJustifyContent = getFlexJustifyContent();
		const realAlignItems = getFlexAlignItems();

		const StackClasses = classnames('Stack', className);

		const StackWrapperClasses = classnames('Stack__InternalWrapper', {
			[`Stack--spacing-${spacing}`]: spacing,
			[`Stack--wrap-${realWrap}`]: realWrap,
			[`Stack--direction-${realDirection}`]: realDirection,
			[`Stack--justify-content-${realJustifyContent}`]:
				realJustifyContent,
			[`Stack--align-items-${realAlignItems}`]: realAlignItems,
			[`Stack--align-content-${alignContent}`]: alignContent,
		});

		return (
			<div
				className={StackClasses}
				ref={ref}
				data-anvil-component="Stack"
				{...rest}
			>
				<div className={StackWrapperClasses}>{children}</div>
			</div>
		);
	}
) as Stack;

Stack.Item = StackItem;
