import { RefObject, useEffect, useState } from 'react';

interface ScrollState {
	/** Scroll position from top, false if no scrollbar */
	top: number | boolean;

	/** Scroll position from right, false if no scrollbar */
	right: number | boolean;

	/** Scroll position from bottom, false if no scrollbar */
	bottom: number | boolean;

	/** Scroll position from left, false if no scrollbar */
	left: number | boolean;
}

export const useScrollPosition = (ref: RefObject<HTMLElement>): ScrollState => {
	if (process.env.NODE_ENV === 'development') {
		if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
			console.error('useScrollPosition expects a single ref argument');
		}
	}

	const [state, setState] = useState<ScrollState>({
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	});

	useEffect(() => {
		const el = ref.current;
		const handleScroll = () => {
			if (el) {
				const horizontalScrollbar = el.scrollWidth > el.clientWidth;
				const verticalScrollbar = el.scrollHeight > el.clientHeight;

				const bottom = el.scrollHeight - el.scrollTop - el.clientHeight;
				const right = el.scrollWidth - el.scrollLeft - el.clientWidth;

				setState({
					top: verticalScrollbar ? Math.round(el.scrollTop) : false,
					right: horizontalScrollbar ? Math.round(right) : false,
					bottom: verticalScrollbar ? Math.round(bottom) : false,
					left: horizontalScrollbar
						? Math.round(el.scrollLeft)
						: false,
				});
			}
		};

		/** Handle scroll once rendered */
		requestAnimationFrame(handleScroll);

		el && on(el, 'scroll', handleScroll, { capture: false, passive: true });

		return () => el && off(el, 'scroll', handleScroll);
	}, [ref]);

	return state;
};

type El = Window | Document | HTMLElement | EventTarget;

/* https://github.com/streamich/react-use/blob/master/src/misc/util.ts */
function on<T extends El>(
	obj: T | null,
	...args:
		| Parameters<T['addEventListener']>
		| [string, Function | null, ...any]
): void {
	obj?.addEventListener?.(
		...(args as Parameters<HTMLElement['addEventListener']>)
	);
}

function off<T extends El>(
	obj: T | null,
	...args:
		| Parameters<T['removeEventListener']>
		| [string, Function | null, ...any]
): void {
	obj?.removeEventListener?.(
		...(args as Parameters<HTMLElement['removeEventListener']>)
	);
}
