import { TimePickerOptionProps } from '../components/TimePickerOptionList';
import { dateToTime } from './';

// Inject option if it was filtered out
export const injectOption = (
	options: TimePickerOptionProps[],
	option: TimePickerOptionProps
): TimePickerOptionProps[] => {
	if (!option) return options;

	// Check whether option exist in options
	const hasOption = options.some(
		(o) => dateToTime(o?.value) === dateToTime(option?.value)
	);

	if (!hasOption) {
		options.push(option); // Inject option if needed
		options.sort((d1, d2) => d1.value.getTime() - d2.value.getTime());
	}

	return options;
};
