import React, { ChangeEvent, FC, FocusEvent, Ref } from 'react';
import { Tooltip } from '../../Tooltip';
import { Input, InputProps } from '../../Input';
import { defaultDateFormat, toDateFormat } from '../../../utilities/dateFormat';
import InputMask from 'react-input-mask';
import { DateTime } from 'luxon';
import { useMergedRef } from '../../../hooks';

export interface DateRangeInputProps {
	/** Set value */
	value?: DateTime | string;

	/** Placeholder value */
	placeholder?: string;

	/** onChange function that returns date value */
	onBlur?: (e?: FocusEvent<HTMLInputElement>, value?: Date | null) => void;
	onChange?: (e?: ChangeEvent<HTMLInputElement>, value?: string) => void;
	onFocus?: () => void;

	/** Error state */
	error?: string | boolean;
	// error?: any;

	/** Autofocus */
	autoFocus?: InputProps['autoFocus'];

	/** onKeyPress: returns event */
	onKeyPress?: InputProps['onKeyPress'];

	/**
	 * Date format string
	 */
	dateFormat?: string;
}

type DateRangeInputPropsRef = DateRangeInputProps & {
	ref?: Ref<HTMLInputElement>;
};

export const DateRangeInput: FC<DateRangeInputPropsRef> = React.forwardRef(
	(
		{
			value,
			placeholder,
			onBlur,
			onChange,
			error,
			autoFocus,
			onFocus,
			onKeyPress,
			dateFormat,
		},
		ref: React.RefObject<HTMLInputElement>
	) => {
		const [dateValue, setDateValue] = React.useState(
			value
				? DateTime.isDateTime(value)
					? toDateFormat.numeric(value, dateFormat)
					: toDateFormat.numeric(
							DateTime.fromFormat(
								value,
								dateFormat ?? defaultDateFormat
							),
							dateFormat
					  )
				: ''
		);
		const [IError, setIError] = React.useState(error);
		const localRef = React.useRef(null);
		const InputRef: React.RefObject<HTMLInputElement> = useMergedRef(
			ref,
			localRef
		);

		React.useEffect(() => {
			setIError(error);
		}, [error]);

		React.useEffect(() => {
			let stringVal = '';
			if (value) {
				if (DateTime.isDateTime(value)) {
					stringVal = toDateFormat.numeric(value, dateFormat);
				} else {
					stringVal = toDateFormat.numeric(
						DateTime.fromFormat(
							value,
							dateFormat ?? defaultDateFormat
						),
						dateFormat
					);
				}
			}
			setDateValue(stringVal);
		}, [dateFormat, value]);

		const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
			setDateValue(e.target.value);
			onChange?.(e, e.target.value);
		};

		const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
			const currentAsDate = DateTime.fromFormat(
				e.target.value,
				dateFormat ?? defaultDateFormat
			);
			if (currentAsDate.isValid) {
				setDateValue(toDateFormat.numeric(currentAsDate, dateFormat));
				onBlur?.(e, currentAsDate.toJSDate());
			} else {
				setDateValue('');
				onBlur?.(e, null);
			}
		};

		const selectAllText = (e: React.ChangeEvent<HTMLInputElement>) => {
			onFocus?.();
			e.target.select();
		};

		const mask = (dateFormat ?? defaultDateFormat).replace(
			/[MmDdYyo]/g,
			'9'
		);

		return (
			<Tooltip
				open={IError as boolean}
				text={IError as string}
				direction="b"
				className="flex-grow-1"
				el="div"
			>
				<InputMask
					mask={mask}
					value={dateValue}
					onFocus={selectAllText}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
				>
					{
						(() => (
							<Input
								onKeyPress={onKeyPress}
								error={IError as boolean}
								value={dateValue}
								icon="event"
								iconPosition="left"
								fluid
								className="m-0 DateRangePicker__input"
								autoFocus={autoFocus}
								inputRef={InputRef}
								placeholder={placeholder}
							/>
						)) as any
					}
				</InputMask>
			</Tooltip>
		);
	}
);
