import { Grid, GridProps } from '@progress/kendo-react-grid';
import {
	CommonDragLogic,
	handler,
} from '@progress/kendo-react-grid/dist/npm/drag/CommonDragLogic';
import { ExtendedColumnProps } from '@progress/kendo-react-grid/dist/npm/GridColumn';

/*
 * This is a temporary fix for issue https://github.com/telerik/kendo-react/issues/1136
 * We modify CommonDragLogic used by Grid to use our own getIndex function, which works fine inside Shadow DOM
 */

// @progress/kendo-react-data-tools/dist/es/header/utils/index.js
function getIndexMod(event: any, parent: HTMLElement | null) {
	if (!parent || !event || !event.originalEvent) {
		return -1;
	}
	const root = parent.getRootNode() as Document; // this works fine with both normal and shadow DOM, in difference with Kendo code
	let target = root
		? root.elementFromPoint(event.clientX, event.originalEvent.clientY)
		: null;
	while (target && target.parentElement !== parent) {
		target = target.parentElement;
	}
	const children = parent.children;
	for (let i = 0; i < children.length; i++) {
		if (children[i] === target) {
			return i;
		}
	}
	return -1;
}

class CommonDragLogicMod extends CommonDragLogic {
	constructor(
		columnReorder: handler,
		groupReorder: handler,
		columnToGroup: handler
	) {
		super(columnReorder, groupReorder, columnToGroup);

		// redefine getGroupIndex to use fixed version of getIndex
		(this as any).getGroupIndex = function (event: any) {
			return getIndexMod(event, this.groupPanelDivElement);
		};

		// redefine getColumnIndex to use fixed version of getIndex
		(this as any).getColumnIndex = function (
			event: any,
			parent: HTMLElement
		) {
			if (!parent || parent.parentElement === this.groupPanelDivElement) {
				return -1;
			}
			const index = getIndexMod(event, parent);
			if (index === -1) {
				return -1;
			}
			for (let i = 0; i < parent.parentNode.children.length; i++) {
				if (parent.parentNode.children[i] === parent) {
					return this.columns.findIndex(
						(c: ExtendedColumnProps) =>
							c.index === index && c.depth === i
					);
				}
			}
			return -1;
		};
	}
}

export class GridMod extends Grid {
	constructor(props: GridProps) {
		super(props);

		this.dragLogic = new CommonDragLogicMod( // redefine drag logic with our fixed version
			(this as any).columnReorder.bind(this), // type cast to overcome privateness of properties
			(this as any).groupReorder.bind(this),
			(this as any).columnToGroup.bind(this)
		);
	}
}
