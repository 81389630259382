import React from 'react';
import { preToCodeBlock } from 'mdx-utils';
import reactElementToJSXString from 'react-element-to-jsx-string';

import { CodeHighlight } from './CodeHighlight';
import { PreviewWrapper } from './PreviewWrapper';

const parseMetaAndUpdateCodeString = (children) => {
	// If rendered through MDX pre block, convert to useful data
	const data = {...preToCodeBlock(children)};

	const metaData = {}
	const hasFrontmatter = data.codeString && data.codeString.split('---').length > 1;

	hasFrontmatter
	&& data.codeString.split('---')[0]
	&& data.codeString
		.split('---')[0]
		.match(/.+/g)
		.map(e => {
			metaData[e.split(/:(.+)/)[0].trim()] = e.split(/:(.+)/)[1].trim()
		})

	const newCodeString = hasFrontmatter ? data.codeString.split('---')[1] : data.codeString

	data.codeString = newCodeString
	data.metaData = metaData

	return data
}

export const Block = (props) => {
	const matchedProps = matchProps(props);
	const data = parseMetaAndUpdateCodeString(matchedProps)

	// If not rendered through MDX pre block, generate data manually
	if (!data) {
		data = {
			className: 'language-js',
			codeString: createCodeStringFromChildren(matchedProps.children),
			language: 'js'
		}
	}

	if (typeof data.codeString === 'string') data.codeString = data.codeString.trim();

	if (data.metaData.code) data.code = true;
	if (data.metaData.customRender) data.customRender = true;
	if (data.metaData.inline) data.inline = true;

	if (data.code) return <CodeHighlight {...data} />;
	return <PreviewWrapper {...data} />;
};

const createCodeStringFromChildren = (children) => {
	const newChildren = children;
	newChildren.type.displayName = newChildren.props.mdxType;

	return reactElementToJSXString(newChildren, {
		filterProps: ['mdxType', 'originalType'],
		showFunctions: true
	});
};

const matchProps = (props) => {
	if (props.children && props.children.type === 'code') {
		const newProps = {...props, children: {
			...props.children,
			props: {
				...props.children.props,
				mdxType: 'code',
				originalType: 'code',
				parentName: 'pre'
			}
		}}
		return newProps;
	} else {
		return props;
	}
};
