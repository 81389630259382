import React, { FC } from 'react';
import classnames from 'classnames';

import { SideNavItem, SideNavItemContext } from './SideNavItem';
import { SideNavGroup } from './SideNavGroup';
import { Headline } from '../Headline';
import { CollapsibleList } from '../../internal/CollapsibleList';
import { useArrowNav } from '../../hooks/useArrowNav';

/**
 * SideNav properties
 */
export interface SideNavPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	children?: React.ReactNode;

	/** Sets the Title for the Navigation List */
	title?: React.ReactNode;
}

export interface SideNavProps extends SideNavPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface SideNav extends FC<SideNavProps> {
	/** Subcomponents */
	Item: typeof SideNavItem;
	Group: typeof SideNavGroup;
}

export const SideNav: SideNav = ({
	children,
	className,
	title,
	'data-anvil-component': dataAnvilComponent,
	...rest
}) => {
	const sideNavItemContext = React.useContext(SideNavItemContext);
	const SideNavClasses = classnames('SideNav', className);
	const containerRef = useArrowNav({
		loop: false,
		onKeyDown: handleArrowKeys,
	});

	// Nested SideNav
	if (sideNavItemContext)
		return (
			<CollapsibleList className={SideNavClasses} el="div" {...rest}>
				<menu className="SideNav__list">{children}</menu>
			</CollapsibleList>
		);

	return (
		<div
			className={SideNavClasses}
			data-anvil-component="SideNav"
			ref={containerRef}
			{...rest}
		>
			{title !== undefined && (
				<Headline el="div" className="SideNav__title" size="small">
					{title}
				</Headline>
			)}
			<menu className="SideNav__list">{children}</menu>
		</div>
	);
};

SideNav.Item = SideNavItem;
SideNav.Group = SideNavGroup;

const handleArrowKeys = (e: KeyboardEvent, data: any) => {
	const itemClasses = data?.active?.parentElement?.classList;
	const isExpanded = itemClasses?.contains('CollapsibleListItem--expanded');
	const expandedItem = data?.active
		?.closest?.('.CollapsibleListItem--expanded')
		?.querySelector?.('.SideNavItem__button--expanded');

	if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') data.disableOnce(); // Prevent focus move on left-right
	if (isExpanded && e.key === 'ArrowRight') data.next.focus(); // Move focus to first focusable
	if (expandedItem && e.key === 'ArrowLeft') expandedItem?.focus(); // Move focus to closest expanded
};
