import { TimePickerOptionProps } from '../components/TimePickerOptionList';
import { TimePickerPropsStrict } from '../';

import {
	inputCaseName,
	spaceless,
	parseDigits,
	parseColon,
	parsePhrase,
} from './utils';

import { checkMinMax } from './checkMinMax';
import { stringToDate } from './stringToDate';
import { toTimeFormat } from './toTimeFormat';

// Getting most expected option based on user's input
export const getClosestOption = (
	options: TimePickerOptionProps[],
	userInput: string,
	defaultTimeSignifier: TimePickerPropsStrict['defaultTimeSignifier'],
	autoRounding: TimePickerPropsStrict['autoRounding'],
	min: TimePickerPropsStrict['min'],
	max: TimePickerPropsStrict['max'],
	format: TimePickerPropsStrict['format']
): TimePickerOptionProps => {
	if (spaceless(userInput) === '') return null;

	// Expected value based on user input
	const value = parseUserInput(userInput);

	if (!value) return null;

	const [hours, minutes, ampm] = value;
	const getDate = (signifier: string) =>
		stringToDate(`${hours}:${minutes} ${signifier}`);
	const getText = (date: Date) => toTimeFormat(date, format);
	const signifier = ampm || defaultTimeSignifier;

	// Inverte defaultTimeSignifier
	const invertedSignifier = defaultTimeSignifier === 'AM' ? 'PM' : 'AM';

	if (!autoRounding) {
		// Ignore dropdown options, which are based on `step` prop
		let v = getDate(signifier);
		if (checkMinMax(min, max, v)) {
			return { text: getText(v), value: v };
		}

		if (!ampm) {
			// Case if ampm is unknown and defaultTimeSignifier do not fit into checkMinMax range
			v = getDate(invertedSignifier);
			if (checkMinMax(min, max, v)) {
				return { text: getText(v), value: v };
			}
		}

		// Reject input, out of min-max range
		return null;
	}

	if (autoRounding) {
		// Look up for closest value in options
		let closestPair = getClosestIndexes(options, getDate(signifier));
		if (closestPair) return options[closestPair[0]];

		if (!ampm) {
			// Case if ampm is unknown
			closestPair = getClosestIndexes(
				options,
				getDate(invertedSignifier)
			);
			if (closestPair) return options[closestPair[0]];
		}
	}

	return null;
};

export const parseUserInput = (userInput: string): null | string[] => {
	const input = spaceless(userInput).toUpperCase();

	let parsed;

	switch (inputCaseName(input)) {
		case 'empty': // Empty string
			return null;
		case '1-digit': // "2"=02:00
		case '2-digit': // "23"=23:00, "02"=02:00, "24"=12:00 AM
		case '3-digit': // "023"=02:30, "234"=02:34, "028"=00:28
		case '4-digit': // "0234"=02:34
		case 'many-digits': // Cutting extra digits off
			parsed = parseDigits(input);
			break;
		case 'colon':
			parsed = parseColon(input);
			break;
		case 'phrase': // No colon, has letters
		default:
			parsed = parsePhrase(input);
	}

	// No expected value found
	if (!parsed || parsed.length < 2) return null;

	// Handling with defaultTimeSignifier
	if (parsed.length === 2) {
		// No signifier found in input
		parsed.push(null);
	}

	// Case of unknown signifier
	if (parsed[2] === null || !(parsed[2] === 'AM' || parsed[2] === 'PM')) {
		parsed[2] = null;
	}

	const hours = parseInt(parsed[0], 10);
	const minutes = parseInt(parsed[1], 10);

	const [maxHours, maxMinutes] = [23, 59];

	// Reject impossible hours value, e.g. "88:88"
	if (maxHours < hours) {
		return null;
	}

	// Reject impossible minutes value, e.g. "88:88"
	if (maxMinutes < minutes) {
		return null;
	}

	// Detect 24-hour format to ignore defaultTimeSignifier
	if (hours > 12) {
		// Extra case? (hours === 12 && minutes !== 0)
		parsed[0] = hours < 22 ? `0${hours - 12}` : `${hours - 12}`;
		parsed[2] = 'PM';
	}

	return parsed;
};

export const getClosestIndexes = (
	options: TimePickerOptionProps[],
	value: TimePickerOptionProps['value']
): null | number[] => {
	const dates = options.map((d) => d.value.getTime());
	const date = value.getTime();

	// No options provided
	if (options.length < 1) {
		return null;
	}

	// Out of range
	if (date < dates[0] || date > dates[dates.length - 1]) {
		return null;
	}

	// Value is equal to an option
	const exactMatch = dates.indexOf(date);
	if (exactMatch !== -1) {
		return [exactMatch];
	}

	// Value is between two options
	let greaterThanIdx = -1;

	dates.some((d) => {
		if (d > date) {
			greaterThanIdx = dates.indexOf(d);
		}
		return d > date;
	});

	if (greaterThanIdx === -1) {
		console.warn(
			'@servicetitan/design-system: Something wrong with TimePicker.getClosestIndexes()'
		);
		return null;
	}

	const distanceToNext = dates[greaterThanIdx] - date;
	const distanceToPrev = date - dates[greaterThanIdx - 1];

	// Date is closer to next
	if (distanceToNext < distanceToPrev) {
		return [greaterThanIdx, greaterThanIdx - 1];
	}

	// Date is closer to prev
	return [greaterThanIdx - 1, greaterThanIdx];
};
