import React, { FC } from 'react';
import classnames from 'classnames';

import { DataListHeaderRow, DataListHeaderRowType } from './DataListHeaderRow';
import {
	DataListHeaderCell,
	DataListHeaderCellProperties,
	DataListHeaderCellType,
} from './DataListHeaderCell';
import { DataListRow, DataListRowType } from './DataListRow';
import { DataListCell, DataListCellType } from './DataListCell';

interface DataListItem {
	[fieldName: string]: any | undefined;
}

interface DataListPropsStrict {
	header: DataListHeaderCellType[] | DataListHeaderCellType;
	children?: DataListRowType[] | DataListRowType;
	items?: DataListItem[];
	className?: string;
	spacing?: 1 | 2;
	simple?: boolean;
}

interface DataListProps extends DataListPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

interface DataList extends FC<DataListProps> {
	HeaderRow: typeof DataListHeaderRow;
	HeaderCell: typeof DataListHeaderCell;
	Row: typeof DataListRow;
	Cell: typeof DataListCell;
}

export const DataList: DataList = ({
	children,
	className,
	header,
	items,
	spacing = 2,
	simple,
	...rest
}) => {
	const generateRows = (
		header: DataListPropsStrict['header'],
		items: DataListPropsStrict['items']
	): DataListHeaderRowType[] => {
		const actualheader: DataListHeaderCellType[] = Array.isArray(header)
			? header
			: [header];

		const headersProperties: DataListHeaderCellProperties[] =
			actualheader.map(
				(
					headerCell: DataListHeaderCellType
				): DataListHeaderCellProperties => headerCell.props
			);

		const rows: DataListHeaderRowType[] = items.map(
			(item: DataListItem, index: number): DataListHeaderRowType => {
				return (
					<DataListRow key={index}>
						{generateCells(item, headersProperties)}
					</DataListRow>
				);
			}
		);

		return rows;
	};

	const generateCells = (
		item: DataListItem,
		headersProperties: DataListHeaderCellProperties[]
	): DataListCellType[] => {
		return headersProperties.map(
			(
				headerProperties: DataListHeaderCellProperties,
				index: number
			): DataListCellType => {
				const { name, children, className, ...cellProperties } =
					headerProperties;
				return (
					<DataListCell key={index} {...cellProperties}>
						{item[headerProperties.name]}
					</DataListCell>
				);
			}
		);
	};

	const classes = classnames(
		'DataList',
		className,
		`DataList--spacing-${spacing}`,
		{
			'DataList--Simple': simple,
		}
	);

	const headerRow: DataListHeaderRowType = (
		<DataListHeaderRow>{header}</DataListHeaderRow>
	);
	const rows: DataListRowType[] | DataListRowType =
		children || generateRows(header, items);

	return (
		<div className={classes} data-anvil-component="DataList" {...rest}>
			{headerRow}
			{rows}
		</div>
	);
};

DataList.HeaderRow = DataListHeaderRow;
DataList.HeaderCell = DataListHeaderCell;
DataList.Row = DataListRow;
DataList.Cell = DataListCell;
