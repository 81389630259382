/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import '@servicetitan/anvil-fonts/dist/css/anvil-fonts.css';
import '@servicetitan/design-system/dist/system.css';
import './src/styles/styles.scss';
import Root from './src/templates/Root';

export const wrapRootElement = Root;

// Prevent window scrolling, as scrollbar is on ('.Page__page')
export const onInitialClientRender = () => {
	window.addEventListener('scroll', () => {
		window.scrollTo(0, 0);
	});
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	if (location.hash) {
		// Inside-page navigation handling, e.g. if hash (#something), scroll to certain item
		return scrollPageTo({ hash: location.hash });
	};

	// Page-to-page navigation handling
	const savedTopPosition = getSavedScrollPosition(location)[1] || 0;
	return scrollPageTo({ top: savedTopPosition }); // Browser's back-forward nav or navigating to a page, scroll to last saved position
};

const scrollPageTo = ({hash, top}) => {
	const page = document.querySelector('.Page__page');
	const item = document.querySelector(`${hash}`);
	const position = top || item?.offsetTop - 24;

	if (!page) return true;

	Promise.resolve().then(() => {
		setTimeout(() => page.scrollTo({ top: position, behavior: 'instant' }), 150);
	});

	return false;
};
