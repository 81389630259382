import React, { FC } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { tokens } from '@servicetitan/tokens/core';

/**
 * Collapsible properties
 */
export interface CollapsiblePropsStrict {
	/** Show animation if true */
	animate?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/**
	 * *Warning* Only works when animate={true}
	 * CSS easing function to be applied to the animation
	 */
	easing?: string;

	/** Whether the Collapsible is expanded or not */
	open?: boolean;
}

export interface CollapsibleProps extends CollapsiblePropsStrict {
	[propName: string]: any;
}

export const Collapsible: FC<CollapsibleProps> = React.forwardRef(
	(
		{
			animate,
			children,
			className,
			el: CollapsibleElement = 'div',
			easing = tokens.easingStandard,
			open = false,
			...props
		},
		ref
	) => {
		const CollapsibleClasses = classnames('Collapsible', className, {
			'Collapsible--hidden': !open,
			'Collapsible--animated': animate,
		});

		const animateHeightProps = {
			animateOpacity: true,
			duration: parseInt(tokens.durationSlow, 10),
			easing,
			height: (open ? 'auto' : 0) as any,
		};

		return (
			<CollapsibleElement
				className={CollapsibleClasses}
				data-anvil-component="Collapsible"
				ref={ref}
				{...props}
			>
				{animate ? (
					<AnimateHeight {...animateHeightProps}>
						{children}
					</AnimateHeight>
				) : (
					children
				)}
			</CollapsibleElement>
		);
	}
);
