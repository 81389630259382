import React, { FC } from 'react';
import classnames from 'classnames';
import { useFocusVisible } from '../../hooks/useFocusVisible';
import { Stack } from '../Stack';
import { Icon, IconPropsStrict } from '../Icon';
import { BodyText } from '../BodyText';
import { Tag } from '../Tag';

export interface FilterButtonPropsStrict {
	/** Additional classes */
	className?: string;

	/** React element to be rendered as an icon */
	icon?: IconPropsStrict['name'];

	/** To show expand icon */
	expandIcon?: boolean | null;

	/** Displays the label of the Filter Button */
	label: string;

	/** Displays the value of the Filter Button */
	value?: boolean | number | string;

	/** Calls when the Filter Button is clicked  */
	onClick?: () => void;
}

export interface FilterButtonProps extends FilterButtonPropsStrict {
	[propName: string]: any;
}

export const FilterButton: FC<FilterButtonProps> = React.forwardRef(
	(
		{ className, icon, onClick, expandIcon = null, value, label, ...rest },
		ref: React.RefObject<HTMLButtonElement>
	) => {
		const [IValue, setIValue] = React.useState(value);

		const newRef = React.useRef(null);
		const itemRef = ref ? ref : newRef;

		const { isFocusVisible } = useFocusVisible(itemRef);

		const filterButtonClassName = classnames('FilterButton', className, {
			'FilterButton--active': IValue,
			'FilterButton--focus-visible': isFocusVisible,
		});

		const onClickHandler = () => {
			onClick?.();
		};

		React.useEffect(() => {
			setIValue(value);
		}, [value]);

		return (
			<button
				className={filterButtonClassName}
				onClick={() => onClickHandler()}
				ref={itemRef}
				aria-expanded={expandIcon}
				aria-label={`Filter ${label}`}
				aria-pressed={!!IValue}
				data-anvil-component="FilterButton"
				{...rest}
			>
				<Stack alignItems="center">
					{icon && (
						<Icon
							name={icon}
							size="16px"
							className="FilterButton__Icon"
						/>
					)}
					{!icon && label && (
						<BodyText
							el="span"
							size="small"
							className="FilterButton__Label"
						>
							{label}
						</BodyText>
					)}
					{typeof IValue === 'string' ? (
						<BodyText el="span" size="small">
							{`: `}
							<BodyText el="span" size="small" bold>
								{IValue}
							</BodyText>
						</BodyText>
					) : typeof IValue === 'number' ? (
						<Tag
							color="info"
							badge
							compact
							className="FilterButton__Tag"
						>
							{IValue}
						</Tag>
					) : null}
					{expandIcon !== null && (
						<Icon
							name={
								expandIcon
									? 'keyboard_arrow_up'
									: 'keyboard_arrow_down'
							}
							className="FilterButton__ExpandIcon"
						/>
					)}
				</Stack>
			</button>
		);
	}
);
