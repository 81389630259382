import React, { FC } from 'react';
import classnames from 'classnames';

export interface Props {
	/** Adds one or more classnames to an element */
	className?: string;
	completed?: boolean;
	[propName: string]: any;
}

export const ProgressTrackerDivider: FC<Props> = ({
	className,
	completed,
	...rest
}) => {
	const ProgressTrackerDividerClasses = classnames(
		'ProgressTrackerDivider',
		className,
		{
			'ProgressTrackerDivider--completed': completed,
		}
	);

	return <div className={ProgressTrackerDividerClasses} {...rest} />;
};
