import React, { FC } from 'react';
import classnames from 'classnames';

import {
	columnsToNumeral,
	SemanticGridWIDTHS,
} from '../../utilities/SemanticUtils';
import { GridColumn } from './GridColumn';
import { GridRow } from './GridRow';

const GridDefaultColumnsCount = 12;

export type GridTextAlign = 'left' | 'right' | 'center' | 'justify';
export type GridVerticalAlign = 'bottom' | 'middle' | 'top';
export type GridColumnsCount = SemanticGridWIDTHS | 'equal';
export type ViewportWidthSegment =
	| 'mobile'
	| 'tablet'
	| 'desktop'
	| 'largescreen'
	| 'widescreen';

/**
 * Grid properties
 */
export interface GridPropsStrict {
	/** A grid can have rows divided into cells */
	celled?: boolean | 'internally';

	/** A grid can have its columns centered */
	centered?: boolean;

	/** Adds one or more classnames to an element */
	className?: string;

	/** Represents column count per row */
	columns?: GridColumnsCount;

	/** A grid can have dividers between its columns */
	divided?: boolean | 'vertically';

	/** A grid can double its column width on tablet and mobile sizes */
	doubling?: boolean;

	/** Set the wrapping element's tagname */
	el?: any;

	/** A grid can preserve its vertical and horizontal gutters on first and last columns */
	padded?: boolean | 'horizontally' | 'vertically';

	/** A grid can increase its gutters to allow for more negative space */
	relaxed?: boolean | 'very';

	/** A grid can specify that its columns should reverse order per device */
	reversed?: string;

	/** A grid can have its columns stack on-top of each other after reaching mobile breakpoints */
	stackable?: boolean;

	/** A grid can stretch its contents to take up the entire grid height */
	stretched?: boolean;

	/** Alignment of content */
	textAlign?: GridTextAlign;

	/** Vertical alignment of content */
	verticalAlign?: GridVerticalAlign;
}

export interface GridProps extends GridPropsStrict {
	[propName: string]: any;
}

interface Grid extends FC<GridProps> {
	/** Subcomponents */
	Row: typeof GridRow;
	Column: typeof GridColumn;
}

export const Grid: Grid = ({
	celled,
	centered,
	children,
	className,
	columns = GridDefaultColumnsCount,
	divided,
	doubling,
	el: GridElement = 'div',
	padded,
	relaxed,
	reversed,
	stackable,
	stretched,
	textAlign,
	verticalAlign,
	...props
}) => {
	// Composing classes out of "reversed" prop
	const reversedSet = new Set(
		typeof reversed === 'string'
			? reversed.trim().split(' ').filter(Boolean)
			: []
	);

	const reversedClasses = ['mobile', 'tablet', 'desktop']
		.filter((device) => reversedSet.has(device))
		.map((device) =>
			reversedSet.has('vertically')
				? `Grid--${device}-vertically-reversed`
				: `Grid--${device}-reversed`
		);

	const GridClasses = classnames('Grid', className, reversedClasses, {
		'Grid--celled': celled,
		'Grid--internally-celled': celled === 'internally',
		'Grid--centered': centered,
		[`Grid--${columnsToNumeral(columns)}-columns`]:
			columnsToNumeral(columns),
		[`Grid--equal-width Grid--${columnsToNumeral(
			GridDefaultColumnsCount
		)}-columns`]: columns === 'equal',
		'Grid--divided': divided,
		'Grid--vertically-divided': divided === 'vertically',
		'Grid--doubling': doubling,
		'Grid--padded': padded,
		'Grid--vertically-padded': padded === 'vertically',
		'Grid--horizontally-padded': padded === 'horizontally',
		'Grid--relaxed': relaxed,
		'Grid--very-relaxed': relaxed === 'very',
		'Grid--stackable': stackable,
		'Grid--stretched': stretched,
		[`Grid--text-align-${textAlign}`]: textAlign,
		[`Grid--vertical-align-${verticalAlign}`]: verticalAlign,
	});

	return (
		<GridElement
			className={GridClasses}
			data-anvil-component="Grid"
			{...props}
		>
			{children}
		</GridElement>
	);
};

Grid.Column = GridColumn;
Grid.Row = GridRow;
