import React, { FC, useState, useEffect, useId } from 'react';
import classnames from 'classnames/dedupe';
import { tokens } from '@servicetitan/tokens/core';
import { Popper, PopperProps } from '../../internal/Popper';

export interface TooltipPropsStrict {
	/** Adds one or more classnames for a Tooltip's trigger wrapper */
	className?: string;

	/** Tooltips direction relative to the element its apart of */
	direction?:
		| 'top'
		| 't'
		| 'right'
		| 'r'
		| 'bottom'
		| 'b'
		| 'left'
		| 'l'
		| 'topleft'
		| 'tl'
		| 'topright'
		| 'tr'
		| 'bottomleft'
		| 'bl'
		| 'bottomright'
		| 'br';

	/** Change the HTML element the Tooltip uses */
	el?: 'span' | 'div';

	/** Whether the Tooltip is open or not */
	open?: boolean;

	/**
	 * Renders Tooltip on document root through createPortal.
	 * Effectively prevents Tooltip from being clipped by the boundaries of any container.
	 */
	portal?: boolean;

	/**
	 * Adds one or more classnames to the Tooltip itself
	 */
	tooltipClassName?: string;

	/** Tooltip text */
	text?: string;

	/** Do not open Tooltip on focus */
	hoverOnly?: boolean;

	/** Adds dashed underline on Tooltip's trigger when Tooltip is not visible */
	underline?: boolean;

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];

	/** Handle show state */
	onShow?: () => void;

	/** Handle hide state */
	onHide?: () => void;
}

export interface TooltipProps extends TooltipPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Tooltip: FC<TooltipProps> = ({
	children,
	className,
	direction = 'top',
	el: TooltipElement = 'span',
	open: forceOpen = false,
	text,
	hoverOnly,
	tooltipClassName,
	onShow,
	onHide,
	underline = false,
	portalDataAttributes,
	...props
}) => {
	const [open, setOpen] = useState(false);
	const [reveal, setReveal] = useState(false);
	const [touch, setTouch] = useState(false);

	/* Delay prevents unintentional hover triggering */
	const delay: number = parseInt(tokens.durationSlow, 10); // '300ms' -> 300

	useEffect(() => {
		const timeout = setTimeout(() => {
			setOpen(!!reveal);
			!reveal && onShow?.();
			reveal && onHide?.();
		}, delay);
		return () => {
			clearTimeout(timeout);
		};
	}, [delay, onHide, onShow, reveal]);

	useEffect(() => {
		if (touch) {
			setOpen(touch);
			onShow?.();
			const timeout = setTimeout(() => {
				setOpen(!touch);
				setTouch(!touch);
				onHide?.();
			}, 1500);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [onHide, onShow, touch]);

	const handleShow = () => {
		setReveal(true);
	};
	const handleShowOnFocus = () => {
		if (!hoverOnly) {
			setReveal(true);
		}
	};
	const handleHide = () => {
		setReveal(false);
	};

	const handleTouch = () => {
		setTouch(true);
	};

	const getDirection = () => {
		if (direction === 'top') return 't';
		if (direction === 'topright') return 'tr';
		if (direction === 'topleft') return 'tl';
		if (direction === 'bottom') return 'b';
		if (direction === 'bottomright') return 'br';
		if (direction === 'bottomleft') return 'bl';
		if (direction === 'right') return 'r';
		if (direction === 'left') return 'l';
		return direction;
	};

	const tooltipVisible = text && (open || forceOpen);

	const id = useId();
	const describedby = props.id || `tooltip_id_${id}`;

	const TooltipClasses = classnames('Tooltip', className, {
		'Tooltip--underline': !tooltipVisible && underline,
	});

	const TooltipBodyClasses = classnames('Tooltip__body', tooltipClassName, {
		[`Tooltip--${getDirection()}`]: getDirection(),
		'Tooltip__body--open': tooltipVisible,
	});

	return (
		<Popper
			className={TooltipClasses}
			width="auto"
			autoFlipVertically
			onMouseEnter={handleShow}
			onMouseLeave={handleHide}
			onTouchStart={handleTouch}
			onFocus={handleShowOnFocus}
			onBlur={handleHide}
			direction={getDirection()}
			el={TooltipElement}
			open={tooltipVisible}
			trigger={
				<span
					aria-describedby={tooltipVisible ? describedby : undefined}
				>
					{children}
				</span>
			}
			focusTrapOptions={{ disabled: true }}
			caret={{ show: true, className: 'Tooltip__caret' }}
			portalDataAttributes={portalDataAttributes}
			{...props}
		>
			<span
				className={TooltipBodyClasses}
				role="tooltip"
				tabIndex={-1}
				aria-hidden="true"
				id={describedby}
				data-anvil-component="Tooltip"
			>
				{text}
			</span>
		</Popper>
	);
};
