import React, { Component } from 'react';
import classnames from 'classnames';

import {
	Dropdown as SemanticDropdown,
	DropdownProps as SemanticDropdownProps,
	DropdownOnSearchChangeData,
	DropdownItemProps,
	DropdownDivider,
	DropdownHeader,
	DropdownItem,
	DropdownMenu,
	DropdownSearchInput,
	Ref,
} from 'semantic-ui-react';
import { DropdownIcon } from './DropdownIcon';
import { debounce } from '../../utilities/debounce';
import { Icon } from '../Icon';
import { FormFieldContext } from '../FormField';

export interface DropdownProps extends SemanticDropdownProps {
	asyncOptions?: {
		initial?: DropdownItemProps[];
		dataFetcher(searchQuery: string): Promise<DropdownItemProps[]>;
		fetchOnFocus?: boolean;
	};
}

interface DropdownState {
	loading: boolean;
	options: DropdownItemProps[];
}

export class Dropdown extends Component<DropdownProps, DropdownState> {
	static Divider: typeof DropdownDivider = DropdownDivider;
	static Header: typeof DropdownHeader = DropdownHeader;
	static Item: typeof DropdownItem = DropdownItem;
	static Menu: typeof DropdownMenu = DropdownMenu;
	static SearchInput: typeof DropdownSearchInput = DropdownSearchInput;

	static defaultProps = {
		icon: <DropdownIcon />,
	};
	static contextType = FormFieldContext;
	context!: React.ContextType<typeof FormFieldContext>;

	private dropdownRef: React.RefObject<HTMLElement>;

	constructor(props: DropdownProps) {
		super(props);

		this.state = {
			loading: false,
			options: props.asyncOptions?.initial || [],
		};
		this.dropdownRef = React.createRef();
	}

	componentDidMount() {
		this.context.setFormFieldContext({ inputRef: this.dropdownRef });
	}

	handleSearch = () => {
		if (Array.isArray(this.props.value)) {
			return this.getOptionsWithoutValue(this.state.options);
		}

		return this.state.options;
	};

	handleFocus = (
		event: React.FocusEvent<HTMLElement>,
		data: DropdownProps
	) => {
		const searchData: DropdownOnSearchChangeData = {
			...this.props,
			searchQuery: '',
		};
		this.handleSearchChange(event, searchData);

		if (this.props.onFocus) {
			this.props.onFocus(event, data);
		}
	};

	handleSearchChange = debounce(
		async (
			event: React.SyntheticEvent<HTMLElement>,
			data: DropdownOnSearchChangeData
		) => {
			this.setState({ loading: true });

			const options = this.getOptionsWithValue(
				await this.props.asyncOptions.dataFetcher(data.searchQuery)
			);

			this.setState({ options, loading: false });

			if (this.props.onSearchChange) {
				this.props.onSearchChange(event, data);
			}
		}
	);

	handleClearClick = (event: React.ChangeEvent<HTMLElement>) => {
		if (this.props.onChange)
			this.props.onChange(event, { value: undefined });
	};

	getClearIcon = () => {
		if (this.isEmptyValue || !this.props.clearable) {
			return null;
		}

		return (
			<Icon
				name="clear"
				className="Select__clear-icon"
				onClick={this.handleClearClick}
			/>
		);
	};

	getOptionsWithValue(options: DropdownItemProps[]) {
		const values = this.arrayValue;

		const missingOptions = values
			.filter(
				(value) =>
					options.findIndex((option) => option.value === value) === -1
			)
			.map((value) =>
				this.state.options.find((option) => option.value === value)
			);

		if (missingOptions.length > 0) {
			options.push(...missingOptions);
		}

		return options;
	}

	getOptionsWithoutValue(options: DropdownItemProps[]) {
		const values = this.arrayValue;

		const optionsWithoutValue = options.filter(
			(option) =>
				values.findIndex((value) => option.value === value) === -1
		);

		return optionsWithoutValue;
	}

	get isEmptyValue() {
		return (
			this.props.value === undefined ||
			this.props.value === '' ||
			(Array.isArray(this.props.value) && this.props.value.length === 0)
		);
	}

	get arrayValue() {
		return this.isEmptyValue
			? []
			: !Array.isArray(this.props.value)
			? [this.props.value]
			: this.props.value;
	}

	render() {
		const { asyncOptions, clearable, className, ...passedProps } =
			this.props;

		const props = { ...passedProps };
		props.className = classnames(className, {
			'Select--clearable': clearable,
		});

		if (asyncOptions) {
			props.search = this.handleSearch; // hijack search when data fetcher present as search happens remotely
			props.onSearchChange = this.handleSearchChange;
			props.onFocus = asyncOptions.fetchOnFocus
				? this.handleFocus
				: props.onFocus;
			props.loading = this.state.loading;
			props.options = this.state.options;
		}

		return (
			<div className="semantic-anvil-theme Select-wrapper">
				<Ref innerRef={this.dropdownRef}>
					<SemanticDropdown {...props} />
				</Ref>
				{this.getClearIcon()}
			</div>
		);
	}
}
