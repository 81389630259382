import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon, IconPropsStrict } from '../Icon';
import { getContent } from '../../utilities/getContent';

/**
 * Button properties
 */
export interface ButtonPropsStrict {
	/** Button Color */
	color?: 'primary' | 'blue' | 'default' | 'grey' | 'negative' | 'red';

	/** Additional classes */
	className?: string;

	/** Disabled button, unclickable */
	disabled?: boolean;

	/** Set the component HTML element. Warning: Using non-standard markup can produce accessibility issues. */
	el?: 'button' | 'a' | 'span';

	/** Button Type. 'none' is deprecated, use 'subtle' instead. */
	fill?: 'solid' | 'outline' | 'none' | 'subtle';

	/** Shortcut for `width="full"` */
	full?: boolean;

	/** URL for Button. Turns into `<a>` tag. */
	href?: string;

	/** Custom Icon element. Use `iconName` to use default element. */
	icon?: React.ReactElement;

	/** Name of Icon to be inside Button. Use `icon` to pass a custom element. */
	iconName?: IconPropsStrict['name'];

	/** Position the icon */
	iconPosition?: 'left' | 'right';

	/** Unclicable button */
	inactive?: boolean;

	/** Shortcut for `size="large"` */
	large?: boolean;

	/** Button with loading indicator */
	loading?: boolean;

	/** Shortcut for `color="negative"` */
	negative?: boolean;

	/** Shortcut for `fill="outline"` */
	outline?: boolean;

	/** Shortcut for `color="primary"` */
	primary?: boolean;

	/** Selected State */
	selected?: boolean;

	/** Button Size */
	size?: 'xsmall' | 'small' | 'medium' | 'large';

	/** Shortcut for `size="small"` */
	small?: boolean;

	/** Deprecated. Shortcut for `fill="subtle"` */
	text?: boolean;

	/** Button Width */
	width?: string | number | 'auto' | 'full';

	/** Shortcut for `size="small"` */
	xsmall?: boolean;
}

export interface ButtonProps extends ButtonPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

const defaultProps = {
	el: 'button',
};

/**
 * **Buttons** provide a possible user actions
 */
export const Button: FC<ButtonProps> = React.forwardRef(
	(
		{
			children,
			className,
			color = 'default',
			content,
			el: Element = defaultProps.el,
			fill = 'solid',
			full,
			href,
			icon,
			iconName,
			iconPosition = 'left',
			inactive,
			large,
			loading,
			negative,
			outline,
			primary,
			selected,
			size = 'medium',
			small,
			text,
			type,
			width = 'auto',
			xsmall,
			onFocus,
			onBlur,
			...props
		},
		ref: React.RefObject<HTMLElement>
	) => {
		const getTagName = () => {
			// If `el` is passed in and isn't default, it is always used
			if (Element !== defaultProps.el) return Element;

			// If `href` is passed in, the element becomes an anchor
			if (href) return 'a';

			// Is default, use it
			return Element;
		};

		const getTabIndex = () => {
			if (props.tabIndex) return props.tabIndex;
			if (getTagName() !== 'button') 0;
			return null;
		};

		const getColorClass = () => {
			let realColor = color;

			// Override string props with boolean values
			if (primary) realColor = 'primary';
			if (negative) realColor = 'negative';

			const ButtonColorClasses: any = {
				primary: 'Button--blue',
				blue: 'Button--blue',
				default: 'Button--grey',
				grey: 'Button--grey',
				negative: 'Button--red',
				red: 'Button--red',
			};

			return ButtonColorClasses[realColor];
		};

		const getFillClass = () => {
			let realFill = fill;
			if (outline) realFill = 'outline';
			if (text || fill === 'subtle' || fill === 'none')
				realFill = 'subtle';

			if (realFill === 'solid') return 'Button--solid';
			if (realFill === 'outline') return 'Button--outline';
			if (realFill === 'subtle') return 'Button--subtle';
		};

		const getSizeClass = () => {
			let realSize = size;
			if (xsmall) realSize = 'xsmall';
			if (small) realSize = 'small';
			if (large) realSize = 'large';

			if (realSize === 'xsmall') return 'Button--xsmall';
			if (realSize === 'small') return 'Button--small';
			if (realSize === 'large') return 'Button--large';
		};

		const getWidth = () => {
			if (full) return 'full';
			return width;
		};

		const getWidthValue = () => {
			const width = getWidth();
			return width !== 'auto' && width !== 'full' ? width : false;
		};

		const getWidthClass = () => {
			return getWidth() === 'full' ? 'Button--full' : null;
		};

		const getTypeAttribute = () => {
			if (type) return { type };
			if (getTagName() === 'button') return { type: 'button' };
		};

		React.useEffect(() => {
			if (text)
				console.warn(
					"@servicetitan/design-system: The `text` prop of the Button component has been deprecated. Please use `fill='subtle'` instead."
				);

			if (fill === 'none')
				console.warn(
					"@servicetitan/design-system: The `fill='none'` prop of the Button component has been deprecated. Please use `fill='subtle'` instead."
				);

			if (getTagName() !== 'button' && !!getTypeAttribute())
				throw new Error(
					'@servicetitan/design-system: The Button component is using incompatible props. Only button elements can have type attributes.'
				);

			if (getTagName() !== 'a' && href)
				throw new Error(
					'@servicetitan/design-system: The Button component is using incompatible props. Only anchor elements can have href attributes.'
				);
		});

		const hasContent = () => {
			if (children != null) return true;
			if (content != null) return true;
			return false;
		};

		const hasIcon = () => {
			if (iconName || icon) return true;
			return false;
		};

		const renderIcon = () => {
			if (!hasIcon()) return;

			let ButtonIcon = null;
			if (iconName)
				ButtonIcon = (
					<Icon className="Button__icon-svg" name={iconName} />
				);
			if (icon) ButtonIcon = icon;

			const ButtonIconClasses = classnames('Button__icon', {
				'Button__icon--left': hasContent() && iconPosition === 'left',
				'Button__icon--right': hasContent() && iconPosition === 'right',
			});

			return <span className={ButtonIconClasses}>{ButtonIcon}</span>;
		};

		const preventClicks = props.disabled || inactive || loading;

		const ButtonClasses = classnames(
			'Button',
			className,
			getColorClass(),
			getFillClass(),
			getSizeClass(),
			getWidthClass(),
			{
				'Button--disabled': props.disabled,
				'Button--inactive': preventClicks,
				'Button--loading': loading,
				'Button--icon-only': !hasContent(),
				'Button--selected': selected,
			}
		);

		const ButtonElement: any = getTagName();

		!hasContent() &&
			!props['aria-label'] &&
			console.warn(
				`Accessibility warning:
				\naria-label attribute does not exist or is empty for button that does not have inner text that is visible to screen readers`
			);

		return (
			<ButtonElement
				className={ButtonClasses}
				disabled={preventClicks}
				href={href}
				role={getTagName() !== 'button' ? 'button' : null}
				style={{ width: getWidthValue() }}
				tabIndex={getTabIndex()}
				ref={ref}
				data-anvil-component="Button"
				{...getTypeAttribute()}
				{...props}
			>
				<span className="Button__content">
					{iconPosition === 'left' && renderIcon()}
					{getContent({ children, content })}
					{iconPosition === 'right' && renderIcon()}
				</span>
			</ButtonElement>
		);
	}
);
