import React from 'react';
import { Card } from '@servicetitan/design-system';
import { tokens } from '@servicetitan/tokens/core';

export const ImageBlock = ({alt, src, customBackground, noPadding, suggest, children}) => {
	const test = suggest ? suggest : null;
	const altText = alt ? alt : src ? src.split("/").pop() : "";
	const getColor = (value) => {
		switch (value) {
			case "dont":
				return tokens.colorRed200
			case "do":
				return tokens.colorGreen200
			case "caution":
				return tokens.colorYellow200
		}
		return null;
	}

	return (
		<Card light={!customBackground} padding="none" elevation={customBackground ? "base" : ""} style={customBackground && {background: 'transparent'}}>
			<Card.Section
				className={noPadding ? "" : "p-3-i ta-center"}
				style={{ border: '1px solid ' + getColor(test) }}
			>
				{src && <img
					srcset={src + " 2x"}
					alt={altText}
					className="max-w-100 ta-center"
				/>}
				{children && <div className="ta-left">{children}</div>}
			</Card.Section>
		</Card>
	)
}
