import React, { FC } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export interface BackLinkPropsStrict {
	pageLabel?: string;
	className?: string;
	href?: string;
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export interface BackLinkProps extends BackLinkPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const BackLink: FC<BackLinkProps> = ({
	className,
	pageLabel,
	onClick,
	onFocus,
	onBlur,
	...props
}) => {
	const itemRef = React.useRef(null);

	const { isFocusVisible, focusFunction, blurFunction } = useFocusVisible(
		itemRef,
		onBlur,
		onFocus
	);

	const BackLinkClasses = classnames('BackLink', className, {
		'BackLink--focus-visible': isFocusVisible,
	});

	const LinkElement = props.href ? 'a' : 'button';

	return (
		<LinkElement
			className={BackLinkClasses}
			onClick={onClick}
			onFocus={focusFunction}
			onBlur={blurFunction}
			ref={itemRef}
			data-anvil-component="BackLink"
			{...props}
		>
			<Icon className="BackLink__back-icon" name="chevron_left" />
			<span className="BackLink__label">
				{pageLabel ? `Back to ${pageLabel}` : `Back`}
			</span>
		</LinkElement>
	);
};
