import React, { FC } from 'react';
import { useVisuallyHidden } from '../../hooks/useVisuallyHidden';

/**
 * VisuallyHidden properties
 */
export interface VisuallyHiddenProps {
	children?: React.ReactNode;
	hidden?: boolean;
}

export const VisuallyHidden: FC<VisuallyHiddenProps> = React.forwardRef(
	({ hidden = false, children }, ref: React.RefObject<HTMLDivElement>) => {
		const { hiddenStyle } = useVisuallyHidden();

		return (
			<div
				ref={ref}
				style={hidden ? hiddenStyle : null}
				data-anvil-component="VisuallyHidden"
			>
				{children}
			</div>
		);
	}
);
