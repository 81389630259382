import React, { Ref, FC, ReactNode, ReactElement } from 'react';
import classnames from 'classnames';
import { Popover, PopoverProps } from '../Popover';
import { OptionList, OptionListProps } from '../OptionList';
import { BodyText } from '../BodyText';
import { Spinner } from '../Spinner';
import { Stack } from '../Stack';
import {
	AnvilSelectFooter,
	AnvilSelectFooterProps,
	AnvilSelectHeader,
	AnvilSelectHeaderProps,
	AnvilSelectMultipleProps,
	AnvilSelectOptionsProps,
	AnvilSelectSearchProps,
	AnvilSelectTreeProps,
	SelectDrillInHeader,
	SelectDrillInFooter,
	SelectDrillInContent,
	SelectDrillInProps,
	Trigger,
	TriggerProps,
	FocusProvider,
} from './components';
import {
	FocusActionType,
	FocusContext,
	getInitialState,
	doesHeaderExist,
	doesFooterExist,
} from './utils';
import { keys } from '../../utilities/keyCodes';
import { PopperProps } from '../../internal';

export type PrimitiveValueType =
	| string
	| number
	| bigint
	| symbol
	| null
	| undefined;

export interface AnvilSelectPropsStrict<
	TValue = AnvilSelectOptionsProps | AnvilSelectOptionsProps[]
> extends Pick<
		PopoverProps,
		| 'autoFlipHorizontally'
		| 'autoFlipVertically'
		| 'onClickOutside'
		| 'open'
		| 'portal'
	> {
	children?(props: OptionListProps): ReactNode;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Closes the Select on outside click */
	closeOnClickOutside?: boolean;

	/** Indicates whether the Select is disabled or not. */
	disabled?: boolean;

	/** The secondary */
	drillIn?: SelectDrillInProps;

	/** Message when a Select has no available options */
	emptyResult?: React.ReactNode;

	/** Visual error state for the trigger */
	error?: boolean;

	/** Footer for the Select */
	footer?: AnvilSelectFooterProps | PopoverProps['footer'];

	/** Header for the Select */
	header?: AnvilSelectHeaderProps;

	/** Adds a Spinner visualization to the Select */
	loading?: boolean;

	/** Enables multiple selections */
	multiple?: AnvilSelectMultipleProps | boolean;

	/** Called when the user changes the value */
	onChange?: (
		data: TValue,
		checked: boolean,
		childObject: AnvilSelectOptionsProps | AnvilSelectOptionsProps[],
		selfObject: AnvilSelectOptionsProps
	) => void;

	/** A handler that is called on every open and close action */
	onOpenChange?(open: boolean, value?: AnvilSelectOptionsProps[]): void;

	/** Choices for the Select */
	options?: AnvilSelectOptionsProps[];

	/** Define the width property of the popover part of the Select */
	popoverWidth?: PopoverProps['width'];

	/** Search properties for the Select */
	search?: AnvilSelectSearchProps;

	/** The element that toggles the Select */
	trigger?: TriggerProps | PopoverProps['trigger'];

	/** The options currently selected in the Select */
	value?: TValue;

	/** Tree view option. Requires controlled state. */
	tree?: AnvilSelectTreeProps;

	/** Passing indeterminate data through to the underlying OptionList */
	indeterminateValue?: OptionListProps['indeterminateValue'];

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];
}

export interface AnvilSelectProps<
	TValue = AnvilSelectOptionsProps | AnvilSelectOptionsProps[]
> extends AnvilSelectPropsStrict<TValue> {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface AnvilSelectState {
	checkboxState: AnvilSelectOptionsProps[];
	radioState: AnvilSelectOptionsProps;
	openState: boolean;
	controlledMode: boolean;
}

// This is only used for Storybook to populate proptable
export const AnvilSelectSB: FC<AnvilSelectProps> = (props) => {
	return <AnvilSelect {...props} />;
};

export class AnvilSelectWrapped<
	TValue extends
		| AnvilSelectOptionsProps
		| AnvilSelectOptionsProps[]
		| PrimitiveValueType
		| PrimitiveValueType[]
> extends React.Component<AnvilSelectProps<TValue>, AnvilSelectState> {
	static defaultProps: Partial<AnvilSelectProps<any>> = {
		autoFlipVertically: true,
		closeOnClickOutside: true,
		popoverWidth: '100',
		portal: true,
	};
	static contextType = FocusContext;
	context!: React.ContextType<typeof FocusContext>;

	constructor(props: AnvilSelectProps<TValue>) {
		super(props);
		this.state = {
			checkboxState: props.value || ([] as any),
			radioState: props.value as AnvilSelectOptionsProps,
			openState: !!props.open,
			controlledMode: false,
		};
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			const initialState = getInitialState(
				this.props.search,
				this.props.header,
				this.getOptionsForFocusContext(),
				false,
				!!this.props.tree
			);
			if (!this.state.openState) {
				initialState.triggerFocus = true;
			}
			this.context.focusDispatch({
				type: FocusActionType.SetFocus,
				payload: initialState,
			});
		}

		/* Show a console warning when using function children */
		if (typeof this.props.children === 'function') {
			console.warn(
				'🚧 ANVIL: Using a function as children for AnvilSelect (and OptionList) is currently an early feature with limited functionality.'
			);
		}
	}

	componentDidUpdate(prevProps: AnvilSelectProps<TValue>) {
		const newValue = this.props.value;
		// If component updated but value didn't change, don't change state at all
		if (prevProps.value === newValue) return;

		// If component updated and value changed, update state to be new value
		this.setState({
			...this.state,
			...AnvilSelectWrapped.getNewCheckboxAndRadioStateByValue(
				newValue,
				this.props.options
			),
		});
	}

	static getDerivedStateFromProps(
		props: AnvilSelectProps<any>,
		state: AnvilSelectState
	) {
		const newValue = props.value;
		if (!state.controlledMode && newValue !== undefined && props.onChange) {
			return {
				controlledMode: true,
				...AnvilSelectWrapped.getNewCheckboxAndRadioStateByValue(
					newValue,
					props.options
				),
			};
		}

		if (
			state.controlledMode &&
			(newValue !== state.checkboxState || newValue !== state.radioState)
		) {
			return AnvilSelectWrapped.getNewCheckboxAndRadioStateByValue(
				newValue,
				props.options
			);
		}

		return null;
	}

	static getCheckboxOptionByValue = (
		value: any,
		options: AnvilSelectPropsStrict<any>['options']
	): AnvilSelectOptionsProps[] => {
		if (
			!Array.isArray(value) ||
			!value.length ||
			value === undefined ||
			value === null
		)
			return [];

		const isPrimitiveValueType = typeof value[0] !== 'object';

		if (isPrimitiveValueType) {
			return options.filter((option) => value.includes(option.value));
		}

		return value as AnvilSelectOptionsProps[];
	};

	static getRadioOptionByValue = (
		value: any,
		options: AnvilSelectPropsStrict<any>['options']
	): AnvilSelectOptionsProps => {
		if (Array.isArray(value) || value === undefined || value === null)
			return undefined;

		const isPrimitiveValueType = typeof value !== 'object';

		if (isPrimitiveValueType) {
			return options.find((option) => option.value === value);
		}

		return value as AnvilSelectOptionsProps;
	};

	static getNewCheckboxAndRadioStateByValue = (
		newValue: any,
		options: AnvilSelectPropsStrict<any>['options']
	): Pick<AnvilSelectState, 'checkboxState' | 'radioState'> => {
		return {
			checkboxState: AnvilSelectWrapped.getCheckboxOptionByValue(
				newValue,
				options
			),
			radioState: AnvilSelectWrapped.getRadioOptionByValue(
				newValue,
				options
			),
		};
	};

	handleKeyDown = (
		e: React.KeyboardEvent<HTMLDivElement>,
		option: AnvilSelectOptionsProps
	) => {
		const handledKeys = new Set([keys.esc, keys.enter, keys.space]);

		if (handledKeys.has(e.key)) {
			e.preventDefault();
		}

		if (e.key === keys.esc) {
			this.toggleOpenState();
			return;
		}

		if (e.key === keys.tab && !e.shiftKey && !doesFooterExist(e.target)) {
			// Tab on single option to select value
			if (this.props?.multiple !== true) this.changeRadioState(option);

			this.toggleOpenState();
			return;
		}

		if (e.key === keys.tab && e.shiftKey) {
			if (!doesHeaderExist(e.target)) {
				e.preventDefault();
				this.toggleOpenState();
				return;
			}
		}

		if (e.key === keys.enter || e.key === keys.space) {
			if (this.props.multiple) {
				const shouldCheck = !this.state.checkboxState.some(
					(checkbox) => checkbox.value === option.value
				);
				const childObject = option?.options ?? [];
				this.onCheckboxChange(option, shouldCheck, childObject, option);
				this.context.focusDispatch({
					type: FocusActionType.SetFocus,
					payload: { optionFocus: option },
				});
			} else {
				this.changeRadioState(option);
				this.toggleOpenState();
			}
		}
	};

	focus = () => {
		const initialState = getInitialState(
			this.props.search,
			this.props.header,
			this.getOptionsForFocusContext(),
			false,
			!!this.props.tree
		);
		if (!this.state.openState) {
			initialState.triggerFocus = true;
		}

		this.context.focusDispatch({
			type: FocusActionType.SetFocus,
			payload: initialState,
		});
	};

	blur = () => {
		const initialState = getInitialState(
			this.props.search,
			this.props.header,
			this.getOptionsForFocusContext(),
			false,
			!!this.props.tree
		);
		initialState.triggerFocus = false;

		this.context.focusDispatch({
			type: FocusActionType.SetFocus,
			payload: initialState,
		});
	};

	/*
	 * Internal state management of Select
	 */

	toggleOpenState = (clickOutside = false) => {
		const initialState = getInitialState(
			this.props.search,
			this.props.header,
			this.getOptionsForFocusContext(),
			clickOutside,
			!!this.props.tree
		);
		if (this.state.openState && !clickOutside) {
			initialState.triggerFocus = true;
		}
		this.context.focusDispatch({
			type: FocusActionType.SetFocus,
			payload: initialState,
		});
		const newOpenState = !this.state.openState;
		this.setState({
			openState: newOpenState,
		});

		this.props.onOpenChange?.(newOpenState, this.state.checkboxState);
	};

	changeCheckboxState = (
		value: AnvilSelectOptionsProps[],
		checked: boolean,
		childObject: AnvilSelectOptionsProps | AnvilSelectOptionsProps[],
		selfObject: AnvilSelectOptionsProps
	) => {
		this.setState({
			checkboxState: value,
		});
		this.handleOnChange(value as TValue, checked, childObject, selfObject);
	};

	onCheckboxChange = (
		value: any,
		checked: boolean,
		childObject: AnvilSelectOptionsProps | AnvilSelectOptionsProps[],
		selfObject: AnvilSelectOptionsProps
	) => {
		let newValue;
		const incomingValue =
			selfObject.value !== undefined ? selfObject.value : selfObject;
		if (checked) {
			newValue = [...this.state.checkboxState, selfObject];
		} else {
			newValue = this.state.checkboxState.filter(
				(item: AnvilSelectOptionsProps) => {
					return item.value !== incomingValue;
				}
			);
		}
		this.changeCheckboxState(newValue, checked, childObject, selfObject);
		this.context.focusDispatch({
			type: FocusActionType.SetFocus,
			payload: { optionFocus: selfObject },
		});
	};

	changeRadioState = (value?: AnvilSelectOptionsProps) => {
		this.setState({
			radioState: value,
		});
		this.handleOnChange(value as TValue, null, null, null);
	};

	getMultipleValues = () => {
		const defaults = {
			groupSelectAll: false,
			onTagClose: false,
			selectAll: false,
		};

		if (typeof this.props.multiple !== 'boolean')
			return { ...defaults, ...this.props.multiple };
		return defaults;
	};

	getEmptyResult = () => {
		const message =
			this.props.emptyResult !== undefined
				? this.props.emptyResult
				: 'No results found';
		return (
			<BodyText className="ta-center" subdued size="small">
				{message}
			</BodyText>
		);
	};

	checkDrillIn = () => {
		if (!this.props.drillIn || !this.props.drillIn.open) return false;
		return true;
	};

	getCheckboxStateFilteredByGroupValues = (
		flattenGroupValues: AnvilSelectOptionsProps[]
	) => {
		return this.state.checkboxState.filter(
			(item: AnvilSelectOptionsProps) =>
				!flattenGroupValues.find(
					(groupValueItem: AnvilSelectOptionsProps) =>
						item.value === groupValueItem.value
				)
		);
	};

	checkIfDisabledItemIsSelected = (option: AnvilSelectOptionsProps) => {
		return (
			this.state.checkboxState.filter(
				(item: AnvilSelectOptionsProps) => item.value === option.value
			).length > 0
		);
	};

	getFlattenValuesFromOptions = (
		options?: AnvilSelectOptionsProps[],
		skipDisabled?: boolean
	): AnvilSelectOptionsProps[] => {
		const result: AnvilSelectOptionsProps[] = [];
		if (options) {
			options.forEach((option: AnvilSelectOptionsProps) => {
				if (option.options) {
					result.push(
						...this.getFlattenValuesFromOptions(option.options)
					);
				} else if (!option.disabled) {
					result.push(option);
				} else if (
					!skipDisabled &&
					this.checkIfDisabledItemIsSelected(option)
				) {
					result.push(option);
				}
			});
		}
		return result;
	};

	getChildOptionsFromGroupOptions = (
		groupOptions: AnvilSelectOptionsProps[] = []
	) => {
		return groupOptions.flatMap(({ options = [] }) => options);
	};

	/*
	 * Handle functions
	 */

	handleOnSelectAll = () => {
		if (this.props.multiple) {
			this.changeCheckboxState(
				this.getFlattenValuesFromOptions(this.props.options),
				true,
				this.getChildOptionsFromGroupOptions(this.props.options),
				[...this.props.options]
			);
		}
	};

	handleOnSelectGroup = (groupOptions: AnvilSelectOptionsProps[]) => {
		const groupValues = this.getFlattenValuesFromOptions(groupOptions);
		const checkboxStateFiltered =
			this.getCheckboxStateFilteredByGroupValues(groupValues);
		this.changeCheckboxState(
			[...checkboxStateFiltered, ...groupValues],
			true,
			this.getChildOptionsFromGroupOptions(groupOptions),
			[...groupOptions]
		);
	};

	handleOnClear = () => {
		if (this.props.multiple) {
			const clearAllButDisabled = this.state.checkboxState.filter(
				(item: AnvilSelectOptionsProps) => item.disabled
			);
			const self = this.state.checkboxState.filter(
				(item: AnvilSelectOptionsProps) => !item.disabled
			);
			this.changeCheckboxState(clearAllButDisabled, false, [], self);
		} else {
			this.changeRadioState();
		}
	};

	handleOnClearGroup = (groupOptions: AnvilSelectOptionsProps[]) => {
		const groupValues = this.getFlattenValuesFromOptions(
			groupOptions,
			true
		);
		const newGroupValues =
			this.getCheckboxStateFilteredByGroupValues(groupValues);
		this.changeCheckboxState(newGroupValues, false, [], [...groupOptions]);
	};

	handleOnChange = (
		value: TValue,
		checked: any,
		childObject: AnvilSelectOptionsProps,
		selfObject: AnvilSelectOptionsProps
	) => {
		if (this.props.onChange) {
			this.props.onChange(value, checked, childObject, selfObject);
		}
	};

	handleOnTagClose = (value: AnvilSelectOptionsProps) => {
		this.onCheckboxChange(value, false, value, value);
	};

	handleClickOutside = () => {
		if (this.props.onClickOutside) this.props.onClickOutside();
		if (!this.state.openState || !this.props.closeOnClickOutside) return;
		this.toggleOpenState(true);
	};

	handleOnClick = (e: any) => {
		(this.props.trigger as TriggerProps)?.onClick?.(e);
		this.toggleOpenState();
	};

	getOnChange = (
		value: any,
		checked: boolean,
		childObject: AnvilSelectOptionsProps,
		selfObject: AnvilSelectOptionsProps
	) => {
		if (this.props.multiple)
			return this.onCheckboxChange(
				value,
				checked,
				childObject,
				selfObject
			);
		this.toggleOpenState();
		return this.changeRadioState(selfObject);
	};

	getFlatGroup = () => {
		if (this.props.tree) return false;
		if (this.getMultipleValues().groupSelectAll) {
			return {
				onGroupSelectAll: this.handleOnSelectGroup,
				onGroupSelectNone: this.handleOnClearGroup,
			};
		}
		return true;
	};

	getOnExpand = () => {
		return this.props.tree?.onExpand ? this.props.tree.onExpand : null;
	};

	getCollapseValue = () => {
		return this.props.tree?.collapseValue
			? this.props.tree.collapseValue
			: null;
	};

	optionsFromOptionList: AnvilSelectOptionsProps[] | null = null;

	getOptionsForFocusContext = () => {
		return this.props.options != null ||
			typeof this.props.children !== 'function'
			? this.props.options
			: this.optionsFromOptionList != null
			? this.optionsFromOptionList
			: null;
	};

	setOptionsFromOptionList = (optionData: AnvilSelectOptionsProps[]) => {
		this.optionsFromOptionList = optionData;
	};

	getOptionList = () => {
		if (this.checkDrillIn())
			return <SelectDrillInContent {...this.props.drillIn} />;
		if (this.props.loading) return this.getLoading();
		if (
			typeof this.props.children !== 'function' &&
			(!this.props.options || this.props.options.length === 0)
		)
			return this.getEmptyResult();

		return (
			<OptionList
				arrowNavOptions={{
					disabled: false,
					loop: true,
					tabIndexGroup: true,
				}}
				options={this.props.options}
				multiple={!!this.props.multiple}
				flatGroup={this.getFlatGroup()}
				value={
					this.props.multiple
						? this.state.checkboxState
						: this.state.radioState
				}
				onChange={this.getOnChange}
				optionOnKeyDown={this.handleKeyDown}
				focusValue={this.context.focusState.optionFocus}
				className="Select__OptionList"
				onExpand={this.getOnExpand()}
				collapseValue={this.getCollapseValue()}
				getOptionsData={this.setOptionsFromOptionList}
				indeterminateValue={this.props.indeterminateValue}
			>
				{this.props.children}
			</OptionList>
		);
	};

	/*
	 * UI get functions
	 * Options, Header, Footer, Trigger
	 */

	getHeader: () => any = () => {
		if (this.checkDrillIn()) {
			return (
				<SelectDrillInHeader
					closeSelectHandler={this.toggleOpenState}
					preventFocus={!!this.props.open}
					{...this.props.drillIn}
				/>
			);
		}
		if (this.props.header || this.props.search) {
			return (
				<AnvilSelectHeader
					search={!!this.props.search}
					closeSelectHandler={this.toggleOpenState}
					{...this.props.search}
					{...this.props.header}
				/>
			);
		}
		return null;
	};

	getFooter: () => any = () => {
		if (this.checkDrillIn()) {
			if (
				this.props.drillIn.footerActionName &&
				this.props.drillIn.footerOnActionClick
			) {
				return (
					<SelectDrillInFooter
						closeSelectHandler={this.toggleOpenState}
						{...this.props.drillIn}
					/>
				);
			}
			return null;
		}

		if (React.isValidElement(this.props.footer)) {
			return React.cloneElement(this.props.footer);
		}

		if (
			this.props.footer ||
			(this.props.multiple && this.getMultipleValues().selectAll)
		) {
			return (
				<AnvilSelectFooter
					selectAll={
						this.props.multiple &&
						this.getMultipleValues().selectAll
					}
					onSelectAllClick={this.handleOnSelectAll}
					onSelectNoneClick={this.handleOnClear}
					closeSelectHandler={this.toggleOpenState}
					{...this.props.footer}
				/>
			);
		}
		return null;
	};

	getTrigger = () => {
		if (React.isValidElement(this.props.trigger)) {
			return React.cloneElement(this.props.trigger as ReactElement, {
				onClick: this.toggleOpenState,
			});
		}
		const triggerValue = this.props.multiple
			? this.state.checkboxState
			: this.state.radioState;

		return (
			<Trigger
				open={this.state.openState}
				disabled={this.props.disabled}
				error={this.props.error}
				value={triggerValue}
				onClearClick={this.handleOnClear}
				onClose={!this.props.tree && this.handleOnTagClose} // Don't allow tag close with Tree
				onBlur={this.blur}
				onKeyDown={this.props.onKeyDown}
				{...(this.props.trigger as TriggerProps)}
				onClick={this.handleOnClick}
			/>
		);
	};

	getLoading = () => {
		return (
			<Stack justifyContent="center">
				<Spinner size="tiny" />
			</Stack>
		);
	};

	/* Popover props */
	getRestOfProps = () => {
		const {
			children,
			autoFlipHorizontally,
			autoFlipVertically,
			className,
			closeOnClickOutside,
			disabled,
			drillIn,
			emptyResult,
			error,
			footer,
			header,
			loading,
			multiple,
			onChange,
			onClickOutside,
			open,
			options,
			popoverWidth,
			search,
			trigger,
			value,
			autoFocus,
			onOpenChange,
			tree,
			...rest
		} = this.props;
		return rest;
	};

	render(): React.ReactNode {
		if (!this.state.openState) return this.getTrigger();

		const AnvilSelectClasses = classnames(
			'a-Select',
			this.props.className,
			{
				'Select--DrillIn': this.props.drillIn?.open,
				'Select--disabled': this.props.disabled,
				'Select--tree': this.props.tree,
				'Select--flat': !this.props.tree,
			}
		);

		return (
			<Popover
				autoFlipVertically={this.props.autoFlipVertically}
				autoFlipHorizontally={this.props.autoFlipHorizontally}
				className={AnvilSelectClasses}
				direction="br"
				focusTrapOptions={{ focusLock: false }}
				footer={this.state.openState && this.getFooter()}
				header={this.state.openState && this.getHeader()}
				open={this.state.openState}
				sharp
				trigger={this.getTrigger()}
				width={this.props.popoverWidth as PopoverProps['width']}
				onClickOutside={this.handleClickOutside}
				data-anvil-component="AnvilSelect"
				portalDataAttributes={this.props.portalDataAttributes}
				{...this.getRestOfProps()}
			>
				{this.state.openState && this.getOptionList()}
			</Popover>
		);
	}
}

/* Wrap Select around focus management */
export const AnvilSelect = React.forwardRef(
	<
		TValue extends
			| AnvilSelectOptionsProps
			| AnvilSelectOptionsProps[]
			| PrimitiveValueType
			| PrimitiveValueType[]
	>(
		props: AnvilSelectProps<TValue>,
		ref: Ref<any>
	) => {
		return (
			<FocusProvider
				header={props.header}
				search={props.search}
				options={props.options}
				preventFocus
			>
				<AnvilSelectWrapped {...props} ref={ref} />
			</FocusProvider>
		);
	}
);

AnvilSelect.displayName = 'AnvilSelect';
