import { queryOnScope, getSelector, isInsideSameKeynav, active } from './utils';
import { SelectorOptions } from '../useArrowNav';

export const initTabIndexes = (
	scopeElements: HTMLElement[],
	selectorOptions: SelectorOptions,
	keynavId: string
) => {
	const getFocusables = (scope: HTMLElement | HTMLElement[]) => {
		const selector = getSelector(selectorOptions);
		const focusables = queryOnScope(scope, selector)?.filter((el) =>
			isInsideSameKeynav(el, keynavId)
		);

		if (!focusables || focusables.length < 1) return null;
		return focusables;
	};

	scopeElements.forEach((el) => setTabIndex(getFocusables(el)));

	const handleFocus = () =>
		scopeElements.forEach((el) => setTabIndex(getFocusables(el)));

	const allFocusables = getFocusables(scopeElements);

	allFocusables?.forEach((el) => {
		el?.addEventListener('focusin', handleFocus);
		el?.addEventListener('focusout', handleFocus);
	});

	return allFocusables?.map((el) => () => {
		el?.removeEventListener('focusout', handleFocus);
		el?.removeEventListener('focusin', handleFocus);
	});
};

const setTabIndex = (els: HTMLElement[]) => {
	if (!els || els?.length < 1) return;

	// One element should always be focusable, having `tabIndex=0`
	const keepFocusable: HTMLElement =
		findActive(els) || findSelected(els) || findLastFocused(els) || els[0];

	els.forEach((el) => (el.tabIndex = -1));
	keepFocusable.tabIndex = 0;
};

const findLastFocused = (els: HTMLElement[]) => {
	const result = els?.filter(
		(el) => el?.hasAttribute('tabIndex') && el.tabIndex >= 0
	);

	if (result?.length === 1) return result[0];
	return null;
};

const findSelected = (els: HTMLElement[]) => {
	const result = els?.find((el) =>
		el?.matches(
			[
				'[aria-current]:not([aria-current=false])',
				'[aria-checked]:not([aria-checked=false])',
				'[aria-selected]:not([aria-selected=false])',
				'[aria-pressed]:not([aria-pressed=false])',
			].join(':not([aria-disabled=true]),')
		)
	);

	if (result) return result;
	return null;
};

const findActive = (els: HTMLElement[]) => {
	if (els?.includes(active())) return active();
	return null;
};
