import { useEffect, useState } from 'react';
import { theme } from '@servicetitan/anvil-themes';

export const usePrefersColorScheme = (light?: theme, dark?: theme) => {
	const [systemPrefersDark, setSystemPrefersDark] = useState();

	useEffect(() => {
		function isSystemDark(event: any) {
			setSystemPrefersDark(event.matches);
		}
		if (typeof window !== 'undefined') {
			const mediaQuery = window.matchMedia(
				'(prefers-color-scheme: dark)'
			);

			if (mediaQuery?.addEventListener) {
				mediaQuery.addEventListener('change', isSystemDark);
			} else {
				mediaQuery.addListener(isSystemDark);
			}

			return () => {
				if (mediaQuery?.addEventListener) {
					mediaQuery.removeEventListener('change', isSystemDark);
				} else {
					mediaQuery.removeListener(isSystemDark);
				}
			};
		}
	}, []);

	return {
		theme: systemPrefersDark ? dark : light,
		mode: systemPrefersDark ? 'dark' : 'light',
	};
};
