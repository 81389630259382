import React, { FC, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';
import { SidebarSection } from './SidebarSection';

export interface SidebarPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Must be unique to instance - key for preserving open state */
	localStorageKey: string;

	/** Set the wrapping element's tagname */
	el?: any;
}

export interface SidebarProps extends SidebarPropsStrict {
	[propName: string]: any;
}

interface Sidebar extends FC<SidebarProps> {
	/** Subcomponents */
	Section: typeof SidebarSection;
}

export const Sidebar: Sidebar = ({
	el: Element = 'div',
	className,
	localStorageKey,
	children,
	...rest
}) => {
	const sidebarRef = useRef<HTMLDivElement>();

	const getInitialOpen = () => {
		const wasPreviouslyOpen =
			typeof window !== 'undefined' &&
			window.localStorage.getItem(localStorageKey);
		return wasPreviouslyOpen ? JSON.parse(wasPreviouslyOpen) : true;
	};

	const screensize = useScreenSize();
	const isDisplaySmall = screensize.smallerThan.desktop;
	const [isHover, setIsHover] = useState(false);
	const [isTempOpen, setIsTempOpen] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(getInitialOpen());
	const hoverTimeout = useRef<ReturnType<typeof setTimeout>>();

	const isTouchScreen =
		typeof window !== 'undefined' &&
		window.matchMedia('(pointer:coarse)').matches;

	const SidebarClasses = classnames('Sidebar', className, {
		'Sidebar--collapsed': !isOpen || isDisplaySmall,
		'Sidebar--temporary': isTempOpen,
		'Sidebar--touch': isTouchScreen,
	});

	const toggleButtonClasses = classnames('Sidebar__toggle-button', {
		'Sidebar__toggle-button-visible':
			isTouchScreen || !isOpen || isDisplaySmall,
	});

	const iconName =
		isDisplaySmall && isTempOpen
			? 'keyboard_arrow_left'
			: isDisplaySmall && !isTempOpen
			? 'keyboard_arrow_right'
			: isOpen
			? 'keyboard_arrow_left'
			: 'keyboard_arrow_right';
	const size = isTouchScreen ? 'medium' : 'small';
	const [tooltipText, setTooltipText] = useState<string>();

	useEffect(() => {
		const getTooltipText = () => {
			if (isTempOpen) {
				if (!isDisplaySmall) return 'Keep sidebar open';
				if (isDisplaySmall) return 'Close sidebar';
			}
			if (!isOpen || isDisplaySmall) return 'Open sidebar';
			return 'Close sidebar';
		};

		setTooltipText(getTooltipText());
	}, [isDisplaySmall, isHover, isOpen, isTempOpen]);

	const handleHover = (state: boolean, delay = 300) => {
		clearTimeout(hoverTimeout.current);
		if (!isOpen || isDisplaySmall) {
			setIsHover(state);
			hoverTimeout.current = setTimeout(() => {
				setIsTempOpen(state);
			}, delay);
		}
	};

	const open = () => {
		if (!isDisplaySmall) {
			handleHover(false, 0);
			setIsOpen(!isOpen);
			return;
		}
		setIsTempOpen(!isTempOpen);
	};

	useEffect(() => {
		localStorage.setItem(localStorageKey, JSON.stringify(isOpen));
	}, [localStorageKey, isOpen]);

	return (
		<Element
			className={SidebarClasses}
			ref={sidebarRef}
			data-anvil-component="Sidebar"
			{...rest}
		>
			<div className="Sidebar__wrapper">
				<Tooltip
					hoverOnly
					className="Sidebar__toggle-button-wrapper"
					direction="r"
					text={tooltipText}
				>
					<Button
						className={toggleButtonClasses}
						onClick={open}
						iconName={iconName}
						size={size}
						onMouseOver={() => clearTimeout(hoverTimeout.current)}
						onMouseLeave={() => isTempOpen && handleHover(false)}
						aria-label={`${isOpen ? 'collapse' : 'expand'} sidebar`}
					/>
				</Tooltip>
				<div
					className="Sidebar__content-wrapper"
					onMouseOver={() => handleHover(true)}
					onMouseLeave={() => handleHover(false)}
				>
					<div className="Sidebar__content">{children}</div>
				</div>
			</div>
		</Element>
	);
};

Sidebar.Section = SidebarSection;
