import { keys } from '../../../utilities';
import { UseArrowNavProps, SelectorOptions } from '../useArrowNav';
import {
	queryOnScope,
	active,
	toArray,
} from '../../useFocusTrap/internal/utils';

// Excluded: input[type=text], select, textarea, [contenteditable]
const focusableElements = [
	'input[type=button]:not([disabled])',
	'input[type=checkbox]:not([disabled])',
	'input[type=radio]:not([disabled])',
	'input[type=file]:not([disabled])',
	'input[type=reset]:not([disabled])',
	'input[type=submit]:not([disabled])',
	'button:not([disabled])',
	'a[href]',
	'area[href]',
	'summary',
	'object',
	'embed',
	'audio[controls]',
	'video[controls]',
	'[tabindex]:not([disabled]):not([aria-disabled=true]):not(input):not(textarea):not(select)',
];

export const focusableSelector = focusableElements
	.map((s) => s + ':not([hidden]):not([contenteditable])')
	.join(',');

export { active, queryOnScope };

export const getScope = (scope: UseArrowNavProps['scope']): HTMLElement[] => {
	if (!scope) return null;

	return toArray(scope)
		.map((el) => (el as React.RefObject<any>).current ?? el)
		.filter((el) => el instanceof HTMLElement);
};

export const isInsideScope = (
	scope: HTMLElement[],
	el: HTMLElement | Element,
	keynavId: string
) => {
	const scopeElement = scope.find(
		(item) =>
			item?.contains(el) &&
			isInsideSameKeynav(el as HTMLElement, keynavId)
	);

	return !!scopeElement;
};

export interface IFocusables {
	active: HTMLElement;
	next: HTMLElement;
}

export const getFocusables = (
	scope: HTMLElement[],
	selectorOptions: SelectorOptions,
	tabLoop: boolean,
	key: string,
	keynavId: string
): IFocusables => {
	const selector = getSelector(selectorOptions);
	const focusables = queryOnScope(scope, selector).filter((el) =>
		isInsideSameKeynav(el, keynavId)
	);
	const result: IFocusables = {
		active: null,
		next: null,
	};

	const current = focusables?.indexOf(active() as any);

	if (!focusables || current < 0) return result;

	result.active = focusables[current];
	result.next = focusables[nextIdx(focusables, tabLoop, key)];

	return result;
};

// Trims commas and whitespaces
const trim = (str: string) => {
	let result = str,
		prevResult = '';
	do {
		prevResult = result;
		result = result.trim().replace(/(^,)|(,$)/, '');
	} while (result !== prevResult);
	return result;
};

const isSelectorValid = (selector: string) => {
	const queryCheck = (s: string) =>
		document.createDocumentFragment().querySelector(s);
	try {
		queryCheck(selector);
	} catch {
		return false;
	}
	return true;
};

export const getSelector = (selectorOptions: any) => {
	const { selector, extendSelector = '' } = selectorOptions;
	let result;

	if (selector) {
		result = trim(selector);
	} else {
		result = trim(focusableSelector + ',' + trim(extendSelector));
	}
	if (isSelectorValid(result)) return result;

	console.warn(
		'@servicetitan/design-system: useKeyNav() provided with invalid `selectorOptions`',
		result
	);
	return focusableSelector;
};

const nextIdx = (focusables: any[], loop: boolean, key: string) => {
	const current = focusables?.indexOf(active() as any);
	const last = focusables?.length - 1;

	if (key === keys.home) return 0;
	if (key === keys.end) return last;
	if ([keys.down, keys.right].includes(key)) {
		if (current === last) return loop ? 0 : last;
		return current + 1;
	}
	if ([keys.up, keys.left].includes(key)) {
		if (current === 0) return loop ? last : 0;
		return current - 1;
	}
};

export const createId = (): string => {
	const res = Math.round(Math.random() * 0xffffff).toString(16);

	return res;
};

export const isInsideSameKeynav = (el: HTMLElement, keynavId: string) => {
	const closest = el.closest('[data-keynav]') as HTMLElement;

	return keynavId === closest?.dataset?.keynav;
};
