import { AnvilSelectOptionsProps } from '../components';

export const getFirstOption = (
	options: AnvilSelectOptionsProps[],
	tree: boolean
): AnvilSelectOptionsProps | null => {
	for (const option of options) {
		if (option.options && !tree) {
			const firstOption = getFirstOption(option.options, false);
			if (firstOption) {
				return firstOption;
			}
			continue;
		}

		if (!option.disabled) {
			return option;
		}
	}

	return null;
};

export const getLastOption = (
	options: AnvilSelectOptionsProps[]
): AnvilSelectOptionsProps | null => {
	return getFirstOption([...options].reverse(), false);
};

const selectElementByClassName = (target: any, className: string) =>
	target.closest('.a-Select')?.getElementsByClassName(className);

const getOptionsArray = (target: any): any[] => {
	const options = selectElementByClassName(target, 'OptionList__option');
	return options
		? Array.from(options).filter((option: HTMLElement) => {
				return !(
					option.classList.contains('OptionList__option--disabled') ||
					option.classList.contains('OptionList__option--read-only')
				);
		  })
		: [];
};

export const doesHeaderExist = (target: EventTarget): boolean =>
	!!selectElementByClassName(target, 'Popover__header')?.length;

export const doesFooterExist = (target: EventTarget): boolean =>
	!!selectElementByClassName(target, 'Popover__footer')?.length;

export const focusOption = (
	option: AnvilSelectOptionsProps | null,
	closeSelectCallback?: Function
): void => {
	if (!option) {
		closeSelectCallback?.();
	} else {
		(option as HTMLElement)?.getElementsByTagName('input')[0]?.focus();
	}
};

export const focusFirstOption = (
	target: EventTarget,
	closeSelectCallback?: Function
): void => {
	const optionsArray: any = getOptionsArray(target);
	const firstOption = getFirstOption(optionsArray, false);
	focusOption(firstOption, closeSelectCallback);
};

export const focusLastOption = (
	target: EventTarget,
	closeSelectCallback?: Function
): void => {
	const optionsArray: any = getOptionsArray(target);
	const lastOption = getLastOption(optionsArray);
	focusOption(lastOption, closeSelectCallback);
};
