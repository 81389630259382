import React, { FC } from 'react';
import classnames from 'classnames';

import { getContent } from '../../utilities/getContent';

export interface StrictInputAddonProps {
	/** Additional classes. */
	className?: string;
}

export interface InputAddonProps extends StrictInputAddonProps {
	[key: string]: any;
}

export const InputAddon: FC<InputAddonProps> = ({
	el: InputAddonElement = 'div',
	className,
	...props
}) => {
	const InputAddonClasses = classnames('InputAddon', className);

	return (
		<InputAddonElement {...props} className={InputAddonClasses}>
			{getContent(props)}
		</InputAddonElement>
	);
};
