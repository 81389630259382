import React, { useCallback, useEffect } from 'react';
import {
	LiveProvider,
	LiveError,
	LiveEditor,
} from 'react-live';
import classnames from 'classnames';
import vsDark from 'prism-react-renderer/themes/vsDark';
import { tokens } from '@servicetitan/tokens/core';
import { Icon, Card, Stack, Eyebrow, Collapsible, Link, Tooltip } from '@servicetitan/design-system';
import { CodeSandboxLogo } from './CodeSandboxSvg';
import { Preview } from './Preview';
import { scope } from './scope';
import { getCodeSandboxLink } from './CodeSandboxLink';
import { ScreenSizeContext } from '../../context/ScreenSizeContext';

const Header = ({title, codeString, onClick, fullscreenToggle, customRender}) => {
	const { size } = React.useContext(ScreenSizeContext);
	const isLargerThanMobile = size.largerThan.mobile;

	return (
		<Stack justifyContent="space-between" alignItems="center">
			<Stack.Item shrink={1}>
				{title && <Eyebrow>{title}</Eyebrow>}
			</Stack.Item>
			<Stack.Item>
				<Stack className="CodeDemo__links" spacing={2}>
					<Stack.Item>
						<Tooltip text="Open in Codesandbox" portal direction="tl">
							<Link
								className="CodeDemo__icon"
								href={getCodeSandboxLink(codeString, customRender)}
								rel="noopener noreferrer"
								target="_blank"
							>
								<CodeSandboxLogo className="link__icon" alt="CodeSandBox" />
							</Link>
						</Tooltip>
					</Stack.Item>
					<Stack.Item>
						<Tooltip text="View and edit code" portal direction="tl">
							<Icon name={"edit"} onClick={onClick} className="CodeDemo__icon" />
						</Tooltip>
					</Stack.Item>
					{isLargerThanMobile &&
						<Stack.Item>
							<Tooltip text="View fullscreen" portal direction="tl">
								<Icon name={"fullscreen"} onClick={fullscreenToggle} className="CodeDemo__icon" />
							</Tooltip>
						</Stack.Item>
					}
				</Stack>
			</Stack.Item>
		</Stack>
	);
};

export const PreviewWrapper = ({
	metaData = {},
	...props
}) => {
	const [open, setOpen] = React.useState(false);
	const [fullscreen, setFullscreen] = React.useState(false);

	const headerProps = {
		title: metaData.title,
		codeString: props.codeString,
		fullscreenToggle: () => setFullscreen(!fullscreen),
		onClick: () => setOpen(!open),
		customRender: props.customRender
	}

	const { size } = React.useContext(ScreenSizeContext);
	const isSmallerThanMobile = size.smallerThan.mobile;

	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) setFullscreen(false);
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => document.removeEventListener("keydown", escFunction, false);
	}, []);

	const classes = classnames('block', 'react-live', {
		[`span-${metaData.span}`]: metaData.span,
		'm-b-4': props.inline
	});

	return (
		<LiveProvider
			code={props.codeString}
			scope={scope}
			noInline={props.customRender}
			theme={vsDark}
			language={props.language}
		>
			<div
				className={classes}
				style={{
					marginBottom: !props.inline && 32,
					maxWidth: isSmallerThanMobile && '100%',
				}}
			>
				{
				props.inline
				? <Preview metaData={metaData} inline={props.inline} />
				: <Card className="CodeDemo" elevation={props.inline && 'base'}>
						{!props.inline &&
							<Card.Section className="CodeDemo__header">
								<Header {...headerProps} />
							</Card.Section>
						}
						<Card.Section
							className="CodeDemo__body"
							style={{backgroundColor: !props.inline && tokens.colorNeutral20}}
						>
							<Preview
								metaData={metaData}
								inline={props.inline}
								fullscreen={fullscreen}
							/>
							{fullscreen && <Icon name="close" className="fullScreenClose" onClick={() => setFullscreen(false)} />}
							<Collapsible animate className="CodeDemo__collapsible" open={open}>
								<LiveEditor className="codeEditor" />
								<LiveError className="codeError" />
							</Collapsible>
						</Card.Section>
					</Card>
				}
			</div>
		</LiveProvider>
	)
};
