import { useRef } from 'react';

import {
	useAutoFocus,
	useAutoFocusContainer,
	useRestoreFocus,
	useDisableClickOutside,
	useFocusLock,
} from './internal';

export type FocusTrapShards = (
	| HTMLElement
	| React.RefObject<HTMLElement>
	| React.MutableRefObject<HTMLElement>
)[];

export interface UseFocusTrapProps {
	/**
	 * Sets focus on first focusable element.
	 * Default is false.
	 */
	autoFocus?: boolean;

	/**
	 * Sets focus on container referenced within useFocusTrap.
	 * Default is false.
	 *
	 * Required to properly handle focus inside Modal, Drawer, etc.
	 * Executed before useAutoFocus hook, thus might be overriden by it.
	 */
	autoFocusContainer?: boolean;

	/** Disables everything: focus lock, auto focus, restore focus, etc. */
	disabled?: boolean;

	/**
	 * Prevents focus to move to another focusable element outside of useFocusTrap when clicked/tapped.
	 * Default is false.
	 */
	disableClickOutside?: boolean;

	/**
	 * Prevent user from from tabbing focus out of scope'ed elements
	 * Default is true.
	 */
	focusLock?: boolean;

	/**
	 * Disables restoring focus position on unmount.
	 * Default is true.
	 */
	restoreFocus?: boolean;

	/** Array of refs or nodes, which useFocusTrap should consider as a part of it */
	shards?: FocusTrapShards;
}

export const useFocusTrap = (
	props: UseFocusTrapProps = {}
): React.RefObject<any> => {
	const {
		autoFocus = false,
		autoFocusContainer = false,
		disabled = false,
		disableClickOutside = false,
		focusLock = true,
		restoreFocus = true,
		shards = [],
		...rest
	} = props;
	const focusTrapRef = useRef(null) as React.RefObject<HTMLElement>;
	const scope = [focusTrapRef, ...shards];

	useRestoreFocus({ enabled: !disabled && restoreFocus });
	useAutoFocusContainer({
		containerRef: focusTrapRef,
		enabled: !disabled && autoFocusContainer,
	});
	useAutoFocus({
		scope,
		enabled: !disabled && autoFocus,
	});
	useDisableClickOutside({
		scope,
		enabled: !disabled && disableClickOutside,
		...rest, // Passing onClickOutside
	});
	useFocusLock({
		scope,
		enabled: !disabled && focusLock,
	});

	return focusTrapRef;
};
