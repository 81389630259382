import React from 'react';
import classnames from 'classnames';
import { Label, LabelProps } from '../Label';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export interface CheckboxProps<T>
	extends Omit<
		React.HTMLAttributes<HTMLInputElement>,
		'onChange' | 'onClick'
	> {
	/** Whether or not the checkbox is checked */
	checked?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Whether the checkbox is in a disabled state */
	disabled?: boolean;

	/** Visual error state for the checkbox */
	error?: boolean;

	/** Whether or not the checkbox is focused */
	focus?: boolean;

	/** ID for the form */
	id?: string;

	/** Indeterminate visual for a checkbox  */
	indeterminate?: boolean;

	/** Text associatged with the label of a checkbox */
	label?: LabelProps['children'];

	/** Property options for the label */
	labelProps?: LabelProps;

	/** Calls when the checkbox changes in value */
	onChange?: (
		value: T,
		checked: boolean,
		event: React.SyntheticEvent<HTMLInputElement>
	) => void;

	/** Calls when the checkbox is clicked  */
	onClick?: (
		event: React.MouseEvent<HTMLInputElement>,
		data: CheckboxProps<T>
	) => void;

	/** HTML Checkbox name value */
	name?: string;

	/** Same as disabled state. Legacy Semantic prop.  */
	readonly?: boolean;

	/** Controls the size of the checkboxes */
	size?: 'small' | 'medium' | 'large';

	/** HTML Checkbox value  */
	value?: T;
}

export const Checkbox: <T>(
	props: React.PropsWithoutRef<CheckboxProps<T>> &
		React.RefAttributes<HTMLDivElement>
) => React.ReactElement | null = React.forwardRef(function <T>(
	props: CheckboxProps<T>,
	ref: React.RefObject<HTMLDivElement>
) {
	const {
		checked,
		className,
		disabled,
		error,
		focus,
		id,
		indeterminate,
		label,
		labelProps,
		name,
		readonly,
		size = 'medium',
		value,
		onChange,
		onClick,
		tabIndex,
		...rest
	} = props;

	const inputRef = React.useRef(null);

	const newRef = React.useRef(null);
	const itemRef = ref ? ref : newRef;

	const { isFocusVisible } = useFocusVisible(itemRef);

	React.useEffect(() => {
		if (focus) inputRef.current?.focus();
	}, [focus]);

	const onChangeHandler = (e: React.SyntheticEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(value, e.currentTarget.checked, e);
		}
	};

	const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
		if (onClick) {
			onClick(e, props);
		}
	};

	const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
		const target = e.target as HTMLElement;
		if (target.tagName !== 'INPUT') {
			e.stopPropagation();
		}
	};

	const getLabel = () => {
		if (label === undefined || label === '') return false;

		const labelClassName = labelProps?.className ?? null;

		const LabelClasses = classnames(labelClassName, 'Checkbox__label');

		return (
			<Label
				error={error}
				disabled={disabled}
				{...labelProps}
				className={LabelClasses}
			>
				{label}
			</Label>
		);
	};

	const CheckboxClasses = classnames('a-Checkbox', className, {
		'Checkbox--indeterminate': indeterminate,
		'Checkbox--disabled': disabled || readonly,
		'Checkbox--checked': checked,
		'Checkbox--error': error,
		'Checkbox--small': size === 'small',
		'Checkbox--large': size === 'large',
		'Checkbox--fitted': label === undefined || label === '',
		'Checkbox--focus-visible': isFocusVisible,
	});

	const indeterminateAttributes = indeterminate
		? { 'indeterminate': 'true', 'aria-checked': 'mixed' as const }
		: { 'aria-checked': checked };

	return (
		<div
			className={CheckboxClasses}
			onClick={handleClickEvent}
			ref={itemRef}
			data-anvil-component="Checkbox"
			{...rest}
		>
			<label className="Checkbox__html-label">
				<input
					tabIndex={tabIndex}
					checked={checked}
					className="Checkbox__input"
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChangeHandler}
					onClick={onClickHandler}
					ref={inputRef}
					role="checkbox"
					type="checkbox"
					{...indeterminateAttributes}
				/>
				<span className="Checkbox__box" />
				{getLabel()}
			</label>
		</div>
	);
});

(Checkbox as any).displayName = 'Checkbox';
