import React, { ReactElement } from 'react';
import { isFragment } from 'react-is';
import { wrapWithComponent } from './wrapWithComponent';

/*
 * Wraps each element‘s child into Wrapper component, if it’s not
 * an instance of Wrapper. Returns wrapped children as an array.
 */
export const wrapChildren = (
	element: React.ReactNode,
	Component: React.ComponentType,
	props?: any
) => {
	const childArray = isFragment(element) ? element.props.children : element;

	const childrens = React.Children.map(childArray, (child: ReactElement) => {
		if (child == null) return;
		return wrapWithComponent(child, Component, props);
	});

	return childrens;
};
