import React, { FC } from 'react';
import classnames from 'classnames';

export interface TakeoverSectionPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;
}

export interface TakeoverSectionProps extends TakeoverSectionPropsStrict {
	[propName: string]: any;
}

export const TakeoverSection: FC<TakeoverSectionProps> = ({
	children,
	className,
	...props
}) => {
	const TakeoverSectionClasses = classnames('TakeoverSection', className);

	return (
		<div className={TakeoverSectionClasses} {...props}>
			{children}
		</div>
	);
};

TakeoverSection.displayName = 'TakeoverSection';
