export const useStorybookFigmaAddon = (link: string) => {
	if (!link) return;
	const url = link.startsWith('http')
		? link
		: `https://www.figma.com/file/KLWxYdHAdFNvmt4WjEbCid/?node-id=${link}`;

	return {
		design: {
			type: 'figma',
			url,
		},
	};
};
