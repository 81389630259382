import React, { FC, useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import {
	Calendar,
	CalendarProps,
	CalendarChangeEvent,
	CalendarCell,
	CalendarCellProps,
} from '@progress/kendo-react-dateinputs';
import { Popover, PopoverProps } from '../Popover';
import { Icon } from '../Icon';
import { keys } from '../../utilities/keyCodes';
import { InputDateMask, InputDateMaskProps } from './InputDateMask';
import { InputPropsStrict } from '../Input';
import { TimezoneContext } from '../../utilities/TimezoneProvider';
import { FormFieldContext } from '../../components/FormField';

import {
	DateObj,
	toDateObj,
	toTimeZone,
	fromTimeZone,
	toFormat,
	compareObj,
	invalidDate,
	isValidDate,
} from './utils/utils';
import { PopperProps } from '../../internal';

export interface DatePickerPropsStrict
	extends Pick<
		PopoverProps,
		'autoFlipVertically' | 'autoFlipHorizontally' | 'direction'
	> {
	/** Use custom component as a Calendar */
	calendar?: React.ComponentType<CalendarProps>;

	/** Disabled state */
	disabled?: InputDateMaskProps['disabled'];

	/** Error state */
	error?: InputDateMaskProps['error'];

	/** Character to use with partially complete dates. Default is '_' */
	maskChar?: InputDateMaskProps['maskChar'];

	/** Object of InputDateMask props */
	maskProps?: Omit<
		InputDateMaskProps,
		| 'onChange'
		| 'value'
		| 'minDate'
		| 'maxDate'
		| 'size'
		| 'maskChar'
		| 'placeholder'
		| 'error'
	>;

	/** Maximum date allowed */
	maxDate?: InputDateMaskProps['maxDate'];

	/** Minimum date allowed */
	minDate?: InputDateMaskProps['minDate'];

	/** Input size */
	size?: InputDateMaskProps['size'];

	/** Placeholder value */
	placeholder?: InputDateMaskProps['placeholder'];

	/** Currently selected date */
	value?: Calendar['value'] | string;

	/** Opens calendar */
	open?: boolean;

	/** Returns ISO string instead of JS Date object */
	isoFormat?: boolean;

	input?: {
		/** Called on every change of input text */
		onChange?: InputPropsStrict['onChange'];

		/** Called on blur of the input */
		onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
	};

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];

	/** Called on DatePicker's blur event */
	onBlur?: (value: Date | string | undefined, data?: DateObj) => void;

	/** Called on every meaningful date change */
	onChange?: (value: Date | string | undefined, data?: DateObj) => void;

	/** Called on every open and close action */
	onOpenChange?: (open: boolean) => void;

	/** This will override default trigger component */
	trigger?: (props: CustomTriggerProps) => React.ReactNode;
}

interface CustomTriggerProps {
	/** Returns internal trigger value */
	value: DatePickerProps['value'] | any;

	/** Returns internal trigger prop */
	onClick?: (e: any) => void;

	/** Returns internal open state */
	open: DatePickerProps['open'];

	/** Returns interal error state */
	error: DatePickerProps['error'];
}

export interface DatePickerProps extends DatePickerPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const DatePicker: FC<DatePickerProps> = React.forwardRef(
	(props, ref: React.RefObject<any>) => {
		const {
			disabled,
			calendar: CustomCalendar,
			direction = 'br',
			error,
			maskChar,
			maskProps,
			maxDate,
			minDate,
			isoFormat,
			input: inputProps,
			onBlur,
			onChange,
			onOpenChange,
			open,
			placeholder,
			required,
			size,
			trigger,
			value,
			portalDataAttributes,
			...rest
		} = props;
		const tz = useContext(TimezoneContext);
		const minTZ = toTimeZone(minDate, tz); // In timezone (with shift)
		const maxTZ = toTimeZone(maxDate, tz); // In timezone (with shift)
		const todayTZ = toTimeZone(new Date(), tz, { startOfDay: true }); // In timezone (with shift)

		const { labelFor: inputId } = React.useContext(FormFieldContext);

		// State
		const [date, setDate] = React.useState<DateObj>(
			toDateObj({ date: value, tz })
		);
		const [showCalendar, setShowCalendar] = React.useState(open);

		// Refs for focusing
		const calendarRef = useRef(null);
		const inputRef = useRef(null);

		// Effects
		useEffect(() => {
			open && openCalendar();
		}, [open]); // eslint-disable-line

		useEffect(() => {
			const next = toDateObj({ date: value, tz });

			if (date.isEqualDate(next)) return;

			setDate(next);
			onChangeReturn(next);
		}, [compareObj(value), isoFormat, tz]); // eslint-disable-line

		// Handlers
		const openCalendar = () => {
			!showCalendar && setShowCalendar(true);
			!showCalendar && onOpenChange?.(true);
		};
		const closeCalendar = (e: any) => {
			if (e?.target?.closest?.(`[for="${inputId}"]`)) return;
			showCalendar && setShowCalendar(false);
			showCalendar && onOpenChange?.(false);
		};
		const toggleCalendar = (e: any) =>
			showCalendar ? closeCalendar(e) : openCalendar();

		const handleKeyDown = (e: React.KeyboardEvent) => {
			const { key } = e;

			if (key === keys.esc) {
				e.preventDefault();
				closeCalendar(e);
				!trigger && inputRef.current?.focus();
			}

			if ([keys.space, keys.down].includes(key)) {
				e.preventDefault();
				openCalendar();
				calendarRef?.current?.focus();
			}

			if (showCalendar && key === keys.tab) closeCalendar(e);
		};

		const handleCalendarChange = (e: CalendarChangeEvent) => {
			let calendarDate = fromTimeZone(e.target?.value, tz); // Local timezone

			if (e.nativeEvent?.target?.className === 'k-nav-today')
				calendarDate = fromTimeZone(todayTZ, tz); // Local timezone

			const next = toDateObj({ date: calendarDate, tz });

			if (!date.isEqual(next)) {
				e.syntheticEvent.stopPropagation(); // Keeps focus on input after click on calendar
				setDate(next);
				onChangeReturn(next);
			}

			closeCalendar(e);
			!trigger && inputRef.current?.focus(); // Sets focus back on enter keypress on calendar's date
		};

		const handleMaskChange = (val: Date | undefined, data?: any) => {
			const next = toDateObj({
				date: val ? fromTimeZone(val, tz) : val, // As mask val is already in tz, sending it back to local tz
				tz,
				invalidReason: data?.invalidReason,
			});

			if (!date.isEqual(next)) {
				setDate(next);
				onChangeReturn(next);
			}
		};

		const onChangeReturn = (val: DateObj | undefined) =>
			onChange?.(getReturnDate(val), val);

		const getReturnDate = (val: DateObj | undefined) => {
			if (!val || !val.dateTime) return undefined; // Empty input
			if (!val.isValid)
				return isoFormat ? invalidDate.toString() : invalidDate; // Invalid Date
			return isoFormat ? val.toISO?.() : val.toJSDate(); // Good value
		};

		const handleMaskBlur = (val: Date | undefined, data?: any) => {
			const current = toDateObj({
				date: val ? fromTimeZone(val, tz) : val, // As mask val is already in tz, sending it back to local tz
				tz,
				invalidReason: data?.invalidReason,
			});

			onBlur?.(getReturnDate(current), current);
		};

		// Internal components
		const maskValue = () => {
			if (typeof date?.dateTime === 'undefined') return undefined; // No entry (empty)
			// Entry is valid and within min-max
			if (date?.isValid) return toTimeZone(date.toJSDate(), tz); // In timezone (with shift)
			if (!date?.isValid || date?.invalidReason) return invalidDate; // Entry is not full or out of min-max range
			console.warn(
				'@servicetitan/design-system: Something wrong with DatePicker, dateObj received:',
				date
			);
			return undefined;
		};

		const inputMaskProps = {
			disabled,
			error,
			maskChar,
			maxDate: maxTZ, // In timezone (with shift)
			minDate: minTZ, // In timezone (with shift)
			onBlur: handleMaskBlur,
			onChange: handleMaskChange,
			placeholder,
			required,
			size,
			value: maskValue(),
			input: inputProps,
			...maskProps,
		};

		// custom cell to override the today highlight to match timezone today
		const customCell = (props: CalendarCellProps) => {
			if (toFormat(props.value) === toFormat(todayTZ)) {
				return <CalendarCell {...props} isToday />;
			}
			return <CalendarCell {...props} isToday={false} />;
		};

		const calendarProps = {
			value: date.isValid
				? date.toJSDate() // Local timezone
				: null,
			max: isValidDate(maxDate) ? maxDate : undefined, // Local timezone
			min: isValidDate(minDate) ? minDate : undefined, // Local timezone
			onChange: handleCalendarChange,
			ref: calendarRef,
			cell: customCell,
		};

		const customTrigger = trigger?.({
			value: date.isValid
				? date.toJSDate() // Local timezone
				: null,
			onClick: toggleCalendar,
			open: showCalendar,
			error,
		});

		const inputMask = (
			<InputDateMask
				{...inputMaskProps}
				id={inputId}
				inputRef={inputRef}
				calendarRef={calendarRef}
				className="DatePicker__input"
				onClick={openCalendar}
				shortLabel={
					<Icon
						name="event"
						className="DatePicker__icon"
						onClick={toggleCalendar}
						aria-label="toggle calendar"
					/>
				}
				fluid
				data-anvil-component="DatePicker"
			/>
		);

		const DatePickerClasses = classnames('DatePicker', {
			'DatePicker--disabled': disabled,
			'DatePicker--open': showCalendar,
			[`DatePicker--${size}`]: size,
		});

		return (
			<Popover
				portal
				wrapperClassName={DatePickerClasses}
				open={showCalendar}
				popoverContentClassName="DatePicker__calendar"
				onKeyDown={handleKeyDown}
				direction={direction}
				padding={null}
				width="auto"
				ref={ref}
				onClickOutside={closeCalendar}
				trigger={customTrigger ?? inputMask}
				portalDataAttributes={portalDataAttributes}
				{...rest}
			>
				{CustomCalendar ? (
					<CustomCalendar {...calendarProps} />
				) : (
					<Calendar {...calendarProps} smoothScroll={false} />
				)}
			</Popover>
		);
	}
);
