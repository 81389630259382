import React, { FC } from 'react';
import classnames from 'classnames';

export type DataListCellType = React.ReactElement<DataListCellProps>;

export interface DataListCellProps {
	className?: string;
	children?: React.ReactNode;
	align?: 'left' | 'right' | 'center' | 'justify';
	width?: string;
	wrapping?: boolean;

	/** Deprecated. */
	content?: React.ReactNode;
}

export const DataListCell: FC<DataListCellProps> = ({
	className,
	children,
	content,
	align,
	width,
	wrapping,
	...rest
}) => {
	const classes: string = classnames('DataList__Cell', className, {
		[`ta-${align}`]: align,
		'DataList__Cell--wrapped': wrapping,
	});

	return (
		<div className={classes} style={{ width }} {...rest}>
			{children || content}
		</div>
	);
};
