import React, { FC, useContext } from 'react';
import { createPortal } from 'react-dom';

import { DefaultPortalContext } from '../../utilities/DefaultPortalContext';

interface PortalProps {
	children?: React.ReactNode;

	/* If true, children are portalled. If false, children are not portalled */
	conditional?: boolean;

	/* Target element to create portal at */
	target?: HTMLElement;
}

export const Portal: FC<PortalProps> = ({
	children,
	conditional = true,
	target,
}) => {
	const defaultPortalContext = useContext(DefaultPortalContext);
	// Check for document for server-side rendered apps
	if (typeof document !== 'undefined') {
		// End early if portal isn't needed
		if (!conditional) return children as React.ReactElement;

		const node = target ?? defaultPortalContext ?? document.body;

		return createPortal(children, node as Element);
	}
	return null;
};
