import React, { FC } from 'react';
import classnames from 'classnames';
import { ProgressTrackerStep } from './ProgressTrackerStep';
import { ProgressTrackerDivider } from './ProgressTrackerDivider';

export interface ProgressTrackerPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Optionally specify the current step array index. Set to `false` to manually track step state */
	currentIndex: number | false;

	/** Turn this on if this flow has been previously completed */
	completed?: boolean;

	/** Disable style for the entire Progress Tracker */
	disabled?: boolean;

	/** Hides labels behind Tooltips. For accessibility, labels should be added even when hidden */
	hideLabels?: boolean;

	/** Size of the Progress Tracker. Defaults to medium. */
	size?: 'small' | 'medium';
}

export interface ProgressTrackerProps extends ProgressTrackerPropsStrict {
	[propName: string]: any;
}

export interface ProgressTracker extends FC<ProgressTrackerProps> {
	/** Subcomponents */
	Step: typeof ProgressTrackerStep;
	Divider: typeof ProgressTrackerDivider;
}

export const ProgressTracker: ProgressTracker = ({
	children,
	className,
	completed,
	currentIndex = 0,
	disabled,
	hideLabels,
	size = 'medium',
	...rest
}) => {
	const renderSteps = (): React.ReactNode => {
		return React.Children.map(
			children,
			(child: React.ReactElement, index) => {
				const childProps = {
					key: `${index}-step`,
					stepNumber: index + 1,
					active: child.props.active,
					completed: child.props.completed,
					editing: child.props.editing,
					hideLabels: child.props.hideLabels,
					size,
				};

				const isIndexed = currentIndex !== false;
				const isActive = index === currentIndex;
				const isBeforeActive = index < currentIndex;
				const flowPreviouslyCompleted = completed;

				if (hideLabels) childProps.hideLabels = true;

				if (isIndexed) {
					if (flowPreviouslyCompleted) {
						// If currentIndex is used and this is the current step
						if (isActive) childProps.editing = true;

						// If currentIndex is used and this step is not the active step and this flow has been previously completed
						if (!isActive) childProps.completed = true;
					}

					if (!flowPreviouslyCompleted) {
						// If currentIndex is used and this is the current step
						if (isActive) childProps.active = true;

						// If currentIndex is used and this step is before the current step
						if (isBeforeActive) childProps.completed = true;
					}
				}

				return [
					index > 0 && (
						<ProgressTrackerDivider
							key={`${index}-divider`}
							completed={
								isBeforeActive ||
								flowPreviouslyCompleted ||
								isActive
							}
						/>
					),
					React.cloneElement(child as React.ReactElement, childProps),
				];
			}
		);
	};

	const ProgressTrackerClasses = classnames('ProgressTracker', className, {
		'ProgressTracker--completed': completed,
		'ProgressTracker--disabled': disabled,
		[`ProgressTracker--${size}`]: size,
	});

	return (
		<div
			className={ProgressTrackerClasses}
			data-anvil-component="ProgressTracker"
			{...rest}
		>
			{renderSteps()}
		</div>
	);
};

ProgressTracker.Step = ProgressTrackerStep;
ProgressTracker.Divider = ProgressTrackerDivider;
