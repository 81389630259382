import { useEffect } from 'react';

const defaultMinColWidth = 60; // 60px
const isSafari =
	typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator?.userAgent); // eslint-disable-line

interface UseTableMinWidthProps {
	tableContainer: HTMLElement;
	minColWidth?: number;
}

/*
 * Workaround to make flexible columns (without width) not collapse to nothing on small screens.
 * Required only for Safari.
 */
export const useTableMinWidth = (props: UseTableMinWidthProps) => {
	const { tableContainer, minColWidth = defaultMinColWidth } = props;

	useEffect(() => {
		if (!isSafari) return; // Exit if not Safari

		const handleResize = throttle(() => {
			if (!tableContainer) return;
			if (!(tableContainer instanceof HTMLElement)) return;

			const { fixedColumnsTotalWidth, flexColumnsCount } =
				colsTotalWidth(tableContainer);

			if (flexColumnsCount === 0) return;

			const newMinWidth =
				fixedColumnsTotalWidth + minColWidth * flexColumnsCount;

			setMinWidth(tableContainer, newMinWidth);
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [tableContainer]); // eslint-disable-line
};

const colsTotalWidth = (container: UseTableMinWidthProps['tableContainer']) => {
	const colsNodeList = container
		.querySelector('table')
		.querySelectorAll('col');

	const cols =
		colsNodeList.length > 0
			? (Array.from(colsNodeList) as HTMLElement[])
			: [];

	const widths = cols.map(getColWidth);

	return {
		fixedColumnsTotalWidth: widths.reduce((cur, sum) => cur + sum, 0), // Total width
		flexColumnsCount: widths.filter((w) => !w).length, // Counting nulls
	};
};

const setMinWidth = (
	container: UseTableMinWidthProps['tableContainer'],
	width: number
) => {
	const th: HTMLElement = container.querySelector('.k-grid-header table');
	const tb: HTMLElement = container.querySelector('.k-grid-container table');

	[th, tb]
		.filter((el) => !!el)
		.forEach((el) => (el.style.minWidth = `${width}px`));
};

const getColWidth = (col: HTMLElement) => {
	if (!col.hasAttribute('width')) return null; // Columns width is unset
	const val = col.getAttribute('width');
	if (isPixelValue) return parseInt(val, 10); // Per-attribute pixel size
	return Math.round(col.clientWidth); // Real size
};

const isPixelValue = (val: string) => val === `${parseInt(val, 10)}px`;

const throttle = (func: Function, delay: number) => {
	let inProgress = false;
	return (...args: any) => {
		if (inProgress) {
			return;
		}
		inProgress = true;
		setTimeout(() => {
			func(...args); // Consider moving this line before the set timeout if you want the very first one to be immediate
			inProgress = false;
		}, delay);
	};
};
