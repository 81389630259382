import React, { FC } from 'react';
import classnames from 'classnames';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Stack } from '../Stack';
import { Icon } from '../Icon';

export interface LabelPropsStrict {
	/** Add a related secondary action to the input */
	action?: React.ReactElement | string | boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Tooltips direction relative to the element its apart of */
	direction?: TooltipProps['direction'];

	/** Applies a visual disabled style */
	disabled?: boolean;

	/** Applies a visual error style */
	error?: boolean;

	/** Adds an explainer help icon with tooltip */
	help?: React.ReactElement | string;

	/** Show that an input is visually optional using the label */
	optional?: boolean;

	/** A field can show that input is mandatory. Requires a label. */
	required?: boolean;
}

export interface LabelProps extends LabelPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Label: FC<LabelProps> = ({
	action,
	children,
	className,
	direction,
	disabled,
	error,
	help,
	optional,
	required,
	...props
}) => {
	const getRequiredOptional = () => {
		const optionalUI = '(optional)';
		const requiredUI = '*';

		return required ? (
			<span className="Label__required">{requiredUI}</span>
		) : optional ? (
			<span className="Label__optional">{optionalUI}</span>
		) : undefined;
	};

	const getLabelHelp = () => {
		if (help === undefined || help === '') return false;
		if (typeof help === 'string') {
			return (
				<Tooltip
					text={help}
					direction={direction}
					onClick={preventControlFocus}
					portal
				>
					<Icon className="Label__help" name="help" size="18" />
				</Tooltip>
			);
		}
		return help;
	};

	/* If Label is in a <label>, it typically brings focus to another element. This function prevents this. */
	const preventControlFocus = (e: React.PointerEvent<HTMLSpanElement>) =>
		e.stopPropagation();

	const LabelClasses = classnames('Label', className, {
		'Label--error': error,
		'Label--disabled': disabled,
	});

	return (
		<Stack className={LabelClasses} data-anvil-component="Label" {...props}>
			<Stack.Item fill>
				{children}
				{getRequiredOptional()}
				{getLabelHelp()}
			</Stack.Item>
			<span className="Label__action" onClick={preventControlFocus}>
				{action}
			</span>
		</Stack>
	);
};
