import { DateTime } from 'luxon';
import moment from 'moment';
import { isValidDate } from './utils';

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const isValidTimeZone = (tz: string) => {
	if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
		throw new Error('Time zones are not available in this environment');
	}

	try {
		Intl.DateTimeFormat(undefined, { timeZone: tz });
		return true;
	} catch (ex) {
		return false;
	}
};

const parseTimeString = (str: string): Date => {
	if (typeof str === 'string') {
		const date = moment(str, ['h:m a', 'H:m']).toDate();
		if (isValidDate(date)) return new Date(date);
	}
	console.warn(
		`@servicetitan/design-system: parseTimeString(val) invalid val:`,
		str
	);
	return null;
};

export const timeToDate = (
	time: string,
	tz = localTimezone,
	baseDate?: Date
): Date => {
	if (tz && !isValidTimeZone(tz)) {
		console.warn(`@servicetitan/design-system: Invalid timezone "${tz}"`);
		return undefined;
	}

	// Date to return time within, now or based on `date` provided
	const d = !baseDate
		? DateTime.now().setZone(tz)
		: DateTime.fromJSDate(baseDate).setZone(tz);

	const t = parseTimeString(time); // '11:22 AM' → Date
	if (!t) return undefined;

	const [h, m] = [t.getHours(), t.getMinutes()];
	const result = d.set({ hour: h, minute: m, second: 0, millisecond: 0 }); // Rounding

	return result.toJSDate();
};

export const dateToTime = (
	d: Date,
	tz = localTimezone,
	format = 'hh:mm xm'
): string => {
	if (!d || !isValidDate(d)) return undefined;

	if (tz && !isValidTimeZone(tz)) {
		console.warn(
			`@servicetitan/design-system: dateToTime() has invalid timezone: ${tz}`
		);
		return undefined;
	}

	if (format !== 'hh:mm xm' && format !== 'hh:mm')
		console.warn(
			`@servicetitan/design-system: dateToTime() invalid format "${format}", usind "hh:mm"`
		);

	let dateTime;

	if (tz && isValidTimeZone(tz))
		dateTime = DateTime.fromJSDate(d).setZone(tz);

	return dateTime.setLocale('en-US').toLocaleString({
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: format !== 'hh:mm xm' ? 'h23' : 'h12',
	});
};

// Returns {min, max} as 24-hour strings within timezone provided
export const consumeMinMax = (
	min: Date | string,
	max: Date | string,
	tz = localTimezone
): { min: string; max: string } => {
	let minStr, maxStr;

	// both min & max are instances of Date
	if (isValidDate(min as Date) && isValidDate(max as Date)) {
		if (sameDay(min, max, tz)) {
			minStr = dateToTime(min as Date, tz, 'hh:mm');
			maxStr = dateToTime(max as Date, tz, 'hh:mm');
		} else {
			// min and max are valid and not same day → use min
			console.warn(
				`@servicetitan/design-system: TimePicker's min (${min}) & max (${max}) are not in the same day, using max=23:59`
			);
			minStr = dateToTime(min as Date, tz, 'hh:mm');
			maxStr = '23:59';
		}
		return { min: minStr, max: maxStr };
	}

	// min is Date, max is string
	if (isValidDate(min as Date) && !(max instanceof Date)) {
		const realMax = parseTimeString(max) ? max : '23:59';
		minStr = dateToTime(min as Date, tz, 'hh:mm');
		maxStr = realMax;

		if (min > timeToDate(realMax, tz, min as Date)) {
			// Reversing min & max
			minStr = realMax;
			maxStr = dateToTime(min as Date, tz, 'hh:mm');
		}
		return { min: minStr, max: maxStr };
	}

	// min is string, max is Date
	if (!(min instanceof Date) && isValidDate(max as Date)) {
		const realMin = parseTimeString(min) ? min : '00:00';
		minStr = realMin;
		maxStr = dateToTime(max as Date, tz, 'hh:mm');

		if (max < timeToDate(realMin, tz, max as Date)) {
			// Reversing min & max
			minStr = dateToTime(max as Date, tz, 'hh:mm');
			maxStr = realMin;
		}
		return { min: minStr, max: maxStr };
	}

	// both min & max are strings
	const minDate = parseTimeString(min as string) || parseTimeString('00:00');
	const maxDate = parseTimeString(max as string) || parseTimeString('23:59');
	const dates = [minDate, maxDate]
		.sort()
		.map((d) => dateToTime(d, localTimezone, 'hh:mm'));

	return { min: dates[0], max: dates[1] };
};

const sameDay = (d1: any, d2: any, tz: string): boolean => {
	const date1 = DateTime.fromJSDate(d1)?.setZone(tz);
	const date2 = DateTime.fromJSDate(d2)?.setZone(tz);

	return date1 && date2 && date1.hasSame(date2, 'day');
};

/**
 * Prioity:
 * 1. min, if min is Date, if not...
 * 2. max, if max is Date, if not...
 * 3. value, if defined, if not...
 * 4. today @ timezone
 */
export const getReturnDate = (
	min: Date | string,
	max: Date | string,
	value?: Date,
	tz = localTimezone
): Date => {
	let returnDate;

	if (isValidDate(min as Date)) {
		returnDate = min;
		if (isValidDate(max as Date)) {
			if (min > max) returnDate = max;
		}
	} else if (isValidDate(max as Date)) {
		returnDate = max;
	} else if (isValidDate(value)) {
		returnDate = value;
	} else {
		returnDate = new Date();
	}

	const res = timeToDate('00:00', tz, returnDate as Date);

	return res;
};
