import React, { FC } from 'react';
import classnames from 'classnames';

import { columnsToNumeral } from '../../utilities/SemanticUtils';
import {
	GridColumnsCount,
	ViewportWidthSegment,
	GridTextAlign,
	GridVerticalAlign,
} from './Grid';

/**
 * A column sub-component for Grid
 */
export interface GridColumnPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** A column can specify a width (colspan) for a computer/desktop */
	desktop?: GridColumnsCount;

	/** A column can sit flush against the left or right edge of a row */
	floated?: 'left' | 'right';

	/** A column can specify a width (colspan) for a large screen device */
	largescreen?: GridColumnsCount;

	/** A column can specify a width (colspan) for a mobile device */
	mobile?: GridColumnsCount;

	/** A column can appear only for a specific device or screen sizes */
	only?: ViewportWidthSegment;

	/** A column can stretch its contents to take up the entire grid or row height */
	stretched?: boolean;

	/** A column can specify a width (colspan) for a tablet device */
	tablet?: GridColumnsCount;

	/** Alignment of content */
	textAlign?: GridTextAlign;

	/** Vertical alignment of content */
	verticalAlign?: GridVerticalAlign;

	/** A column can specify a width (colspan) for a wide screen device */
	widescreen?: GridColumnsCount;

	/** Column may span over several nearby columns. Similar to colspan attribute of <td /> element. */
	width?: GridColumnsCount;
}

export interface GridColumnProps extends GridColumnPropsStrict {
	[propName: string]: any;
}

export const GridColumn: FC<GridColumnProps> = ({
	children,
	className,
	el: TagName = 'div',
	desktop,
	floated,
	largescreen,
	mobile,
	only,
	stretched,
	tablet,
	textAlign,
	verticalAlign,
	widescreen,
	width,
	...props
}) => {
	const desktopColspan = columnsToNumeral(desktop);
	const largescreenColspan = columnsToNumeral(largescreen);
	const mobileColspan = columnsToNumeral(mobile);
	const tabletColspan = columnsToNumeral(tablet);
	const widescreenColspan = columnsToNumeral(widescreen);
	const colspan = columnsToNumeral(width);

	const GridColumnClasses = classnames('GridColumn', className, {
		[`GridColumn--${floated}-floated`]: floated,
		[`GridColumn--${only}-only`]: only,
		'GridColumn--stretched': stretched,
		[`GridColumn--text-align-${textAlign}`]: textAlign,
		[`GridColumn--vertical-align-${verticalAlign}`]: verticalAlign,
		[`GridColumn--${desktopColspan}-wide-desktop`]: desktopColspan,
		[`GridColumn--${largescreenColspan}-wide-largescreen`]:
			largescreenColspan,
		[`GridColumn--${mobileColspan}-wide-mobile`]: mobileColspan,
		[`GridColumn--${tabletColspan}-wide-tablet`]: tabletColspan,
		[`GridColumn--${widescreenColspan}-wide-widescreen`]: widescreenColspan,
		[`GridColumn--${colspan}-wide`]: colspan,
	});

	return (
		<TagName className={GridColumnClasses} {...props}>
			{children}
		</TagName>
	);
};
