import React from 'react';
import { FormField, FormFieldProps } from '../FormField';
import { TextArea } from '../TextArea';

export type FormTextAreaProps = FormFieldProps;

export const FormTextArea: React.FC<FormTextAreaProps> = React.forwardRef(
	(props, ref) => {
		return <FormField control={TextArea} ref={ref} {...props} />;
	}
);
