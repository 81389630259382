import React, { FC } from 'react';
import classnames from 'classnames';
import { isDark } from '../../utilities/color-utils';
import { Icon, IconPropsStrict } from '../Icon';
import { Button } from '../Button';

export interface TagPropsStrict {
	/** Badge variation of a tag */
	badge?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Smaller version of the tag */
	compact?: boolean;

	/** Name of icon to render inside of Tag */
	icon?: IconPropsStrict['name'];

	/** Gives the tag a less visually intense color scheme */
	subtle?: boolean;

	/** Gives the tag more descriptive title attribute, users can hover for more information and screen readers will parse the tag correctly */
	title?: string;

	/** What happens when the user clicks the close icon */
	onClose?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Determines the visual appearance of the tag */
	color?:
		| 'info'
		| 'warning'
		| 'critical'
		| 'success'
		| 'inactive'
		| 'default'
		| 'inverse'
		| string;
}

export interface TagProps extends TagPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Tag: FC<TagProps> = ({
	badge,
	children,
	className,
	color = 'default',
	compact,
	icon: iconName,
	onClose,
	subtle,
	title,
	tagRef,
	...props
}) => {
	const isCustomColor = () => {
		const uniqueColors = [
			'info',
			'warning',
			'critical',
			'success',
			'inactive',
			'default',
			'inverse',
		];

		if (!uniqueColors.includes(color)) return true;
		return false;
	};

	const getColorClasses = () => {
		if (isCustomColor())
			return isDark(color) ? 'Tag--lightText' : 'Tag--darkText';
		if (!isCustomColor()) return `Tag--${color}`;
	};

	const TagClasses = classnames('Tag', className, getColorClasses(), {
		'Tag--compact': compact,
		'Tag--subtle': subtle,
		'Tag--badge': badge,
	});

	const getTextFromChildren = (children: React.ReactNode): string => {
		return (React.Children.toArray(children) as any)
			.reduce(
				(flattened: any, child: any) => [
					...flattened,
					child?.props?.children
						? getTextFromChildren(child.props.children)
						: child,
				],
				[]
			)
			.filter((str: string) => !str.toString().includes('object Object'))
			.join('');
	};

	const actualTitle =
		title !== undefined ? title : `Tag: ${getTextFromChildren(children)}`;

	return (
		<div
			className={TagClasses}
			title={actualTitle}
			style={isCustomColor() ? { backgroundColor: color } : null}
			ref={tagRef}
			data-anvil-component="Tag"
			{...props}
		>
			{iconName ? (
				<span className="Tag__icon">
					<Icon name={iconName} size={compact ? 14 : 16} />
				</span>
			) : null}
			<span className="Tag__body">{children}</span>
			{onClose && (
				<Button
					aria-label="remove tag"
					fill="subtle"
					className="Tag__close"
					iconName="clear"
					size="xsmall"
					onClick={onClose}
				/>
			)}
		</div>
	);
};
