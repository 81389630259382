import React from 'react';
import classnames from 'classnames';
import { ToastProps } from './Toast';
import { Portal } from '../../internal/Portal';
import { ToastGroupContext } from './ToastGroupContext';

type ToastGroupChildType =
	| React.ReactElement<ToastProps>
	| boolean
	| null
	| undefined;

export interface ToastGroupPropsStrict {
	/** Toast Elements */
	children: ToastGroupChildType | ToastGroupChildType[];

	/** Adds one or more classnames for an element */
	className?: string;

	/** Makes Toast open through ReactDOM.createPortal */
	portal?: HTMLElement | boolean;
}

export interface ToastGroupProps extends ToastGroupPropsStrict {
	[propName: string]: any;
}

export const ToastGroup = React.forwardRef<HTMLDivElement, ToastGroupProps>(
	({ children, className, portal = true, ...props }, ref) => {
		const ToastGroupClasses = classnames('ToastGroup', className, {
			'ToastGroup--floating': portal,
		});

		const toastGroup = (
			<ToastGroupContext.Provider value={{ isInToastGroup: true }}>
				<div ref={ref} className={ToastGroupClasses} {...props}>
					{children}
				</div>
			</ToastGroupContext.Provider>
		);

		return portal ? (
			<Portal target={typeof portal !== 'boolean' ? portal : undefined}>
				{toastGroup}
			</Portal>
		) : (
			toastGroup
		);
	}
);
