import React, { FC, ElementType } from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export type BreadcrumbLinkType = React.ReactElement<BreadcrumbLinkProps>;

export interface BreadcrumbLinkPropsStrict {
	label: string;
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
	el?: ElementType;
}

export interface BreadcrumbLinkProps extends BreadcrumbLinkPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const BreadcrumbLink: FC<BreadcrumbLinkProps> = ({
	className,
	label,
	onClick,
	onFocus,
	onBlur,
	el: Element = 'li',
	...props
}) => {
	const itemRef = React.useRef(null);

	const { isFocusVisible, focusFunction, blurFunction } = useFocusVisible(
		itemRef,
		onBlur,
		onFocus
	);

	const BreadcrumbLinkClasses = classnames('Breadcrumb-Link', className, {
		'Breadcrumb-Link--hover': onClick,
	});

	const BreadcrumbLinkLabelClasses = classnames('Breadcrumb-Link__label', {
		'Breadcrumb-Link__label--focus-visible': isFocusVisible,
	});

	return (
		<Element className={BreadcrumbLinkClasses} {...props}>
			<span
				className={BreadcrumbLinkLabelClasses}
				tabIndex={0}
				onClick={onClick}
				onFocus={focusFunction}
				onBlur={blurFunction}
				ref={itemRef}
			>
				{label}
			</span>
			<Icon className="Breadcrumb-Link__icon" name="chevron_right" />
		</Element>
	);
};
