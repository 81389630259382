import React, { FC, Ref } from 'react';
import { BodyText } from '../BodyText';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Icon } from '../Icon';
import { Stack } from '../Stack';
import classNames from 'classnames';

export interface MoreDetailsPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Direction of Tooltip */
	direction?: TooltipProps['direction'];

	/** Full value. If different from `short`, this is shown in a Tooltip */
	full: string;

	/** Adds one or more classnames to Icon wrapper */
	iconClassName?: string;

	/** Adds one or more classnames to always visible text */
	labelClassName?: string;

	/** Abbreviated value. This is always shown */
	short: string;

	/** Font size of abbreviated `short` value. Uses BodyText sizing. */
	size?: 'large' | 'medium' | 'small' | 'xsmall';

	/** Give Tooltip additional props for customization */
	tooltipProps?: TooltipProps;
}

export interface MoreDetailsProps extends MoreDetailsPropsStrict {
	[propName: string]: any;
}

export const MoreDetails: FC<MoreDetailsProps> = React.forwardRef(
	(
		{
			full,
			short,
			direction = 't',
			className,
			labelClassName,
			iconClassName,
			tooltipProps,
			size,
			...props
		},
		ref: Ref<any>
	) => {
		const MoreDetailsIconClassNames = classNames(
			'MoreDetailsIcon',
			iconClassName,
			{
				[`MoreDetailsIcon--${size}`]: size,
			}
		);

		const MoreDetailsClassNames = classNames('d-if', className);

		return (
			<Stack
				alignItems="center"
				className={MoreDetailsClassNames}
				ref={ref}
				data-anvil-component="MoreDetails"
				{...props}
			>
				<BodyText size={size} className={labelClassName}>
					{short}
				</BodyText>
				{short !== full && (
					<Tooltip
						text={full}
						className="lh-1"
						portal
						direction={direction}
						{...tooltipProps}
					>
						<div className={MoreDetailsIconClassNames}>
							<Icon name="more_horiz" />
						</div>
					</Tooltip>
				)}
			</Stack>
		);
	}
);
