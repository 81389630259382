type SemanticWIDTHSNUMBER = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type SemanticWIDTHSSTRING =
	| '1'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| '11'
	| '12'
	| 'one'
	| 'two'
	| 'three'
	| 'four'
	| 'five'
	| 'six'
	| 'seven'
	| 'eight'
	| 'nine'
	| 'ten'
	| 'eleven'
	| 'twelve';

export type SemanticWIDTHS = SemanticWIDTHSNUMBER | SemanticWIDTHSSTRING;

export type SemanticGridWIDTHS =
	| SemanticWIDTHS
	| 13
	| 14
	| 15
	| 16
	| '13'
	| '14'
	| '15'
	| '16'
	| 'thirteen'
	| 'fourteen'
	| 'fifteen'
	| 'sixteen';

export function columnsToNumeral(value: any) {
	if (!value) {
		return value;
	}
	const possibleValues = [
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
		13,
		14,
		15,
		16,
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
	];
	const possibleNumerals = [
		'one',
		'two',
		'three',
		'four',
		'five',
		'six',
		'seven',
		'eight',
		'nine',
		'ten',
		'eleven',
		'twelve',
		'thirteen',
		'fourteen',
		'fifteen',
		'sixteen',
	];
	if (possibleNumerals.includes(value)) {
		return value;
	}
	if (possibleValues.includes(value)) {
		return possibleNumerals[parseInt(value, 10) - 1];
	}
	return false;
}
