import * as anvil from '@servicetitan/design-system';
import { getParameters } from 'codesandbox/lib/api/define';
import anvilPackageJson from '@servicetitan/design-system/package.json';

const extractTags = (str) => {
	if (typeof str !== 'string') return [];
	return str.match(/(<\w+)/g)?.map((e) => e.replace(/</g, ""));
};

// Get all Anvil component used by tag name
function generateComponentImports(code) {
	let result = '';

	const tags = extractTags(code);
	if (!tags) return result;

	const anvilTags = tags.filter(item => Object.keys(anvil).includes(item));
	if (!anvilTags) return result;

	const set = [...new Set(anvilTags)];
	result += `import { ${set} } from "@servicetitan/design-system";`;

	return result;
}

// Content is not indented because it will add indent to actual code passed into CSB
function generateContentString(code, render) {
	if (!render) return `
function Root() {
	return (
<>
${code}
</>
	);
}

const rootElement = document.getElementById("root");
render(<Root className="bg-neutral-10"/>, rootElement);
	`;

	const renderRegex = /render.*([^<]+?)\)/is;

	// Filter out render method for custom one
	const codeForCustom = code.replace(renderRegex, "")

	// Filter content of render method to pass into CodeSandbox render method
	const filterRender = code.match(renderRegex);
	const renderForCustom = Array.isArray(filterRender) && filterRender[0].replace("render (", "").replace(")", "").trim();
	const checkRenderWrapper = () => {
		if (renderForCustom[0] === '<') return renderForCustom
		return `<${renderForCustom} />`
	}

	return `
${codeForCustom}
const rootElement = document.getElementById("root");
render(${checkRenderWrapper()}, rootElement);
`;
}

// State component must be equal to ../State.jsx
const stateComponent = (code) => extractTags(code)?.includes('State')
	? `\n` + `const State = (props) => props.children(React.useState(props.initial));`
	: '';

export const getCodeSandboxLink = (codeString, customRender) => {
	const importedComponents = generateComponentImports(codeString);
	const inlineComponents = stateComponent(codeString);
	const content = generateContentString(codeString, customRender);

	const codeDefault = `
import React from "react";
import { render } from "react-dom";

import "@servicetitan/anvil-fonts/dist/css/anvil-fonts.css";
import "@servicetitan/design-system/dist/system.css";`;

	const code = `
${codeDefault}

${importedComponents}
${inlineComponents}
${content}
`;

	const anvilDependencyVersion = {
		react: anvilPackageJson?.devDependencies?.react || "18.2.0",
		reactDOM: anvilPackageJson?.devDependencies?.['react-dom'] || "18.2.0"
	}

	const parameters = getParameters({
		files: {
			'index.js': {
				content: code,
			},
			'package.json': {
				content: {
					dependencies: {
						"@servicetitan/anvil-fonts": "*",
						"@servicetitan/design-system": "*",
						"@servicetitan/anvil-themes": "*",
						"@servicetitan/tokens": "*",
						"react": anvilDependencyVersion.react,
						"react-dom": anvilDependencyVersion.react,
						"react-scripts": "*",
						"dom-helpers": "*" // Bundle issue with CSB. May remove line after https://github.com/codesandbox/codesandbox-client/issues/5177 is resolved.
					},
				},
			},
		},
	});

	return `https://codesandbox.io/api/v1/sandboxes/define?parameters=${parameters}`;
}
