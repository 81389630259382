import React, { FC } from 'react';
import classnames from 'classnames';
import { useScrollPosition } from '../../hooks/useScrollPosition';

/**
 * Scroll properties
 */
export interface ScrollPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Set the component HTML element. */
	el?: 'div' | 'pre' | 'nav' | 'form' | 'blockquote' | 'table';

	/** Maximum height. If undefined, height to be set 100% and must be restricted by external container. */
	height?: string;

	/** Control overflow-x behavior */
	horizontal?: 'auto' | 'hidden' | 'scroll';

	/** Hide scrollbar keepeng content scrollable */
	scrollbar?: 'hidden';

	/** Show or hide scroll shadows */
	scrollIndicator?: boolean;

	/** Control overflow-y behavior */
	vertical?: 'auto' | 'hidden' | 'scroll';

	/** Maximum width. If undefined, width to be set 100% and must be restricted by external container.  */
	width?: string;
}

export interface ScrollProps extends ScrollPropsStrict {
	[propName: string]: any;
}

export const Scroll: FC<ScrollProps> = ({
	el: ScrollElement = 'div',
	children,
	className,
	height: maxHeight,
	width: maxWidth,
	horizontal = 'auto',
	vertical = 'auto',
	scrollIndicator = false,
	scrollbar,
	...props
}) => {
	const scrollbarRef = React.useRef(null);
	const { top, right, bottom, left } = useScrollPosition(scrollbarRef);
	const isScrollable =
		top ||
		right ||
		bottom ||
		left ||
		horizontal === 'scroll' ||
		vertical === 'scroll';

	const ScrollClasses = classnames('Scroll', className, {
		[`Scroll--of-x-${horizontal}`]: horizontal,
		[`Scroll--of-y-${vertical}`]: vertical,
		'Scroll--scrollable-top': top,
		'Scroll--scrollable-right': right,
		'Scroll--scrollable-bottom': bottom,
		'Scroll--scrollable-left': left,
		'Scroll--hidden-scrollbar': scrollbar === 'hidden', // Content is scrollable without visible scrollbar
		'Scroll--height-auto': !maxHeight,
		'Scroll--width-auto': !maxWidth,
	});

	const shadows = (
		<div className="Scroll__Shadows">
			<div className="Shadow--top" />
			<div className="Shadow--right" />
			<div className="Shadow--bottom" />
			<div className="Shadow--left" />
		</div>
	);

	const styles: React.CSSProperties = {
		maxWidth,
		maxHeight,
	};

	return (
		<ScrollElement
			className={ScrollClasses}
			style={styles}
			data-anvil-component="Scroll"
		>
			{scrollIndicator && shadows}
			<div
				className="Scroll__Scrollbar"
				tabIndex={props.tabIndex || isScrollable ? 0 : null}
				ref={scrollbarRef}
				{...props}
			>
				{children}
			</div>
		</ScrollElement>
	);
};
