import React, { FC, useContext } from 'react';
import classnames from 'classnames';
import { Portal } from '../Portal';
import { Backdrop, BackdropProps } from '../../components/Backdrop';
import { OverlayContext } from '../../utilities/OverlayContext';

export interface OverlayProps extends Omit<BackdropProps, 'onClick'> {
	/** Use animation for visibility transitions */
	animate?: boolean;

	/** Enable Backdrop */
	backdrop?: boolean;

	children?: React.ReactNode;

	/** onClose function for Backdrop */
	onClose?: BackdropProps['onClick'];

	/** Visibility of Backdrop */
	open?: BackdropProps['open'];

	/** Render in a React Portal */
	portal?: boolean;
}

export const Overlay: FC<OverlayProps> = ({
	animate,
	backdrop,
	children,
	onClose,
	open,
	portal,
}) => {
	const context = useContext(OverlayContext);

	/*
	 * Short circuit component so DOM order is correct.
	 * See Nesting Order Check story in Storybook.
	 * The Modal should be visibly on top of the Drawer, and the Overlay containing it should be second in DOM order.
	 */
	if (!open) return null;

	const OverlayClassNames = classnames('Overlay', {
		'Overlay--is-portalled': portal,
		'Overlay--is-open': open,
		'Overlay--has-animation': animate,
		'Overlay--has-backdrop': backdrop,
	});

	return (
		<Portal conditional={portal}>
			<div className={OverlayClassNames}>
				{backdrop && (
					<Backdrop animate={animate} onClick={onClose} open={open} />
				)}
				<OverlayContext.Provider value={context.concat('Overlay')}>
					{children}
				</OverlayContext.Provider>
			</div>
		</Portal>
	);
};
