import React, { FC } from 'react';
import { TabProps } from '../Tab';

export interface TabGroupItemProps {
	children: React.ReactElement<TabProps>;
}

export const TabGroupItem: FC<TabGroupItemProps> = ({ ...props }) => {
	return (
		<li
			role="presentation"
			className="TabGroup__Item"
			data-anvil-component="TabGroupItem"
			{...props}
		/>
	);
};

TabGroupItem.displayName = 'TabGroupItem';
