import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Option, SingleOptionProps, RegisterOptionData } from './Option';
import { Collapsible } from '../Collapsible';
import { Eyebrow } from '../Eyebrow';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Link } from '../Link';
import { useArrowNav, UseArrowNavProps } from '../../hooks/useArrowNav';

export interface OptionListProps extends FlatGroupProps {
	arrowNavOptions?: UseArrowNavProps;

	children?(optionProps: SingleOptionProps): ReactNode;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Holds values of options that are expanded or collapsed */
	collapseValue?: SingleOptionProps['value'][];

	/** Holds an indeterminate condition for option trees */
	indeterminateValue?: SingleOptionProps['value'][];

	/** Non-tree display of flat options with non-selectable group header */
	flatGroup?: FlatGroupProps | boolean;

	/** Helper value of what option, if any, is currently focused */
	focusValue?: any;

	/** Adds one or more classnames to grouped options */
	groupClassName?: string;

	/** Whether the Option List is single or multiselect */
	multiple?: boolean;

	/** Called when the user changes the value */
	onChange?: (
		value: SingleOptionProps['value'],
		checked: boolean,
		childObject: SingleOptionProps,
		selfObject: SingleOptionProps
	) => void;

	/** Calls when the expand/collapse button is clicked */
	onExpand?: (value: SingleOptionProps['value']) => void;

	/** Adds one or more classnames to each individual option */
	optionClassName?: string;

	/** Detects key event and provides option that was focused */
	optionOnKeyDown?: (
		e: React.KeyboardEvent<HTMLDivElement>,
		selfObject: SingleOptionProps
	) => void;

	/** Array of all the options in the Option List */
	options?: SingleOptionProps[];

	/** Value of selected options within the List */
	value?: any;

	/** Callback to pass a list of options up to AnvilSelect */
	getOptionsData?: (data: SingleOptionProps[]) => void;
}

interface FlatGroupProps {
	onGroupSelectAll?: (data: SingleOptionProps[]) => void;
	onGroupSelectNone?: (data: SingleOptionProps[]) => void;
}

export const OptionList: FC<OptionListProps> = ({
	arrowNavOptions,
	children,
	className,
	collapseValue,
	flatGroup,
	focusValue,
	groupClassName,
	indeterminateValue,
	multiple,
	onChange,
	onExpand,
	onGroupSelectAll,
	onGroupSelectNone,
	optionClassName,
	optionOnKeyDown,
	options,
	value,
	getOptionsData,
	...props
}) => {
	const OptionsClasses = classnames('OptionList', className, {
		'OptionList--single-select': !multiple,
		'OptionList--multi-select': multiple,
	});

	const ref = useArrowNav({
		disabled: true,
		focusableSelector: {
			selector: 'button, *:not([class*="read-only"]) input',
		},
		...arrowNavOptions,
	});

	const GroupClasses = classnames('OptionList__group', groupClassName);
	const ValuesFromArray = Array.isArray(value)
		? value.map((v: OptionListProps['value']) =>
				v.value !== undefined ? v.value : v
		  )
		: null;

	const setDefaultOptionProperties = (option: SingleOptionProps) => {
		let defaultSelected = false;
		if (
			value !== undefined &&
			option.value !== undefined &&
			((Array.isArray(value) &&
				value.length > 0 &&
				ValuesFromArray.includes(option.value)) || // If value is an Array (multiple selections)
				(typeof value === 'object' && value.value === option.value)) // If value is an Object (single selection)
		) {
			defaultSelected = true;
		}

		const defaults = {
			collapsed: option.collapsed ? option.collapsed : true,
			selected: defaultSelected,
			text:
				flatGroup && typeof option.content === 'string'
					? option.content
					: option.text
					? option.text
					: null,
			indeterminate: option.indeterminate || false,
		};
		return { ...defaults, ...option };
	};

	const setInitialLevel = () =>
		!flatGroup && options?.some((option) => option.options) ? 2 : 1;

	/* Loops through options object to display grouped and non-grouped options */
	const getOptions = (options: SingleOptionProps[], level = 2) => {
		return options?.map((optionProp, index: number) => {
			const localOption = setDefaultOptionProperties(optionProp);
			const checkForChildren = !!localOption.options;

			if (checkForChildren) {
				if (!flatGroup)
					return getGroupedOptions(
						localOption,
						optionProp,
						localOption.options,
						index,
						level,
						optionProp.value,
						optionProp.collapsed
					);
				if (level === 1)
					return getFlatGroupedOptions(
						localOption.options,
						localOption.text,
						level,
						index
					);
			}
			return getOption(localOption, optionProp, !flatGroup ? level : 1);
		});
	};

	/* Called when the options prop is detected in an individual option */
	const getGroupedOptions = (
		parentOption: Record<string, unknown>,
		originalParentOption: Record<string, unknown>,
		options: SingleOptionProps[],
		index: number,
		level: number,
		parentCollapseValue: SingleOptionProps['value'],
		collapsed: boolean
	) => {
		/* Variable determines if an option should be expanded to show children */
		const isOpen: boolean = collapsed
			? !collapsed
			: collapseValue
			? collapseValue.includes(parentCollapseValue)
			: true;

		return (
			<div className={GroupClasses} role="group" key={index}>
				{getOption(
					parentOption,
					originalParentOption,
					level,
					true,
					isOpen,
					options
				)}
				<Collapsible open={isOpen}>
					{getOptions(options, level + 1)}
				</Collapsible>
			</div>
		);
	};

	/* Pull flatGroup prop data for selection handling */
	const objectFlatGroup =
		typeof flatGroup === 'object' ? { ...flatGroup } : undefined;

	const handleGroupSelectAll = (groupedOptions: SingleOptionProps[]) => {
		if (objectFlatGroup.onGroupSelectAll === undefined) return;
		return objectFlatGroup.onGroupSelectAll(groupedOptions);
	};

	const handleGroupSelectNone = (groupedOptions: SingleOptionProps[]) => {
		if (objectFlatGroup.onGroupSelectNone === undefined) return;
		return objectFlatGroup.onGroupSelectNone(groupedOptions);
	};

	const getFlatGroupedOptions = (
		options: SingleOptionProps[],
		title: string,
		level: number,
		index: number
	) => {
		const selectAll = () => handleGroupSelectAll(options);
		const selectNone = () => handleGroupSelectNone(options);

		return (
			<React.Fragment key={`${index}${Object(options)}`}>
				{index !== 0 && <Divider spacing={1} />}
				{title && (
					<Stack alignItems="center" className="OptionList__header">
						<Eyebrow>{title}</Eyebrow>
						{multiple && title && typeof flatGroup !== 'boolean' && (
							<React.Fragment>
								<Link
									primary
									className="m-l-1 fs-1"
									onClick={selectAll}
									aria-label="select all"
								>
									All
								</Link>
								<Link
									primary
									className="m-l-1 fs-1"
									onClick={selectNone}
									aria-label="select none"
								>
									None
								</Link>
							</React.Fragment>
						)}
					</Stack>
				)}
				{getOptions(options, level + 1)}
			</React.Fragment>
		);
	};

	const handleOnChange = () => {
		if (onChange === undefined) return;
		return onChange;
	};

	const handleOnExpand = () => {
		if (onExpand === undefined) return;
		return onExpand;
	};

	/*
	 ** NOTE: The following functions (setSelected, setFocus, registerOption, etc)
	 ** are all plumbing to support the use case where <Option> components are
	 ** rendered in user-land via function children.
	 */
	const setSelectedFromOptionList = (optionValue: any) => {
		if (value == null || optionValue == null) {
			return false;
		}

		if (value.length) {
			return value.some((item: any) => {
				if (item === optionValue) {
					return true;
				}
				if (item.value === optionValue) {
					return true;
				}
				return false;
			});
		}

		if (value === optionValue) {
			return true;
		}
		if (value.value === optionValue) {
			return true;
		}

		return false;
	};

	const setFocusFromOptionList = (optionValue: any) => {
		return focusValue && focusValue.value === optionValue;
	};

	const setIndeterminateFromOptionList = (optionValue: any) => {
		return indeterminateValue?.includes(optionValue);
	};

	/*
	 ** The <Option> components call back with registerOption once they've rendered
	 */
	const optionsData: RegisterOptionData[] = [];

	const registerOption = (optionData: RegisterOptionData) => {
		optionsData.push(optionData);
	};

	React.useLayoutEffect(() => {
		optionsData.sort((a, b) => {
			if (
				a.element != null &&
				b.element != null &&
				a.element.compareDocumentPosition(b.element) &
					Node.DOCUMENT_POSITION_PRECEDING
			) {
				return 1;
			}
			return -1;
		});

		getOptionsData?.(optionsData);
	});

	/* Generates the individual option in the Option List */
	const getOption = (
		option: Record<string, unknown>,
		originalOption: Record<string, unknown>,
		level: number,
		collapseControl = false,
		collapseBoolean = false,
		localOptions: any = []
	) => {
		const { className, ...options } = Object(option);
		const OptionClasses = classnames(className, optionClassName);

		return (
			<Option
				{...options}
				className={OptionClasses}
				collapseControl={collapseControl}
				collapsed={collapseBoolean}
				focus={setFocusFromOptionList(Object(option).value)}
				indeterminate={setIndeterminateFromOptionList(
					Object(option).value
				)}
				key={`${Object(option).value}${Object(option).text}${level}`}
				level={level}
				onChange={handleOnChange()}
				onExpand={handleOnExpand()}
				onKeyDown={optionOnKeyDown}
				options={localOptions}
				selfObject={originalOption}
			/>
		);
	};

	const optionProps = {
		className: optionClassName,
		collapseControl: false,
		collapsed: false,
		getSelected: setSelectedFromOptionList,
		getFocus: setFocusFromOptionList,
		getIndeterminate: setIndeterminateFromOptionList,
		level: 1,
		onChange: handleOnChange(),
		onExpand: handleOnExpand(),
		onKeyDown: optionOnKeyDown,
		registerOption,
	};

	return (
		<div
			className={OptionsClasses}
			data-anvil-component="OptionList"
			role="tree"
			ref={ref}
			{...props}
		>
			{typeof children === 'function'
				? children(optionProps)
				: getOptions(options, setInitialLevel())}
		</div>
	);
};
