import React, { FC } from 'react';
import classnames from 'classnames';

export interface ActionToolbarProps {
	/** Content of the toolbar */
	content?: React.ReactElement;

	/** If true, position sticky to top */
	sticky?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;
}

export const ActionToolbar: FC<ActionToolbarProps> = ({
	content,
	sticky,
	className,
}) => {
	const actionToolbarSticky = sticky ?? false;

	const ActionToolbarClasses = classnames('Page__actionToolbar', className, {
		'Page__actionToolbar--sticky': actionToolbarSticky,
	});

	return <div className={ActionToolbarClasses}>{content}</div>;
};
