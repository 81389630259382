import React, { FC } from 'react';
import classnames from 'classnames';

import { useArrowNav } from '../../hooks/useArrowNav';
import { useMergedRef } from '../../hooks';

export interface ColorPickerPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Array of available colors (for example, ['#8772e5', '#01f346'])*/
	colors: string[];

	/** Currently selected color value */
	value: string | undefined;

	/** Called when the user changes the value */
	onChange?: (value: string) => void;

	/** Indicates whether selection cannot be changed. */
	disabled?: boolean;

	/** Not able to make changes, but not visually disabled */
	readonly?: boolean;
}

export interface ColorPickerProps extends ColorPickerPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const ColorPicker: FC<ColorPickerProps> = React.forwardRef(
	(
		{
			className,
			colors,
			value,
			disabled = false,
			readonly = false,
			onChange,
			...rest
		},
		ref: React.RefObject<HTMLDivElement>
	) => {
		const handleItemClick = (color: string) => () => {
			if (!disabled && !readonly && color !== value && onChange) {
				onChange(color);
			}
		};

		const arrowNavRef = React.useRef(null);
		const mergedRef = useMergedRef(ref, arrowNavRef);

		useArrowNav({
			disabled: disabled || readonly,
			scope: arrowNavRef,
			handleY: false,
			focusableSelector: { selector: 'button' },
			onKeyDown: (e: KeyboardEvent, data: any) => data.next?.click(),
		});

		return (
			<div
				{...rest}
				className={classnames('ColorPicker', className, {
					'ColorPicker--disabled': disabled,
				})}
				ref={mergedRef}
				role="radiogroup"
				data-anvil-component="ColorPicker"
			>
				{colors.map((color, index) => (
					<button
						key={index}
						className={classnames('ColorPicker__Item', {
							'ColorPicker__Item--active': color === value,
						})}
						style={{ backgroundColor: color }}
						onClick={handleItemClick(color)}
						role="radio"
						aria-label={color}
						aria-checked={color === value}
						tabIndex={disabled || readonly ? -1 : null}
					/>
				))}
			</div>
		);
	}
);
