import React, { useEffect } from 'react';
import { Keys } from '../../utilities/keyCodes';

export interface KeyPressListenerProps {
	code: Keys;
	handler(event: KeyboardEvent): void;
	keyEvent?: KeyEvent;
	disabled?: boolean;
	targetElement?: HTMLElement;
}

type KeyEvent = 'keydown' | 'keyup';

export const KeyPressListener: React.FC<KeyPressListenerProps> = ({
	code,
	handler,
	keyEvent = 'keyup',
	disabled = false,
	targetElement,
}) => {
	const handleKeyEvent = (event: KeyboardEvent) => {
		if (event.code !== code) return;

		if (disabled) {
			event.preventDefault();
			return;
		}

		handler(event);
	};

	const getElement = () => (targetElement ? targetElement : document);

	useEffect(() => {
		const target = getElement();
		target.addEventListener(keyEvent, handleKeyEvent);

		return () => {
			target.removeEventListener(keyEvent, handleKeyEvent);
		};
	});

	return null;
};
