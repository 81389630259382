import React, { FC } from 'react';
import classnames from 'classnames';

import { OptionList } from '../..';
import { keys } from '../../../utilities';

export interface TimePickerOptionProps {
	text: string;
	value: Date;
	className?: string;
	disabled?: boolean;
	selected?: boolean;
}

export interface TimePickerOptionListProps {
	className?: string;
	options: TimePickerOptionProps[];
	onChange: (value: TimePickerOptionProps['value']) => void;
	focusValue?: TimePickerOptionProps;
	inputRef?: React.RefObject<HTMLElement>;
	dropdownRef?: React.RefObject<HTMLDivElement>;
	closeDropdown?: () => void;
}

const TimeOptions: FC<TimePickerOptionListProps> = ({
	className,
	options,
	onChange,
	focusValue,
	inputRef,
	dropdownRef,
	closeDropdown,
	...rest
}) => {
	const handleOptionKeyDown = (
		e: React.KeyboardEvent<HTMLElement>,
		option: TimePickerOptionProps
	) => {
		const { key } = e;

		switch (key) {
			case keys.space:
			case keys.enter:
				e.preventDefault();
				onChange(option.value);
				return;
			case keys.esc:
				e.preventDefault();
				inputRef.current?.focus(); // Close picker and go back to input
				closeDropdown();
				return;
			case keys.tab:
				if (!e.shiftKey) {
					onChange(option.value); // Select value and go next focusable
				}
				if (e.shiftKey) {
					e.preventDefault();
					inputRef.current?.focus(); // Focus on input
					closeDropdown();
				}
		}
	};

	React.useEffect(() => {
		const timer = setTimeout(() => scrollOptionIntoView(dropdownRef), 10);

		return () => clearTimeout(timer);
	}, [options.length]); // eslint-disable-line

	return (
		options.length > 0 && (
			<div ref={dropdownRef}>
				<OptionList
					arrowNavOptions={{
						disabled: false,
						loop: true,
						handleX: false,
						tabIndexGroup: false,
					}}
					className={classnames('m-1', className)}
					focusValue={focusValue}
					options={options}
					onChange={onChange}
					optionOnKeyDown={handleOptionKeyDown}
					{...rest}
				/>
			</div>
		)
	);
};

export const TimePickerOptionList = React.memo(TimeOptions);

const scrollOptionIntoView = (dropdownRef: React.RefObject<HTMLDivElement>) => {
	const selectedEl: any = dropdownRef?.current?.querySelector(
		`.OptionList__option--selected, .OptionList__option-highlighted`
	);

	try {
		// Chrome, Safari, Edge
		selectedEl?.scrollIntoViewIfNeeded(); // Scrolls element into center of nearest scroll
	} catch {
		// Firefox
		selectedEl?.scrollIntoView({
			block: 'nearest', // Scroll over nearest block (dropdown) only, prevent body scrolling
		});
	}
};
