import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { Togglebox, ToggleboxProps } from '../Togglebox';

export interface FormToggleboxProps<T>
	extends FormFieldPropsStrict,
		ToggleboxProps<T> {
	error?: any; // Weirdly enough, this is what Semantic is doing to solve the extending issue
	label: React.ReactNode;
	control?: ToggleboxProps<T>['control'];
}

type FormToggleboxType<T = any> = FC<FormToggleboxProps<T>>;

export const FormTogglebox: FormToggleboxType = ({ control, ...props }) => (
	<FormField toggleboxControl={control} control={Togglebox} {...props} />
);
