import React, { FC, Component } from 'react';
import classnames from 'classnames';
import { FormField, FormFieldProps } from '../FormField';
import { ColorPicker, ColorPickerProps } from '../ColorPicker';
import { Card, CardSection } from '../Card';

export type FormColorPickerProps = FormFieldProps;

class ColorPickerCardWrapper extends Component<ColorPickerProps> {
	static displayName = 'ColorPicker';
	state = {
		focus: false,
	};

	handleColorPickerOnFocus = (e: React.FocusEvent<HTMLDivElement>) => {
		this.setState({ focus: true });
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	};

	handleColorPickerOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
		this.setState({ focus: false });
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};

	render() {
		const props = this.props;
		return (
			<Card
				sharp
				className={classnames(
					'ColorPicker__CardWrapper',
					{ 'ColorPicker__CardWrapper--disabled': props.disabled },
					{ 'ColorPicker__CardWrapper--focus': this.state.focus }
				)}
			>
				<CardSection className="ColorPicker__CardSection">
					<ColorPicker
						{...props}
						onFocus={this.handleColorPickerOnFocus}
						onBlur={this.handleColorPickerOnBlur}
					/>
				</CardSection>
			</Card>
		);
	}
}

export const FormColorPicker: FC<FormColorPickerProps> = ({ ...props }) => {
	return <FormField control={ColorPickerCardWrapper} {...props} />;
};
