import { useEffect } from 'react';

import { FocusTrapShards } from '../';
import {
	getScopeElements,
	isInside,
	tabIndexInside,
	focusNext,
	focusPrev,
	handleFocusablesUnmount,
	cleanFocusLockMarkers,
	setFocusLockMarkers,
} from './utils';

export interface FocusLockProps {
	scope: FocusTrapShards;
	enabled?: boolean;
}

export const useFocusLock = (props: FocusLockProps) => {
	const { scope: initialScope, enabled = true } = props;

	useEffect(() => {
		if (!enabled) {
			cleanFocusLockMarkers(getScopeElements(initialScope));
			return;
		}

		setFocusLockMarkers(getScopeElements(initialScope));

		// Warning in case tabIndex was set inside FocusLock
		if (tabIndexInside(initialScope))
			console.warn(
				'@servicetitan/design-system: (FocusTrap) Avoid using tabIndex values greater than 0. Instead put elements in a logical sequence.'
			);

		const handleTab = (event: any) => {
			const tab = event.code === 'Tab';
			const shift = event.shiftKey;

			if (!tab) return; // Exit fast if no Tab key was pressed

			const scope = getScopeElements(initialScope);

			if (scope.length === 0) return;

			// Prevent focus management if Tab happens outside or over nested useFocusLock
			if (!isInside(scope, event.target)) return;

			handleFocusablesUnmount(scope); // Case of removal currently focused focusable element, so that focus wouldn't go to the body

			if (tab && !shift) {
				focusNext(scope, event);
				return;
			}
			if (tab && shift) {
				focusPrev(scope, event);
			}
		};

		document?.addEventListener('keydown', handleTab);
		return () => document?.removeEventListener('keydown', handleTab);
	}, [initialScope, enabled]);
};
