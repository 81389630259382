import React, { Component } from 'react';
import classnames from 'classnames';
import { ToggleSwitch } from '../ToggleSwitch';
import { Checkbox } from '../Checkbox';
import { Radio } from '../Radio';
import { Card } from '../Card';

export interface ToggleboxPropsStrict<T> {
	/** Whether or not the togglebox is checked */
	checked?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Which element is used in the togglebox */
	control?: 'radio' | 'checkbox' | 'toggleSwitch';

	/** The vertical alignment of the control within the togglebox */
	controlAlign?: 'center' | 'top';

	/** Whether the togglebox is in a disabled state */
	disabled?: boolean;

	/** Visual error state for the togglebox */
	error?: boolean;

	/** ID for the checkbox within the togglebox */
	id?: string;

	/** Content associated with the label for the togglebox, control is centered by this field */
	label?: React.ReactNode;

	/** HTML name for the checkbox within the togglebox */
	name?: string;

	/** Calls when the togglebox is clicked  */
	onClick?: (value: T, checked: boolean) => void;

	/** Top text of the Togglebox */
	title?: string;

	/** HTML value for the checkbox within the togglebox */
	value?: T;
}

export interface ToggleboxProps<T> extends ToggleboxPropsStrict<T> {
	/** Unstrict Props */
	[propName: string]: any;
}

export class Togglebox<T> extends Component<ToggleboxProps<T>> {
	static displayName = 'Togglebox';

	onClickHandler = (e?: React.SyntheticEvent<HTMLInputElement>) => {
		if (this.props.onClick) {
			this.props.onClick(this.props.value, e.currentTarget.checked);
			e.currentTarget.focus;
		}
	};

	getControl = (control: string, controlProps: any) => {
		if (control === 'checkbox') return <Checkbox {...controlProps} />;
		if (control === 'radio') return <Radio {...controlProps} />;
		if (control === 'toggleSwitch') {
			const { error, ...props } = controlProps;
			return <ToggleSwitch {...props} />;
		}
	};

	getTitle = () => {
		if (this.props.title === undefined || this.props.title === '') return;
		return <div className="Togglebox__title">{this.props.title}</div>;
	};

	getLabel = () => {
		if (this.props.label === undefined || this.props.label === '') return;
		return <div className="Togglebox__label">{this.props.label}</div>;
	};

	render() {
		const {
			checked,
			className,
			control = 'checkbox',
			controlAlign = 'center',
			disabled,
			error,
			id,
			label,
			name,
			onChange,
			onClick,
			title,
			value,
			...props
		} = this.props;

		const toggleboxProps = { checked, disabled, error, ...props };
		const uiProps = { checked, disabled, error, name, value, id };

		const ToggleboxClasses = classnames('Togglebox', className, {
			[`Togglebox--${control}`]: control,
			'Togglebox--controlTop': controlAlign === 'top',
		});

		const ContentClasses = classnames('Togglebox__content', {
			'Togglebox__content--title': title,
		});

		return (
			<Card
				active={checked}
				className={ToggleboxClasses}
				onClick={this.onClickHandler}
				raised={checked}
				sharp
				thin
				data-anvil-component="Togglebox"
				{...toggleboxProps}
			>
				<Card.Section>
					<span className={ContentClasses}>
						<span className="Togglebox__control">
							{this.getControl(control, { ...uiProps })}
						</span>
						<span className="Togglebox__children">
							{this.getTitle()}
							{this.getLabel()}
						</span>
					</span>
				</Card.Section>
			</Card>
		);
	}
}
