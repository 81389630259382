import React, { FC } from 'react';
import classnames from 'classnames';
import { Headline } from '../Headline';

export interface ProgressCirclePropsStrict {
	/** String of class names to add to component */
	className?: string;

	/** Color variants */
	color?: 'default' | 'blue' | 'green' | 'red' | 'yellow';

	/** Value of filled bar without percent sign. E.g. <ProgressBar progress={50} /> */
	progress?: number;
}

export interface ProgressCircleProps extends ProgressCirclePropsStrict {
	[propName: string]: any;
}

export const ProgressCircle: FC<ProgressCircleProps> = ({
	className,
	progress = 0,
	color,
	children,
	...props
}) => {
	const ProgressCircleClasses = classnames('ProgressCircle', className, {
		'ProgressCircle--blue': color === 'blue',
		'ProgressCircle--green': color === 'green',
		'ProgressCircle--red': color === 'red',
		'ProgressCircle--yellow': color === 'yellow',
	});

	function value_limit(val: number, min: number, max: number) {
		return val < min ? min : val > max ? max : val;
	}

	const thickness = 10;
	const radius = 50 - thickness / 2;
	const circumference = radius * 2 * Math.PI;
	const [dashoffset, setDashoffset] = React.useState(circumference);

	React.useEffect(() => {
		setDashoffset(
			circumference -
				(value_limit(progress, 0, 100) / 100) * circumference
		);
	}, [circumference, progress]);

	const ProgressCircleComponent = (
		<div
			className={ProgressCircleClasses}
			data-anvil-component="ProgressCircle"
			{...props}
		>
			<svg
				className="ProgressCircle__svg"
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					className="ProgressCircle__background"
					strokeDasharray={`${circumference} ${circumference}`}
					cx="50"
					cy="50"
					strokeWidth={thickness}
					r={radius}
				/>
				<circle
					className="ProgressCircle__circle"
					style={{ strokeDashoffset: dashoffset }}
					strokeDasharray={`${circumference} ${circumference}`}
					cx="50"
					cy="50"
					strokeWidth={thickness}
					r={radius}
				/>
			</svg>
			<Headline className="ProgressCircle__children">{children}</Headline>
		</div>
	);

	return ProgressCircleComponent;
};
