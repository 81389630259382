import React, { FC, useEffect } from 'react';
import { light, dark, theme as iTheme } from '@servicetitan/anvil-themes';
import { usePrefersColorScheme } from './usePrefersColorScheme';

function capitalizeFirstLetter(string: string) {
	return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

function flattenObj(obj: any, parent?: any, res: any = {}) {
	for (const key in obj) {
		const propName = parent
			? `${parent}` + capitalizeFirstLetter(key)
			: key;
		if (typeof obj[key] === 'object') {
			flattenObj(obj[key], propName, res);
		} else {
			res[propName] = obj[key];
		}
	}
	return res;
}

export interface themeProviderProps {
	theme?: iTheme;
	children?: React.ReactNode;
}

const createStyleTag = (themeObject: iTheme) => {
	const themeVar = flattenObj(themeObject);
	let styleTag = document.getElementById('themeVariables');

	if (!styleTag) {
		styleTag = document.createElement('style');
		styleTag.setAttribute('id', 'themeVariables');
		document.head.appendChild(styleTag);
	}

	let style = ':root{';
	for (const key in themeVar) {
		style += `--${key}:${themeVar[key]};`;
	}
	style += '}';

	styleTag.textContent = style;
};

export const ThemeProvider: FC<themeProviderProps> = ({ theme, children }) => {
	const { theme: systemPreferred } = usePrefersColorScheme(light, dark);

	useEffect(() => {
		createStyleTag(theme ?? systemPreferred);
	}, [theme, systemPreferred]);

	return <React.Fragment>{children}</React.Fragment>;
};
