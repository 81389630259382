import React, { FC } from 'react';
import { useScreenSize } from '../../hooks/useScreenSize';

interface BreakPointProps {
	children?: React.ReactNode;

	/** Maximal screen width at which children to be shown */
	max?: 'mobile' | 'tablet' | 'desktop' | 'large' | 'widescreen' | number;

	/** Minimal screen width at which children to be shown */
	min?: 'mobile' | 'tablet' | 'desktop' | 'large' | 'widescreen' | number;
}

export const BreakPoint: FC<BreakPointProps> = React.forwardRef(
	({ children, max, min, ...props }, ref: React.RefObject<any>) => {
		let maxWidthState;
		let minWidthState;

		const screensize = useScreenSize();

		if (typeof max !== 'number') {
			maxWidthState = !max;
			Object.keys(screensize.smallerThan).forEach((maxWidthBP: any) => {
				if (max === maxWidthBP && screensize.smallerThan[max]) {
					maxWidthState = true;
				}
			});
		} else {
			maxWidthState =
				screensize.innerWidth <= max ? true : false || max <= 0;
		}

		if (typeof min !== 'number') {
			minWidthState = !min;
			Object.keys(screensize.largerThan).forEach((minWidthBP: any) => {
				if (min === minWidthBP && screensize.largerThan[min]) {
					minWidthState = true;
				}
			});
		} else {
			minWidthState =
				screensize.innerWidth > min ? true : false || min <= 0;
		}

		if (maxWidthState && minWidthState) {
			return (
				<div ref={ref} {...props}>
					{children}
				</div>
			);
		}

		return null;
	}
);
