import React, { FC } from 'react';
import classnames from 'classnames';
import { isDark } from '../../utilities/color-utils';
import { tokens } from '@servicetitan/tokens/core';
import { Icon, IconPropsStrict } from '../Icon';

export const allowedRandomColors = [
	/* 400 */
	tokens.colorBlueGrey400,
	tokens.colorOrange400,
	tokens.colorCyan400,
	tokens.colorPurple400,
	tokens.colorMagenta400,

	/* 600  */
	tokens.colorBlueGrey600,
	tokens.colorOrange600,
	tokens.colorCyan600,
	tokens.colorPurple600,
	tokens.colorMagenta600,
];

export interface AvatarPropsStrict {
	/** Automatically apply a randomized and consistent color */
	autoColor?: boolean;

	/** Additional classes */
	className?: string;

	/** Apply a solid colored background */
	color?: string;

	/** Image URL, replaces initials and color with image */
	image?: string;

	/** Initials will be generated from a full name string. To be required in future. */
	name?: string;

	/** Turn Avatar into a Rounded Square instead of Circle */
	rounded?: boolean;

	/** Size of Avatar */
	size?: 's' | 'm' | 'l';

	/** Icon Name */
	icon?: IconPropsStrict['name'];
}

export interface AvatarProps extends AvatarPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Avatar: FC<AvatarProps> = ({
	autoColor,
	className,
	color = tokens.colorBlueGrey100,
	image,
	name,
	rounded,
	size = 'm',
	icon,
	...props
}) => {
	const getRandomColor = () => {
		const colorLength = allowedRandomColors.length;

		const nameLength = name ? name.length : icon.length;

		const colorIndex = (nameLength - 1) % colorLength;

		return allowedRandomColors[colorIndex];
	};

	const getInitials = () => {
		if (!name) return;

		const nameSplit = name.split(' ');
		const first = nameSplit[0].substr(0, 1);
		const last = nameSplit.length > 1 ? nameSplit.pop().substr(0, 1) : '';
		return `${first}${last}`;
	};

	const getContent = () => {
		if (image) return;
		if (icon) return <Icon name={icon} />;
		if (name) return getInitials();
	};

	let customColor = color;
	if (!image && autoColor) customColor = getRandomColor();

	const AvatarClasses = classnames('Avatar', className, `Avatar--${size}`, {
		'Avatar--circle': !rounded,
		'Avatar--rounded': rounded,
		'Avatar--text-alignment': !!name,
		'Avatar--lightText': isDark(customColor),
		'Avatar--icon': !name && !!icon,
	});

	const style = image
		? {
				backgroundImage: `url(${image})`,
		  }
		: {
				backgroundColor: customColor,
		  };

	return (
		<div
			className={AvatarClasses}
			style={style}
			title={name}
			data-anvil-component="Avatar"
			{...props}
		>
			{getContent()}
		</div>
	);
};
