import { DateTime } from 'luxon';

export const isValid = (date: any) => {
	if (!isNaN(date) && date instanceof Date) return date;
	return null;
};

export const toDateFormat = (date: any) => {
	return isValid(new Date(date));
};

export const defaultDateFormat = 'MM/dd/yyyy';

toDateFormat.numeric = (date: DateTime, dateFormat?: string) => {
	if (date === null) return;
	return date.toFormat(dateFormat ?? defaultDateFormat);
};

toDateFormat.shortMonth = (date: any) => {
	if (date === null) return;
	const fmtl = new Intl.DateTimeFormat('en', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});
	return fmtl.format(toDateFormat(date));
};

toDateFormat.startOfDay = (date: any) => {
	const startOfDay = new Date(date as any).setHours(0, 0, 0, 0);
	return isValid(new Date(startOfDay));
};

toDateFormat.endOfDay = (date: any) => {
	const endOfDay = new Date(date as any).setHours(23, 59, 59, 999);
	return isValid(new Date(endOfDay));
};
