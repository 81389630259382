import { useEffect } from 'react';

interface usePrefocusContainerProps {
	containerRef: React.RefObject<HTMLElement>;
	enabled?: boolean;
}

/**
 * Helper which provides focus handling over modals, dialogs, etc.
 * Sets focus on overlay wrapper if there is no other element already focused inside.
 */
export const useAutoFocusContainer = (props: usePrefocusContainerProps) => {
	const { containerRef, enabled = false } = props;

	useEffect(() => {
		const container = containerRef.current;

		if (!enabled || !container) return;
		if (container.contains(document?.activeElement)) return; // Already has focus inside, e.g. some internal input was autofocused before

		setTabindex(container);
		container.focus({ preventScroll: true });
	}, [enabled]); // eslint-disable-line
};

const setTabindex = (el: HTMLElement) => {
	if (!el.hasAttribute('tabIndex')) {
		el.tabIndex = 0; // Crucial value
		el.addEventListener('blur', () => clearTabindex(el));
	}
};
const clearTabindex = (el: HTMLElement) => {
	el.removeAttribute('tabIndex');
	el.removeEventListener('blur', () => clearTabindex(el));
};
