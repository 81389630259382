import React, { FC, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { BodyText } from '../../BodyText';
import { InputProps } from '../../Input';
import { Stack } from '../../Stack';
import { Link } from '../../Link';
import { Form } from '../../Form';
import { Icon } from '../../Icon';
import { Tag } from '../../Tag';
import {
	focusFirstOption,
	focusLastOption,
	FocusContext,
	FocusActionType,
} from '../utils';
import { keys } from '../../../utilities/keyCodes';
import { Tooltip } from '../../Tooltip';
import { Button } from '../../Button';

export interface AnvilSelectSearchProps extends InputProps {
	/** Displays clear button in the search field */
	clearable?: boolean;
}

export interface AnvilSelectHeaderProps extends AnvilSelectSearchProps {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Displays the visual filter option in the Select */
	filter?: boolean;

	/** Displays how many filters are currently applied */
	filtersApplied?: number;

	/** Function that fires when the user clicks on the filter link */
	onFilterClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Displays the search in the Select's header */
	search?: boolean;

	/** Top text of the Select */
	title?: string;

	/** Function that closes the select when the user clicks on the action button */
	closeSelectHandler?: () => void;
}

export const AnvilSelectHeader: FC<AnvilSelectHeaderProps> = React.memo(
	(props) => {
		const {
			className,
			filter,
			filtersApplied,
			onFilterClick,
			search,
			title,
			closeSelectHandler,
			clearable = true,
			onChange,
			...rest
		} = props;
		const { focusState, focusDispatch } = React.useContext(FocusContext);
		const { searchFocus, filterFocus } = focusState;
		const [searchValue, setSearchValue] = React.useState('');
		const filterRef = React.useRef(null);
		const searchInputRef = React.useRef(null);

		React.useEffect(() => {
			filterFocus && filterRef.current?.focus();
		}, [filterFocus, filterRef]);

		const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
			const target = e.target as HTMLElement;

			if (e.key === keys.esc) {
				e.preventDefault();
				closeSelectHandler();
				return;
			}

			if (target.nodeName === 'INPUT') {
				if (e.key === keys.tab && e.shiftKey) {
					e.preventDefault();
					closeSelectHandler();
					return;
				}

				if (e.key === keys.down) {
					e.preventDefault();
					focusFirstOption(e.target, closeSelectHandler);
					return;
				}

				if (e.key === keys.up) {
					e.preventDefault();
					focusLastOption(e.target, closeSelectHandler);
					return;
				}
			}

			if (e.key === keys.tab) {
				if (
					!e.shiftKey &&
					target.nodeName === 'INPUT' &&
					clearable &&
					!!searchValue
				) {
					return;
				}

				if (
					!e.shiftKey &&
					target.nodeName !== 'INPUT' &&
					!target.nextSibling &&
					!clearable
				) {
					e.preventDefault();
					focusFirstOption(e.target, closeSelectHandler);
					return;
				}

				const searchSibling =
					target?.closest('.Select__search')?.nextSibling;
				if (
					!e.shiftKey &&
					target.nodeName === 'INPUT' &&
					!searchSibling
				) {
					e.preventDefault();
					focusFirstOption(e.target, closeSelectHandler);
					return;
				}
			}

			if (
				target.classList.contains('filter') &&
				(e.key === keys.enter ||
					e.key === keys.space ||
					e.key === keys.down)
			) {
				onFilterClick(e);
			}
		};

		const handleFocusSearchInput = () => {
			focusDispatch({
				type: FocusActionType.SetFocus,
				payload: { searchFocus: true },
			});
		};

		const clearClick = (e: SyntheticEvent) => {
			onChange?.(e as any, { value: '' });
			setSearchValue('');
			searchInputRef.current.focus();
		};

		const onChangeHandler = (
			event: React.ChangeEvent<HTMLInputElement>,
			data: { value: string }
		) => {
			setSearchValue(data.value);
			onChange?.(event, data);
		};

		const clearBtnClassNames = classnames('Select__search-clear', {
			'Select__search-clear--loading': props.loading,
		});

		const getClearable = () => {
			if (!clearable || !searchValue) return;
			return (
				<Tooltip
					text="Clear search"
					className={clearBtnClassNames}
					direction="bl"
				>
					<Button
						aria-label="clear search"
						xsmall
						fill="subtle"
						iconName="clear"
						onClick={clearClick}
					/>
				</Tooltip>
			);
		};

		const AnvilSelectHeaderClassNames = classnames(
			'Select__header',
			className
		);

		return (
			<div
				className={AnvilSelectHeaderClassNames}
				onKeyDown={handleKeyDown}
			>
				{title && (
					<BodyText
						className="Select__title ff-display lh-1"
						bold
						size="small"
					>
						{title}
					</BodyText>
				)}
				<Stack className="Select__header-controls w-100">
					{search && (
						<Stack.Item fill className="Select__search">
							<Form>
								<Form.Input
									autoFocus={searchFocus}
									onFocus={handleFocusSearchInput}
									icon="search"
									iconPosition="left"
									size="small"
									onChange={onChangeHandler}
									value={searchValue}
									inputRef={searchInputRef}
									className={
										clearable
											? 'Select__input-clearable'
											: 'Select__input'
									}
									{...rest}
								/>
							</Form>
							{getClearable()}
						</Stack.Item>
					)}
					{filter && (
						<Link
							size={2}
							className="Select__filter d-f fs-2 fw-bold align-items-center"
							onClick={onFilterClick}
						>
							<span
								className="Select__focus filter"
								ref={filterRef}
							>
								<Stack alignItems="center">
									<Icon name="funnel" />
									Filter
									{filtersApplied > 0 && (
										<Tag badge compact className="m-l-half">
											{filtersApplied}
										</Tag>
									)}
								</Stack>
							</span>
						</Link>
					)}
				</Stack>
			</div>
		);
	}
);
