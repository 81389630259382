import React, { FC } from 'react';
import classnames from 'classnames';
import { SemanticWIDTHS } from '../../utilities/SemanticUtils';

export interface FormGroupPropsStrict {
	/** Additional classes */
	className?: string;

	/** Element to use for base component */
	el?: any;

	/** Display fields vertically grouped */
	grouped?: boolean;

	/** Display labels and fields in a row */
	inline?: boolean;

	/** Set all Form Fields to be specific widths */
	widths?: SemanticWIDTHS | 'equal';
}

export interface FormGroupProps extends FormGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const FormGroup: FC<FormGroupProps> = ({
	className,
	el: Element = 'div',
	grouped,
	inline,
	widths,
	...props
}) => {
	const FormGroupClasses = classnames('FormGroup', className, {
		'FormGroup--grouped': grouped,
		'FormGroup--inline': inline,
		'FormGroup--equal-width': widths === 'equal',
		[`FormGroup--widths-${widths}`]: widths && widths !== 'equal',
	});

	return (
		<Element
			className={FormGroupClasses}
			data-anvil-component="FormGroup"
			{...props}
		/>
	);
};

FormGroup.displayName = 'FormGroup';
