import React from "react";
import { useScreenSize } from "@servicetitan/design-system";

export const ScreenSizeContext = React.createContext();

export const ScreenSizeProvider = ({ children }) => {
  const useSize = useScreenSize();
  const [size, setSize] = React.useState(useSize);
  React.useEffect(() => {
    setSize(useSize);
  }, []);

  return (
    <ScreenSizeContext.Provider value={{size, setSize}}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
