import React from 'react';
import { FormField, FormFieldProps } from '../FormField';
import { Input, InputElement } from '../Input';

export type FormInputProps = FormFieldProps;

export const FormInput = React.forwardRef<InputElement, FormInputProps>(
	(props, ref: any) => {
		return <FormField control={Input} ref={ref} {...props} />;
	}
);
