import React, { FC } from 'react';
import classnames from 'classnames';
import { Spinner } from '../Spinner';
import { Card } from '../Card';

/**
 * Mask properties
 */
export interface MaskPropsStrict {
	/** Show/hide Mask */
	active?: boolean;

	/** Apply border-radius when wrapping an element such as a card */
	borderRadius?: 0 | 1 | 2;

	/** String of class names to add to component */
	className?: string;

	/** Freeform content to add to the mask overlay */
	content?: React.ReactElement;
}

export interface MaskProps extends MaskPropsStrict {
	[propName: string]: any;
}

export const Mask: FC<MaskProps> = ({
	active = true,
	borderRadius = 0,
	children,
	className,
	content,
	...props
}) => {
	const MaskClasses = classnames('Mask', className, {
		'Mask--active': active,
	});

	const MaskOverlayClasses = classnames('Mask__overlay', {
		'border-radius-1': borderRadius === 1,
		'border-radius-2': borderRadius === 2,
	});

	return (
		<div className={MaskClasses} data-anvil-component="Mask" {...props}>
			{active && (
				<div className={MaskOverlayClasses}>
					<div className="Mask__backdrop" />
					{content ? (
						<Card className="Mask__card">{content}</Card>
					) : (
						<Spinner className="Mask__spinner" container />
					)}
				</div>
			)}
			{children}
		</div>
	);
};
