import { useEffect } from 'react';
import { getScopeElements, isInside } from './utils';
import { FocusTrapShards } from '../';

interface useDisableClickOutsideProps {
	scope: FocusTrapShards;
	enabled?: boolean;
	onClickOutside?: (e: React.SyntheticEvent) => void;
}

export const useDisableClickOutside = (props: useDisableClickOutsideProps) => {
	const { scope, enabled = true, onClickOutside } = props;

	useEffect(() => {
		function handleClick(event: any) {
			if (!enabled) return;

			const target = event.composedPath?.()[0] ?? event.target;
			const elements = getScopeElements(scope);

			// Suppress if inside current scope
			if (isInside(elements, target)) return; // How to prevent disabling on popovers?

			event.stopPropagation();
			event.preventDefault();
			if (onClickOutside) onClickOutside(event);
		}

		document?.addEventListener('mousedown', handleClick);
		document?.addEventListener('touchstart', handleClick);
		return () => {
			document?.removeEventListener('mousedown', handleClick);
			document?.removeEventListener('touchstart', handleClick);
		};
	}, [enabled, scope, onClickOutside]);
};
