import React from 'react';
import classnames from 'classnames';

import { ButtonToggleItem, ButtonToggleItemProps } from './ButtonToggleItem';
import { useArrowNav } from '../../hooks/useArrowNav';
import { useMergedRef } from '../../hooks';

/**
 * Option properties
 */
export interface OptionProps<T> {
	/** Content of the element, i.e. the displayed text */
	text?: ButtonToggleItemProps<T>['text'];

	/** Value of the option */
	value: ButtonToggleItemProps<T>['value'];

	/** Returns value of option for the click event */
	onClick?: ButtonToggleItemProps<T>['onClick'];

	/** Indicates whether the element is disabled or not. It makes the option unselectable */
	disabled?: ButtonToggleItemProps<T>['disabled'];

	/** Sets the option's selected state */
	selected?: ButtonToggleItemProps<T>['selected'];

	/** Hide the option (not commonly used) */
	hidden?: ButtonToggleItemProps<T>['hidden'];

	/** React element to be rendered as an icon */
	icon?: ButtonToggleItemProps<T>['icon'];

	/** Name of icon to render inside of button */
	iconName?: ButtonToggleItemProps<T>['iconName'];
}

/**
 * ButtonToggle properties
 */
export interface ButtonTogglePropsStrict<T> {
	/** String of class names to add to component */
	className?: string;

	/** Switch on unselected state */
	clearable?: boolean;

	/** Selected button background color.<br /> Possible values: <i>'primary'</i>, <i>'blue'</i>, <i>'negative'</i>, <i>'red'</i> */
	color?: ButtonToggleItemProps<T>['color']; // 'default', 'grey' are colors of not selected buttons

	/** Sets all the option's unselectable */
	disabled?: ButtonToggleItemProps<T>['disabled'];

	/** Element to use for base component */
	el?: any;

	/** Shortcut for large-sized buttons */
	large?: boolean;

	/** Specifies the name of the ButtonToggle element */
	name?: string;

	/** Negative styling (red) */
	negative?: boolean;

	/** Called when the user changes the value */
	onChange?: (value: T | undefined) => void;

	/** List of options */
	options?: OptionProps<T>[];

	/** Primary button */
	primary?: boolean;

	/** Sets size of the buttons.<br /> Possible values: <i>'large'</i>, <i>'small'</i> */
	size?: ButtonToggleItemProps<T>['size'];

	/** Shortcut for small-sized buttons */
	small?: boolean;

	/** Value of the ButtonToggle element. Sets corresponding option selected */
	value?: ButtonToggleItemProps<T>['value'];

	/** Shortcut for x-small-sized buttons */
	xsmall?: boolean;
}

export interface ButtonToggleProps<T> extends ButtonTogglePropsStrict<T> {
	/** Unstrict Props */
	[propName: string]: any;
}

interface ButtonToggleState<T> {
	selected: ButtonToggleItemProps<T>['value'];
}

export const ButtonToggle: <T>(
	props: React.PropsWithoutRef<ButtonToggleProps<T>> &
		React.RefAttributes<HTMLElement>
) => React.ReactElement | null = React.forwardRef(function <T>(
	{
		color = 'primary',
		primary,
		negative,
		className,
		disabled,
		el: ButtonToggleElement = 'div',
		clearable,
		options = [],
		large,
		small,
		xsmall,
		name,
		size = 'medium',
		value,
		onChange,
		...props
	}: ButtonToggleProps<T>,
	ref: React.RefObject<HTMLElement>
) {
	const [selected, setSelected] =
		React.useState<ButtonToggleState<T>['selected']>(value);

	React.useEffect(() => {
		setSelected(value);
	}, [value]);

	const arrowNavRef = React.useRef(null);
	const mergedRef = useMergedRef(ref, arrowNavRef);

	useArrowNav({
		scope: arrowNavRef,
		handleY: false,
		focusableSelector: { selector: 'button:not([disabled])' },
		onKeyDown: (e: KeyboardEvent, data: any) => data.next?.click(), // Auto-selection
	});

	const handleClick = (select: ButtonToggleItemProps<T>['value']) => {
		const realSelected =
			select !== selected || !clearable ? select : undefined;

		if (onChange) onChange(realSelected);
		setSelected(realSelected);
	};

	const copyOptions = (options: OptionProps<T>[]): OptionProps<T>[] => {
		const copiedOptions = options.map((option) => {
			return {
				text: option.text,
				value: option.value,
				onClick: option.onClick,
				disabled: option.disabled,
				selected: option.selected,
				hidden: option.hidden,
				icon: option.icon,
				iconName: option.iconName,
			};
		});

		return copiedOptions;
	};

	const getOnlySelected = (options: OptionProps<T>[]): OptionProps<T>[] => {
		let isSelected = false;

		const onlyOneSelectedOptions = options.map((option) => {
			if (option.selected && !isSelected) {
				isSelected = true;
			} else {
				option.selected = false;
			}
			return option;
		});

		return onlyOneSelectedOptions;
	};

	const getNormalized = (options: OptionProps<T>[]): OptionProps<T>[] => {
		const realOptions = options.map((option) => {
			if (disabled) option.disabled = disabled;
			if (option.text === undefined) option.text = null;
			if (selected !== undefined || clearable)
				option.selected = selected === option.value;

			return option;
		});

		return realOptions;
	};

	const getRealColor = () => {
		let realColor: ButtonToggleItemProps<T>['color'] = color;

		if (primary) realColor = 'primary';
		if (negative) realColor = 'negative';

		return realColor;
	};

	const getButtonToggleItemProps = (
		options: OptionProps<T>[]
	): ButtonToggleItemProps<T>[] => {
		let realSize: ButtonToggleItemProps<T>['size'] = size;

		// Override string props with boolean values
		if (xsmall) realSize = 'xsmall';
		if (small) realSize = 'small';
		if (large) realSize = 'large';

		const buttonToggleItemProps = options.map((option) => {
			return {
				name,
				text: option.text,
				value: option.value,
				disabled: option.disabled,
				selected: option.selected,
				color: getRealColor(),
				size: realSize,
				hidden: option.hidden,
				onClick: (value: ButtonToggleItemProps<T>['value']) => {
					if (option.onClick) option.onClick(value);
					handleClick(value);
				},
				icon: option.icon,
				iconName: option.iconName,
			};
		});

		return buttonToggleItemProps;
	};

	const copiedOptions = copyOptions(options);

	const onlySelectedOptions = getOnlySelected(copiedOptions);

	const normalizedOptions = getNormalized(onlySelectedOptions);

	const buttonToggleItemProps = getButtonToggleItemProps(normalizedOptions);

	const contents = buttonToggleItemProps.map((buttonProp, index) => {
		return <ButtonToggleItem<T> key={index} {...buttonProp} />;
	});

	const realColor = getRealColor();

	const ButtonToggleClasses = classnames('ButtonToggle', className, {
		'ButtonToggle--disabled': disabled,
		[`ButtonToggle--${realColor}`]: realColor,
	});

	return (
		<ButtonToggleElement
			className={ButtonToggleClasses}
			data-anvil-component="ButtonToggle"
			ref={mergedRef}
			role={clearable ? null : 'radiogroup'}
			{...props}
		>
			{contents}
		</ButtonToggleElement>
	);
});
