import { FocusTrapShards } from '../';

const focusableElements = [
	'input:not([disabled]):not([type=hidden])',
	'select:not([disabled])',
	'textarea:not([disabled])',
	'button:not([disabled])',
	'a[href]',
	'area[href]',
	'summary',
	'iframe',
	'object',
	'embed',
	'audio[controls]',
	'video[controls]',
	'[contenteditable]',
	'[tabindex]:not([disabled]):not([tabindex="-1"])',
];

export const focusableSelector = focusableElements.join(
	':not([hidden]):not([tabindex="-1"]),'
);
export const priorityFocusableSelector = 'input, textarea';
export const active = () => document?.activeElement as HTMLElement;

export const getScopeElements = (shards: FocusTrapShards): HTMLElement[] => {
	if (!shards) return [];

	return toArray(shards)
		.map((el) => (el as React.RefObject<any>).current ?? el)
		.filter((el) => el instanceof HTMLElement);
};

const queryOnElement = (el: HTMLElement, selector: string) => {
	let result = [];

	if (el.matches(selector)) result.push(el); // Return self if matches

	const nodeList = el.querySelectorAll(selector);

	if (nodeList.length > 0)
		result = result.concat(Array.from(nodeList) as HTMLElement[]);

	return result.length > 0 ? result : null;
};

// True if `display: none`, etc.
const isHidden = (el: HTMLElement) => !el?.offsetWidth || !el?.offsetHeight;

export const queryOnScope = (
	scope: HTMLElement | HTMLElement[],
	selector: string
) => {
	let targetElements: HTMLElement[] = toArray(scope);

	if (targetElements.length === 0) return null;

	targetElements = targetElements
		.map((el) => queryOnElement(el, selector))
		.flat()
		.filter((el) => !isHidden(el));

	if (targetElements.length === 0) return null;

	return targetElements;
};

export const getFocusables = (
	scope: HTMLElement | HTMLElement[],
	selector: string = focusableSelector // Crucial thing
) => {
	const focusables = queryOnScope(scope, selector);

	if (!focusables || focusables.length === 0)
		return { focusables: [], first: null, last: null };

	const first = focusables[0];
	const last = focusables[focusables.length - 1];

	return { focusables, first, last };
};

export const isInside = (
	scope: HTMLElement[],
	target: HTMLElement | Element
) => {
	const scopeElement = scope.find((item) => item?.contains(target));

	if (!scopeElement) return false;

	// Check if target element is inside another FocusTrap scope
	if (isInsideNestedTrap(scopeElement, target)) return false;

	return true;
};

const isInsideNestedTrap = (
	scopeElement: HTMLElement,
	target: HTMLElement | Element
) => {
	let current = target;
	let found = false;

	// Check if some parent of active element has nested useFocusTrap marker
	while (scopeElement.contains(current) && scopeElement !== current) {
		if (current.hasAttribute('data-focus-locked')) {
			found = true; // Nested scope found
			break;
		}
		current = current.parentElement;
	}
	return found;
};

export const tabIndexInside = (scope: FocusTrapShards) => {
	return getScopeElements(scope).some((el) =>
		getFocusables(el).focusables.some((e) => getTabIndex(e) > 0)
	);
};

export const shardsUtils = (initialScope: HTMLElement | HTMLElement[]) => {
	let currentShardIdx = 0;
	const scope = toArray(initialScope);

	scope.forEach((el, i) => {
		if (el?.contains(active())) currentShardIdx = i;
	});

	const nextShardIdx = nextIdx(scope, currentShardIdx);
	const prevShardIdx = prevIdx(scope, currentShardIdx);

	const current = getFocusables(scope[currentShardIdx]);
	const next = getFocusables(scope[nextShardIdx]);
	const prev = getFocusables(scope[prevShardIdx]);

	return {
		firstCurrentShard: current?.first,
		lastCurrentShard: current?.last,
		firstNextShard: next.first,
		lastPrevShard: prev.last,
	};
};

export const focusNext = (
	scope: HTMLElement | HTMLElement[],
	event?: React.KeyboardEvent<HTMLElement>
) => {
	const { first, last } = getFocusables(scope);
	const shards = shardsUtils(scope);

	if (active() === last) {
		event?.preventDefault(); // Prevent focus on focusable outside of useFocusLock
		first.focus();
		return;
	}
	if (active() === shards.lastCurrentShard) {
		event?.preventDefault();
		shards.firstNextShard.focus();
	}
};

export const focusPrev = (
	scope: HTMLElement | HTMLElement[],
	event?: React.KeyboardEvent<HTMLElement>
) => {
	const { first, last } = getFocusables(scope);
	const shards = shardsUtils(scope);

	if (active() === first) {
		event?.preventDefault(); // Prevent focusing on focusable outside of useFocusLock scope
		last.focus();
		return;
	}
	if (active() === shards.firstCurrentShard) {
		event?.preventDefault();
		shards.lastPrevShard.focus();
	}
};

export const toArray = (obj: any) => (obj instanceof Array ? obj : [obj]);

export const prevIdx = (array: any[], current: number) => {
	if (current < 0) return current;
	return current === 0 ? array.length - 1 : current - 1;
};

export const nextIdx = (array: any[], current: number) => {
	if (current < 0) return current;
	return current === array.length - 1 ? 0 : current + 1;
};

const getTabIndex = (el: HTMLElement) => {
	const idx = parseInt(el.getAttribute('tabIndex'), 10);
	if (!idx) return 0;
	return idx;
};

// Focus on prev focusable if focusable was removed
const focusPrevOnUnmount = (el: HTMLElement, scope: HTMLElement[]) => {
	const { focusables: f1 } = getFocusables(scope);
	const idx = f1.indexOf(el);
	const length1 = f1.length;

	setTimeout(() => {
		const { focusables: f2 } = getFocusables(scope);

		if (document?.body.contains(el)) return;
		if (f2.length === 0) return;
		if (length1 !== f2.length + 1) return;

		idx === 0 ? f2[f2.length - 1].focus() : f2[idx - 1].focus();
	}, 10);
};

export const handleFocusablesUnmount = (scope: HTMLElement[]) => {
	const once = { once: true };

	getFocusables(scope).focusables.forEach((el) => {
		const handleBlur = () => focusPrevOnUnmount(el, scope);
		el.removeEventListener('blur', handleBlur);
		el.addEventListener('blur', handleBlur, once);
	});
};

export const setFocusLockMarkers = (scope: HTMLElement[]) => {
	scope.forEach((el) => el.setAttribute('data-focus-locked', ''));
};

export const cleanFocusLockMarkers = (scope: HTMLElement[]) => {
	scope.forEach((el) => el.removeAttribute('data-focus-locked'));
};
