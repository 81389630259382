import React, { FC } from 'react';
import classnames from 'classnames';

import { BodyText } from '../BodyText';
import { Icon, IconProps, IconPropsStrict } from '../Icon';
import { keys } from '../../utilities/keyCodes';

import {
	CollapsibleContext,
	CollapsibleList,
	CollapsibleListItemProps,
} from '../../internal';

export interface SideNavItemPropsStrict {
	/** Controls if this is the currently active item in SideNav */
	active?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	children?: React.ReactNode;

	/**
	 * If provided, item to be rendered as collapsible menu (accordion).
	 * In this case one nested SideNav must be provided inside children.
	 */
	collapsible?: CollapsibleListItemProps['collapsible'] | true;

	/** Indicates whether the SideNav.Item is disabled or not. */
	disabled?: boolean;

	/** Add a href attribute so right click context menus work (open in new tab, etc) */
	href?: string;

	/** Name of icon to render on right side of SideNav.Item */
	icon?: IconPropsStrict['name'] | IconProps;

	/** Action for when SideNav.Item is clicked */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** To be rendered on the right side of SideNav.Item */
	prefix?: React.ReactElement;

	/** To be rendered on the left side of SideNav.Item */
	suffix?: React.ReactElement;
}

export interface SideNavItemProps extends SideNavItemPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const SideNavItem: FC<SideNavItemProps> = ({ ...props }) => {
	const { className, ...rest } = props;
	const SideNavItemClasses = classnames('SideNavItem', className, {
		'SideNavItem--active': props.active,
		'SideNavItem--disabled': props.disabled,
	});
	const sideNavItemContext = React.useContext(SideNavItemContext);

	if (props.collapsible || sideNavItemContext) {
		const realCollapsible =
			props.collapsible === true
				? {
						expanded: false,
						animate: true,
						extractCollapsibleList: true,
				  }
				: { ...props.collapsible, extractCollapsibleList: true };

		return (
			<CollapsibleList.Item
				el="li"
				collapsible={props.collapsible && realCollapsible}
				className={SideNavItemClasses}
				data-anvil-component="SideNavItem"
			>
				<SideNavItemButton {...rest} />
			</CollapsibleList.Item>
		);
	}

	return (
		<li className={SideNavItemClasses} data-anvil-component="SideNavItem">
			<SideNavItemButton {...rest} />
		</li>
	);
};

/**
 * SideNav Item's Button
 */
const SideNavItemButton: FC<SideNavItemProps> = ({
	active,
	className,
	collapsible,
	children,
	disabled,
	href,
	icon,
	prefix,
	suffix,
	...rest
}) => {
	const El = href ? 'a' : 'span';
	const buttonRef = React.useRef(null);
	const collapsibleContext = React.useContext(CollapsibleContext);

	const expanded = collapsible && collapsibleContext?.expanded;
	const SideNavItemButtonClasses = classnames('SideNavItem__button', {
		'SideNavItem__button--expanded': expanded,
	});

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (![keys.enter, keys.space].includes(e.key)) return;

		if (e.target !== buttonRef.current) {
			e.stopPropagation(); // Disable higher handlers (collapse-expand) for buttons placed inside Item's suffix
			return;
		}

		if (e.key === keys.space) e.preventDefault(); // Prevent scroll on space keypress

		buttonRef?.current?.click();
	};

	const renderPrefix = () => {
		const i = renderIcon(icon);

		if (!i && !prefix) return null;

		return (
			<span className="SideNavItem__button__prefix">
				{i}
				{prefix}
			</span>
		);
	};

	const renderSuffix = () => {
		const arrow = collapsible ? (
			<Icon
				className="SideNavItem__button__suffix__collapsible-icon"
				name="keyboard_arrow_down"
				size={20}
			/>
		) : null;
		if (!suffix && !arrow) return null;
		return (
			<span className="SideNavItem__button__suffix">
				{suffix}
				{arrow}
			</span>
		);
	};

	return (
		<SideNavItemContext.Provider value={{ buttonRef }}>
			<El
				{...rest}
				className={SideNavItemButtonClasses}
				href={href}
				tabIndex={disabled ? -1 : 0}
				target={href && rest.target}
				ref={buttonRef}
				onKeyDown={handleKeyDown}
				aria-current={active && !expanded ? 'page' : null}
				aria-disabled={disabled ? true : null}
			>
				{renderPrefix()}
				<BodyText
					el="span"
					size="small"
					className="SideNavItem__button__content"
				>
					{children}
				</BodyText>
				{renderSuffix()}
			</El>
		</SideNavItemContext.Provider>
	);
};

export const SideNavItemContext = React.createContext(null);

const renderIcon = (icon: IconPropsStrict['name'] | IconProps) => {
	// Icon passed as Icon name
	if (typeof icon === 'string') {
		return (
			<Icon className="SideNavItem__button__icon" name={icon} size={20} />
		);
	}

	// Icon passed as an IconProps object
	if (icon != null && !React.isValidElement(icon)) {
		return (
			<Icon
				className={classnames(
					'SideNavItem__button__icon',
					icon.className
				)}
				{...icon}
			/>
		);
	}

	return null;
};
