import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import InputMask, { InputState } from 'react-input-mask';

import { Input, InputProps } from '../Input';
import { keys } from '../../utilities/keyCodes';
import { compareObj, isInvalidDate } from './utils/utils';
import {
	formatDate,
	isFocusInsideDatePicker,
	parseEntry,
} from './utils/maskUtils';
import {
	expandMaskValue,
	findMaskFieldBoundariesAtPosition,
} from './utils/expandMaskUtils';

export const defaultFormat = 'MM/DD/YYYY';
export const defaultMaskChar = '_';

export interface InputDateMaskProps
	extends Omit<InputProps, 'onChange' | 'value'> {
	/** If the mask should always be present. */
	alwaysShowMask?: boolean;

	/** Format of date string to display. Default is "MM/DD/YYYY" */
	dateFormat?: string;

	/** Character to use with partially complete dates */
	maskChar?: string;

	/** Maximum date allowed */
	maxDate?: Date;

	/** Minimum date allowed */
	minDate?: Date;

	/** onChange function */
	onChange(value: Date | undefined, data?: any): void;

	/** onChange function */
	onBlur(value: Date | undefined, data?: any): void;

	/** Current value of Input */
	value?: Date;

	/** forwarded refs */
	inputRef?: React.RefObject<HTMLInputElement>;
	calendarRef?: React.RefObject<HTMLInputElement>;
}

export const InputDateMask: FC<InputDateMaskProps> = ({
	alwaysShowMask,
	className,
	dateFormat = defaultFormat,
	disabled,
	error,
	maskChar = defaultMaskChar,
	maxDate: max,
	minDate: min,
	onChange,
	onBlur,
	placeholder,
	required,
	shortLabel,
	size,
	value,
	tabIndex,
	inputRef,
	calendarRef,
	input,
	...rest
}: InputDateMaskProps) => {
	const [entry, setEntry] = useState<string>(''); // Input's text entry, e.g. mask value
	const [rangeError, setRangeError] = useState(false);
	const [requiredError, setRequiredError] = useState(false);
	const [invalidEntryError, setInvalidEntryError] = useState(false);
	const [expandMaskAtCursor, setExpandMaskAtCursor] =
		React.useState<boolean>(false);

	const isChangeFromMask = React.useRef(false);
	const parse = (str = entry) =>
		parseEntry(str, dateFormat, maskChar, min, max, required);

	// Effects
	useEffect(() => {
		if (isInvalidDate(value) || typeof value === 'undefined') {
			if (isChangeFromMask.current) {
				isChangeFromMask.current = false; // In case value came from InputDateMask...
				return; // ...keep user's entry as is
			}
		}

		const nextEntry = formatDate(value, dateFormat);

		setRangeError(parse(nextEntry).isOutRange);
		setRequiredError(false);
		setInvalidEntryError(false);
		setEntry(nextEntry);
	}, [compareObj(value)]); // eslint-disable-line

	useEffect(() => {
		if (!isChangeFromMask.current) return; // Prevent double onChange if value changed from outside of InputDateMask

		const { date, isEmpty, isFull, isOutRange, isValid, invalidReason } =
			parse(); // New entry

		setInvalidEntryError(isFull && !isValid);

		if (!isValid && !isFull && !isEmpty) {
			if (
				(value && !isInvalidDate(value)) || // `12/12/2023` → `12/12/202_`
				(typeof value === 'undefined' && !isEmpty) // `__/__/____` → `1_/__/____`
			) {
				isChangeFromMask.current = true;
				onChange(date, { invalidReason });
			}
			return; // Suppress onChange if entry not full
		}

		if (isOutRange || (isFull && !isValid)) {
			isChangeFromMask.current = true;
			setRangeError(true);
			onChange(date, { invalidReason });
			return;
		}
		// Suppress firing onChange for same values
		if (compareObj(value) !== compareObj(date)) {
			isChangeFromMask.current = true;
			onChange(date, { invalidReason }); // Finally
		}
	}, [entry]); // eslint-disable-line

	// Handlers
	const handleMaskKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === keys.delete) {
			e.preventDefault();
			isChangeFromMask.current = true;
			setRequiredError(required && !parse().isEmpty);
			setEntry(''); // Clear user's entry
		}
		if (e.key === keys.slash) {
			e.preventDefault();
			setExpandMaskAtCursor(true); // Performs `1_/__/____` → `01/__/____`, etc.
		}
	};

	const handleMaskBlur = (e: React.FocusEvent<any>) => {
		if (isFocusInsideDatePicker(inputRef, calendarRef, e)) return; // Suppress if focus is still inside DatePicker

		const { date, isEmpty, isFull, isOutRange, isValid, invalidReason } =
			parse();

		setRequiredError(required && !isFull);
		setRangeError(isOutRange);
		setInvalidEntryError(!isEmpty && !isValid);

		onBlur(date, { invalidReason });
		isChangeFromMask.current = false;
	};

	const handleMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.type !== 'change') return; // Other possible types: focus, blur

		isChangeFromMask.current = true;
		const nextEntry = e?.target?.value;

		if (nextEntry === entry) return;

		input?.onChange?.(e, nextEntry);

		const { isEmpty, isFull, isOutRange, isValid } = parse(nextEntry);

		setRangeError(isOutRange);
		setRequiredError(required && isEmpty);
		setInvalidEntryError(isFull && !isValid);
		setEntry(nextEntry); // Runs effect on state change later to handle onChange
	};

	const handleBeforeMaskChange = (next: InputState) => {
		if (expandMaskAtCursor) {
			setExpandMaskAtCursor(false);

			const expanded = expandMaskValue(next.value, next.selection.start);

			if (expanded === entry || parse(expanded).isEmpty) return next;

			setEntry(expanded);

			const nextIndex = findMaskFieldBoundariesAtPosition(
				next.value,
				next.selection.start
			)[1];

			next.selection = { start: nextIndex + 1, end: nextIndex + 1 }; // Reposition cursor to next boundary
			next.value = expanded;
		}

		if (entry === '' && parse(next.value).isEmpty)
			next.selection = { start: 0, end: 0 }; // Send cursor to start on clearing mask

		return next;
	};

	/*
	 * InputMask mask prop format: https://github.com/sanniassin/react-input-mask
	 */
	const mask = dateFormat.replace(/[MmDdYyo]/g, '9');
	const realPlaceholder = placeholder || mask.replace(/9/g, maskChar);

	return (
		<InputMask
			{...rest}
			mask={mask}
			disabled={disabled}
			maskChar={maskChar}
			onChange={handleMaskChange}
			onKeyDown={handleMaskKeyDown}
			onBlur={handleMaskBlur}
			beforeMaskedValueChange={handleBeforeMaskChange}
			alwaysShowMask={alwaysShowMask}
			value={entry}
		>
			{
				((props: React.InputHTMLAttributes<HTMLInputElement>) => (
					<Input
						{...props}
						inputRef={inputRef}
						shortLabel={shortLabel}
						className={classnames('InputDateMask', className)}
						size={size}
						fluid
						error={
							error ||
							(required && requiredError) ||
							invalidEntryError ||
							((min || max) && rangeError)
						}
						disabled={disabled}
						placeholder={realPlaceholder}
						tabIndex={tabIndex}
					/>
				)) as any
			}
		</InputMask>
	);
};
