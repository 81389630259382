import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Text properties
 */
export interface BodyTextPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Set style `display: inline;` to nest Text components */
	inline?: boolean;

	/** Set style to italic */
	italic?: boolean;

	/** Headline Font size */
	size?: 'large' | 'medium' | 'small' | 'xsmall';

	/** Deemphasized text */
	subdued?: boolean;

	/** Bold */
	bold?: boolean;
}

export interface BodyTextProps extends BodyTextPropsStrict {
	[propName: string]: any;
}

export const BodyText: FC<BodyTextProps> = React.forwardRef(
	(
		{
			children,
			className,
			el: BodyTextElement = 'p',
			inline,
			italic,
			subdued,
			bold,
			size = 'medium',
			...rest
		},
		ref
	) => {
		const TextClasses = classnames('BodyText', className, {
			[`BodyText--${size}`]: size,
			'BodyText--inline': inline,
			'BodyText--bold': bold,
			'BodyText--italic': italic,
			'BodyText--subdued': subdued,
		});

		return (
			<BodyTextElement
				ref={ref}
				className={TextClasses}
				data-anvil-component="BodyText"
				{...rest}
			>
				{children}
			</BodyTextElement>
		);
	}
);
