import React, { FC } from 'react';
import classnames from 'classnames';
import { DataListCell, DataListCellProps } from './DataListCell';

export type DataListHeaderCellType =
	React.ReactElement<DataListHeaderCellProperties>;

export interface DataListHeaderCellProperties extends DataListCellProps {
	name: string;
	className?: string;
	children?: React.ReactNode;

	/** Deprecated. */
	content?: React.ReactNode;
}

export const DataListHeaderCell: FC<DataListHeaderCellProperties> = ({
	className,
	children,
	content,
	...rest
}) => {
	const classes = classnames('DataList__HeaderCell', className);

	return (
		<DataListCell className={classes} {...rest}>
			{children || content}
		</DataListCell>
	);
};
