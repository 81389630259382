import React, { FC } from 'react';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { tokens } from '@servicetitan/tokens/core';
import { Overlay, OverlayProps } from '../../internal/Overlay';
import { JustifyProps } from '../../utilities/justify-props';
import { useFocusTrap, UseFocusTrapProps } from '../../hooks/useFocusTrap';
import { Headline } from '../Headline';
import { Button } from '../Button';

export interface ModalContentProps {
	children?: React.ReactNode;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Allows the Modal to fit within the browser window */
	scrollable?: boolean;

	id?: string;
}

export const ModalContent: FC<ModalContentProps> = ({
	children,
	className,
	scrollable = true,
	id,
}) => {
	const ModalContentClassNames = classnames('Modal__content', className, {
		'Modal__content--scrollable': scrollable,
	});
	return (
		<div id={id} className={ModalContentClassNames}>
			{children}
		</div>
	);
};

ModalContent.displayName = 'ModalContent';

export interface ModalFooterProps extends ModalContentProps {
	align?: JustifyProps;
}

export const ModalFooter: FC<ModalFooterProps> = ({
	align = 'right',
	children,
	className,
	...props
}) => {
	const ModalFooterClassNames = classnames('Modal__footer', className, {
		'Modal__footer--left': align === 'left',
		'Modal__footer--center': align === 'center',
		'Modal__footer--right': align === 'right',
		'Modal__footer--space-between': align === 'space-between',
	});
	return (
		<div className={ModalFooterClassNames} {...props}>
			{children}
		</div>
	);
};

ModalFooter.displayName = 'ModalFooter';

export interface ModalHeaderProps {
	/** Add class attribute */
	className?: string;
	onClose?: (e: React.SyntheticEvent<HTMLElement>) => void;
	title?: React.ReactNode;
	id?: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
	className,
	title,
	onClose,
	id,
	...props
}) => {
	if (!onClose && title == null) {
		return null;
	}

	const ModalHeaderClassNames = classnames('Modal__header', className);

	return (
		<div className={ModalHeaderClassNames} {...props}>
			<Headline className="Modal__header-title" id={id}>
				{title}
			</Headline>
			{onClose && (
				<Button
					aria-label="close modal"
					fill="subtle"
					className="Modal__header-close"
					iconName="clear"
					size="xsmall"
					onClick={onClose}
				/>
			)}
		</div>
	);
};

ModalHeader.displayName = 'ModalHeader';

/**
 * Modal Sizes
 */
export enum ModalSizes {
	XS = 'XS',
	S = 'S',
	M = 'M',
	L = 'L',
}

function sizeClass(size: ModalSizes): string {
	return `Modal--${size}`;
}

/**
 * Modal Window Properties
 */
export interface ModalWindowProps extends OverlayProps {
	/** Add class attribute */
	className?: string;

	size?: ModalSizes;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;
}

export const ModalWindow: FC<ModalWindowProps> = ({
	animate = true,
	children,
	className,
	focusTrapOptions,
	onClose,
	open,
	portal = true,
	size = ModalSizes.M,
	...props
}) => {
	const ModalWindowClassNames = classnames(
		'Modal',
		sizeClass(size),
		className
	);

	// Focus trapping
	const containerRef = useFocusTrap({
		autoFocusContainer: true,
		disabled: !open,
		...focusTrapOptions,
	});

	const duration: number = parseInt(tokens.durationStandard, 10) / 1000; // '200ms' -> 0.2
	const framerAnimation = {
		initial: { opacity: 0, y: 50 },
		animate: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: 50 },
		transition: { type: 'easeInOut', duration },
	};

	const Animate = animate ? AnimatePresence : React.Fragment;
	const ModalEl = animate ? motion.div : 'div';
	const ModalProps = {
		className: ModalWindowClassNames,
		...(animate && framerAnimation),
		...props,
		ref: containerRef,
	};

	return (
		<Overlay
			animate={animate}
			backdrop
			onClose={onClose}
			open={open}
			portal={portal}
		>
			<Animate>
				{open && <ModalEl {...ModalProps}>{children}</ModalEl>}
			</Animate>
		</Overlay>
	);
};
