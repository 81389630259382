import React, { FC } from 'react';
import classnames from 'classnames';

import { CollapsibleContext } from './CollapsibleContext';
import { keys } from '../../utilities';

export interface CollapsibleListItemProps {
	className?: string;
	children?: React.ReactNode;
	el?: any;
	collapsible?: {
		expanded: boolean;
		animate?: boolean;
		extractCollapsibleList?: boolean;
	};
}

export const CollapsibleListItem: FC<CollapsibleListItemProps> = ({
	children,
	className,
	collapsible,
	el: Element = 'li',
	...rest
}) => {
	const collapsibleItemRef = React.useRef(null);
	const collapsibleListRef = React.useRef(null);
	const [expanded, setExpanded] = React.useState(
		collapsible?.expanded || false
	);
	const ListItemClasses = classnames('CollapsibleListItem', className, {
		'CollapsibleListItem--expanded': collapsible && expanded,
		'CollapsibleListItem--collapsed': collapsible && !expanded,
		'cursor-pointer': collapsible,
	});

	const handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
		if (collapsibleListRef?.current?.contains(e.target)) return; // Prevent exapand/collapse on nested list items

		const { onClick } = rest as any;

		if (collapsible) setExpanded(!expanded);
		if (onClick) onClick(e);
	};

	const handleArrowKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
		if (collapsibleListRef?.current?.contains(e.target)) return;
		if (collapsible) {
			e.preventDefault();
			if (e.key === keys.left && expanded) setExpanded(false);
			if (e.key === keys.right && !expanded) setExpanded(true);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		switch (e.key) {
			case keys.space:
			case keys.enter:
				handleClick(e as React.SyntheticEvent<HTMLElement>);
				break;
			case keys.left: // Collapse
			case keys.right: // Expand
				handleArrowKeypress(e);
				break;
		}
	};

	// Appends CollapsibleList as a last element if CollapsibleItem
	const extract = collapsible?.extractCollapsibleList || false;
	React.useEffect(() => {
		if (extract && collapsibleListRef?.current)
			collapsibleItemRef.current.append(collapsibleListRef.current);
	}, [extract]);

	const collapsibleContextValue = collapsible
		? {
				animate: collapsible.animate || true,
				collapsibleRef: collapsibleListRef,
				expanded,
				extractCollapsibleList:
					collapsible.extractCollapsibleList || false,
		  }
		: undefined;

	return (
		<CollapsibleContext.Provider value={collapsibleContextValue}>
			<Element
				className={ListItemClasses}
				data-anvil-component="CollapsibleList"
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				ref={collapsibleItemRef}
				{...rest}
			>
				{children}
			</Element>
		</CollapsibleContext.Provider>
	);
};
