import React, { FC } from 'react';
import classnames from 'classnames';

import { FormField } from '../FormField';
import { FormGroup } from '../FormGroup';
import {
	FormButton,
	FormButtonToggle,
	FormCheckbox,
	FormColorPicker,
	FormDatePicker,
	FormDateRangePicker,
	FormDropdown,
	FormInput,
	FormRadio,
	FormSelect,
	FormTextArea,
	FormTogglebox,
	FormToggleSwitch,
	FormAnvilSelect,
	FormTimePicker,
} from '../FormControls';

export interface FormPropsStrict {
	/** Additional classes */
	className?: string;

	/** Element to use for base component */
	el?: any;

	/**
	 * Called on form submit.
	 * @param {SyntheticEvent} event - The React SyntheticEvent object
	 * @param {object} data - All props and the event value.
	 */
	onSubmit?: (event: React.SyntheticEvent, data: any) => void;

	/** @deprecated Semantic UI legacy code */
	size?: string;
}

export interface FormProps extends FormPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

interface Form extends FC<FormProps> {
	AnvilSelect: typeof FormAnvilSelect;
	Checkbox: typeof FormCheckbox;
	Radio: typeof FormRadio;
	TextArea: typeof FormTextArea;
	Button: typeof FormButton;
	ButtonToggle: typeof FormButtonToggle;
	ColorPicker: typeof FormColorPicker;
	DatePicker: typeof FormDatePicker;
	DateRangePicker: typeof FormDateRangePicker;
	Dropdown: typeof FormDropdown;
	Field: typeof FormField;
	Group: typeof FormGroup;
	Input: typeof FormInput;
	Select: typeof FormSelect;
	Togglebox: typeof FormTogglebox;
	ToggleSwitch: typeof FormToggleSwitch;
	TimePicker: typeof FormTimePicker;
}

export const Form: Form = ({
	action,
	children,
	className,
	el: Element = 'form',
	onSubmit,
	size,
	...rest
}) => {
	React.useEffect(() => {
		if (size)
			console.warn(
				'@servicetitan/design-system: The `size` prop of the Form component has been deprecated.'
			);
	}, [size]);

	const handleSubmit = (
		e: React.ChangeEvent<HTMLFormElement>,
		...args: any
	) => {
		// Third party libs can pass own data as first argument, we need to check that it has preventDefault() method.
		if (typeof action !== 'string') {
			e.preventDefault();
		}

		if (onSubmit) {
			onSubmit(e, { ...rest, ...args });
		}
	};

	const FormClasses = classnames('Form', className);

	return (
		<Element
			{...rest}
			className={FormClasses}
			onSubmit={handleSubmit}
			data-anvil-component="Form"
		>
			{children}
		</Element>
	);
};

Form.Checkbox = FormCheckbox;
Form.Radio = FormRadio;
Form.TextArea = FormTextArea;

Form.Button = FormButton;
Form.ButtonToggle = FormButtonToggle;
Form.ColorPicker = FormColorPicker;
Form.DatePicker = FormDatePicker;
Form.DateRangePicker = FormDateRangePicker;
Form.Dropdown = FormDropdown;
Form.Field = FormField;
Form.Group = FormGroup;
Form.Input = FormInput;
Form.Select = FormSelect;
Form.Togglebox = FormTogglebox;
Form.ToggleSwitch = FormToggleSwitch;
Form.AnvilSelect = FormAnvilSelect;
Form.TimePicker = FormTimePicker;
