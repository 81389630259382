import React, { FC } from 'react';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { tokens } from '@servicetitan/tokens/core';

/**
 * Backdrop properties
 */
export interface BackdropPropsStrict {
	/** Use animation for visibility transitions */
	animate?: boolean;

	/** Add class attribute */
	className?: string;

	/** onClick Function */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Visibility of Backdrop */
	open?: boolean;
}

export interface BackdropProps extends BackdropPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Backdrop: FC<BackdropProps> = ({
	animate,
	className,
	onClick,
	open = true,
	...rest
}) => {
	const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
		e.stopPropagation();

		if (e.target !== e.currentTarget) return;

		if (onClick) onClick(e);
	};

	const BackdropClassNames = classnames('Backdrop', className, {
		'Backdrop--is-open': open,
		'Backdrop--has-onClick': onClick,
		'cursor-not-allowed': !onClick,
	});

	const durationSlow: number = parseInt(tokens.durationSlow, 10) / 1000; // '300ms' -> 0.3
	const framerAnimation = {
		initial: { opacity: 0 },
		animate: { opacity: 0.6 },
		exit: { opacity: 0 },
		transition: { type: 'linear', duration: durationSlow },
	};

	const Animate = animate ? AnimatePresence : React.Fragment;
	const ScreenEl = animate ? motion.div : 'div';
	const ScreenProps = {
		className: BackdropClassNames,
		onClick: handleClick,
		style: { opacity: !animate && 0.6 },
		...(animate && framerAnimation),
	};

	return (
		<Animate>
			{open && (
				<ScreenEl
					data-anvil-component="Backdrop"
					{...ScreenProps}
					{...rest}
				/>
			)}
		</Animate>
	);
};
