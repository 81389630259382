
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import stLogo from "../images/ST_Logo_Landscape-black.png"
import { SearchBox } from "./SearchBox"
import { useScreenSize, Drawer, Stack, Icon, Headline, Announcement } from '@servicetitan/design-system'
import { Footer } from './Footer'
import { ScreenSizeContext } from '../context/ScreenSizeContext';

export const Header = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					menuLinks {
						name
						link
					}
				}
			}
			search: localSearchPages {
				index
				store
			}
		}
	` )

	const { size, setSize } = React.useContext(ScreenSizeContext);
	const screenSize = useScreenSize();

	const [open, setOpen] = React.useState(false);
	const [IscreenSize, setIscreenSize] = React.useState(screenSize);

	const isPartiallyActive = ({ isPartiallyCurrent }) => {
		return isPartiallyCurrent ? { className: "active" } : null
	}
	const toggleDrawer = () => setOpen(!open);
	const menuClick = () => setOpen(false);

	React.useEffect(() => {
		if (screenSize.innerHeight !== IscreenSize.innerHeight || screenSize.innerWidth !== IscreenSize.innerWidth) {
			setIscreenSize(screenSize)
		}
	}, [screenSize]);

	React.useEffect(() => {
		setSize(IscreenSize)
	}, [IscreenSize]);

	return (
		<>
			<Announcement status="warning" title={<>You are currently on Anvil1, which is now in maintenance mode. <Link style={{ textDecoration: "underline", color: `var(--colorsTextDefault)` }} to="https://anvil.servicetitan.com">Visit Anvil2</Link> to get the latest version of the design system.</>} actionName="Go to Anvil2" />
			<header className="DocsHeader">
				<div className="DocsHeader__logo" style={{ margin: 0 }}>
					<Link to="/" className="DocHeader__homelink" >
						<img src={stLogo} alt="ServiceTitan | Anvil" />
					</Link>
				</div>
				<SearchBox index={data.search.index} store={data.search.store} />
				{ size.smallerThan.desktop
				? <>
						<Icon name="menu" size="24px" className="m-l-2" onClick={toggleDrawer}/>
						<Drawer
							open={open}
							onClose={toggleDrawer}
							backdrop
							footer={<Footer />}
						>
							<Stack direction="column" className="m-t-6 DocsHeader__mobileMenu" alignItems="center">
								{data.site.siteMetadata.menuLinks.map(
									(link, index) => (
										<Link style={{marginBottom: 24}} key={index} getProps={isPartiallyActive} to={link.link} onClick={menuClick}>
											<Headline>{link.name}</Headline>
										</Link>
								))}
							</Stack>
						</Drawer>
					</>
				: <>
						<ul className="DocsHeader__nav">
							{data.site.siteMetadata.menuLinks.map(
								(link, index) => (
								<li key={link.name}>
									<Link key={index} getProps={isPartiallyActive} to={link.link} >
										{link.name}
									</Link>
								</li>
							))}
						</ul>
					</>
				}
			</header>
		</>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}
