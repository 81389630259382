import React, { FC } from 'react';
import classnames from 'classnames';
import { BodyText } from '../../BodyText';
import { Button } from '../../Button';
import { Stack } from '../../Stack';
import { Icon } from '../../Icon';
import { FocusActionType, FocusContext } from '../utils';
import { keys } from '../../../utilities/keyCodes';

export interface SelectDrillInProps {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Function that closes the select when the user clicks on the action button */
	closeSelectHandler?: () => void;

	/** Blank canvas for Drill-in content */
	content?: React.ReactNode;

	/** Text for the action button */
	footerActionName?: string;

	/** Function that fires when the user clicks on the button */
	footerOnActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** If passed an onBack function, <Takeover.Header> gets a Back button in the top left */
	onBack?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Visually display the drill-in from an opened Select */
	open?: boolean;

	/** The drill-in focuses on open. Setting to false prevents the focus */
	preventFocus?: boolean;

	/** Name of the drill-in page */
	title?: string;
}

export const SelectDrillInHeader: FC<SelectDrillInProps> = ({
	className,
	onBack,
	title,
	closeSelectHandler,
	preventFocus,
}) => {
	const SelectDrillInHeaderClassNames = classnames(
		className,
		'SelectDrillIn__header'
	);

	const focusContext = React.useContext(FocusContext);
	const { focusDispatch } = focusContext;
	const backButtonRef = React.useRef(null);

	React.useEffect(() => {
		if (!preventFocus) backButtonRef.current?.focus();
	}, [backButtonRef, preventFocus]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
		if (e.key === keys.enter || e.key === keys.esc) {
			onBack(e);
			focusDispatch({
				type: FocusActionType.SetFocus,
				payload: { filterFocus: true },
			});
			return;
		}

		if (e.key === keys.tab && e.shiftKey) {
			onBack(e);
			closeSelectHandler();
		}
	};

	return (
		<Stack className={SelectDrillInHeaderClassNames} alignItems="center">
			<span
				className="Select__focus"
				tabIndex={0}
				ref={backButtonRef}
				onKeyDown={handleKeyDown}
			>
				<Icon
					className="BackLink__back-icon cursor-pointer SelectDrillIn__back"
					name="chevron_left"
					onClick={onBack}
					size="20px"
				/>
			</span>
			{title && (
				<BodyText
					className="SelectDrillIn__title ff-display lh-1 ta-center flex-grow-1"
					bold
					size="small"
				>
					{title}
				</BodyText>
			)}
		</Stack>
	);
};

export const SelectDrillInFooter: FC<SelectDrillInProps> = ({
	className,
	footerActionName,
	footerOnActionClick,
	closeSelectHandler,
	onBack,
}) => {
	const SelectDrillInFooterClassNames = classnames(
		className,
		'AnvilDropdown__footer w-100'
	);

	const hasAction = !!(footerActionName && footerOnActionClick);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === keys.enter) {
			footerOnActionClick(e);
			return;
		}

		if (e.key === keys.tab && !e.shiftKey) {
			onBack(e);
			closeSelectHandler();
		}
	};

	if (!hasAction) {
		return null;
	}

	return (
		<Stack
			alignItems="center"
			justifyContent="flex-end"
			className={SelectDrillInFooterClassNames}
		>
			<Button
				primary
				xsmall
				onClick={footerOnActionClick}
				className="Select__focus"
				tabIndex={0}
				onKeyDown={handleKeyDown}
			>
				{footerActionName}
			</Button>
		</Stack>
	);
};

export const SelectDrillInContent: FC<SelectDrillInProps> = ({
	className,
	content,
}) => {
	return <div className={className}>{content}</div>;
};
