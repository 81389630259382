import React, { FC } from 'react';
import classnames from 'classnames';

import { CollapsibleContext } from './CollapsibleContext';
import { CollapsibleListItem } from './CollapsibleListItem';
import { Collapsible } from '../../components/Collapsible/Collapsible';

export interface CollapsibleListProps {
	className?: string;
	children?: React.ReactNode;
	el?: any;
}

export interface CollapsibleList extends FC<CollapsibleListProps> {
	/** Subcomponents */
	Item: typeof CollapsibleListItem;
}

export const CollapsibleList: CollapsibleList = ({
	children,
	className,
	el: Element = 'ul',
	...rest
}) => {
	const ListClasses = classnames('CollapsibleList cursor-default', className);
	const context = React.useContext(CollapsibleContext);

	const withWrapper = (
		<Element
			className={ListClasses}
			data-anvil-component="CollapsibleList"
			{...rest}
		>
			{children}
		</Element>
	);

	if (context)
		return (
			<Collapsible
				open={context.expanded}
				animate={context.animate}
				ref={context.collapsibleRef}
			>
				{withWrapper}
			</Collapsible>
		);

	return withWrapper;
};

CollapsibleList.Item = CollapsibleListItem;
