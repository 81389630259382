import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { Dropdown, DropdownProps } from '../Dropdown';

export interface FormDropdownProps extends FormFieldPropsStrict, DropdownProps {
	error?: any; // Weirdly enough, this is what Semantic is doing to solve the extending issue
}

export const FormDropdown: FC<FormDropdownProps> = (props) => (
	<FormField control={Dropdown} {...props} />
);
