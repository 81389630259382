import React, { FC, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { Input, InputProps } from '../Input';
import { Popover, PopoverProps } from '../Popover';
import { TimezoneContext } from '../../utilities/TimezoneProvider';
import { keys } from '../../utilities';
import { FormFieldContext } from '../../components/FormField';

import {
	dateToTime as dtt,
	timeToDate as ttd,
	localTimezone,
	consumeMinMax,
	getReturnDate,
	generateOptions,
	filterOptionsWithRounding,
	filterOptions,
	getClosestOption,
	getOptionByText,
	highlightOption,
	injectOption,
} from './utils';

import { TimePickerOptionList, TimePickerOptionProps } from './components';
import { PopperProps } from '../../internal';

export interface TimePickerPropsStrict
	extends Omit<InputProps, 'value'>,
		Pick<
			PopoverProps,
			| 'autoFlipVertically'
			| 'autoFlipHorizontally'
			| 'direction'
			| 'width'
		> {
	/**
	 * If false, ignores `step` prop, so that user may fill in intermidiate values.
	 * If true, user only allowed to choose options generated with `step` prop,
	 * and user's input to be automatically rounded to the closest option.
	 * Closest value to be highlighted in a dropdown.
	 * Default is false.
	 */
	autoRounding?: boolean;

	/** Preferable signifier of a value to select on autocomplete when user left the control */
	defaultTimeSignifier?: 'AM' | 'PM';

	/**
	 * Turns off TimePicker's ability to display a dropdown with options.
	 * Default is false.
	 */
	disableDropdown?: boolean;

	/**
	 * Specified in minutes, default is 30. Should not exceed 1440 (24 hours).
	 * Step used to:
	 * 1. hide intermediate values (these values are still possible to pick by typing)
	 * from TimePicker's dropdown if `autoRounding` is false, or
	 * 2. generate strict values for dropdown options if `autoRounding` is true.
	 */
	step?: number;

	/**
	 * Time format.
	 * Value of `hh:mm` stands for 24-hour format with leading zero.
	 * Default value is `hh:mm xm`.
	 */
	format?: 'hh:mm xm' | 'hh:mm';

	/**
	 * Minimum time allowed.
	 * String value must have `hh:mm xm` or `hh:mm` format, from 00:00 to 23:59.
	 * Default is `12:00 AM`.
	 */
	min?: Date | string;

	/**
	 * Maximum time allowed.
	 * String value must have `hh:mm xm` or `hh:mm` format, from 00:00 to 23:59.
	 * Default is `11:59 PM`.
	 */
	max?: Date | string;

	/** Called when the user changes the value */
	onChange?: (value: TimePickerOptionProps['value']) => void;

	/** Show a dropdown on render, if `disableDropdown` is unset */
	open?: boolean;

	/** Date object, unrestricted by min and max props */
	value?: TimePickerOptionProps['value'];

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];
}

export interface TimePickerProps extends TimePickerPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const TimePicker: FC<TimePickerProps> = React.forwardRef(
	(props, ref: React.RefObject<any>) => {
		const {
			autoRounding = false,
			className,
			defaultTimeSignifier = 'AM',
			disabled,
			disableDropdown = false,
			direction = 'br',
			error,
			fluid,
			format = 'hh:mm xm',
			min = '12:00 AM',
			max = '23:59 PM',
			onChange,
			open,
			placeholder,
			step = 30,
			size,
			value,
			width = '100',
			portalDataAttributes,
			...rest
		} = props;
		const timezone = React.useContext(TimezoneContext);

		// Date (00:00 in timezone), based on which selected time to be returned
		const returnDate = () => getReturnDate(min, max, value, timezone);
		const dateToTime = (d: Date, tz?: string) =>
			dtt(d, tz || timezone, format);
		const timeToDate = (t: string) => ttd(t, timezone, returnDate());

		/**
		 * In case min or max provided as strings, no timezone adjustment needed.
		 * In case of Date objects, their time should be adjusted to timezone provided.
		 */
		const mm = () => consumeMinMax(min, max, timezone);

		const inputRef = React.useRef(null);
		const dropdownRef = React.useRef<HTMLDivElement>(null);
		const text =
			typeof value === 'string' ? value : dateToTime(value) || ''; // Try to parse `value: string`, if `value: Date` use TZ
		const [opened, setOpened] = React.useState(false); // Dropdown's visibility
		const [dropdownFocused, setDropdownFocused] = React.useState(false); // Dropdown is in focus
		const [input, setInput] = React.useState(text); // User's input string
		const [filter, setFilter] = React.useState(''); // Dropdown's options filter string (differs from user's input)

		const { labelFor: inputId } = React.useContext(FormFieldContext);

		// Effects
		useEffect(() => {
			if (disableDropdown || disabled) {
				setOpened(false);
				return;
			}
			setOpened(open);
		}, [disableDropdown, disabled, open]);

		useEffect(() => {
			// Set input's text on each non-null value change or timezone change
			const text =
				typeof value === 'string' ? value : dateToTime(value) || ''; // Try to parse `value: string`, if `value: Date` use TZ
			if (text !== input) setInput(text);
		}, [value, timezone]); // eslint-disable-line

		const handleInputFocus = () => {
			if (disabled) return;
			if (input === dateToTime(value)) setFilter(''); // Don't filter options until typing
			setDropdownFocused(false); // Dropdown unfocus
			if (!disableDropdown && !opened) setOpened(true);
		};

		let mouseDownOnDropdown = false; // To be false on each re-render

		// Prevents onBlur event handling on input when clicked inside dropdown
		const handleDropdownMouseDown = () => (mouseDownOnDropdown = true);
		const handleClickOutside = (e: any) => {
			if (!opened || disabled || disableDropdown) return;
			if (e?.target?.closest?.(`[for="${inputId}"]`)) return;
			setOpened(false);
			autoComplete();
		};

		const handleInputBlur = () => {
			// User is choosing from dropdown'ed options
			if (dropdownFocused && !disableDropdown) return;

			// User clicked inside dropdown, prevent blur handling on input
			if (mouseDownOnDropdown) return;

			// Fake blur detection, case if active element not changed (alt+tab, etc)
			if (document.activeElement === getNode(inputRef)) return;
			autoComplete();
		};

		// Initial list of options to be generated based on min, max, step
		const optionsArgs = [mm().min, mm().max, step, format] as const;
		const options: TimePickerOptionProps[] = useCallback(
			generateOptions,
			optionsArgs
		)(...optionsArgs);

		// Getting most expected option based on user's input
		const getClosestOptionArgs = [
			options,
			input,
			defaultTimeSignifier,
			autoRounding,
			mm().min,
			mm().max,
			format,
		] as const;
		const closestOption = useCallback(
			getClosestOption,
			getClosestOptionArgs
		)(...getClosestOptionArgs);

		// Filtered list of options with an extra one to highligh (in case autoRounding is disabled)
		const dropdownOptions = useMemo(() => {
			if (input === '') return options;

			if (filter === '')
				// Value is set, but user not yet modified input's text string
				return highlightOption(
					injectOption(options, closestOption), // Inject currently selected option in case it not yet exist
					closestOption,
					true // Highlight selected value
				);

			// Filtering started, due to user's input manipulations
			let filtered;

			filtered = autoRounding
				? filterOptionsWithRounding(options, filter)
				: filterOptions(options, filter);

			filtered = injectOption(filtered, closestOption); // Inject closest option in case it was filtered out
			filtered = highlightOption(filtered, closestOption); // Highlight closest option

			return filtered;
		}, [input, filter, options, autoRounding, closestOption]);

		const autoComplete = () => {
			if (!value && input === '') {
				return;
			}
			if (input === '') {
				onChange?.(null);
				return;
			}
			if (closestOption) {
				if (closestOption.text === dateToTime(value)) {
					setInput(closestOption.text);
					setFilter('');

					return; // Suppress onChange, as same time aready selected
				}
				onChange?.(timeToDate(closestOption.text)); // Accept user input
			} else {
				// Reject user input, if input !== ''
				onChange?.(null);
				setInput(''); // Clear user's input
				setFilter('');
				return;
			}

			if (opened) setOpened(false);
		};

		// Handling with user's typing
		const handleInputChange = (e: any, data: any) => {
			setInput(data.value?.toUpperCase());
			setFilter(data.value?.toUpperCase());
			if (!opened && !disableDropdown) setOpened(true);
		};

		const handleIconClick = () => {
			if (disabled) return;
			focus(inputRef);
			if (opened || disableDropdown) return;
			setOpened(true);
		};

		const handleInputKeyDown = (e: React.KeyboardEvent) => {
			const { key } = e;

			// Autocomplete on focus lost (on tab or shift+tab)
			if (key === keys.tab) {
				if (opened) setOpened(false);
			}

			// Explicit autocomplete (on enter)
			if (key === keys.enter) autoComplete();

			// Move focus to the dropdown
			if (key === keys.down || key === keys.up) {
				if (!disableDropdown) {
					e.preventDefault();
					if (!opened) setOpened(true);
					if (!dropdownFocused) setDropdownFocused(true);
				}
			}
		};

		const dropdownFocusValue = () => {
			const focusValue = closestOption
				? getOptionByText(dropdownOptions, closestOption.text)
				: dropdownOptions[0];

			return dropdownFocused ? focusValue : null;
		};

		const handleDropdownChange = (val: Date) => {
			const time = dateToTime(val, localTimezone); // Dropdown is using local
			const date = timeToDate(time); // get date based on timezone and returnDate
			onChange?.(date);
			setInput(time);
			setFilter('');
			focus(inputRef); // Move focus back to the field
			if (opened) setOpened(false);
		};

		const inputProps = {
			id: inputId,
			fluid,
			disabled,
			error,
			placeholder:
				typeof placeholder === 'string'
					? placeholder
					: format === 'hh:mm xm'
					? `HH:MM ${defaultTimeSignifier}`
					: 'HH:MM',
			size,
			onChange: handleInputChange,
			onFocus: handleInputFocus,
			onBlur: handleInputBlur,
			value: input,
		};

		const dropdownProps = {
			className: classnames('TimePicker__options', {
				'TimePicker__options-focused': dropdownFocused,
			}),
			focusValue: dropdownFocusValue(),
			options: dropdownOptions,
			onChange: handleDropdownChange,
			inputRef: inputRef.current?.inputRef || inputRef,
			dropdownRef,
			closeDropdown: () => opened && setOpened(false),
		};

		const Field = (
			<Input
				{...inputProps}
				className="TimePicker__input"
				data-anvil-component="TimePicker"
				icon={{
					className: 'TimePicker__icon',
					el: 'span',
					name: 'access_time',
					onClick: handleIconClick,
				}}
				iconPosition="left"
				onKeyDown={handleInputKeyDown}
				ref={inputRef}
			/>
		);

		const TimePickerClasses = classnames(className, 'TimePicker', {
			'TimePicker--open': opened,
			'TimePicker--disabled': disabled,
			[`TimePicker--${size}`]: size,
		});

		return (
			<Popover
				autoFlipVertically
				className={classnames('TimePicker__dropdown', 'a-Select')}
				direction={direction}
				onClickOutside={handleClickOutside}
				open={
					!disabled &&
					!disableDropdown &&
					opened &&
					dropdownOptions.length !== 0
				}
				padding={null}
				portal
				ref={ref}
				scrollHeight={props.scrollHeight || '192px'}
				trigger={Field}
				width={width}
				wrapperClassName={TimePickerClasses}
				onMouseDown={handleDropdownMouseDown}
				portalDataAttributes={portalDataAttributes}
				{...rest}
			>
				<TimePickerOptionList {...dropdownProps} />
			</Popover>
		);
	}
);

TimePicker.displayName = 'TimePicker';

const getNode = (ref: any) => {
	if (ref.current?.inputRef?.current) {
		return ref.current.inputRef.current; // Input component
	}
	return ref.current; // Something else
};

const focus = (ref: any) => getNode(ref).focus();
