import React, { useLayoutEffect, useRef, FC } from 'react';
import { Checkbox } from '../Checkbox';
import { Spinner } from '../Spinner';
import { Button } from '../Button';
import classnames from 'classnames';

export interface SingleOptionPropStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Whether child options are visible or not. */
	collapsed?: boolean;

	/** Whether the option should have the expand/collapse button. */
	collapseControl?: boolean;

	/** The default visualization of an option in the Popover of the Select */
	content?: React.ReactNode | string;

	/** Indicates whether the option is disabled or not. It makes the option unselectable */
	disabled?: boolean;

	/** Function to allow OptionList to set focus */
	getFocus?: (value: any) => boolean;

	/** Indeterminate visual for a checkbox */
	indeterminate?: boolean;

	/** Function to allow OptionList to set indeterminate */
	getIndeterminate?: (value: any) => boolean;

	/** Provides tree-type styling to option, and ARIA label. Can be automatically calculated. */
	level?: number;

	/** Visual that an option is loading. Used when child options need to be brought in on expand */
	loading?: boolean;

	/** Calls when the option changes in value */
	onChange?: (
		data: SingleOptionProps['value'],
		checked: boolean,
		childObject: SingleOptionProps[],
		selfObject: SingleOptionProps
	) => void;

	/** Calls when the expand/collapse button is clicked */
	onExpand?: (data: SingleOptionProps['value']) => void;

	/** Grouping of options within options */
	options?: SingleOptionProps[];

	/** A secondary action a user can take on a single option */
	secondaryAction?: React.ReactNode;

	/** Whether or not the option is selected */
	selected?: boolean;

	/** Function to allow OptionList to set selected */
	getSelected?: (value: any) => boolean;

	/** Prevents a user from selecting the option, without giving it the disabled styling/state */
	readOnly?: boolean;

	/** Function to pass option data (value, DOM element) to OptionList */
	registerOption?: (optionData: RegisterOptionData) => void;

	/** The default string of an option in the trigger of the Select */
	text?: string;

	/** Value of the option, passed into Anvil's Checkbox value */
	value?: any;
}

export interface SingleOptionProps extends SingleOptionPropStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface RegisterOptionData {
	text?: SingleOptionProps['string'];
	value?: SingleOptionProps['value'];
	disabled?: SingleOptionProps['disabled'];
	element?: HTMLDivElement | null; // TODO: Make this type more generic
}

export const Option: FC<SingleOptionProps> = ({
	className,
	collapseControl,
	collapsed,
	content,
	disabled,
	focus,
	getFocus,
	indeterminate,
	getIndeterminate,
	label,
	level,
	loading,
	onChange,
	onExpand,
	onKeyDown,
	options,
	readOnly,
	registerOption,
	secondaryAction,
	selected,
	getSelected,
	selfObject,
	text,
	value,
	...props
}) => {
	// after the component has rendered, pass the option data to registerOption
	const checkboxRef = useRef<RegisterOptionData['element']>(null);

	useLayoutEffect(() => {
		if (registerOption) {
			registerOption({
				text,
				value,
				disabled,
				element: checkboxRef.current,
			});
		}
	});

	const OptionClasses = classnames('OptionList__option', className, {
		'OptionList__option--selected': selected,
		'OptionList__option--disabled': disabled,
		'OptionList__option--read-only': readOnly || disabled,
		'OptionList__option--custom': content && typeof content !== 'string',
		'OptionList__option--text': !(content && typeof content !== 'string'),
		'OptionList__option--collapsible': collapseControl,
	});

	const getCollapseButton = () => {
		if (!collapseControl) return;
		const arrow = collapsed
			? 'keyboard_arrow_down'
			: 'keyboard_arrow_right';
		return (
			<Button
				iconName={arrow}
				xsmall
				fill="subtle"
				onClick={handleOnClick}
				className="OptionList__collapse"
				aria-expanded={collapsed}
				aria-label={collapsed ? 'collapse options' : 'expand options'}
			/>
		);
	};

	const getLabel = () => {
		if (label) return label;
		if (content !== undefined && content !== null) return content;
		if (text !== undefined && text !== null) return text;
		return String(value);
	};

	const getLoading = () => {
		if (!loading) return;
		return <Spinner size="tiny" className="OptionList__spinner" />;
	};

	const getSecondaryAction = () => {
		if (secondaryAction === undefined) return;
		return (
			<div className="OptionList__option--secondary-action">
				{secondaryAction}
			</div>
		);
	};

	const handleOnClick = () => {
		return onExpand(value);
	};

	const handleOnChange = (value: any, checked: boolean) => {
		if (readOnly || onChange === undefined) return;
		return onChange(value, checked, options, selfObject);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (onKeyDown === undefined) return;
		return onKeyDown(e, selfObject);
	};

	return (
		<div
			className={OptionClasses}
			role="treeitem"
			aria-level={level}
			{...props}
		>
			{getLoading() || getCollapseButton()}
			<Checkbox
				ref={checkboxRef}
				checked={
					selected != null
						? selected
						: getSelected
						? getSelected(value)
						: null
				}
				disabled={disabled}
				focus={
					focus != null ? focus : getFocus ? getFocus(value) : null
				}
				indeterminate={
					indeterminate != null
						? indeterminate
						: getIndeterminate
						? getIndeterminate(value)
						: null
				}
				label={getLabel()}
				onChange={handleOnChange}
				onKeyDown={handleOnKeyDown}
				value={value}
			/>
			{getSecondaryAction()}
		</div>
	);
};
