import React, { FC } from 'react';
import classnames from 'classnames';
import {
	IconNameType,
	IconNameArray,
	getIconByName,
} from '@servicetitan/anvil-icons';

export { IconNameType, IconNameArray };

export interface IconPropsStrict {
	/** Additional classes */
	className?: string;

	/** Fill color of icon */
	color?: string;

	/** An element type to render as */
	el?: any;

	/** Name of the icon */
	name?: IconNameType;

	/** Size of icon */
	size?: string | number;
}

export interface IconProps extends IconPropsStrict {
	[propName: string]: any;
}

export const Icon: FC<IconProps> = ({
	color = 'currentColor',
	className,
	el,
	name,
	size = '1em',
	...props
}) => {
	if (!name) {
		console.warn('No name prop passed to Icon');
		return null;
	}

	const { viewbox, content } = getIconByName(name);

	if (!content) {
		console.warn('Icon does not exist', name);
		return null;
	}

	const IconClasses = classnames('a-Icon', `a-Icon--${name}`, className);
	const IconElement: any = el ?? (props.onClick ? 'button' : 'i');

	return (
		<IconElement
			className={IconClasses}
			width={size}
			height={size}
			data-anvil-component="Icon"
			{...(!props.type && IconElement === 'button' && { type: 'button' })}
			{...props}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill={color}
				height={size}
				width={size}
				viewBox={viewbox}
				/* eslint-disable react/no-danger */
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		</IconElement>
	);
};

Icon.displayName = 'Icon';
