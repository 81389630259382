import { stringToDate } from './stringToDate';

export const toTimeFormat = (date: Date | string, format: string): string => {
	// Format is defined
	if (typeof format !== 'undefined') {
		if (format === 'hh:mm xm') return formatAMPM(date);
		if (format === 'hh:mm') return format24(date);

		console.warn('toTimeFormat: unknown format', format);
		return null;
	}

	// Format is not defined, use current locale
	if (!isNaN(date as any) && date instanceof Date) {
		return date.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	if (typeof date === 'string') {
		return stringToDate(date).toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	return null;
};

toTimeFormat.hhmm = (date: Date | string): string => format24(date);
toTimeFormat.hhmmxm = (date: Date | string): string => formatAMPM(date);

const format24 = (date: Date | string): string => {
	if (typeof date === 'undefined' || date === '' || date === null) {
		return null;
	}

	let d: Date;
	typeof date === 'string' ? (d = stringToDate(date)) : (d = date);

	if (!d) return null;

	let hours: any = d.getHours();
	let minutes: any = d.getMinutes();
	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;

	return `${hours}:${minutes}`;
};

const formatAMPM = (date: Date | string): string => {
	if (typeof date === 'undefined' || date === '' || date === null) {
		return null;
	}

	let d: Date;
	typeof date === 'string' ? (d = stringToDate(date)) : (d = date);

	if (!d) return null;

	let hours: any = d.getHours();
	let minutes: any = d.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // hour '0' → '12'
	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;

	return `${hours}:${minutes} ${ampm}`;
};
