import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { FlowCardStep } from './FlowCardStep';

export interface FlowCardProps {
	children?: React.ReactNode;

	/** Optionally specify the current step array index. Set to `false` to manually track step state */
	currentIndex?: number | boolean;

	/** Adds order number to the steps */
	ordered?: boolean;

	/** Adds one or more classnames to an element */
	className?: string;
}

interface FlowCard extends FC<PropsWithChildren<FlowCardProps>> {
	Step: typeof FlowCardStep;
}

export const FlowCard: FlowCard = ({
	currentIndex = undefined,
	ordered,
	className,
	...props
}) => {
	const { children, ...rest } = props;

	const childrenArray = Array.isArray(children) ? children : [children];
	const renderChildren = childrenArray.map((child: any, index) => {
		const hasIndex = currentIndex !== false;
		return React.cloneElement(child, {
			active: hasIndex && index === currentIndex,
			key: index,
			orderNumber:
				ordered && !child.props.orderNumber
					? index + 1
					: child.props.orderNumber,
		});
	});

	return (
		<div
			className={classnames('FlowCard', className)}
			data-anvil-component="FlowCard"
			{...rest}
		>
			{renderChildren}
		</div>
	);
};

FlowCard.Step = FlowCardStep;
