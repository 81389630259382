import React, {
	ChangeEvent,
	FC,
	FocusEvent,
	useContext,
	useRef,
	MouseEvent,
} from 'react';
import { KeyPressListener } from '../KeyPressListener';
import { Popover, PopoverProps } from '../Popover';
import { Stack } from '../Stack';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Link } from '../Link';
import {
	DateRangeInput,
	DateRangeTrigger,
	DateRangeOptionList,
	DateRangeOptionListProps,
	DateRangeInputProps,
} from './components';
import {
	MultiViewCalendar,
	MultiViewCalendarProps,
	SelectionRange,
	CalendarCell,
	CalendarCellProps,
} from '@progress/kendo-react-dateinputs';
import { Keys } from '../../utilities/keyCodes';
import { isValid } from '../../utilities/dateFormat';
import { UseFocusTrapProps } from '../../hooks';
import { TimezoneContext } from '../../utilities/TimezoneProvider';
import { DateTime } from 'luxon';
import { checkScope } from './utils';
import { FormFieldContext } from '../../components/FormField';
import { PopperProps } from '../../internal';

interface CalendarInputProps {
	placeholder?: DateRangeInputProps['placeholder'];
	onChange?: DateRangeInputProps['onChange'];
	onBlur?: DateRangeInputProps['onBlur'];
}

interface CalendarProps {
	onChange?: MultiViewCalendarProps['onChange'];
	focusedDate?: Date;
}

interface ClearButtonProps {
	onClick?: (e: MouseEvent<HTMLButtonElement>, value: innerDateRange) => void;
}

interface DropdownProps {
	portal?: boolean;
	direction?: PopoverProps['direction'];
	autoFlipVertically?: PopoverProps['autoFlipVertically'];
	autoFlipHorizontally?: PopoverProps['autoFlipHorizontally'];
	options?: DateRangeOptionListProps['options'];
	startDateInput?: CalendarInputProps;
	endDateInput?: CalendarInputProps;
	views?: 1 | 2;
	calendar?: CalendarProps;
	clearButton?: ClearButtonProps;
}

interface InputProps {
	size?: 'small' | 'medium' | 'large';
	placeholder?: string;
	displayValue?: string;
	element?: (
		value: DateRangePickerProps['value'] | any,
		triggerProp: triggerProps,
		open: DateRangePickerProps['open'],
		error: DateRangePickerProps['error']
	) => React.ReactElement;
}

export interface DateRangePickerProps {
	/** Disabled state */
	disabled?: boolean;

	/** Indicates that the field is required */
	required?: boolean;

	/** Error state */
	error?: boolean;

	/** onChange function */
	onChange?: (value: MultiViewCalendarProps['value'] | any) => void;

	/** A handler that is called on every open and close action */
	onOpenChange?: (open: boolean) => void;

	/** A handler that is called on every click on any element outside of the anchor element when it is open */
	onClickOutside?: PopoverProps['onClickOutside'];

	/** Default calendar value */
	defaultValue?: MultiViewCalendarProps['value'];

	/** Maximum date allowed */
	maxDate?: MultiViewCalendarProps['max'];

	/** Minimum date allowed */
	minDate?: MultiViewCalendarProps['min'];

	/** Set value */
	value?: SelectionRange;

	/** Whether the DateRangePicker is open or not */
	open?: boolean;

	/** Disables close on click outside */
	disableCloseOnClickOutside?: boolean;

	/**
	 * Date format string
	 */
	dateFormat?: string;

	/** Changes the onChange return to ISO string format */
	isoFormat?: boolean;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;

	/** popover component prop */
	dropdown?: DropdownProps;

	/** trigger component prop */
	input?: InputProps; // new trigger props

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];

	// Keeping below for backward compatibility

	/**
	 * @deprecated - use input.size
	 */
	size?: 'small' | 'medium' | 'large';
	/**
	 * @deprecated - use input.placeholder
	 */
	placeholder?: string;
	/**
	 * @deprecated - use input.displayValue
	 */
	displayValue?: string;
	/**
	 * @deprecated - use input.element
	 */
	trigger?: (
		value: DateRangePickerProps['value'] | any,
		triggerProp: triggerProps,
		open: DateRangePickerProps['open'],
		error: DateRangePickerProps['error']
	) => React.ReactElement;
	/**
	 * @deprecated - use dropdown.portal
	 */
	portal?: boolean;
	/**
	 * @deprecated - use dropdown.direction
	 */
	direction?: PopoverProps['direction'];
	/**
	 * @deprecated - use dropdown.autoFlipVertically
	 */
	autoFlipVertically?: PopoverProps['autoFlipVertically'];
	/**
	 * @deprecated - use dropdown.autoFlipHorizontally
	 */
	autoFlipHorizontally?: PopoverProps['autoFlipHorizontally'];
	/**
	 * @deprecated - use dropdown.options
	 */
	options?: DateRangeOptionListProps['options'];
	/**
	 * @deprecated - use dropdown.startDateInput.placeholder
	 */
	startPlaceholder?: InputProps['placeholder'];
	/**
	 * @deprecated - use dropdown.endDateInput.placeholder
	 */
	endPlaceholder?: InputProps['placeholder'];
	/**
	 * @deprecated - use dropdown.focusedDate
	 */
	focusedDate?: Date;
	/**
	 * @deprecated - use dropdown.views
	 */
	views?: 1 | 2;
}

interface triggerProps {
	/** onClick function for the trigger */
	onClick?: () => void;
}

interface innerDateRange {
	start: string | null;
	end: string | null;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
	disabled,
	error,
	required,
	open,
	disableCloseOnClickOutside,
	onClickOutside,
	onOpenChange,
	focusTrapOptions,
	dateFormat,
	value,
	defaultValue,
	onChange,
	maxDate,
	minDate,
	isoFormat,
	input,
	dropdown,
	portalDataAttributes,

	// deprecated props
	trigger,
	size: dsize = 'medium',
	placeholder: dplaceholder = 'Select Date Range',
	direction: ddirection = 'br',
	autoFlipHorizontally: dautoFlipHorizontally,
	autoFlipVertically: dautoFlipVertically,
	portal: dPortal = true,
	displayValue: ddisplayValue,
	views: dviews = 1,
	focusedDate: dfocusedDate,
	options: doptions,
	startPlaceholder = 'Start Date',
	endPlaceholder = 'End Date',
	...rest
}) => {
	const {
		portal = dPortal,
		direction = ddirection,
		autoFlipVertically = dautoFlipVertically,
		autoFlipHorizontally = dautoFlipHorizontally,
		options = doptions,
		startDateInput,
		endDateInput,
		views = dviews,
		calendar,
		clearButton,
	} = { ...dropdown };

	const { onChange: CalendarOnChange, focusedDate = dfocusedDate } = {
		...calendar,
	};
	const { onClick: ClearButtonOnClick } = { ...clearButton };
	const {
		size: inputSize = dsize,
		placeholder: inputPlaceholder = dplaceholder,
		displayValue: inputDisplayValue = ddisplayValue,
		element: inputEl = trigger,
	} = { ...input };

	const {
		placeholder: startDateInputplaceholder = startPlaceholder,
		onBlur: startDateInputOnBlur,
		onChange: startDateInputOnChange,
	} = { ...startDateInput };

	const {
		placeholder: endDateInputplaceholder = endPlaceholder,
		onBlur: endDateInputOnBlur,
		onChange: endDateInputOnChange,
	} = { ...endDateInput };

	const triggerRef = React.useRef(null);
	const timezone = useContext(TimezoneContext);
	const getNewToday = DateTime.fromJSDate(new Date(), {
		zone: timezone,
	});

	const [openCal, setOpenCal] = React.useState(open);
	const [internalError, setInternalError] = React.useState(error);
	const [initialValue, setInitialValue] = React.useState({
		start: value?.start
			? DateTime.fromJSDate(value.start).setZone(timezone)
			: null,
		end: value?.end
			? DateTime.fromJSDate(value.end).setZone(timezone)
			: null,
	});
	const [iValue, setIValue] = React.useState<any>(initialValue);
	const minMaxRange = React.useMemo(() => {
		return {
			min:
				minDate &&
				DateTime.fromJSDate(minDate).startOf('day').setZone(timezone),
			max:
				maxDate &&
				DateTime.fromJSDate(maxDate).endOf('day').setZone(timezone),
		};
	}, [minDate, timezone, maxDate]);
	const [outOfRange, setOutOfRange] = React.useState(
		checkScope(
			value,
			minMaxRange?.min?.toJSDate(),
			minMaxRange?.max?.toJSDate()
		)
	);

	React.useEffect(() => {
		const newValue = {
			start: value?.start
				? DateTime.fromJSDate(value.start).setZone(timezone)
				: null,
			end: value?.end
				? DateTime.fromJSDate(value.end).setZone(timezone)
				: null,
		};

		setInitialValue(newValue);
		setIValue(newValue);
		setOutOfRange(
			checkScope(
				value,
				minMaxRange?.min?.toJSDate(),
				minMaxRange?.max?.toJSDate()
			)
		);
	}, [minMaxRange?.max, minMaxRange?.min, timezone, value]);

	const stopRender = useRef(false);
	React.useEffect(() => {
		let start;
		let end;
		if (isoFormat) {
			start = iValue.start.toISO();
			end = iValue.end.toISO();
		} else {
			start = iValue.start ? iValue.start.toJSDate() : null;
			end = iValue.end ? iValue.end.toJSDate() : null;
		}

		stopRender.current && onChange({ start, end });
		stopRender.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timezone]);

	/*
	 * handler for MultiViewCalendar onChange
	 */
	const blurBlocker = useRef(false);
	const handleCalendarOnChange = (event: any) => {
		let eventValue: any = event.target.value as SelectionRange;
		blurBlocker.current = true;
		// Force today to select today of the timezone
		if (event.nativeEvent.target.className === 'k-nav-today') {
			event.syntheticEvent.stopPropagation();

			const today = DateTime.now().setZone(timezone);

			if (iValue.start && iValue.end) {
				// reset selection if both values are already filled
				eventValue = {
					start: today.startOf('day'),
				};
			} else {
				if (event.target.activeRangeEnd === 'start') {
					eventValue = {
						start: iValue.start ? iValue.start : undefined,
						end: today.endOf('day'),
					};
				}

				if (event.target.activeRangeEnd === 'end') {
					eventValue = {
						start: today.startOf('day'),
						end: iValue.end ? iValue.end : undefined,
					};
				}

				if (eventValue.start > eventValue.end) {
					const reverseEventValue = {
						start: eventValue.end,
						end: eventValue.start,
					};

					eventValue = reverseEventValue;
				}
			}

			setIValue(eventValue);
			return;
		}

		const startValue = eventValue?.start
			? DateTime.fromJSDate(eventValue.start).setZone(timezone, {
					keepLocalTime: true,
			  })
			: null;

		const endValue = eventValue?.end
			? DateTime.fromJSDate(eventValue.end).setZone(timezone, {
					keepLocalTime: true,
			  })
			: null;

		setIValue({
			start: startValue,
			end: endValue,
		});

		CalendarOnChange?.(event);
	};

	// handler for DateInput onBlur
	const handleDateOnBlur = (
		e: FocusEvent<HTMLInputElement>,
		data: Date,
		type: 'start' | 'end'
	) => {
		type === 'start'
			? startDateInputOnBlur?.(e, data)
			: endDateInputOnBlur?.(e, data);
		if (!blurBlocker.current) {
			if (!isValid(data)) {
				setOutOfRange({ ...outOfRange, [type]: 'Enter valid date' });
				return;
			}
			const value = {
				...iValue,
				[type]: DateTime.fromJSDate(data).setZone(timezone, {
					keepLocalTime: true,
				}),
			};

			setIValue(value);
		}
	};

	const handleDateOnChange = (
		e: ChangeEvent<HTMLInputElement>,
		data: string,
		type: 'start' | 'end'
	) => {
		type === 'start'
			? startDateInputOnChange?.(e, data)
			: endDateInputOnChange?.(e, data);
		setOutOfRange({ ...outOfRange, [type]: false });
	};

	const handleOptionListOnChange = (data: any) => {
		const newValue = {
			start: data.start ? DateTime.fromJSDate(data.start) : null,
			end: data.end ? DateTime.fromJSDate(data.end) : null,
		};
		setInitialValue(newValue);
		setIValue(newValue);
		onChange(data);
		setOpenCal(false);
		onOpenChange?.(false);
	};

	React.useEffect(() => {
		setOutOfRange(
			checkScope(
				{
					start: iValue?.start?.toJSDate(),
					end: iValue?.end?.toJSDate(),
				},
				minMaxRange?.min?.toJSDate(),
				minMaxRange?.max?.toJSDate()
			)
		);

		blurBlocker.current = false;
	}, [iValue, minMaxRange?.max, minMaxRange?.min]);

	React.useEffect(() => {
		const scope = checkScope(
			{
				start: initialValue?.start?.toJSDate(),
				end: initialValue?.end?.toJSDate(),
			},
			minMaxRange?.min?.toJSDate(),
			minMaxRange?.max?.toJSDate()
		);

		if (!scope.start && !scope.end) setInternalError(error ?? false);
		if (!!scope.start || !!scope.end) setInternalError(true);
	}, [error, initialValue, minMaxRange?.max, minMaxRange?.min]);

	// confirm handler, this returns dateRange value through onChange
	const handleOnConfirm = () => {
		const scope = checkScope(
			{
				start: iValue?.start?.toJSDate(),
				end: iValue?.end?.toJSDate(),
			},
			minMaxRange?.min?.toJSDate(),
			minMaxRange?.max?.toJSDate()
		);
		!scope.start && !scope.end
			? setInternalError(error ?? false)
			: setInternalError(error ?? true);
		setInitialValue(iValue);
		setOpenCal(false);
		onOpenChange?.(false);

		if (isoFormat) {
			onChange?.({
				start: iValue?.start?.toISO(),
				end: iValue?.end?.endOf('day').setZone(timezone).toISO(),
			});
			return;
		}
		onChange?.({
			start: iValue?.start?.startOf('day').setZone(timezone).toJSDate(),
			end: iValue?.end?.endOf('day').setZone(timezone).toJSDate(),
		});
	};

	// close popover function - this will revert dateRange to initial value
	const close = () => {
		if (openCal) {
			setIValue(initialValue);
			setOpenCal(false);
			onOpenChange?.(false);
			!inputEl && triggerRef.current.focus();
		}
	};

	const { labelFor: inputId } = React.useContext(FormFieldContext);

	const handleOnClickOutside = (e: any) => {
		if (openCal) {
			if (e?.target?.closest?.(`[for="${inputId}"]`)) return;

			onClickOutside?.(e);

			if (!disableCloseOnClickOutside) {
				setIValue(initialValue);
				setOpenCal(false);
				onOpenChange?.(false);
			}
		}
	};

	// toggle open state
	const toggleOpen = () => {
		if (openCal) {
			close();
		} else {
			setOpenCal(!openCal);
			onOpenChange?.(true);
		}
	};

	// keyboard event for end date input to confirm and close
	const calendarKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.code === Keys.Space && !isApplyDisabled) {
			handleOnConfirm();
			event.preventDefault();
		}
	};

	const clearDateRange = (d: any) => {
		close();
		onChange?.(d);
		setIValue(d);
		setInternalError(error ?? required ?? false);
		setInitialValue(d);
	};

	const clearInner = (e: MouseEvent<HTMLButtonElement>) => {
		const nullDateRange: innerDateRange = { start: null, end: null };
		setIValue(nullDateRange);
		ClearButtonOnClick?.(e, nullDateRange);
	};

	// keyboard event for trigger to open popover
	const triggerKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.code === Keys.Enter || event.code === Keys.Space) {
			setOpenCal(true);
			onOpenChange?.(true);
		}
	};

	// Handles when open prop changes
	const initialRender = useRef(true);
	React.useEffect(() => {
		setOpenCal(open);
		if (!initialRender.current) {
			onOpenChange?.(open);
		}
		initialRender.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const hasError = outOfRange.start || outOfRange.end;
	const hasAcceptableValues =
		(iValue.start && iValue.end) ||
		(iValue.start === null && iValue.end === null);
	const requiredCheck =
		required && (iValue.start === null || iValue.end === null);
	const isApplyDisabled = (requiredCheck ||
		!hasAcceptableValues ||
		hasError ||
		!outOfRange) as boolean;

	const triggerProps = {
		onClick: toggleOpen,
	};

	// custom cell to override the today highlight to match timezone today
	const customCell = (props: CalendarCellProps) => {
		if (format(props.value) === format(getNewToday)) {
			return <CalendarCell {...props} isToday />;
		}
		return <CalendarCell {...props} isToday={false} />;
	};
	return (
		<Popover
			open={!disabled && openCal}
			onClickOutside={handleOnClickOutside}
			trigger={
				inputEl?.(
					initialValue,
					triggerProps,
					openCal,
					internalError
				) ?? (
					<DateRangeTrigger
						open={openCal}
						placeholder={inputPlaceholder}
						onClick={toggleOpen}
						onKeyDown={triggerKeyPress}
						onChange={clearDateRange}
						value={{
							start: initialValue.start?.isValid
								? new Date(format(initialValue.start))
								: null,
							end: initialValue.end?.isValid
								? new Date(format(initialValue.end))
								: null,
						}}
						displayValue={inputDisplayValue}
						disabled={disabled}
						error={internalError}
						size={inputSize}
						ref={triggerRef}
						required={required}
						data-anvil-component="DateRangePicker"
						{...rest}
					/>
				)
			}
			direction={direction}
			padding={null}
			width="auto"
			className="DateRangePicker__popover"
			portal={portal}
			focusTrapOptions={focusTrapOptions}
			autoFlipHorizontally={autoFlipHorizontally}
			autoFlipVertically={autoFlipVertically}
			portalDataAttributes={portalDataAttributes}
		>
			<KeyPressListener
				code={Keys.Esc}
				handler={close}
				disabled={disableCloseOnClickOutside || !openCal}
			/>
			<div className="d-f flex-row">
				{options && options.length > 0 && (
					<DateRangeOptionList
						options={options}
						min={minMaxRange?.min?.toJSDate()}
						max={minMaxRange?.max?.toJSDate()}
						onChange={handleOptionListOnChange}
					/>
				)}
				<div className="DateRangePicker">
					<div className="DateRangePicker__header">
						<div className="DateRangePicker__inputGroup">
							<DateRangeInput
								placeholder={startDateInputplaceholder}
								value={iValue?.start}
								error={outOfRange?.start}
								dateFormat={dateFormat}
								onBlur={(e, data) => {
									handleDateOnBlur(e, data, 'start');
								}}
								onChange={(e, data) => {
									handleDateOnChange(e, data, 'start');
								}}
							/>
							<Icon
								name="arrow_forward"
								className="DateRangePicker__fromTo"
							/>
							<DateRangeInput
								placeholder={endDateInputplaceholder}
								value={iValue?.end}
								error={outOfRange?.end}
								dateFormat={dateFormat}
								onBlur={(e, data) => {
									handleDateOnBlur(e, data, 'end');
								}}
								onChange={(e, data) => {
									handleDateOnChange(e, data, 'end');
								}}
							/>
						</div>
					</div>
					<div
						onKeyPress={calendarKeyPress}
						className="DatePicker__calendar"
					>
						<MultiViewCalendar
							mode="range"
							views={views}
							value={{
								start: iValue.start
									? new Date(format(iValue.start))
									: null,
								end: iValue.end
									? new Date(format(iValue.end))
									: null,
							}}
							onChange={handleCalendarOnChange}
							focusedDate={
								focusedDate
									? focusedDate
									: !(value?.start && value?.end)
									? new Date()
									: undefined
							}
							defaultValue={defaultValue}
							min={minMaxRange?.min?.toJSDate()}
							max={minMaxRange?.max?.toJSDate()}
							cell={customCell}
						/>
					</div>
				</div>
			</div>
			<Stack
				className="DateRangePicker__actions"
				direction="row-reverse"
				justifyContent="space-between"
			>
				<Stack direction="row-reverse" spacing={2}>
					<Button
						disabled={isApplyDisabled}
						onClick={handleOnConfirm}
						small
						color="primary"
					>
						Apply
					</Button>
					<Button small fill="subtle" onClick={close}>
						Cancel
					</Button>
				</Stack>
				<Link
					primary
					onClick={clearInner}
					disabled={!(iValue?.start || iValue?.end)}
					className="fs-2"
				>
					Clear
				</Link>
			</Stack>
		</Popover>
	);
};

const format = (date: any, format = 'LL/dd/yyyy') => {
	if (date instanceof Date) return DateTime.fromJSDate(date).toFormat(format);
	if (date instanceof DateTime) return date.toFormat(format);
	return date;
};
