import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { Select, SelectProps } from '../Select';

export interface FormSelectProps extends FormFieldPropsStrict, SelectProps {
	error?: any; // Weirdly enough, this is what Semantic is doing to solve the extending issue
}

export const FormSelect: FC<FormSelectProps> = (props) => (
	<FormField control={Select} {...props} />
);
