import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Sidebar as AnvilSidebar, SideNav, Eyebrow, BodyText, ActionMenu, Icon, Stack } from '@servicetitan/design-system';
import { Tags } from './Tags';
import { ScreenSizeContext } from '../context/ScreenSizeContext';
import { map, filter, groupBy, flow, union, orderBy, sortBy, partialRight, upperFirst } from 'lodash';

export const Sidebar = ({current}) => {
	const data = useStaticQuery(graphql`
		query PageList {
			navList: allSitePage {
				group(field: context___parent) {
					fieldValue
					edges {
						node {
							path
							id
							component
							context {
								parent
								category
								globalNav
								hidden
								isIndex
								linkTo
								tags
								created_at
								updated_at
							}
						}
					}
				}
			}
			indexPages: allMdx(filter: {fields: {isIndex: {eq: true}}}) {
				edges {
					node {
						frontmatter {
							pageOrder
							title
						}
						fields {
							category
						}
					}
				}
			}
			globalNavIndex: allMdx(filter: {frontmatter: {hidden: {eq: true}}}) {
				edges {
					node {
						fields {
							globalNav
						}
					}
				}
			}
		}

	`)

	const [navState, setNavState] = React.useState(false);
	const [isMobile, setIsMobile] = React.useState(true);
	const toggleNav = () => setNavState(!navState);
	const handleClickOutside = () => setNavState(false);
	const { size } = React.useContext(ScreenSizeContext);

	React.useEffect(() => {
		size.smallerThan.mobile ? setIsMobile(true) : setIsMobile(false);
	}, [size])

	// Get GlobalIndex info
	const globalNav = current.fields.globalNav;

	// Get all Sidenav items per current global menu
	const getNavList = filter(data.navList.group, (c) => c.edges[0].node.context.globalNav === globalNav);

	// Group Sidenav items by Categories
	const groupNavListByCat = flow(
		partialRight(groupBy, obj => obj.edges[0].node.context.category, 1),
		partialRight(map, (obj, key) => {
			return {
				"name": key,
				"data": obj
			}
		})
	)(getNavList);

	// Split the groups by type and sort each
	const otherGroupsByASC = flow(
		partialRight(filter, g => g.name !== 'null'),
		partialRight(sortBy, 'name')
	)(groupNavListByCat);

	const nullGroup = filter(groupNavListByCat, (g) => g.name === 'null');

	// Join the groups back to one
	const orderedGroup = union(otherGroupsByASC, nullGroup);

	const NavContent = () => (
		orderedGroup
			.filter(a => a.data.length > 0)
			.map(
				(category, index) => {
					const pageOrder = () => {
						const pageIndex =  category.data.filter(d => d.edges[0].node.context.isIndex === true)[0]
						if (pageIndex) {
							const pageIndexName = pageIndex.fieldValue
							const currentPageOrder = data.indexPages.edges.filter(d => d.node.frontmatter.title === pageIndexName)[0]?.node.frontmatter.pageOrder
							const newOrder = currentPageOrder?.map(c => {return c.toLowerCase()})
							return newOrder
						} else return []
					}

					// Split the pages by type and sort each
					const customOrderedPages = flow(
						partialRight(filter, g => pageOrder()?.includes(g.fieldValue.toLowerCase()) && g.fieldValue.toLowerCase() !== "null"),
						partialRight(orderBy, g => pageOrder()?.indexOf(g.fieldValue.toLowerCase()))
					)(category.data);

					const otherPagesByASC = flow(
						partialRight(filter, g => !pageOrder()?.includes(g.fieldValue.toLowerCase()) && g.fieldValue.toLowerCase() !== "null"),
						partialRight(sortBy, 'fieldValue')
					)(category.data);

					// Join the pages back to one
					const orderedPages = union(customOrderedPages, otherPagesByASC);

					return (
						<ItemWrapper key={index} index={index} category={category}>
							{orderedPages
								.map(
									(doc, index) => {
										if(doc.edges[0].node.context.hidden) return null;

										const indexEdge = doc.edges.length > 1 ? doc.edges.find(page => page.node.context.isIndex) : doc.edges[0];
										const linkTo = indexEdge.node.context.linkTo;
										const pagePath = indexEdge.node.path;
										const tags = indexEdge.node.context.tags;
										const today = new Date();
										const createdAt = new Date(new Date(indexEdge.node.context.created_at).setHours(0, 0, 0, 0));
										const createdAtMax = new Date(
											createdAt.getFullYear(),
											createdAt.getMonth() + 2,
											createdAt.getDate()
										);
										const updatedAt = new Date(new Date(indexEdge.node.context.updated_at).setHours(0, 0, 0, 0));
										const updatedAtMax = new Date(
											updatedAt.getFullYear(),
											updatedAt.getMonth() + 2,
											updatedAt.getDate()
										);
										const isNew = createdAtMax.getTime() > today.getTime();
										const isUpdated = updatedAtMax.getTime() > today.getTime();

										const tagToShow = () => {
											if (tags?.length > 0) return tags;
											if (isNew) return ['new']
											if (isUpdated) return ['updated']
										};

										const itemName = doc.fieldValue;
										const active = doc.fieldValue === current.frontmatter.title || doc.fieldValue === current.frontmatter.component;

										const linkProps = {
											key: index,
											...linkTo && {
												href: linkTo,
												target: '_blank',
												rel: 'noopener noreferrer'
											},
											...!linkTo && {
												active,
												href: pagePath,
												onClick: () => {
													navigate(pagePath);
													setTimeout(() => {
														setNavState(false);
													}, 200)
												}
											}
										};

										// Active item to scroll into view
										React.useEffect(() => {
											if (!active || isMobile) return;
											const activeItem = document.querySelector('.SideNavItem--active');
											activeItem?.scrollIntoView({ block: 'center', inline: 'nearest'  })
										}, [])

										if ( isMobile ) {
											return (
												<ActionMenu.Item {...linkProps}>
													{itemName} <Tags tags={tagToShow()} />
												</ActionMenu.Item>
											)
										}
										return (
											<SideNav.Item {...linkProps}>
												<span className="m-r-1">
													{itemName}
												</span>
												<Tags tags={tagToShow()} />
											</SideNav.Item>
										)
									}
								)
							}
						</ItemWrapper>
					)
				}
			)
	)

	const ItemWrapper = ({children, index, category}) => {
		const categoryName = category.name === 'null' ? 'Others' : category.name
		if(orderedGroup.length < 2) {
			return children
		} else if(category.data.filter(d => !d.edges[0].node.context.hidden).length > 0 && !isMobile) {
			return <SideNav.Group title={categoryName} key={index}>{children}</SideNav.Group>
		} else if(category.data.filter(d => !d.edges[0].node.context.hidden).length > 0 && isMobile) {
			return <ActionMenu.Group label={categoryName} key={index}>{children}</ActionMenu.Group>
		} else return null
	}

	if (isMobile) {
		return (
			<ActionMenu
				className='mobileNav'
				scrollHeight="100%"
				width='100'
				trigger={(
					<Stack
						className="bg-neutral-40"
						onClick={toggleNav}
						justifyContent="space-between"
						alignItems="center"
						style={{padding: '8px 18px 8px 16px'}}
					>
						<Stack direction="column" justifyContent="center">
							<Eyebrow>{`${globalNav} / ${current.fields.category}`}</Eyebrow>
							<BodyText>{current.frontmatter.title}</BodyText>
						</Stack>
						<Icon size="20px" name={navState? "arrow_drop_up" : "arrow_drop_down"} />
					</Stack>
				)}
				open={navState}
				onClickOutside={handleClickOutside}
				style={{width: '100%'}}
				portal={false}
			>
				<NavContent />
			</ActionMenu>
		);
	}

	return (
		<AnvilSidebar>
			<AnvilSidebar.Section padding="y">
				<SideNav className="SideNav__wrapper" title={upperFirst(globalNav)}>
					<NavContent />
				</SideNav>
			</AnvilSidebar.Section>
		</AnvilSidebar>
	)
}
