import React, { createContext } from 'react';

interface CollapsibleContextType {
	animate?: boolean;
	expanded?: boolean;
	extractCollapsibleList?: boolean;
	collapsibleRef?: React.RefObject<any>;
}

export const CollapsibleContext =
	createContext<CollapsibleContextType>(undefined);
