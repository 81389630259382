import React, { FC } from 'react';
import classnames from 'classnames';

import { TakeoverSection } from './TakeoverSection';

export interface TakeoverFooterPropsStrict {
	/** Align TakeoverFooter content using flexbox justify-content properties */
	align?: 'right' | 'left' | 'center' | 'space-between';

	/** Adds one or more classnames to an element */
	className?: string;
}

export interface TakeoverFooterProps extends TakeoverFooterPropsStrict {
	[propName: string]: any;
}

export const TakeoverFooter: FC<TakeoverFooterProps> = ({
	align,
	children,
	className,
	...props
}) => {
	const TakeoverFooterClasses = classnames('TakeoverFooter', className, {
		'TakeoverFooter--left': align === 'left',
		'TakeoverFooter--center': align === 'center',
		'TakeoverFooter--right': align === 'right',
		'TakeoverFooter--space-between': align === 'space-between',
	});

	if (React.Children.count(children) === 0) return null;

	return (
		<TakeoverSection className={TakeoverFooterClasses} {...props}>
			{children}
		</TakeoverSection>
	);
};

TakeoverFooter.defaultProps = {
	align: 'right',
};
