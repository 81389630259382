import moment from 'moment';
import { defaultFormat, defaultMaskChar } from '../InputDateMask';
import { invalidDate } from './utils';

export const formatDate = (date?: Date, dateFormat = defaultFormat) => {
	return date ? moment(date).format(dateFormat) : '';
};

const hasDigits = (str: string | number) =>
	str ? /\d/.test(str?.toString()) : false;

const isFull = (mask?: string, maskChar = defaultMaskChar) => {
	if (!mask || mask?.length < 1 || isEmpty(mask)) return false;
	return !mask.includes(maskChar);
};

const isEmpty = (mask?: string) => !hasDigits(mask);

export const parseEntry = (
	str: string,
	dateFormat: string,
	maskChar: string,
	min: Date,
	max: Date,
	required: boolean
) => {
	const empty = isEmpty(str);
	const full = isFull(str, maskChar);
	const parsed = moment(str, dateFormat);
	const valid = full && parsed.isValid();

	let invalidReason;
	let date = empty
		? undefined
		: full && valid
		? parsed.toDate()
		: invalidDate;

	const outRange = valid && !isDateInRange(date, min, max);

	if (empty) invalidReason = `no entry${required ? ' (required)' : ''}`;
	if (!valid && !empty) invalidReason = `unable to parse entry: ${str}`;
	if (outRange) {
		date = invalidDate;
		invalidReason = `date ${str} is out of min-max range: ${
			min ? formatDate(min, dateFormat) : '...'
		} - ${max ? formatDate(max, dateFormat) : '...'}`;
	}

	return {
		date,
		isValid: valid,
		isEmpty: empty,
		isFull: full,
		isOutRange: outRange,
		invalidReason,
	};
};

export const isDateInRange = (date: Date, minDate: Date, maxDate: Date) => {
	const sameDay = (d1: Date, d2: Date) =>
		d1.toDateString() === d2.toDateString();

	if (!date) return true; // undefined and Invalid Date are in range
	if (minDate && maxDate) {
		return (
			(date >= minDate || sameDay(date, minDate)) &&
			(date <= maxDate || sameDay(date, maxDate))
		);
	} else if (minDate) {
		return date >= minDate || sameDay(date, minDate);
	} else if (maxDate) {
		return date <= maxDate || sameDay(date, maxDate);
	}
	return true;
};

export const getNode = (ref: any) => {
	if (ref.current?.inputRef?.current) return ref.current.inputRef.current; // Input component
	if (ref.current?._element) return ref.current._element; // Kendo calrndar
	return ref.current; // Something else
};

export const isFocusInsideDatePicker = (
	inputRef: any,
	calendareRef: any,
	e: React.FocusEvent<any>
) => {
	const input = getNode(inputRef)?.closest(
		'[data-anvil-component=DatePicker]'
	);
	const cal = getNode(calendareRef);
	const active = e.relatedTarget || document?.activeElement;

	return input?.contains(active) || cal?.contains(active);
};
