import React, { FC, useState, useId, isValidElement } from 'react';
import classnames from 'classnames';
import { SemanticWIDTHS } from '../../utilities/SemanticUtils';
import { FormFieldContext } from './FormFieldContext';
import { ButtonToggle } from '../ButtonToggle';
import { ToggleSwitch } from '../ToggleSwitch';
import { Label, LabelProps } from '../Label';
import { Togglebox } from '../Togglebox';
import { Checkbox } from '../Checkbox';
import { Button } from '../Button';
import { Radio } from '../Radio';

export interface FormFieldPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Adds one or more classnames to the control element */
	controlClassName?: string;

	/** Set the wrapping element's type to render as (string or function). */
	control?: any;

	/** Individual fields may be disabled. */
	disabled?: boolean;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Individual fields may display an error state. */
	error?: boolean | React.ReactElement | string;

	/** Adds additional help to the input */
	description?: React.ReactElement | string;

	/** Displays the describes the form element */
	label?: LabelProps['children'];

	/** Property options for the label */
	labelProps?: LabelProps;

	/* Indicates that the field is required */
	required?: boolean;

	/** Set Form Field to be specific widths */
	width?: SemanticWIDTHS;
}
export interface FormFieldProps extends FormFieldPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const FormField: FC<FormFieldProps> = React.forwardRef(
	(
		{
			children,
			className,
			control,
			controlRef,
			controlClassName,
			description,
			disabled,
			el = 'div',
			error,
			label,
			labelProps,
			required,
			size,
			toggleboxControl,
			width,
			onChange,
			...props
		},
		ref: any
	) => {
		const controlType = (() => {
			if (typeof control === 'undefined') return undefined;

			if (control?.displayName?.toString().length > 2)
				return control?.displayName?.toString();

			if (control?.name?.toString().length > 2)
				return control?.name?.toString();

			return 'control';
		})();

		const hasCounter =
			props.showCounter &&
			!!props.maxLength &&
			['Input', 'TextArea', 'control'].includes(controlType);

		const [counter, setCounter] = React.useState<string>(
			props.defaultValue?.length ?? 0
		);

		const passProps = () => {
			if (['Input', 'TextArea', 'control'].includes(controlType)) {
				const { showCounter, ...rest } = props;
				return rest;
			}
			return props;
		};

		const id = useId();
		const labelFor = props.id || `formfield_id_${id}`;
		const errMsgID = props.id || `errMsg_id_${id}`;

		const [context, setContext] = useState({
			labelFor,
			setFormFieldContext: (): void => {},
		} as FormFieldContext);

		const setFormFieldContext = (newContext: FormFieldContext) =>
			setContext({
				...context,
				...newContext,
			});

		const handleLabelClick = () => {
			const inputNode = context?.inputRef?.current;

			if (['AnvilSelect', 'Select', 'Dropdown'].includes(controlType)) {
				inputNode?.focus();
			}

			if (['Select', 'Dropdown'].includes(controlType)) {
				return;
			}

			inputNode?.click();
		};

		const handleOnChange = (e: any, d: any) => {
			if (hasCounter) {
				setCounter(d?.value?.length);
			}
			onChange?.(e, d);
		};

		const getLabel = () => {
			if (label === undefined || label === '') return false;

			const labelClassName = labelProps?.className
				? labelProps.className
				: null;
			const labelClasses = classnames(labelClassName, 'FormField__label');

			return (
				<Label
					disabled={disabled}
					required={required}
					{...labelProps}
					className={labelClasses}
				>
					{label}
				</Label>
			);
		};

		const hasDescription =
			(description &&
				typeof description === 'string' &&
				description.length > 0) ||
			isValidElement(description);

		const hasErrorText =
			(error && typeof error === 'string' && error.length > 0) ||
			isValidElement(error);

		const getControl = () => {
			const controlProps = {
				children,
				disabled,
				required,
				size,
				onChange: hasCounter ? handleOnChange : onChange,
				className: controlClassName,
				...passProps(),
				...(control !== ToggleSwitch &&
				control !== ButtonToggle &&
				toggleboxControl !== 'toggleSwitch' &&
				control !== Button
					? { error: typeof error !== 'undefined' ? !!error : error }
					: {}),
			};

			// Togglebox
			if (control === Togglebox) {
				return (
					<Togglebox
						label={label}
						control={toggleboxControl}
						aria-errormessage={errMsgID}
						{...controlProps}
					/>
				);
			}

			// Checkbox, Radio, ToggleSwitch
			if (
				control === Checkbox ||
				control === Radio ||
				control === ToggleSwitch
			) {
				return React.createElement(control, {
					...controlProps,
					label,
					labelProps,
					'aria-errormessage': errMsgID,
					required,
					ref,
				});
			}

			const createControl = control
				? React.createElement(control, {
						...controlProps,
						'aria-errormessage': errMsgID,
						ref,
				  })
				: children;

			const hasHtmlFor = !['AnvilSelect', 'DateRangePicker'].includes(
				controlType
			);

			return (
				<React.Fragment>
					<label
						className="Field__html-label"
						htmlFor={hasHtmlFor ? context.labelFor : undefined}
						onClick={handleLabelClick}
					>
						{getLabel()}
					</label>
					<FormFieldContext.Provider
						value={{
							...context,
							setFormFieldContext,
						}}
					>
						{createControl}
					</FormFieldContext.Provider>
				</React.Fragment>
			);
		};

		const FormFieldProps = { children, disabled };

		const FormFieldClasses = classnames('FormField', className, {
			'FormField--disabled': disabled,
			'FormField--error': error,
			[`FormField--${controlType}`]: controlType,
			'FormField--small': size === 'small',
			'FormField--large': size === 'large',
			'FormField--fitted': label === undefined || label === '',
			[`FormField--width-${width}`]: width,
		});

		const FormFieldElement = el;

		return (
			<FormFieldElement
				className={FormFieldClasses}
				data-anvil-component="FormField"
				{...FormFieldProps}
			>
				<div className="FormField__wrapper">
					{getControl()}
					{(hasCounter || hasErrorText || hasDescription) && (
						<div className="FormField__addons">
							{(hasErrorText || hasDescription) && (
								<div className="FormField__helpers">
									{hasErrorText && (
										<div
											className="FormField__errorText"
											id={errMsgID}
										>
											{error}
										</div>
									)}
									{hasDescription && (
										<div className="FormField__description">
											{description}
										</div>
									)}
								</div>
							)}
							{hasCounter && (
								<div className="FormField__counter">{`${counter}/${props.maxLength}`}</div>
							)}
						</div>
					)}
				</div>
			</FormFieldElement>
		);
	}
);
