import React from 'react';

export const ContrastSvg = ({fg, bg, b = '#DFE0E1'}) => (
	<svg key={fg} width="180px" height="90px" viewBox="0 0 180 90" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Artboard" transform="translate(-337.000000, -2840.000000)">
				<g id="Group-4-Copy-2">
					<g id="Group-7" transform="translate(79.000000, 2442.000000)">
						<g id="span-copy-16" transform="translate(258.000000, 398.000000)">
							<rect id="shapeGroup" stroke={b} fill={bg} x="0.5" y="0.5" width="179" height="89"></rect>
							<rect id="shapeGroup-Copy" fill={fg} x="15.078534" y="18" width="83.8743455" height="6.75"></rect>
							<rect id="shapeGroup-Copy-25" fill={fg} x="15.078534" y="33.75" width="151.727749" height="6.75"></rect>
							<rect id="shapeGroup-Copy-26" fill={fg} x="15.078534" y="49.5" width="151.727749" height="6.75"></rect>
							<rect id="shapeGroup-Copy-27" fill={fg} x="15.078534" y="65.25" width="151.727749" height="6.75"></rect>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
