import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Progress Bar properties
 */
export interface ProgressBarPropsStrict {
	/** String of class names to add to component */
	className?: string;

	/** Blue variant */
	blue?: boolean;

	/** Element to use for base component */
	el?: any;

	/** Green variant */
	green?: boolean;

	/** This is an indeterminate state */
	indeterminate?: boolean;

	/** Value of filled bar without percent sign. E.g. <ProgressBar progress={50} /> */
	progress?: number;

	/** Absolutely position to the top or bottom of the parent element */
	position?: 'bottom' | 'top';

	/** Red variant */
	red?: boolean;

	/** Small variant */
	small?: boolean;

	/** Yellow variant */
	yellow?: boolean;

	/** Blue grey variant */
	blueGrey?: boolean;
}

export interface ProgressBarProps extends ProgressBarPropsStrict {
	[propName: string]: any;
}

export const ProgressBar: FC<ProgressBarProps> = ({
	blue,
	className,
	el: ProgressBarElement = 'progress',
	green,
	indeterminate,
	progress,
	position,
	red,
	small,
	yellow,
	blueGrey,
	...props
}) => {
	const ProgressBarClasses = classnames('ProgressBar', className, {
		'ProgressBar--blue': blue,
		'ProgressBar--bottom': position === 'bottom',
		'ProgressBar--green': green,
		'ProgressBar--indeterminate': indeterminate,
		'ProgressBar--red': red,
		'ProgressBar--small': small,
		'ProgressBar--top': position === 'top',
		'ProgressBar--yellow': yellow,
		'ProgressBar--blue-grey': blueGrey,
	});

	if (indeterminate) {
		return (
			<div className={ProgressBarClasses} {...props}>
				<div className="ProgressBar__bg">
					<div className="ProgressBar__value" />
				</div>
			</div>
		);
	}

	return (
		<ProgressBarElement
			className={ProgressBarClasses}
			max="100"
			value={progress}
			data-anvil-component="ProgressBar"
			{...props}
		>
			<div className="ProgressBar__bg" role="presentation">
				<div
					className="ProgressBar__value"
					style={{ width: `${progress}%` }}
				/>
			</div>
		</ProgressBarElement>
	);
};
