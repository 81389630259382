import React from "react"
import { Header } from "./Header"
import { Frame, ThemeProvider } from "@servicetitan/design-system"

export const GlobalWrapper = ({children}) => (
	<ThemeProvider>
	<Frame
		header={<Header />}
		className="Docs"
	>
		{children}
	</Frame>
	</ThemeProvider>
);
