import React, { ForwardRefExoticComponent } from 'react';
import { Popper, PopperProps } from '../../internal/Popper';
import classnames from 'classnames';
import {
	PopoverContent,
	PopoverContentProps,
	PopoverDivider,
	PopoverFooter,
	PopoverFooterProps,
	PopoverHeader,
	PopoverHeaderProps,
} from './components';
import { UseFocusTrapProps } from '../../hooks/useFocusTrap';

export interface PopoverPropsStrict {
	/** If there isn't enough space on the screen the Popover will be repositioned to the opposite side of the anchor */
	autoFlipHorizontally?: boolean;

	/** If there isn't enough space on the screen the Popover will be repositioned to the opposite side of the anchor */
	autoFlipVertically?: boolean;

	/** Adds one or more classnames for Popover's overlay element */
	className?: string;

	children?: React.ReactNode;

	/** Popover direction relative to the element its apart of */
	direction?:
		| 't'
		| 'tr'
		| 'tl'
		| 'r'
		| 'rt'
		| 'rb'
		| 'b'
		| 'br'
		| 'bl'
		| 'l'
		| 'lt'
		| 'lb'
		| 'c';

	/** Change the HTML element of the Popover's source element */
	el?: 'span' | 'div';

	/** Footer for the popover */
	footer?: React.ReactElement;

	/** Basic alignment for footer */
	footerAlign?: PopoverFooterProps['align'];

	/** Header for the popover */
	header?: React.ReactElement | string;

	/** Basic alignment for header */
	headerAlign?: PopoverHeaderProps['align'];

	/** Basic alignment for header */
	headerNoPadding?: PopoverHeaderProps['noPadding'];

	/** Whether the Popover is open or not */
	open?: boolean;

	/**
	 * *Warning* Only works when portal={true}
	 * A handler that is called on every click on any element outside of the anchor element and the stick node
	 */
	onClickOutside?: PopperProps['onClickOutside'];

	/** Define the overall padding of the popover */
	padding?: 's' | 'm' | 'l' | null;

	/** Adds classnames to Popover's content wrapper */
	popoverContentClassName?: string;

	/**
	 * Renders Popover on document root through createPortal.
	 * Effectively prevents Popover from being clipped by the boundaries of any container.
	 */
	portal?: boolean;

	/** Removes the padding content around the Popover content */
	contentNoPadding?: PopoverContentProps['noPadding'];

	/** Add a specific max-height value to the popover body */
	scrollHeight?: PopoverContentProps['contentScrollHeight'];

	/** Makes corners less rounded */
	sharp?: boolean;

	/** Element wrapped around Popover */
	trigger?: React.ReactNode;

	/** Define the width property of the popover */
	width?: 'xs' | 's' | 'm' | 'l' | '100' | 'auto';

	/** Adds one or more classnames to Popover's source element */
	wrapperClassName?: string;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;

	/** offset ammount between Popover and trigger */
	offset?: PopperProps['offset'];

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];
}

export interface PopoverProps extends PopoverPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface Popover extends ForwardRefExoticComponent<PopoverProps> {
	Header: typeof PopoverHeader;
	Footer: typeof PopoverFooter;
	Content: typeof PopoverContent;
	Divider: typeof PopoverDivider;
}

export const Popover: Popover = React.forwardRef(
	(
		{
			width = 's',
			padding = 'm',
			portal = false,
			children,
			className,
			contentNoPadding,
			footer,
			footerAlign,
			header,
			headerAlign,
			headerNoPadding,
			popoverContentClassName,
			scrollHeight,
			sharp,
			wrapperClassName,
			open,
			focusTrapOptions = { disabled: true },
			offset = { skidding: 0, distance: 4 },
			portalDataAttributes,
			...props
		},
		ref: React.RefObject<any>
	) => {
		const PopoverBaseWidth = 16;

		const widthSwitch = () => {
			switch (width) {
				case 'l':
					return PopoverBaseWidth * 40;
				case 'm':
					return PopoverBaseWidth * 32;
				case 's':
					return PopoverBaseWidth * 20;
				case 'xs':
					return PopoverBaseWidth * 12;
				case '100':
					return `100`;
				default:
					return `auto`;
			}
		};

		const PopoverBodyClasses = classnames('Popover__body', className, {
			[`Popover--p-${padding}`]: padding,
			[`Popover--w-auto`]: width === 'auto',
			'Popover__body--sharp': sharp,
			'Popover__body--portal': portal,
			'Popover__body--open': open,
		});

		const PopoverClasses = classnames('Popover', wrapperClassName, {
			'Popover--portal': portal,
			'Popover--open': open,
		});

		return (
			<Popper
				className={PopoverClasses}
				bodyClassName={PopoverBodyClasses}
				width={widthSwitch()}
				portal={portal}
				ref={ref}
				open={open}
				offset={offset}
				focusTrapOptions={focusTrapOptions}
				data-anvil-component="Popover"
				data-portal-popover={open && portal ? 'open' : undefined}
				portalDataAttributes={portalDataAttributes}
				{...props}
			>
				{header && (
					<PopoverHeader
						noPadding={headerNoPadding}
						align={headerAlign}
					>
						{header}
					</PopoverHeader>
				)}
				<PopoverContent
					contentScrollHeight={scrollHeight}
					noPadding={contentNoPadding}
					className={popoverContentClassName}
				>
					{children}
				</PopoverContent>
				{footer && (
					<PopoverFooter align={footerAlign}>{footer}</PopoverFooter>
				)}
			</Popper>
		);
	}
) as Popover;

Popover.Header = PopoverHeader;
Popover.Footer = PopoverFooter;
Popover.Content = PopoverContent;
Popover.Divider = PopoverDivider;
