import React, { FC, useContext, useState, useRef } from 'react';
import classnames from 'classnames';
import { BodyText } from '../../BodyText';
import { TagGroup } from '../../TagGroup';
import { Tooltip } from '../../Tooltip';
import { Button } from '../../Button';
import { Stack } from '../../Stack';
import { Icon } from '../../Icon';
import { Tag } from '../../Tag';
import { FormFieldContext } from '../../FormField';
import { keys } from '../../../utilities/keyCodes';
import { FocusContext } from '../utils';
import { useWindowWidth } from '@react-hook/window-size/throttled';

export interface TriggerProps {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Allow the user to clear all selections in the trigger (true by default) */
	clearable?: boolean;

	/** Indicates whether the Trigger is disabled or not. */
	disabled?: boolean;

	/** Change the HTML element the trigger uses */
	el?: any;

	/** Visual error state for the trigger */
	error?: boolean;

	/** Calls when the clear button is clicked  */
	onClearClick?: (e: React.SyntheticEvent) => void;

	/** Calls when the trigger is clicked  */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Calls when a Tag in the trigger is closed */
	onClose?: (value: any) => void;

	/** Calls when a trigger is blurred */
	onBlur?: (value: any) => void;

	/** Called when onKeyDown is passed */
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;

	/** Visually displays the trigger being opened */
	open?: boolean;

	/** HTML placeholer value for the trigger */
	placeholder?: string;

	/** Optional label to display along side the trigger */
	shortLabel?: React.ReactNode | string;

	/** A short label can appear on the left or right. */
	shortLabelPosition?: 'left' | 'right';

	/** Sets the vertical height of the trigger */
	size?: 'small' | 'medium' | 'large';

	/** HTML tabIndex property. If not passed will default to 0. Use tabIndex={null} to disable */
	tabIndex?: number;

	/** Limits how many lines of tags will be displayed in the Select's trigger */
	rows?: number;

	/** Displays the values currently selected */
	value?: any;

	/** Returns value and received custom content */
	content?: (value: any) => React.ReactNode;
}

export const Trigger: FC<TriggerProps> = ({
	className,
	disabled,
	el: TriggerEl = 'div',
	error,
	onClearClick,
	onClick,
	onClose,
	onBlur,
	onKeyDown,
	open,
	placeholder,
	value,
	shortLabel,
	shortLabelPosition = 'left',
	size = 'medium',
	tabIndex = 0,
	rows = 1,
	clearable = true,
	content,
	...props
}) => {
	const { focusState } = useContext(FocusContext);
	const { triggerFocus } = focusState;
	const triggerRef = useRef(null);
	const tagArrRef = useRef([]);

	const { labelFor: inputId, setFormFieldContext } =
		useContext(FormFieldContext);

	React.useEffect(() => {
		setFormFieldContext({ inputRef: triggerRef });
		return () => setFormFieldContext({ inputRef: undefined });
	}, []); // eslint-disable-line

	React.useEffect(() => {
		triggerFocus && triggerRef?.current?.focus();
	}, [triggerFocus, triggerRef]);

	const [tagLineCount, setTagLineCount] = useState<number>(0);
	const [tagLineCutoffCount, setTagLineCutoffCount] = useState<number>(0);
	const [widthCutoff, setWidthCutoff] = useState<number>(0);
	const windowWidth = useWindowWidth({ fps: 60 });

	React.useLayoutEffect(() => {
		const tagTopSet = new Set<number>();
		if (value) {
			for (let i = 0; i < value.length; i++) {
				const tag = tagArrRef.current[i];
				const tagTop = Math.ceil(tag?.getBoundingClientRect().top);
				if (isNaN(tagTop)) return;
				if (!tagTopSet.has(tagTop)) {
					tagTopSet.add(tagTop);

					if (tagTopSet.size === rows + 1) {
						setTagLineCutoffCount(i);
						setWidthCutoff(windowWidth);
					}
				}
			}
		}
		setTagLineCount(tagTopSet.size);
	}, [value, rows, windowWidth]);

	const TriggerClasses = classnames('Trigger', className, {
		'Trigger--open': open,
		'Trigger--error': error,
		'Trigger--disabled': disabled,
		[`Trigger--s-${size}`]: size,
	});

	const handleClick = (e: any) => {
		onClick?.(e);
	};

	const preventClick = (e: any) => e.stopPropagation();

	const clearClick = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		onClearClick(e);
		open ? triggerRef?.current?.focus() : handleClick(e); // Focus select and open dropdown
	};

	const handleKey = (e: any) => {
		if (onKeyDown) onKeyDown(e);
		if (e.key === keys.tab) return e; // Skip tabbing
		if (e.target !== triggerRef.current) return e; // Trigger isn't in focus
		if (
			e.key === keys.enter ||
			e.keyCode === 32 ||
			(e.key === keys.down && !open) ||
			(e.key === keys.up && open)
		) {
			e.preventDefault();
			handleClick(e);
		}
	};

	const shortLabelClasses = classnames('Trigger__shortLabel', className, {
		'Trigger__shortLabel--left': shortLabelPosition === 'left',
		'Trigger__shortLabel--right': shortLabelPosition === 'right',
	});

	const getShortLabel = () => {
		if (shortLabel === undefined || shortLabel === '') return;
		return <div className={shortLabelClasses}>{shortLabel}</div>;
	};

	const getValues = () => {
		if (content) {
			return (
				<BodyText className="Trigger__value" aria-disabled={disabled}>
					{content(value)}
				</BodyText>
			);
		}

		if (!value || value.length === 0) {
			return (
				<BodyText
					className="Trigger__placeholder"
					aria-label="placeholder text"
					role="textbox"
					aria-disabled="true"
				>
					{placeholder}
				</BodyText>
			);
		}

		if (Array.isArray(value)) {
			if (
				rows === 0 ||
				(rows > 0 &&
					tagLineCount > rows &&
					tagLineCutoffCount < value.length &&
					widthCutoff >= windowWidth)
			) {
				return (
					<BodyText
						className="Trigger__value"
						aria-disabled={disabled}
					>
						{value.length} selected
					</BodyText>
				);
			}

			tagArrRef.current = new Array(value.length);
			return (
				<TagGroup>
					{value.map((singleValue: any, index: number) => {
						const tagText = singleValue.text
							? singleValue.text
							: typeof singleValue.content === 'string'
							? singleValue.content
							: String(singleValue.value);
						const handleOnClose = () => onClose(singleValue);

						return (
							<Tag
								key={index}
								onClose={
									!singleValue.disabled &&
									onClose &&
									!disabled &&
									handleOnClose
								}
								onClick={preventClick}
								color={singleValue.color}
								tagRef={(el: any) =>
									(tagArrRef.current[index] = el)
								}
							>
								{tagText}
							</Tag>
						);
					})}
				</TagGroup>
			);
		}

		const text = value.text
			? value.text
			: typeof value.content === 'string'
			? value.content
			: String(value.value);
		return (
			<BodyText className="Trigger__value" aria-disabled={disabled}>
				{text}
			</BodyText>
		);
	};

	const checkIfAllDisabled = () => {
		if (
			value &&
			value.length > 0 &&
			value.filter((item: TriggerProps['value']) => item.disabled)
				.length === value.length
		)
			return true;
	};

	const getClearable = () => {
		if (
			!clearable ||
			!value ||
			value.length === 0 ||
			Object.entries(value).length === 0 ||
			checkIfAllDisabled()
		)
			return;

		return (
			<Tooltip
				portal
				text={disabled ? null : 'Clear selection'}
				className="d-f"
			>
				<Button
					aria-label="clear selection"
					disabled={disabled}
					xsmall
					fill="subtle"
					iconName="clear"
					onClick={clearClick}
					className="Trigger__clear"
				/>
			</Tooltip>
		);
	};

	const getArrow = () => {
		if (open) return 'keyboard_arrow_up';
		return 'keyboard_arrow_down';
	};

	return (
		<TriggerEl
			className={TriggerClasses}
			onClick={disabled ? undefined : handleClick}
			tabIndex={disabled ? undefined : tabIndex}
			onKeyPress={handleKey}
			onKeyDown={handleKey}
			ref={triggerRef}
			onBlur={onBlur}
			id={inputId}
			{...props}
		>
			<Stack>
				{shortLabelPosition === 'left' && getShortLabel()}
				{getValues()}
				<Stack
					className="Trigger__controls"
					spacing={1}
					alignItems="flex-start"
				>
					{getClearable()}
					<Icon
						name={getArrow()}
						size="16px"
						className="Trigger__arrow"
					/>
				</Stack>
				{shortLabelPosition === 'right' && getShortLabel()}
			</Stack>
		</TriggerEl>
	);
};
