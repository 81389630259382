import React, { FC } from 'react';
import classnames from 'classnames';

export interface SidebarSectionPropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Set which sides of Sidebar.Section have padding */
	padding?: boolean | 'x' | 'y';
}

export interface SidebarSectionProps extends SidebarSectionPropsStrict {
	[propName: string]: any;
}

export const SidebarSection: FC<SidebarSectionProps> = ({
	children,
	className,
	el: Element = 'div',
	padding = true,
	...props
}) => {
	const SidebarSectionClasses = classnames('SidebarSection', className, {
		'SidebarSection--p-x': padding === 'x',
		'SidebarSection--p-y': padding === 'y',
		'SidebarSection--p-none': !padding,
	});

	return (
		<Element
			className={SidebarSectionClasses}
			data-anvil-component="SidebarSection"
			{...props}
		>
			{children}
		</Element>
	);
};
