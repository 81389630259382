import React, { createContext, FC } from 'react';

export const TimezoneContext = createContext(
	Intl.DateTimeFormat().resolvedOptions().timeZone
);

interface TimezoneProviderProps {
	children: React.ReactNode;
	timezone: string;
}

export const TimezoneProvider: FC<TimezoneProviderProps> = ({
	children,
	timezone,
}) => {
	if (timezone && !isValidTimeZone(timezone))
		console.warn(
			`@servicetitan/design-system: Invalid timezone ${timezone}`
		);

	return (
		<TimezoneContext.Provider value={timezone}>
			{children}
		</TimezoneContext.Provider>
	);
};

const isValidTimeZone = (tz: string) => {
	if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
		throw new Error('Time zones are not available in this environment');
	}

	try {
		Intl.DateTimeFormat(undefined, { timeZone: tz });
		return true;
	} catch (ex) {
		return false;
	}
};
