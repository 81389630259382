import React, { FC } from 'react';
import classnames from 'classnames';

import { Divider } from '../Divider';
import { Eyebrow } from '../Eyebrow';

/**
 * ActionMenu.Group properties
 */
export interface ActionMenuGroupPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Adds a label for group of menu items */
	label?: string;
}

export interface ActionMenuGroupProps extends ActionMenuGroupPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const ActionMenuGroup: FC<ActionMenuGroupProps> = ({
	children,
	className,
	label,
	...props
}) => (
	<div className={classnames('ActionMenuGroup', className)} {...props}>
		<Divider
			alignContent="start"
			el="div"
			spacing={label ? 'half' : '1'}
			className={classnames(
				label ? 'ActionMenuGroup__label' : 'ActionMenuGroup__divider'
			)}
		>
			{label ? <Eyebrow className="m-r-1">{label}</Eyebrow> : null}
		</Divider>
		{children}
	</div>
);
