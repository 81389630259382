import React, { FC } from 'react';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { tokens } from '@servicetitan/tokens/core';
import { Overlay } from '../../internal/Overlay';
import { wrapWithComponent } from '../../utilities/wrapWithComponent';
import { useFocusTrap, UseFocusTrapProps } from '../../hooks/useFocusTrap';
import { TakeoverHeader, TakeoverHeaderProps } from './TakeoverHeader';
import { TakeoverSection, TakeoverSectionProps } from './TakeoverSection';
import { TakeoverFooter, TakeoverFooterProps } from './TakeoverFooter';

export interface TakeoverPropsStrict {
	/** Content for Action area next to close button */
	actions?: TakeoverHeaderProps['actions'];

	/**
	 * Use animation for show/hide transitions
	 * Defaults to false because Takeovers are used as full page layouts
	 */
	animate?: boolean;

	/** Label for Back button. Turns Back button into `Back to {backLabel}` button */
	backLabel?: TakeoverHeaderProps['backLabel'];

	/** Add <ProgressTracker.Step> components to be rendered in the <ProgressTracker> */
	children: TakeoverSectionProps['children'];

	/** Adds one or more classnames to an element */
	className?: string;

	/** Adds one or more classnames to TakeoverContent */
	contentWrapperClass?: string;

	/** Footer Elements */
	footer?: TakeoverFooterProps['children'];

	/** Align TakeoverFooter content using flexbox justify-content properties */
	footerAlign?: TakeoverFooterProps['align'];

	/** False will automatically wrap children with <Takeover.Section /> */
	sectioned?: boolean;

	/** If passed an onBack function, <Takeover.Header> gets a Back button in the top right */
	onBack?: TakeoverHeaderProps['onBack'];

	/** If passed an onClose function, <Takeover.Header> gets a Close button in the top right */
	onClose?: TakeoverHeaderProps['onClose'];

	/** Set visibility of the Takeover */
	open?: boolean;

	/** Theme of Takeover */
	theme?: TakeoverHeaderProps['theme'];

	/** Name of Takeover */
	title: TakeoverHeaderProps['title'];

	/** Render in a React Portal */
	portal?: boolean;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;
}

export interface TakeoverProps extends TakeoverPropsStrict {
	[propName: string]: any;
}

export interface Takeover extends FC<TakeoverProps> {
	Header: typeof TakeoverHeader;
	Section: typeof TakeoverSection;
	Footer: typeof TakeoverFooter;
}

export const Takeover: Takeover = ({
	actions,
	animate = false,
	backLabel,
	children,
	className,
	contentWrapperClass,
	focusTrapOptions,
	footer,
	footerAlign = TakeoverFooter.defaultProps.align,
	onBack,
	onClose,
	open = true,
	portal = true,
	sectioned,
	theme = TakeoverHeader.defaultProps.theme,
	title,
	...props
}) => {
	React.useEffect(() => {
		if (open) {
			document.body.classList.add('Takeover--is-open');
		} else {
			const otherOpenTakeovers =
				document.getElementsByClassName('Takeover').length > 0;
			if (otherOpenTakeovers) {
				document.body.classList.add('Takeover--is-open');
			}
		}

		return () => document.body.classList.remove('Takeover--is-open');
	}, [open]);

	// Focus trapping
	const takeoverRef = useFocusTrap({
		autoFocusContainer: true,
		disabled: !open,
		...focusTrapOptions,
	});

	const headerProps = {
		actions,
		backLabel,
		onBack,
		onClose,
		theme,
		title,
	};

	const footerProps = {
		align: footerAlign,
		children: footer,
	};

	const TakeoverClasses = classnames(
		'Takeover',
		className,
		`Takeover--${theme}`
	);

	const contentWrapperClasses = classnames(
		'TakeoverContent',
		contentWrapperClass
	);

	const content = !sectioned
		? wrapWithComponent(children, TakeoverSection)
		: children;

	const duration: number = parseInt(tokens.durationStandard, 10) / 1000; // '200ms' -> 0.2
	const framerAnimation = {
		initial: { opacity: 0, y: 50 },
		animate: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: 50 },
		transition: { type: 'easeInOut', duration },
	};

	const Animate = animate ? AnimatePresence : React.Fragment;
	const TakeoverEl = animate ? motion.div : 'div';
	const TakeoverProps = {
		className: TakeoverClasses,
		ref: takeoverRef,
		...(animate && framerAnimation),
		...props,
	};

	return (
		<Overlay portal={portal} animate={animate} open={open}>
			<Animate>
				{open && (
					<TakeoverEl
						data-anvil-component="Takeover"
						{...TakeoverProps}
					>
						<TakeoverHeader {...headerProps} />
						<div className={contentWrapperClasses}>{content}</div>
						<TakeoverFooter {...footerProps} />
					</TakeoverEl>
				)}
			</Animate>
		</Overlay>
	);
};

Takeover.Header = TakeoverHeader;
Takeover.Section = TakeoverSection;
Takeover.Footer = TakeoverFooter;
