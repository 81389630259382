import { keys } from '../../../utilities';

export const handledKeys = [
	keys.up,
	keys.right,
	keys.down,
	keys.left,
	keys.home,
	keys.end,
];
export const keysX = [keys.left, keys.right];
export const keysY = [keys.up, keys.down, keys.home, keys.end];
