import { checkMinMax } from './checkMinMax';
import { stringToDate } from './stringToDate';
import { toTimeFormat } from './toTimeFormat';

import { TimePickerProps } from '../TimePicker';
import { TimePickerOptionProps } from '../components/TimePickerOptionList';

/* Generate options based on min, max and step */
export const generateOptions = (
	min: TimePickerProps['min'],
	max: TimePickerProps['max'],
	step: TimePickerProps['step'],
	format: TimePickerProps['format']
): TimePickerOptionProps[] => {
	const filledRangeMinMax = fillDateRange(min, max, step);

	return filledRangeMinMax.map((date) => ({
		text: toTimeFormat(date, format),
		value: date,
	}));
};

/* Return array of Dates from min to max, step in munutes */
export const fillDateRange = (
	min: Date | string,
	max: Date | string,
	step: number
): Date[] => {
	let realMin =
		min instanceof Date ? min : stringToDate(min) || stringToDate('00:00');
	let realMax =
		max instanceof Date ? max : stringToDate(max) || stringToDate('23:59');

	if (realMin > realMax) {
		realMin = stringToDate(max); // Reverse range if min > max
		realMax = stringToDate(min);
	}

	const range = [];
	let current: Date = realMin;

	while (checkMinMax(realMin, realMax, current)) {
		range.push(current);
		current = addMinutes(current, step);
	}

	return range;
};

const addMinutes = (date: Date | string, minutes: number): Date => {
	const d = stringToDate(date);
	return new Date(d.getTime() + minutes * 60000);
};
