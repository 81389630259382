import { CSSProperties } from 'react';

export const useVisuallyHidden = () => {
	const hiddenStyle = {
		border: 'none',
		display: 'table-cell',
		float: 'left',
		height: '1px',
		width: '1px',
		maxWidth: '1px',
		minWidth: '1px',
		margin: '-.5em -1px -.5em 0',
		opacity: '0',
		overflow: 'hidden',
		padding: '0',
		pointerEvents: 'none',
		position: 'absolute',
	} as CSSProperties;

	return { hiddenStyle };
};
