import React, { FC } from 'react';
import classnames from 'classnames';

export interface FramePropsStrict {
	/** Adds one or more classnames to an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Content for a Global Header */
	header?: React.ReactElement;
}

export interface FrameProps extends FramePropsStrict {
	[propName: string]: any;
}

export const Frame: FC<FrameProps> = ({
	children,
	className,
	el: El = 'div',
	header,
	...props
}) => {
	React.useEffect(() => {
		document.body.classList.add('of-hidden');
		return () => document.body.classList.remove('of-hidden');
	}, []);

	/*
	 * Hacky way of handling with mobile Chrome's head and 100vh layout.
	 * To be removed after Chrome to support dvh units.
	 */
	React.useEffect(() => {
		const isHackRequired = !is100dvhSupported();

		isHackRequired && frameParentsAddClass('with-frame-inside');
		return () =>
			isHackRequired && frameParentsRemoveClass('with-frame-inside');
	}, []);

	return (
		<El
			className={classnames('Frame', className)}
			data-anvil-component="Frame"
			{...props}
		>
			{header && <div className="Frame__header">{header}</div>}
			<div className="Frame__content">{children}</div>
		</El>
	);
};

const getFrameParents = () => {
	const frame = document.querySelector('[data-anvil-component=Frame]');
	if (!frame) return [];
	return [
		...(function* (e) {
			// eslint-disable-next-line no-param-reassign
			while ((e = e.parentElement)) {
				yield e;
			}
		})(frame),
	];
};

const frameParentsAddClass = (className: string) =>
	getFrameParents().forEach((parent) => parent.classList.add(className));

const frameParentsRemoveClass = (className: string) =>
	getFrameParents().forEach((parent) => parent.classList.remove(className));

const is100dvhSupported = () => {
	try {
		return CSS.supports('height', '100dvh');
	} catch (e) {
		return false;
	}
};
