import React from 'react';
import { tokens } from '@servicetitan/tokens/core';
import colorObj from '@servicetitan/tokens/core/colors';
import { Stack, Headline, BodyText, Tooltip } from '@servicetitan/design-system';

const tinycolor = require('tinycolor2');

const getBorder = (value) => {
	if (value === 'neutral-0' || value === 'neutral-10' || value === 'neutral-20' || value === 'neutral-30' || value === 'neutral-40') return 'paletteBorder';
}

const Dot = ({value, size = '16px', checkSubdued = false}) => {
	const white = tinycolor.isReadable(value, tokens.colorWhite, {level:"AA",size:"large"});
	const gray = checkSubdued && tinycolor.isReadable(value, tokens.colorNeutral90, {level:"AA",size:"large"});

	return (
		<Stack spacing={1} style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
			<Tooltip text={`Works with ${white ? 'white' : 'black'} text or background`} direction="tr" el="div">
				<div style={{width: size, height: size, borderRadius: '50%' }} className={white ? 'bg-white' : 'bg-black'} />
			</Tooltip>
			{gray &&
				<Tooltip text={`Works with subdued text`} direction="tr" el="div">
					<div style={{width: size, height: size, borderRadius: '50%' }} className='bg-neutral-90' />
				</Tooltip>
			}
		</Stack>
	);
}

const Showcase = ({name, showcase}) => {
	const category = Object.keys(showcase);
	const number = Object.values(showcase);
	const bgClass = 'bg-' + category + '-' + number;
	const borderClass = getBorder(category + '-' + number);
	const colorValue = colorObj.color[category][number][0].value;

	return (
		<div className="mobile-hide" style={{ marginBottom: tokens.spacing5, marginRight: tokens.spacing5 }}>
			<div className={'align-self-start ' + bgClass + ' ' + borderClass} style={{ width: '180px', height: '90px', position: 'relative' }}>
				<Dot value={colorValue} size={'16px'} subdued={category != 'yellow'} />
			</div>
			{name && (<Headline className="m-t-1">{name}</Headline> )}
			<BodyText size="small">
				{category.toString()[0].toUpperCase() + category.toString().substring(1) + number}
				<BodyText size="small" subdued el='span' className="m-l-half">{tinycolor(colorValue).toHexString()}</BodyText>
			</BodyText>
		</div>
	)
}

const Palette = ({palette}) => (
	<React.Fragment>
		{Object.keys(colorObj.color[palette]).map((value) => {
			const bgClass = 'bg-' + palette + '-' + value;
			const borderClass = getBorder(palette + '-' + value);
			const colorValue = colorObj.color[palette][value][0].value;

			return (
				<Stack.Item className="m-r-5 m-b-5 palette" key={value}>
					<div className={bgClass + ' ' + borderClass} style={{ width: '180px', height: '60px', position: 'relative' }}>
						<Dot value={colorValue} size={'12px'} />
					</div>
					<BodyText size="small" className="m-t-1">
						{palette[0].toUpperCase() + palette.substring(1)}{value}
						<BodyText size="small" subdued el='span' className="m-l-half">{tinycolor(colorValue).toHexString()}</BodyText>
					</BodyText>
				</Stack.Item>
			);
		})}
	</React.Fragment>
);

export const DisplayColors = ({
	showcase,
	palette,
	name
}) => {
	return (
		<Stack className="palette" wrap="wrap">
			<style>{`.paletteBorder { border: 1px solid #dfe0e1 }`}</style>
			{showcase && <Showcase showcase={showcase} name={name} />}
			{palette && <Palette palette={palette} />}
		</Stack>
	);
}
