import React, { FC } from 'react';
import classnames from 'classnames';
import { BodyText } from '../BodyText';
import { Tooltip } from '../Tooltip';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';

export interface FileDescriptor {
	/** File name to display to user in case you want to override file.name */
	displayName?: string;

	/** Direct link to file download */
	downloadLink?: string;

	/** Original file descriptor or at least file name (in case you've got file somewhere not from user input) */
	file: File | string;

	/** A number from 0-100 range used to show upload progress. Undefined if no upload is going on. */
	uploadProgress?: number;
}

export const isFileDescriptor = (
	toBeDetermined: any
): toBeDetermined is FileDescriptor => {
	return (
		toBeDetermined?.file &&
		(typeof toBeDetermined.file === 'string' ||
			toBeDetermined.file instanceof File)
	);
};

export interface FilePickerPropsStrict {
	/** Specifiers for file types allowed to upload. Can be any value valid for <input type="file" /> accept attribute */
	accept?: string;

	/** Aligns the content within the page */
	align?: 'left' | 'center';

	/** Button label */
	buttonLabel?: string;

	/** Property options for the button */
	buttonProps?: ButtonProps;

	/** Disabled and unclickable */
	disabled?: boolean;

	/** Layout type for a list of uploaded files */
	layout?: 'grid' | 'list';

	/** Indicates FilePickerMulti that no more files are allowed to add */
	limitReached?: boolean;

	/** Allow more then one file to be selected */
	multiple?: boolean;

	/** Delete file event; file === one from value */
	onDelete?(file: FileDescriptor): void;

	/** Download file(???) event; file === one from value */
	onDownload?(file: FileDescriptor): void;

	/** Event fired when user wants to replace one file with another; file === one from value */
	onReplace?({
		file,
		newFile,
	}: {
		file: FileDescriptor;
		newFile: File;
	}): void;

	/** Event fired when file(s) are selected by user */
	onSelected(files: FileList): void;

	/** Types note text */
	typesNote?: string | null;

	/** @deprecated - Use flex box display for the items */
	useFlex?: boolean;

	/** FileDescriptor or array of FileDescriptors */
	value?: FileDescriptor | FileDescriptor[];
}

export interface FilePickerProps extends FilePickerPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const FilePicker: FC<FilePickerProps> = ({
	accept,
	align = 'left',
	buttonLabel,
	buttonProps,
	className,
	disabled,
	layout = 'list',
	limitReached,
	multiple,
	onDelete,
	onDownload,
	onReplace,
	onSelected,
	typesNote: propsTypesNote,
	value,
	...rest
}) => {
	const inputRef: React.RefObject<HTMLInputElement> = React.useRef();

	const getRestOfButtonProps = () => {
		const { disabled, onClick, ...rest } = buttonProps ?? {};
		return rest;
	};

	const handleButtonClick = (event: React.SyntheticEvent) => {
		inputRef.current.click();
		buttonProps?.onClick?.(event);
	};

	const handleFileReplace =
		(file: FileDescriptor) => (e: React.ChangeEvent<HTMLInputElement>) =>
			onReplace
				? onReplace({ file, newFile: e.target.files[0] })
				: undefined;

	const handleFileDelete = (file: FileDescriptor) => () =>
		onDelete ? onDelete(file) : undefined;

	const handleFileDownload = (file: FileDescriptor) => () =>
		onDownload ? onDownload(file) : undefined;

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		onSelected(e.target.files);

	const valueArr = isFileDescriptor(value) ? [value] : value;

	const inProgress = (file: any) =>
		!(file.uploadProgress === undefined || file.uploadProgress === 100);

	let typesNote = propsTypesNote;

	if (accept && typesNote === undefined) {
		typesNote = `Allowed file types: ${accept}`;
	}

	const classes = classnames('FilePicker', className, {
		'FilePicker--disabled': disabled,
		'FilePicker--grid': layout === 'grid',
		'FilePicker--list': layout === 'list',
		'FilePicker--align-center': align === 'center',
	});

	return (
		<div {...rest} className={classes} data-anvil-component="FilePicker">
			{valueArr?.map((file, index) => (
				<div key={index} className={classnames('FilePicker__File')}>
					<Icon
						name="description"
						size={24}
						className="FilePicker__File__Icon"
					/>
					<BodyText className="FilePicker__File__Name">
						{file.displayName ||
							(typeof file.file === 'string'
								? file.file
								: file.file.name)}
					</BodyText>
					{inProgress(file) ? (
						<div>Loading...</div>
					) : (
						<React.Fragment>
							{onReplace && (
								<label className="FilePicker__File__ActionIcon">
									<Tooltip text="Replace file">
										<Icon
											name="cached"
											size={24}
											role="button"
											aria-label="replace file"
										/>
									</Tooltip>
									<input
										type="file"
										className="FilePicker__Input"
										onChange={handleFileReplace(file)}
										accept={accept}
										disabled={disabled}
									/>
								</label>
							)}
							{(onDownload || file.downloadLink) && (
								<Tooltip
									text="Download file"
									className="FilePicker__File__ActionIcon"
								>
									{file.downloadLink ? (
										<Icon
											name="get_app"
											size={24}
											onClick={handleFileDownload(file)}
											href={file.downloadLink}
											role="button"
											aria-label="download file"
										/>
									) : (
										<Icon
											name="get_app"
											size={24}
											onClick={handleFileDownload(file)}
											role="button"
											aria-label="download file"
										/>
									)}
								</Tooltip>
							)}
							{onDelete && (
								<Tooltip
									text="Remove file"
									className="FilePicker__File__ActionIcon"
								>
									<Icon
										name="delete"
										size={24}
										onClick={handleFileDelete(file)}
										role="button"
										aria-label="remove file"
									/>
								</Tooltip>
							)}
						</React.Fragment>
					)}
				</div>
			))}
			{(!valueArr || (multiple && !limitReached)) && (
				<span className="FilePicker__File__ActionContainer">
					<label>
						<Button
							default
							disabled={disabled}
							iconName="attach_file"
							onClick={handleButtonClick}
							{...getRestOfButtonProps()}
						>
							{buttonLabel
								? buttonLabel
								: `Upload File${multiple ? 's' : ''}`}
						</Button>
						<input
							type="file"
							className="FilePicker__Input"
							onChange={handleFileChange}
							multiple={multiple}
							accept={accept}
							disabled={disabled}
							value=""
							ref={inputRef}
						/>
					</label>
					{typesNote && (
						<BodyText
							size="xsmall"
							subdued
							className="m-t-1 FilePicker__Note"
						>
							{typesNote}
						</BodyText>
					)}
				</span>
			)}
		</div>
	);
};
