type CancellerType = (foo: () => void) => void;

export function debounce(
	callback: (...args: any[]) => void,
	delay = 100,
	canceller: CancellerType = () => {}
) {
	let timerId: number | undefined;

	canceller(() => {
		window.clearTimeout(timerId);
	});

	return function (...args: any[]) {
		if (timerId !== undefined) {
			window.clearTimeout(timerId);
		}

		timerId = window.setTimeout(() => {
			callback(...args);
			timerId = undefined;
		}, delay);
	};
}
