import { DateTime } from 'luxon';

// Generate Date object with value Invalid Date
export const invalidDate = new Date('');

export const isInvalidDate = (d: Date | undefined): boolean => {
	if (typeof d === 'undefined') return false;
	return d instanceof Date && isNaN(d as any);
};

export const isValidDate = (date: Date) => {
	if (date instanceof Date) return !isInvalidDate(date);
	return false;
};

export interface DateObj {
	dateTime: DateTime | undefined;
	timezone: string;
	isValid: boolean;
	invalidReason?: string;
	toISO?: () => string;
	toJSDate?: () => Date;
	isEqualDate?: (obj: DateObj) => boolean;
	isEqual?: (obj: DateObj) => boolean;
}

interface ToDateObj {
	date: Date | DateTime | string | undefined;
	tz?: string;
	isValid?: boolean;
	invalidReason?: string;
}

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const isBoolean = (value: any) => typeof value === 'boolean';

export const toDateObj = ({
	date,
	tz = defaultTimezone,
	isValid,
	invalidReason,
	...rest
}: ToDateObj): DateObj => {
	const dateTime = toDateTime(date, tz);
	const valid = isBoolean(isValid) ? isValid : dateTime?.isValid;
	const reason = invalidReason;

	const result: DateObj = {
		dateTime,
		timezone: tz,
		isValid: valid,
		invalidReason: reason,
		...rest,
	};

	result.toISO = (): string =>
		result.dateTime ? result.dateTime.toISO() : undefined;
	result.toJSDate = (): Date =>
		result.dateTime ? result.dateTime.toJSDate() : undefined;
	result.isEqualDate = (other: DateObj): boolean => {
		const dt1 = result.dateTime;
		const dt2 = other.dateTime;
		if ((!dt1 && !!dt2) || (!!dt1 && !dt2)) return false; // One is valid, other not
		if (typeof dt1 === 'undefined' && typeof dt2 === 'undefined')
			return true; // Both undefined
		if (!dt1.isValid && !dt2.isValid) return true; // Both invalid
		return +dt1 === +dt2; // Compare millis
	};
	result.isEqual = (other: DateObj): boolean =>
		result.isValid === other.isValid &&
		result.timezone === other.timezone &&
		result.invalidReason === other.invalidReason &&
		result.isEqualDate(other); // Equal dates

	return result;
};

export const toDateTime = (
	value: Date | DateTime | string | undefined,
	tz?: string
): DateTime | null => {
	if (!value) return undefined;
	if (value instanceof DateTime) return value.setZone(tz);

	const timezone = tz || defaultTimezone;

	return value instanceof Date
		? DateTime.fromJSDate(value)?.setZone(timezone) // Parse Date
		: DateTime.fromISO(value)?.setZone(timezone); // Parse String
};

export const toFormat = (date: any, format = 'LL/dd/yyyy') => {
	if (date instanceof Date) return DateTime.fromJSDate(date).toFormat(format);
	if (date instanceof DateTime) return date.toFormat(format);
	return date;
};

/*
 * Shifts date to timezone, so that date.toString()
 * to have same digits as corresponding date in timezone.
 */
export const toTimeZone = (date: Date, tz?: string, opts?: any) => {
	const dateTime = opts?.startOfDay
		? toDateTime(date, tz).startOf('day')
		: toDateTime(date, tz);
	return dateTime?.isValid
		? new Date(dateTime.toISO({ includeOffset: false })) // Main routine
		: undefined;
};

// Inverse function for toTimeZone
export const fromTimeZone = (date: Date, tz: string) => {
	const unzoned = DateTime.fromJSDate(date)?.toISO({ includeOffset: false });
	return DateTime.fromISO(unzoned, { zone: tz })?.toJSDate();
};

export const compareObj = (value?: Date | string) => {
	if (value instanceof Date) {
		if (isInvalidDate(value)) return 'Invalid Date';
		return value.getTime();
	}
	if (typeof value === 'undefined') return 'undefined';
	return value;
};
