import React, { FC, isValidElement } from 'react';
import classnames from 'classnames';
import { ConditionalWrapper } from '../../utilities/ConditionalWrapper';
import {
	ActionToolbar,
	ActionToolbarProps,
	Panel,
	PanelProps,
} from './components';

export interface PagePropsStrict {
	/** Aligns the content within the page */
	align?: 'left' | 'center';

	backgroundColor?: 'default' | 'white' | 'neutral-0' | 'neutral-10';

	/** Adds one or more classnames for an element */
	className?: string;

	/** Header for the page */
	header?: React.ReactElement;

	/** Footer for the page */
	footer?: React.ReactElement;

	/** Modifies the max-width of the page. Wide removes the max-width, narrow shrinks it. */
	maxWidth?: 'default' | 'narrow' | 'wide';

	/** If false, a min-width is applied to the page */
	minWidth?: boolean;

	/** Content for an optional Sidebar */
	sidebar?: React.ReactElement;

	/** The padding between the page and the parent content */
	spacing?: 'none' | 'relaxed';

	/** ActionToolbar Props */
	actionToolbar?: ActionToolbarProps;

	/** Panel Props */
	panel?: PanelProps;
}
export interface PageProps extends PagePropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export const Page: FC<PageProps> = ({
	align = 'center',
	backgroundColor = 'default',
	children,
	className,
	header,
	headerAlign,
	footer,
	maxWidth = 'default',
	minWidth,
	sidebar,
	spacing = 'relaxed',
	actionToolbar,
	panel,
	...props
}) => {
	const PageClasses = classnames('Page', className, {
		[`bg-${backgroundColor}`]: backgroundColor !== 'default',
		'Page--align-center': align === 'center',
		'Page--no-padding': spacing === 'none',
		'Page--relaxed': spacing === 'relaxed',
		'Page--wide': maxWidth === 'wide',
		'Page--narrow': maxWidth === 'narrow',
		'Page--min-width': minWidth,
	});

	const isToolbar = isValidElement(actionToolbar?.content);
	const isPanel = isValidElement(panel?.content);
	const panelPosition = panel?.position ?? 'right';

	return (
		<div className={PageClasses} data-anvil-component="Page" {...props}>
			{sidebar && <div className="Page__sidebar">{sidebar}</div>}
			<div className="Content__wrapper">
				{isToolbar && <ActionToolbar {...actionToolbar} />}
				<ConditionalWrapper
					condition={isPanel}
					wrapper={(children) => (
						<div className="Panel__wrapper">{children}</div>
					)}
				>
					{isPanel && panelPosition === 'left' && (
						<Panel {...panel} />
					)}
					<div className="Page__page">
						{header && <div className="Page__header">{header}</div>}
						<div className="Page__content">{children}</div>
					</div>
					{isPanel && panelPosition === 'right' && (
						<Panel {...panel} />
					)}
				</ConditionalWrapper>
				{footer && <div className="Page__footer">{footer}</div>}
			</div>
		</div>
	);
};
