import { useRef, useEffect, useCallback } from 'react';

import {
	handledKeys,
	keysX,
	keysY,
	active,
	getScope,
	getFocusables,
	isInsideScope,
	initTabIndexes,
	createId,
} from './utils';

export type ScopeElement =
	| HTMLElement
	| React.RefObject<HTMLElement>
	| React.MutableRefObject<HTMLElement>;

export interface SelectorOptions {
	selector?: string;
	extendSelector?: string;
}

export interface UseArrowNavProps {
	/** Completely disables any activity of the hook */
	disabled?: boolean;

	/** Called on each arrow keydown (before focus move) */
	onKeyDown?: (
		event: KeyboardEvent,
		data: {
			/** Returns currently active element */
			active?: HTMLElement;

			/** Returns element to be focused in the next moment */
			next?: HTMLElement;

			/** May be called conditionaly to override default behavior of focus move */
			disableOnce?: () => void;
		}
	) => void;

	/** Povides control over focusable elements */
	focusableSelector?: SelectorOptions;

	/*
	 * A ref or an array of refs, to set elements on which arrow navigation should be handled.
	 */
	scope?: ScopeElement | ScopeElement[];

	/*
	 * If false, handling of keys ArrowLeft, ArrowRight to be disabled.
	 * Default is true.
	 */
	handleX?: boolean;

	/*
	 * If false, handling of keys ArrowUp, ArrowDown, Home, End to be disabled.
	 * Default is true.
	 */
	handleY?: boolean;

	/*
	 * If true, arrow navigation to be looped from first to last element and vice versa.
	 * If false, arrow navigation to be stopped reaching first or last element.
	 * Default is true.
	 */
	loop?: boolean;

	/*
	 * Set tabIndex on scopes focusable elements, so that Tab keypress makes focus to exit the scope.
	 * Default is true.
	 */
	tabIndexGroup?: boolean;
}

export const useArrowNav = (
	props: UseArrowNavProps = {}
): React.RefObject<any> => {
	const {
		disabled = false,
		focusableSelector = {},
		handleX = true,
		handleY = true,
		loop = true,
		onKeyDown,
		scope,
		tabIndexGroup = true,
	} = props;
	const keynavId = useRef(createId());
	const containerRef = useRef(null) as React.RefObject<HTMLElement>;
	const scp = useCallback(
		() => (scope ? getScope(scope) : getScope(containerRef)),
		[scope]
	);

	let disabledOnce = false;
	const disableOnce = () => (disabledOnce = true);

	const handleKeydown = (e: KeyboardEvent): void => {
		if (![...handledKeys].includes(e.key)) return; // Proper key wasn't pressed

		if (!handleX && keysX.includes(e.key)) return;
		if (!handleY && keysY.includes(e.key)) return;

		if (!isInsideScope(scp(), active(), keynavId.current)) return;

		const focusables = getFocusables(
			scp(),
			focusableSelector,
			loop,
			e.key,
			keynavId.current
		);

		onKeyDown?.(e, {
			active: focusables.active,
			next: focusables.next,
			disableOnce,
		});

		if (disabledOnce) {
			disabledOnce = false; // Re-enable hook action
			return;
		}

		e.preventDefault(); // Prevent scrolling
		focusables.next?.focus(); // Main routine
	};

	// Effect for setting keynav id attribute
	useEffect(() => {
		if (disabled) return;

		const scopeElements = scp();

		scopeElements.forEach((el) => (el.dataset.keynav = keynavId.current));

		return () => scopeElements.forEach((el) => delete el.dataset.keynav);
	}, [disabled, scope]); // eslint-disable-line

	// Effect for key listeners
	useEffect(() => {
		if (disabled) return;

		const scopeElements = scp();

		if (scopeElements?.length < 1) return;

		scopeElements.forEach((el) =>
			el.addEventListener('keydown', handleKeydown)
		);

		return () => {
			scopeElements.forEach((el) =>
				el.removeEventListener('keydown', handleKeydown)
			);
		};
	}, [disabled, scope, loop, handleX, handleY, tabIndexGroup, compare(focusableSelector)]); // eslint-disable-line

	// Effect for tab grouping, to be fired on each re-render
	useEffect(() => {
		if (!tabIndexGroup || disabled) return;

		const cleanTabIndexes = initTabIndexes(
			scp(),
			focusableSelector,
			keynavId.current
		);

		return () => cleanTabIndexes?.forEach((clearFn) => clearFn?.());
	}, [disabled, scope, tabIndexGroup, compare(focusableSelector), {}]); // eslint-disable-line

	// Returns ref of the container if scope was not set
	if (!scope) return containerRef;
};

const compare = (prop: any) =>
	JSON.stringify(prop, ['selector', 'extendSelector']);
