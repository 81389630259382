import { createContext } from 'react';
import { tokens } from '@servicetitan/tokens/core';

/*
 * Provides stacked context of overlays as an array, for example
 * a tooltip may be found placed consiquently inside ['Overlay', 'Popper']
 */
export const OverlayContext = createContext<string[]>([]);

/*
 * Converts overlay context into numeric z-index value
 */
export const calculateZindex = (overlayContext: string[]) => {
	const isInOverlay = overlayContext.some((item) => item === 'Overlay');

	if (isInOverlay) {
		return parseInt(tokens.zIndexOverlay, 10);
	}

	return parseInt(tokens.zIndexPopover, 10);
};
