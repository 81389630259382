import { useEffect, useCallback } from 'react';
import {
	getScopeElements,
	getFocusables,
	priorityFocusableSelector,
} from './utils';
import { FocusTrapShards } from '../';

export interface useAutoFocusProps {
	scope: FocusTrapShards;
	enabled?: boolean;
}

export const useAutoFocus = (props: useAutoFocusProps) => {
	const { scope, enabled = true } = props;

	const autoFocus = useCallback(() => {
		if (!enabled) return;

		const elements = getScopeElements(scope);
		if (elements.length === 0) return;

		// Do not auto focus on wrappers, buttons and so on, if input exist
		const priorityFocusable = getFocusables(
			elements,
			priorityFocusableSelector
		)?.first;

		const focusElement = priorityFocusable
			? priorityFocusable
			: getFocusables(elements)?.first;

		focusElement?.focus(preventScroll);
	}, [enabled, scope]);

	useEffect(autoFocus, [enabled, scope]); // eslint-disable-line
};

const preventScroll = { preventScroll: true };
