import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

enum ThumbnailSizes {
	XS = 'XS',
	S = 'S',
	M = 'M',
	L = 'L',
	XL = 'XL',
}

type fitType = 'contain' | 'cover' | 'fill' | 'none';

export interface ThumbnailPropsStrict {
	/** Image source path */
	src: string;

	/** Alternate text for image */
	alt?: string;

	/**  Controls the width and height of thumbnail */
	size?: ThumbnailSizes;

	/** Adds one or more classNames for thumbnail */
	className?: string;

	/** Adds padding from all sides */
	padding?: 'none' | 'thin';

	/** Set how image should be resized to fit it's border */
	fit?: fitType;

	/** What happens when user clicks the thumbnail */
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Adds transparent layer and will set given ReactNode over thumbnail  */
	overlay?: ReactNode;
}

export interface ThumbnailProps extends ThumbnailPropsStrict {
	[propName: string]: any;
}

export interface Thumbnail extends FC<ThumbnailProps> {
	Sizes: typeof ThumbnailSizes;
}

export const Thumbnail: Thumbnail = ({
	src,
	alt,
	size = ThumbnailSizes.M,
	fit = 'cover',
	padding = 'none',
	className,
	onClick,
	overlay = null,
	...props
}) => {
	const ThumbnailClassNames = classnames(
		'Thumbnail',
		sizeClass(size),
		fitClass(fit),
		className,
		{
			'Thumbnail--thin': padding === 'thin',
			'Thumbnail--clickable': !!onClick,
		}
	);
	return (
		<div
			className={ThumbnailClassNames}
			onClick={onClick}
			data-anvil-component="Thumbnail"
			{...props}
		>
			<img src={src} alt={alt} />
			{overlay !== null && (
				<div className="Thumbnail__overlay">{overlay}</div>
			)}
		</div>
	);
};

Thumbnail.Sizes = ThumbnailSizes;
Thumbnail.displayName = 'Thumbnail';

function sizeClass(size: ThumbnailSizes): string {
	return `Thumbnail--${size}`;
}

function fitClass(fit: fitType): string {
	return `Thumbnail--${fit}`;
}
