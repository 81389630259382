import React from 'react';
import { getFirstOption, FocusActionType, FooterAction } from './';
import {
	AnvilSelectSearchProps,
	AnvilSelectHeaderProps,
	AnvilSelectOptionsProps,
} from '../components';

export interface FocusState {
	searchFocus?: boolean;
	optionFocus?: AnvilSelectOptionsProps | null;
	actionFocus?: FooterAction;
	filterFocus?: boolean;
	triggerFocus?: boolean;
}

interface FocusAction {
	type: FocusActionType;
	payload: FocusState;
}

export interface FocusContext {
	focusState: FocusState;
	focusDispatch: React.Dispatch<FocusAction>;
}

const setFocus = (focusState: FocusState = {}): FocusState => {
	return {
		searchFocus: false,
		optionFocus: null,
		actionFocus: null,
		filterFocus: false,
		triggerFocus: false,
		...focusState,
	};
};

export const focusReducer = (state: FocusState, action: FocusAction) => {
	switch (action.type) {
		case FocusActionType.SetFocus: {
			return setFocus({ ...action.payload });
		}
		default:
			return setFocus();
	}
};

export const getInitialState = (
	search: AnvilSelectSearchProps,
	header: AnvilSelectHeaderProps,
	options: AnvilSelectOptionsProps[],
	preventFocus: boolean,
	tree?: boolean
): FocusState => {
	const searchExists = !!search || !!header?.search;
	const firstOption = options ? getFirstOption(options, tree) : null;

	if (preventFocus) {
		return {
			searchFocus: false,
			optionFocus: null,
			actionFocus: null,
			filterFocus: false,
			triggerFocus: false,
		};
	}

	return {
		searchFocus: searchExists,
		optionFocus: searchExists || !options ? null : firstOption,
		actionFocus: null,
		filterFocus: false,
		triggerFocus: !searchExists && !firstOption,
	};
};

export const FocusContext = React.createContext<FocusContext>(null);
