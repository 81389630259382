import React, { FC } from 'react';
import classnames from 'classnames';

import { DataListHeaderCellType } from './DataListHeaderCell';

export type DataListHeaderRowType =
	React.ReactElement<DataListHeaderRowProperties>;

export interface DataListHeaderRowProperties {
	className?: string;
	children?: DataListHeaderCellType[] | DataListHeaderCellType;
}

export const DataListHeaderRow: FC<DataListHeaderRowProperties> = (props) => {
	const classes: string = classnames('DataList__HeaderRow', props.className);
	return <div className={classes}>{props.children}</div>;
};
