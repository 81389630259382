import React, { FC } from 'react';
import { OptionList, OptionListProps } from '../../OptionList';
import { DateRangePickerProps } from '../DateRangePicker';
import { checkScope } from '../utils';
import { toDateFormat } from '../../../utilities/dateFormat';
import { Tooltip } from '../../Tooltip';
import { BodyText } from '../../BodyText';

export interface DateRangeOptionProps {
	value: {
		start: Date;
		end: Date;
	};
	content: React.ReactElement;
	text: string;
	disabled?: boolean;
}

export interface DateRangeOptionListProps {
	options: OptionListProps['options'];
	min: Date;
	max: Date;
	onChange: (value: DateRangePickerProps['value']) => void;
}

export const DateRangeOptionList: FC<DateRangeOptionListProps> = ({
	options,
	min,
	max,
	onChange,
}) => {
	const normalizeValueInOptions = options.map((option) => {
		return {
			...option,
			value: {
				start: toDateFormat.startOfDay(option.value.start),
				end: toDateFormat.endOfDay(option.value.end),
			},
		};
	});

	const checkOptions = normalizeValueInOptions.map(
		(option: DateRangeOptionProps) => {
			const isOutOfScope = checkScope(option.value, min, max);

			if (isOutOfScope.start || isOutOfScope.end) {
				return {
					...option,
					disabled: true,
					content: (
						<Tooltip direction="r" portal text="Out of Date Range">
							<BodyText size="small">{option.text}</BodyText>
						</Tooltip>
					),
				};
			}

			return option;
		}
	);

	const handleOnChange = (data: DateRangePickerProps['value']) => {
		onChange(data);
	};

	return (
		<React.Fragment>
			{checkOptions.length > 0 && (
				<div className="DateRangePicker__options">
					<OptionList
						options={checkOptions}
						onChange={(data) => handleOnChange(data)}
					/>
				</div>
			)}
		</React.Fragment>
	);
};
