import React, { Component } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { ToastGroup } from './ToastGroup';
import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from '../../internal/Portal';
import { tokens } from '@servicetitan/tokens/core';
import { ToastGroupContext } from './ToastGroupContext';

export interface ToastPropsStrict {
	/** Adds one or more classnames for an element */
	className?: string;

	children?: React.ReactNode;

	/** How long the Toast stays on screen, default value is 8000 (milliseconds) */
	duration?: number;

	/** Text for the primary button */
	primaryActionName?: boolean | string;

	/** Function that fires when the user clicks on the primary button/action */
	onPrimaryActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks the close icon */
	onClose?: (e?: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks on the secondary button/action */
	onSecondaryActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Makes Toast open through ReactDOM.createPortal */
	portal?: HTMLElement | boolean;

	/** Text for the secondary button */
	secondaryActionName?: boolean | string;

	/** Determines the visual appearance and icon of the toast */
	status?: 'info' | 'critical' | 'success' | 'warning';

	/** The message displayed to the user */
	title: string;
}

export interface ToastProps extends ToastPropsStrict {
	[propName: string]: any;
}

interface ToastState {
	visible: boolean;
}

export class Toast extends Component<ToastProps, ToastState> {
	static Group = ToastGroup;
	static contextType = ToastGroupContext;
	declare context: React.ContextType<typeof ToastGroupContext>;

	static defaultProps = {
		status: 'info',
		duration: 8000,
		portal: true,
	};

	private timeout: number | null = null;

	constructor(props: ToastProps) {
		super(props);

		this.state = {
			visible: true,
		};
	}

	componentDidMount() {
		if (this.props.duration < 1) return;

		this.timeout = window.setTimeout(() => {
			this.handleClose();
		}, this.props.duration);
	}

	componentWillUnmount() {
		this.clearSubscription();
	}

	handleClose = (e?: React.SyntheticEvent<HTMLElement>) => {
		const { onClose } = this.props;

		this.clearSubscription();
		this.setState({ visible: false });

		if (onClose) {
			onClose(e);
		}
	};

	hasPrimaryAction() {
		return !!(
			this.props.primaryActionName && this.props.onPrimaryActionClick
		);
	}

	hasSecondaryAction() {
		return !!(
			this.props.secondaryActionName && this.props.onSecondaryActionClick
		);
	}

	private clearSubscription() {
		if (this.timeout !== null) {
			window.clearTimeout(this.timeout);
		}
	}

	private getIconNameByStatus() {
		switch (this.props.status) {
			case 'critical':
				return 'warning';
			case 'warning':
				return 'warning';
			case 'success':
				return 'check_circle';
		}

		// if status == 'info' || undefined
		return 'info';
	}

	render() {
		const {
			primaryActionName,
			secondaryActionName,
			children,
			className,
			onPrimaryActionClick,
			onSecondaryActionClick,
			onClose,
			status,
			title,
			...props
		} = this.props;

		let portal = this.props.portal;
		const { isInToastGroup } = this.context;

		if (isInToastGroup) {
			// Ignore portal in a toast group.
			portal = undefined;
		}

		const ToastClasses = classnames('Toast', className, {
			'Toast--critical': status === 'critical',
			'Toast--info': status === 'info',
			'Toast--success': status === 'success',
			'Toast--warning': status === 'warning',
			'Toast--floating': portal,
		});

		const durationSlow: number = parseInt(tokens.durationSlow, 10) / 1000; // '300ms' -> 0.3
		const toastAnimation = {
			initial: { opacity: 0, y: 10, scale: 0.3 },
			animate: { opacity: 1, y: 0, scale: 1 },
			exit: { opacity: 0, y: 20, scale: 0.5 },
			transition: { type: 'easeInOut', duration: durationSlow },
		};

		const toast = (
			<AnimatePresence>
				<motion.div layout {...toastAnimation}>
					<div
						className={ToastClasses}
						data-anvil-component="Toast"
						{...props}
					>
						<Icon
							className="Toast__icon"
							name={this.getIconNameByStatus()}
						/>
						<div className="Toast__text">
							<div
								style={{
									paddingRight:
										onClose &&
										parseInt(tokens.spacing3, 10) * 2,
								}}
							>
								<div className="Toast__title">{title}</div>
								{onClose && (
									<Button
										aria-label="close toast"
										fill="subtle"
										className="Toast__close"
										iconName="clear"
										size="xsmall"
										onClick={this.handleClose}
									/>
								)}
							</div>
							{this.props.children !== undefined && (
								<div className="Toast__description">
									{this.props.children}
								</div>
							)}
						</div>
						{(primaryActionName || secondaryActionName) && (
							<div className="Toast__action">
								{this.hasSecondaryAction() && (
									<Button
										fill="subtle"
										small
										onClick={onSecondaryActionClick}
									>
										{secondaryActionName}
									</Button>
								)}
								{this.hasPrimaryAction() && (
									<Button
										fill="outline"
										small
										onClick={onPrimaryActionClick}
									>
										{primaryActionName}
									</Button>
								)}
							</div>
						)}
					</div>
				</motion.div>
			</AnimatePresence>
		);

		return (
			<Portal
				conditional={!!portal}
				target={typeof portal !== 'boolean' ? portal : undefined}
			>
				{toast}
			</Portal>
		);
	}
}
