import React from 'react';

export const getContent = (props: any) => {
	let content: React.ReactNode = null;

	if (props.children != null) content = props.children;
	if (props.content != null) content = props.content;

	return content;
};
