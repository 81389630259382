import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

/**
 * StackItem properties
 */
export interface StackItemProps {
	/** Align Self */
	alignSelf?:
		| 'auto'
		| 'stretch'
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'baseline';

	/** flex-basis */
	basis?: string | number;

	/** Additional classes */
	className?: string;

	/** If true, item fills maximum availiable space */
	fill?: boolean;

	/** flex-grow */
	grow?: number | 0 | 1 | 2 | 3 | 4 | 5;

	/** order */
	order?: number;

	/** flex-shrink */
	shrink?: number;
}

export const StackItem: FC<PropsWithChildren<StackItemProps>> = ({
	alignSelf = 'auto',
	basis,
	className,
	fill,
	grow,
	order,
	shrink,
	...props
}) => {
	const AlignSelfClasses: any = {
		'auto': 'align-self-auto',
		'flex-start': 'align-self-start',
		'flex-end': 'align-self-end',
		'center': 'align-self-center',
		'baseline': 'align-self-baseline',
		'stretch': 'align-self-stretch',
	};

	const StackItemClass = classnames(
		'StackItem',
		className,
		AlignSelfClasses[alignSelf],
		{
			'StackItem--fill': fill,
			[`flex-grow-${grow}`]: Number.isInteger(grow),
			[`flex-shrink-${shrink}`]: Number.isInteger(shrink),
			[`order-${order}`]: Number.isInteger(order),
		}
	);

	const StackItemStyles = {
		flexBasis: basis,
	};

	return (
		<div
			className={StackItemClass}
			style={StackItemStyles}
			data-anvil-component="StackItem"
			{...props}
		/>
	);
};
