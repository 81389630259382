import moment from 'moment';
import { isValidDate } from './utils';

export const stringToDate = (str: Date | string): Date => {
	if (isValidDate(str as any)) return new Date(str);

	if (typeof str === 'string') {
		const date = moment(str, ['h:m a', 'H:m']).toDate();
		if (isValidDate(date)) return new Date(date);
		return null;
	}

	return null;
};
