import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { Radio, RadioProps } from '../Radio';

export interface FormRadioProps<T>
	extends FormFieldPropsStrict,
		Omit<RadioProps<T>, 'error'> {
	label: React.ReactNode;
}

type FormRadioType<T = any> = FC<FormRadioProps<T>>;

export const FormRadio: FormRadioType = (props) => (
	<FormField control={Radio} {...props} />
);
