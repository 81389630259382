import React, { FC, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { Portal } from '../../internal/Portal';

/**
 * Link properties
 */
export interface SnackbarPropsStrict {
	/** Name of the action shown to users */
	actionName?: string;

	/** Adds one or more classnames for an element */
	className?: string;

	children?: React.ReactNode;

	/**
	 * How long the Snackbar stays on screen.
	 * Default is 5000.
	 */
	duration?: number;

	/** Fired when the user clicks on the action */
	onActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Fired when the user clicks the close icon */
	onClose?: (e?: React.SyntheticEvent<HTMLElement>) => void;

	/**
	 * Makes Snackbar open through ReactDOM.createPortal
	 * Default is true.
	 */
	portal?: boolean;

	/** The message displayed to the user */
	title: string;
}

export interface SnackbarProps extends SnackbarPropsStrict {
	[propName: string]: any;
}

export const Snackbar: FC<SnackbarProps> = ({
	actionName,
	className,
	duration = 5000,
	onActionClick,
	onClose,
	portal = true,
	title,
	...rest
}) => {
	const [visible, setVisible] = useState(true);
	const snackbarRef = useRef(null);

	const handleClose = React.useCallback(
		(e?: React.SyntheticEvent<HTMLElement>) => {
			setVisible(false);
			onClose?.(e);
			clearTimeout(snackbarRef?.current);
		},
		[onClose]
	);

	useEffect(() => {
		if (duration === null || duration === 0) return;
		snackbarRef.current = setTimeout(() => handleClose(), duration);
		return () => clearTimeout(snackbarRef.current);
	}, [duration, handleClose]);

	if (!visible) return null;

	const SnackbarClasses = classnames('Snackbar', className, {
		'Snackbar--floating': portal,
	});

	const snackbar = (
		<div
			className={SnackbarClasses}
			data-anvil-component="Snackbar"
			{...rest}
		>
			{title && <div className="Snackbar__title">{title}</div>}
			{actionName && onActionClick && (
				<div onClick={onActionClick} className="Snackbar__action">
					{actionName}
				</div>
			)}
			{onClose && (
				<Button
					aria-label="close snackbar"
					fill="subtle"
					className="Snackbar__close"
					iconName="clear"
					size="xsmall"
					onClick={handleClose}
				/>
			)}
		</div>
	);

	return <Portal conditional={portal}>{snackbar}</Portal>;
};
