import React, { Children } from 'react';
import { isFragment } from 'react-is';
import { isElementOfType } from './isElementOfType';

/*
 * Wraps `element` in `Component`, if it is not already an instance of
 * `Component`. If `props` is passed, those will be added as props on the
 * wrapped component. If `element` is null, the component is not wrapped.
 */
export const wrapWithComponent = (
	element: React.ReactNode,
	Component: React.ComponentType,
	props?: any
) => {
	const childArray = isFragment(element) ? element.props.children : element;

	const toArray = Children.toArray(childArray);

	const hasSections = toArray.some((child) =>
		isElementOfType(child, Component)
	);

	if (hasSections) return element;
	return <Component {...props}>{element}</Component>;
};
