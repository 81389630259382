import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Divider properties
 */
export interface DividerPropsStrict {
	/** Content position */
	alignContent?: 'start' | 'center' | 'end';

	/** Adds one or more classnames for an element */
	className?: string;

	/** Set the wrapping element's tagname */
	el?: any;

	/** Adjust thickness */
	spacing?:
		| '0'
		| 'half'
		| '1'
		| '2'
		| '3'
		| '4'
		| '5'
		| 0
		| 1
		| 2
		| 3
		| 4
		| 5;

	/** Separate content vertically */
	vertical?: boolean;
}

export interface DividerProps extends DividerPropsStrict {
	[propName: string]: any;
}

export const Divider: FC<DividerProps> = React.forwardRef(
	(
		{
			alignContent,
			children,
			className,
			el,
			spacing = 0,
			vertical,
			...props
		},
		ref
	) => {
		const DividerElement = el
			? el
			: children
			? 'div'
			: vertical
			? 'span'
			: 'hr';

		const DividerClasses = classnames('Divider', className, {
			'Divider--has-children': children && el !== 'hr',
			[`Divider--spacing-${spacing}`]: spacing,
			'Divider--vertical': vertical,
		});

		const line = <div className="Divider__line" />;

		return (
			<DividerElement
				ref={ref}
				className={DividerClasses}
				data-anvil-component="Divider"
				{...props}
			>
				{children && el !== 'hr' ? (
					<React.Fragment>
						{alignContent === 'start' ? null : line}
						<div className="Divider__content">{children}</div>
						{alignContent === 'end' ? null : line}
					</React.Fragment>
				) : null}
			</DividerElement>
		);
	}
);
