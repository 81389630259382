import React from 'react';
import classnames from 'classnames';

import { handleRef } from '../../utilities/SemanticUtils';
import { isElementOfType } from '../../utilities/isElementOfType';

import { InputAddon } from './InputAddon';
import { Icon, IconNameType, IconProps } from '../Icon';
import { Spinner } from '../Spinner';
import { Button } from '../Button';
import { FormFieldContext } from '../FormField';

import {
	splitPropsUsingArray,
	htmlInputPropsArray,
	inputPropsArray,
} from './inputProps';

const { Children, createElement, createRef, cloneElement, isValidElement } =
	React;

export type InputElement = Pick<HTMLInputElement, 'focus' | 'select'>;

/**
 * Input properties
 */
export interface InputPropsStrict
	extends Omit<
		React.InputHTMLAttributes<HTMLInputElement>,
		'size' | 'onChange' | 'tabIndex'
	> {
	/** An element type to render as (string or function). */
	el?: any;

	/** An Input can be formatted to alert the user to an action they may perform. */
	action?: React.ReactNode;

	/** An action can appear along side an Input on the right (default) or left side. */
	actionPosition?: 'right' | 'left';

	/** Additional classes. */
	className?: string;

	/** An Input field can show that it is disabled. */
	disabled?: boolean;

	/** An Input field can show the data contains errors. */
	error?: boolean;

	/** Take on the size of its container. */
	fluid?: boolean;

	/** An Input field can show a user is currently interacting with it. */
	focus?: boolean;

	/** Optional Icon to display inside the Input. */
	icon?: IconProps | string | React.ReactElement;

	/** An Icon can appear inside an Input on the right (default) or left side. */
	iconPosition?: 'right' | 'left';

	/** Shorthand for creating the HTML Input. */
	input?: React.ReactNode;

	/** Format to appear on dark backgrounds. */
	inverted?: boolean;

	/** Shortcut for large-sized input */
	large?: boolean;

	/** An Icon Input field can show that it is currently loading data. */
	loading?: boolean;

	/**
	 * Called on value change.
	 *
	 * @param {ChangeEvent} event - React's original SyntheticEvent.
	 * @param {object} data - All props and a proposed value.
	 */
	onChange?: (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => void;

	/** An Input can vary in size. */
	size?: 'xsmall' | 'small' | 'medium' | 'large';

	/** Optional Label to display along side the Input. */
	shortLabel?: React.ReactNode;

	/** A Label can appear outside an Input on the left (default) or right side. */
	shortLabelPosition?: 'left' | 'right';

	/** Shortcut for small-sized input */
	small?: boolean;

	/** An Input can receive focus. */
	tabIndex?: number | string;

	/** Transparent Input has no background. */
	transparent?: boolean;

	/** The HTML input type. */
	type?: string;

	/** Shortcut for x-small-sized input */
	xsmall?: boolean;

	/** forwarded input ref */
	inputRef?: React.RefObject<HTMLInputElement>;
}

export interface InputProps extends InputPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface InputOnChangeData extends InputProps {
	value: string;
}

export class Input extends React.Component<InputProps> {
	static defaultProps: Partial<InputProps> = {
		actionPosition: 'right',
		el: 'div',
		iconPosition: 'right',
		shortLabelPosition: 'left',
		size: 'medium',
		type: 'text',
	};

	static Addon = InputAddon;
	static contextType = FormFieldContext;
	context!: React.ContextType<typeof FormFieldContext>;

	inputRef: React.RefObject<HTMLInputElement> =
		this.props.inputRef || createRef();

	renderAction = () => {
		const { action, disabled } = this.props;

		if (action == null) return null;
		if (typeof action === 'string') {
			return (
				<Button disabled={disabled} size={this.computeSize().inputSize}>
					{action}
				</Button>
			);
		}
		if (action != null && isValidElement(action)) {
			return cloneElement(action as any, {
				disabled,
				size: this.computeSize().inputSize,
			});
		}
	};

	renderIcon = () => {
		const { icon } = this.props;

		// Icon passed as string
		if (typeof icon === 'string') {
			return (
				<InputAddon onClick={() => this.focus()}>
					<Icon
						name={icon as IconNameType}
						size={this.computeSize().iconSize}
					/>
				</InputAddon>
			);
		}

		// Icon passed as React element
		if (icon != null && isValidElement(icon)) {
			const sizedIcon = cloneElement(icon as any, {
				size: this.computeSize().iconSize,
			});
			const hasOnClick =
				typeof (icon as any)?.props?.onClick !== 'undefined';

			return (
				<InputAddon onClick={() => !hasOnClick && this.focus()}>
					{sizedIcon}
				</InputAddon>
			);
		}

		// Icon passed as Icon.props object
		if (icon != null && !isValidElement(icon)) {
			const hasOnClick = typeof (icon as any)?.onClick !== 'undefined';

			return (
				<InputAddon onClick={() => !hasOnClick && this.focus()}>
					<Icon {...icon} />
				</InputAddon>
			);
		}

		return null;
	};

	// Creating an input element
	renderInput = (htmlInputProps: any) => {
		const { input, type } = this.props;

		// Handling case of duplicate ID's for the same FormField label
		const isLabelAssigned = this.context.inputRef;
		const labelFor = this.context.labelFor;
		if (!isLabelAssigned) {
			this.context.inputRef = this.inputRef;
		}

		if (input != null && isValidElement(input)) {
			return cloneElement(input as any, {
				...htmlInputProps,
				id: this.props.id || (!isLabelAssigned ? labelFor : undefined),
			});
		}

		return createElement('input', {
			...htmlInputProps,
			type,
			id: this.props.id || (!isLabelAssigned ? labelFor : undefined),
		});
	};

	renderShortLabel = () => {
		const { shortLabel } = this.props;

		if (typeof shortLabel === 'string' && shortLabel !== '') {
			return (
				<InputAddon onClick={() => this.focus()}>
					{shortLabel}
				</InputAddon>
			);
		}

		// Label passed as React element
		if (shortLabel != null && isValidElement(shortLabel)) {
			const iconSize =
				(shortLabel as any).props.size || this.computeSize().iconSize;

			const hasOnClick =
				typeof (shortLabel.props as any)?.onClick !== 'undefined';

			return (
				<InputAddon onClick={() => !hasOnClick && this.focus()}>
					{cloneElement(shortLabel as any, { size: iconSize })}
				</InputAddon>
			);
		}

		return null;
	};

	// Handling with size-related shortcut props, like xsmall="true"...
	computeSize = (): { inputSize: InputProps['size']; iconSize: string } => {
		let inputSize = this.props.size;

		if (this.props.xsmall) inputSize = 'xsmall';
		if (this.props.small) inputSize = 'small';
		if (this.props.large) inputSize = 'large';

		const iconSize = {
			xsmall: '16px',
			small: '20px',
			medium: '20px',
			large: '24px',
		}[inputSize];

		return { inputSize, iconSize };
	};

	computeTabIndex = () => {
		if (this.props.tabIndex) return this.props.tabIndex;
		if (this.props.disabled) return -1;
	};

	focus = () => this.inputRef.current.focus();

	select = () => this.inputRef.current.select();

	handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e, { ...this.props, value: e.target.value });
		}
	};

	handleChildOverrides = (child: any, defaultProps: any) => ({
		...defaultProps,
		...child.props,
		ref: (c: any) => {
			handleRef(child.ref, c);
			(this.inputRef.current as any) = c;
		},
	});

	render() {
		const {
			actionPosition,
			children,
			className,
			disabled,
			el: InputElement,
			error,
			fluid,
			focus,
			iconPosition,
			inverted,
			loading,
			shortLabelPosition,
			transparent,
			readOnly,
			readonly,
		} = this.props;
		const { inputRef, ...inputProps } = this.props;

		if ('readonly' in inputProps && !('readOnly' in inputProps)) {
			inputProps.readOnly = inputProps.readonly;
		}

		const InputClasses = classnames('Input', className, {
			[`Input--${this.computeSize().inputSize}`]:
				this.computeSize().inputSize,
			'Input--disabled': disabled,
			'Input--error': error,
			'Input--fluid': fluid,
			'Input--focus': focus,
			'Input--inverted': inverted,
			'Input--loading': loading,
			'Input--transparent': transparent,
			'Input--readonly': readOnly || readonly,
		});

		const [strictHtmlInputProps] = splitPropsUsingArray(
			inputProps,
			htmlInputPropsArray
		);
		const htmlInputProps = {
			...strictHtmlInputProps,
			tabIndex: this.computeTabIndex(),
			onChange: this.handleChange,
			ref: this.inputRef,
		};
		const [, rest] = splitPropsUsingArray(inputProps, [
			...htmlInputPropsArray,
			...inputPropsArray,
		]);

		// Case of children passed directly into Input
		if (Children.count(children) !== 0) {
			const childElements = Children.toArray(children).map((child, k) => {
				if (isElementOfType(child, Button)) {
					return child;
				}

				if ((child as any).type === 'input') {
					// Passing htmlInputProps to the <input />
					return cloneElement(
						child as any,
						this.handleChildOverrides(child, htmlInputProps)
					);
				}

				return <Input.Addon key={k}>{child}</Input.Addon>;
			});

			return (
				<InputElement
					{...rest}
					className={classnames(InputClasses, 'Input--with-children')}
				>
					<div className="Input__InnerWrapper">{childElements}</div>
				</InputElement>
			);
		}

		// Render with shorthands
		return (
			<InputElement
				className={InputClasses}
				data-anvil-component="Input"
				{...rest}
			>
				{actionPosition === 'left' && this.renderAction()}
				<div className="Input__InnerWrapper">
					{iconPosition === 'left' && this.renderIcon()}
					{shortLabelPosition === 'left' && this.renderShortLabel()}
					{this.renderInput(htmlInputProps)}
					{loading && (
						<InputAddon onClick={() => this.focus()}>
							<Spinner />
						</InputAddon>
					)}
					{shortLabelPosition === 'right' && this.renderShortLabel()}
					{iconPosition === 'right' && this.renderIcon()}
				</div>
				{actionPosition === 'right' && this.renderAction()}
			</InputElement>
		);
	}
}
