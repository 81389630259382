import React, { FC } from 'react';
import classnames from 'classnames';

import {
	ModalWindow,
	ModalWindowProps,
	ModalHeader,
	ModalHeaderProps,
	ModalFooter,
	ModalContent,
	ModalSizes,
} from '../Modal/components';

import { UseFocusTrapProps } from '../../hooks';
import { Button } from '../Button';
import { Stack } from '../Stack';

export interface DialogProps extends ModalHeaderProps, ModalWindowProps {
	/** Action buttons layout direction */
	actionsLayout?: 'row' | 'row-reverse' | 'column' | 'column-reverse';

	children?: React.ReactNode;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Negative styling (red) for primary action */
	negative?: boolean;

	/** Function that fires when the user clicks on the primary button/action */
	onPrimaryActionClick: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Function that fires when the user clicks on the secondary button/action */
	onSecondaryActionClick?: (e: React.SyntheticEvent<HTMLElement>) => void;

	/** Whether the dialog is open or not */
	open?: boolean;

	/** Render in a React Portal */
	portal?: boolean;

	/** Text for the primary button */
	primaryActionName: string;

	/** Text for the secondary button */
	secondaryActionName?: string;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;
}

interface Dialog extends FC<DialogProps> {
	/** Subcomponents */
	Window: typeof ModalWindow;
	Header: typeof ModalHeader;
	Content: typeof ModalContent;
	Footer: typeof ModalFooter;
}

export const Dialog: Dialog = ({
	open = false,
	children,
	className,
	onClose,
	title,
	actionsLayout,
	negative,
	onPrimaryActionClick,
	onSecondaryActionClick,
	portal = true,
	primaryActionName,
	secondaryActionName,
	focusTrapOptions,
	...rest
}) => {
	const DialogClasses = classnames('Dialog', className);

	return (
		<ModalWindow
			className={DialogClasses}
			onClose={onClose}
			size={ModalSizes.XS}
			focusTrapOptions={focusTrapOptions}
			open={open}
			portal={portal}
			data-anvil-component="Dialog"
			{...rest}
		>
			<ModalHeader title={title} className="Dialog__header" />
			<ModalContent className="Dialog__content">{children}</ModalContent>
			<ModalFooter className="Dialog__footer">
				<DialogActions
					actionsLayout={actionsLayout}
					negative={negative}
					onPrimaryActionClick={onPrimaryActionClick}
					onSecondaryActionClick={onSecondaryActionClick}
					primaryActionName={primaryActionName}
					secondaryActionName={secondaryActionName}
				/>
			</ModalFooter>
		</ModalWindow>
	);
};

const DialogActions = (props: DialogProps) => {
	const {
		actionsLayout = 'row',
		negative,
		onPrimaryActionClick,
		onSecondaryActionClick,
		primaryActionName,
		secondaryActionName,
	} = props;

	const hasPrimaryAction = !!(primaryActionName && onPrimaryActionClick);
	const hasSecondaryAction = !!(
		secondaryActionName && onSecondaryActionClick
	);

	const [primaryRect, primaryRef] = useClientRect();
	const [secondaryRect, secondaryRef] = useClientRect();

	const checkActionsWrapped =
		actionsLayout.includes('row') &&
		primaryRect !== null &&
		secondaryRect !== null &&
		Math.round(primaryRect.top - secondaryRect.top) !== 0; // Check whether action buttons placed on different lines

	const columnedButtons =
		checkActionsWrapped || actionsLayout.includes('column');

	return hasSecondaryAction && hasPrimaryAction ? (
		<Stack
			alignItems={columnedButtons ? 'stretch' : null}
			className={columnedButtons ? 'w-100' : null}
			direction={checkActionsWrapped ? 'column-reverse' : actionsLayout}
			spacing={columnedButtons ? 1 : 2}
			wrap="wrap"
		>
			<span ref={secondaryRef}>
				<Button full small onClick={onSecondaryActionClick}>
					{secondaryActionName}
				</Button>
			</span>
			<span ref={primaryRef}>
				<Button
					full
					primary
					small
					negative={negative}
					onClick={onPrimaryActionClick}
				>
					{primaryActionName}
				</Button>
			</span>
		</Stack>
	) : (
		<Button primary small onClick={onPrimaryActionClick}>
			{primaryActionName}
		</Button>
	);
};

Dialog.Window = ModalWindow;
Dialog.Header = ModalHeader;
Dialog.Content = ModalContent;
Dialog.Footer = ModalFooter;

/** https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node */
const useClientRect = () => {
	const [rect, setRect] = React.useState(null);
	const ref = React.useCallback((node: HTMLElement) => {
		if (node !== null) {
			setRect(node.getBoundingClientRect());
		}
	}, []);
	return [rect, ref];
};
