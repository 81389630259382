import { toTimeFormat } from './toTimeFormat';
import { isValidInput, spaceless, colonless, inputCaseName } from './utils';
import { parseUserInput, getClosestIndexes } from './getClosestOption';
import { stringToDate } from './stringToDate';

import { TimePickerOptionProps } from '../components/TimePickerOptionList';

// Filter options based on user's input
export const filterOptions = (
	options: TimePickerOptionProps[],
	userInput = ''
): TimePickerOptionProps[] => {
	if (!isValidInput(userInput)) {
		return [];
	}

	const input = spaceless(userInput); // Ignore spaces
	const optionsHasLeadingZero = hasLeadingZero(options);

	switch (inputCaseName(input)) {
		case 'empty': // Empty string
			return options;
		case '1-digit': // Match hours only
		case '2-digit': // Match hours only
			return options.filter((o) =>
				matchHours(o, input, optionsHasLeadingZero)
			);
		case '3-digit':
		case '4-digit':
		case 'many-digits': // Cutting extra digits off...
			return options.filter((o) => matchDigits(o, input.substring(0, 4)));
		case 'colon':
			return options.filter((o) => matchColon(o, input));
		case 'phrase': // No colon, has letters
		default:
			return options.filter((o) => matchPhrase(o, input));
	}
};

// Filter options in case autoRounding enabled, when two nearest options must be displayed in a dropdown
export const filterOptionsWithRounding = (
	options: TimePickerOptionProps[],
	userInput: string
): TimePickerOptionProps[] => {
	const filtered = filterOptions(options, userInput);
	const closestValue = parseUserInput(userInput);
	const indexes = closestValue
		? getClosestIndexes(options, stringToDate(closestValue.join()))
		: null;

	if (indexes && filtered.length <= 1) {
		switch (indexes.length) {
			case 1:
				return [options[indexes.sort()[0]]];
			case 2:
				return [options[indexes.sort()[0]], options[indexes.sort()[1]]];
			default:
				return [];
		}
	}

	return filtered;
};

const matchHours = (
	item: TimePickerOptionProps,
	input: string,
	optionsHasLeadingZero: boolean
): boolean => {
	const date = item.value;
	const hours = toTimeFormat.hhmmxm(date).substring(0, 2);
	const hours24 = toTimeFormat.hhmm(date).substring(0, 2);

	// 1-digit case (leading zero logic)
	if (input.length === 1) {
		if (optionsHasLeadingZero) {
			return hours === '0' + input || hours24 === '0' + input;
		}

		// Edge vase of input "1" to keep showing "10:00 AM" in dropdown when options has no leading zero value (e.g. "08:00 AM")
		return hours.startsWith(input) || hours24.startsWith(input);
	}

	// 2-digit case
	const hoursAndMinutes = toTimeFormat.hhmmxm(date).replace(':', '');
	const numericInput = parseInt(input, 10);

	return (
		hours === input ||
		hours24 === input ||
		(numericInput > 23 && hoursAndMinutes.startsWith('0' + input)) // Case of impossible hour entry "35" to keep showing "03:50" in dropdown
	);
};

const matchDigits = (item: TimePickerOptionProps, input: string): boolean => {
	const date = item.value;
	const hours = toTimeFormat.hhmmxm(date).substring(0, 2);
	const hours24 = toTimeFormat.hhmm(date).substring(0, 2);
	const minutes = toTimeFormat.hhmm(date).substring(3, 5);

	const time = hours + minutes;
	const time24 = hours24 + minutes;

	// 3-digit case
	if (input.length === 3) {
		return (
			time.includes('0' + input) ||
			time24.includes('0' + input) ||
			time.substring(0, 3).includes(input) ||
			time24.substring(0, 3).includes(input)
		);
	}

	// 4-digit case
	return time.includes(input) || time24.includes(input);
};

const matchColon = (item: TimePickerOptionProps, input: string): boolean => {
	if (!isValidInput(input)) {
		return null;
	}

	const date = item.value;
	const hours = toTimeFormat.hhmmxm(date).substring(0, 2);
	const hours24 = toTimeFormat.hhmm(date).substring(0, 2);
	const minutes = toTimeFormat.hhmm(date).substring(3, 5);
	const ampm = toTimeFormat.hhmmxm(date).substring(6, 8)?.toUpperCase();

	const parts = spaceless(input)?.toUpperCase()?.split(':');
	const strHours = parts[0];
	const strMinutes = parts[1].replace(/[^\d]+/g, ''); // Keep digits only
	const strAmpm = parts[1].replace(/[^a-zA-Z]+/g, ''); // Keep letters only

	// Excluding case "12:am30"
	if (parts[1] !== strMinutes + strAmpm) {
		return false;
	}

	const hoursFound =
		hours === strHours || hours24 === strHours || hours === '0' + strHours;

	const minutesFound =
		minutes === strMinutes ||
		strMinutes === '' ||
		minutes === strMinutes + '0';

	const ampmFound =
		ampm.substring(0, 1) === strAmpm.substring(0, 1) || strAmpm === '';

	return hoursFound && minutesFound && ampmFound;
};

const matchPhrase = (item: TimePickerOptionProps, input: string): boolean => {
	const timeText = colonless(spaceless(item?.text?.toUpperCase())); // Ignore spaces and case

	const timeDigits = timeText.substring(0, 4);
	const timeAmpm = timeText.substring(4, 6);

	const str = input?.toUpperCase();

	const digits = str.replace(/[^\d]+/g, ''); // Keep digits only

	const firstLetter = str
		.replace(/[^a-zA-Z]+/g, '') // Keep letters only
		.substring(0, 1);

	return timeDigits.includes(digits) && timeAmpm?.startsWith(firstLetter);
};

const hasLeadingZero = (options: TimePickerOptionProps[]) => {
	return options.some((item) => {
		const date = item.value;
		const hoursFirstDigit = toTimeFormat.hhmmxm(date).substring(0, 1);

		return hoursFirstDigit === '0';
	});
};
