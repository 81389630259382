import React, { FC } from 'react';
import classnames from 'classnames';
import { JustifyProps } from '../../utilities/justify-props';
import { wrapChildren } from '../../utilities/wrapChildren';
import { LayoutSection } from './LayoutSection';

export interface LayoutPropsStrict {
	/** Auto-wraps each child with a <Layout.Section> */
	autoSections?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	direction?: 'left' | 'right';

	/** Sets justify alignment on columns in the layout */
	justifyContent?: JustifyProps;

	/** Types of columns for the layout component */
	type?: '1Col' | '2Col' | '3Col' | '4Col' | 'support' | 'island';

	/** The margins between the page and the parent content */
	spacing?: 'none' | 'compact' | 'relaxed';
}

export interface LayoutProps extends LayoutPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface Layout extends FC<LayoutProps> {
	/** Subcomponents */
	Section: typeof LayoutSection;
}

export const Layout: Layout = ({
	autoSections = true,
	children,
	className,
	direction = 'left',
	justifyContent,
	spacing = 'compact',
	type = '1Col',
	...rest
}) => {
	const getChildren = () => {
		if (!autoSections) return children;

		const parentProps: any = {};
		const wrappedChildren = wrapChildren(
			children,
			LayoutSection,
			parentProps
		);

		return wrappedChildren;
	};

	const LayoutClasses = classnames('Layout', className, {
		'Layout--1Col': type === '1Col',
		'Layout--2Col': type === '2Col',
		'Layout--3Col': type === '3Col',
		'Layout--4Col': type === '4Col',
		'Layout--island': type === 'island',
		'Layout--leftSupport': type === 'support' && direction === 'left',
		'Layout--rightSupport': type === 'support' && direction === 'right',
		'Layout--compact': spacing === 'compact',
		'Layout--relaxed': spacing === 'relaxed',
		'Layout--noGutter': spacing === 'none',
		[`Layout--justify-${justifyContent}`]: justifyContent,
	});

	return (
		<div className={LayoutClasses} data-anvil-component="Layout" {...rest}>
			{getChildren()}
		</div>
	);
};

Layout.Section = LayoutSection;
