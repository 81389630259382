import React, { FC, forwardRef } from 'react';
import classnames from 'classnames';
import { AnvilSelectOptionsProps } from '../AnvilSelect';
import { Popover, PopoverProps } from '../Popover';
import { ButtonGroup } from '../ButtonGroup';
import { TagGroup } from '../TagGroup';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Tag } from '../Tag';
import { keys } from '../../utilities/keyCodes';
import { PopperProps } from '../../internal';

export interface InlineEditPropsStrict {
	/** Сontrol component to be shown on edit */
	control?: (controlProps: ControlProps, actionProps: ActionProps) => any;

	/** Custom content for the read mode, this will override value output */
	content?: any;

	/** Value to be displayed on read mode */
	value?: valueObject | string | number;

	/** Decoration on displayed value */
	decoration?: InlineEditDecorationProps;

	/** If true, read content and control will show error state */
	error?: boolean;

	/** Callback fired on save */
	onSave?: (data?: any) => void;

	/** Callback fired on cancel */
	onCancel?: () => void;

	/** Callback fired on click */
	onClick?: (event?: React.MouseEvent<HTMLElement>) => void;

	/** Callback fired when a user changes the value */
	onChange?: (event?: React.ChangeEvent<HTMLElement>, data?: any) => void;

	/** Position of the save and cancel action buttons. */
	actionDirection?: PopoverProps['direction'];

	/** Layout option of the save and cancel action buttons. */
	actionLayout?: 'none' | 'inline' | 'floating';

	/** Adds one or more classnames for an element */
	className?: string;

	/** If true, control will be displayed */
	mode?: 'edit' | 'read' | string;

	/** width of the component */
	width?: 'fluid' | number | string;

	/** If true, read content will show with the control during editing */
	showContentOnEdit?: boolean;

	/** ID of the component */
	id?: string;

	/** If true, control component will have autoFocus applied when edit mode */
	autoFocusControl?: boolean;

	/** Custom placeholder text */
	placeholder?: string;

	/** Props to be passed to the portaled div */
	portalDataAttributes?: PopperProps['portalDataAttributes'];
}

interface valueObject extends AnvilSelectOptionsProps {
	title?: string;
}

interface ActionProps {
	save?: (event: React.ChangeEvent<HTMLElement>) => void;
	cancel?: (event: React.ChangeEvent<HTMLElement>) => void;
	open?: boolean;
}

interface ControlProps {
	id?: string;
	className?: string;
	tabIndex?: number;
	autoFocus?: boolean;
	ref?: React.Ref<any> & React.LegacyRef<any> & React.RefObject<any>;
	error?: boolean;
	value?: any;
	onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
	onChange?: (event: React.ChangeEvent<HTMLElement>, data: any) => void;
}

export interface InlineEditProps extends InlineEditPropsStrict {
	/** Unstrict Props */
	[propName: string]: any;
}

export interface InlineEditDecorationProps {
	color?: boolean;
	underline?: boolean;
	icon?: boolean;
}

const defaultDecoration = { icon: true, underline: true, color: true };

export const InlineEdit: FC<InlineEditProps> = forwardRef(
	(
		{
			control: Control,
			decoration = defaultDecoration,
			onSave,
			onCancel,
			onChange,
			onClick,
			error: errorState = false,
			actionDirection = 'rb',
			actionLayout = 'inline',
			className,
			style,
			mode = 'read',
			value,
			width,
			showContentOnEdit,
			content: Content,
			autoFocusControl = true,
			id,
			placeholder = 'Enter value',
			portalDataAttributes,
			...props
		},
		ref: React.RefObject<HTMLSpanElement>
	) => {
		const normalizeValue = (value: InlineEditPropsStrict['value']) =>
			typeof value === 'string' || typeof value === 'number'
				? String(value)
				: value;

		const [newValue, setNewValue] = React.useState(normalizeValue(value));
		const [editing, setEditing] = React.useState(mode === 'edit');
		const [fontsize, setFontsize] = React.useState(16);
		const [isClosed, setIsClosed] = React.useState(false);
		const controlRef = React.useRef(null);
		const saveRef = React.useRef(null);
		const cancelRef = React.useRef(null);
		const textRef = React.useRef(null);

		const getDecoration = () => ({ ...defaultDecoration, ...decoration });

		React.useEffect(() => {
			setNewValue(normalizeValue(value));
		}, [value]);

		React.useEffect(() => {
			if (textRef.current) {
				setFontsize(
					Number(
						window
							.getComputedStyle(textRef.current)
							.getPropertyValue('font-size')
							.replace('px', '')
					)
				);
			}
		}, []);

		const handleOpen = () => {
			onClick?.();
			setEditing(true);
			setIsClosed(false);
		};

		const handleClose = () => {
			setEditing((prevState) => {
				prevState === true && setIsClosed(true);
				return false;
			});
		};

		React.useEffect(() => {
			isClosed && textRef?.current?.focus();
		}, [isClosed]);

		React.useEffect(() => {
			if (mode === 'edit') setEditing(true);
			if (mode === 'read') {
				setEditing(false);
				setNewValue(normalizeValue(value));
			}
		}, [mode, value]);

		const InlineEditKeyDown = (e: React.KeyboardEvent) => {
			const isSpacePressed = e.key === keys.space;
			const isEnterPressed = e.key === keys.enter;
			if (isSpacePressed || isEnterPressed) {
				handleOpen();
				e.preventDefault();
			}
		};

		const handleCancel = () => {
			onCancel?.();
			setNewValue(normalizeValue(value));
			handleClose();
		};

		const handleSave = () => {
			if (errorState) return; // if error is true, do not save
			onSave?.({ ...controlProps, value: newValue });
			handleClose();
		};

		const handleOnChange = (e: React.ChangeEvent & any, data: any) => {
			onChange?.(e, data);
			if (
				e?.target instanceof HTMLInputElement ||
				e?.target instanceof HTMLTextAreaElement
			) {
				setNewValue(data.value);
			} else {
				setNewValue(e);
			}
		};

		const escapeOnKeyDown = (e: React.KeyboardEvent) => {
			const isESCPressed = e.key === keys.esc;
			if (isESCPressed) {
				if (controlRef.current.state?.openState === true) {
					return e;
				}
				handleCancel();
				e.preventDefault();
			}
		};

		const keyOnCancel = (e: React.KeyboardEvent) => {
			escapeOnKeyDown(e);
			const isTabPressed = e.key === keys.tab && actionLayout !== 'none';
			if (!isTabPressed) return;
			e.preventDefault();
			if (!e.shiftKey) {
				/* tab */
				controlRef.current.focus();
			} else {
				/* shift + tab */
				saveRef.current.focus();
			}
		};

		const keyOnControl = (e: React.KeyboardEvent) => {
			escapeOnKeyDown(e);
			const isTabPressed = e.key === keys.tab && actionLayout !== 'none';
			if (!isTabPressed) return;
			e.preventDefault();
			if (!e.shiftKey) {
				/* tab */ saveRef.current.focus();
			} else {
				/* shift + tab */
				cancelRef.current.focus();
			}
		};

		const keyOnSave = (e: React.KeyboardEvent) => {
			escapeOnKeyDown(e);
			const isTabPressed = e.key === keys.tab && actionLayout !== 'none';
			if (!isTabPressed) return;
			e.preventDefault();
			if (!e.shiftKey) {
				/* tab */ cancelRef.current.focus();
			} else {
				/* shift + tab */
				controlRef.current.focus();
			}
		};

		const left = ['l', 'lb', 'lt'];
		const bottom = ['b', 'br', 'bl'];
		const top = ['t', 'tr', 'tl'];

		const InlineEditClasses = classnames('InlineEdit', className, {
			'flex-column':
				bottom.includes(actionDirection) && actionLayout !== 'floating',
			'flex-column-reverse':
				top.includes(actionDirection) && actionLayout !== 'floating',
			'flex-row-reverse':
				left.includes(actionDirection) && actionLayout !== 'floating',
			'InlineEdit--fluid': width === 'fluid',
		});

		const ContentClasses = classnames('InlineEdit__content', {
			'InlineEdit__content--textcolor': !Content && getDecoration().color,
			'InlineEdit__content--underline':
				!Content && getDecoration().underline,
			'InlineEdit__content--icon': getDecoration().icon,
			'InlineEdit__content--error': errorState,
		});

		const ActionClasses = classnames(`InlineEdit__action`, {
			[`InlineEdit__action--${actionDirection}`]:
				actionLayout !== 'floating',
			'InlineEdit__action--floating': actionLayout === 'floating',
		});

		const EditIconClasses = classnames(
			'InlineEdit__editIcon c-neutral-80 m-l-half',
			{
				'InlineEdit__editIcon--small': fontsize <= 16,
				'InlineEdit__editIcon--medium': fontsize <= 26 && fontsize > 16,
				'InlineEdit__editIcon--large': fontsize > 26,
			}
		);

		const tagArray = (valueArr: valueObject[]) => {
			return valueArr.map((singleValue: any, index: number) => {
				const tagText = singleValue.text
					? singleValue.text
					: typeof singleValue.content === 'string'
					? singleValue.content
					: String(singleValue.value);

				return (
					<Tag
						key={index}
						title={singleValue.title ?? tagText}
						color={singleValue.color}
					>
						{tagText}
					</Tag>
				);
			});
		};

		const getContent = () => {
			const value = newValue;
			if (Content !== undefined) {
				return Content();
			}
			if (typeof value === 'string') {
				return value;
			}
			if (Array.isArray(value)) {
				if (value.length <= 3) {
					return <TagGroup>{tagArray(value.slice(0, 3))}</TagGroup>;
				}
				const truncated = value.slice(3);
				const title = truncated.reduce((acc, curr, i) => {
					return acc.concat(`${i === 0 ? '' : ', '}`, curr.text);
				}, '');
				const truncatedData = {
					text: `+${truncated.length}`,
					title,
				};
				const combinedArr = value.slice(0, 3).concat(truncatedData);
				return <TagGroup>{tagArray(combinedArr)}</TagGroup>;
			}

			return String(value?.text ?? '');
		};

		const ValueClasses = classnames('InlineEdit__value', {
			'InlineEdit__value--placeholder':
				(newValue === undefined || newValue.length === 0) &&
				Content === undefined,
		});

		const readComponent = (
			<span
				className={ContentClasses}
				ref={textRef}
				onClick={handleOpen}
				tabIndex={0}
				onKeyDown={InlineEditKeyDown}
				{...props}
			>
				<span className={ValueClasses}>
					{(newValue === undefined || newValue.length === 0) &&
					Content === undefined
						? placeholder
						: getContent()}
				</span>
				<Icon name="edit" className={EditIconClasses} />
			</span>
		);

		const controlProps = {
			id,
			className: `InlineEdit__control`,
			ref: controlRef,
			error: errorState,
			autoFocus: autoFocusControl,
			onKeyDown: keyOnControl,
			onChange: handleOnChange,
			placeholder,
			trigger: { placeholder },
			value: newValue,
		};

		const internalCompProps = {
			save: handleSave,
			cancel: handleCancel,
			open: editing,
		};

		const actions = (
			<ButtonGroup attached className={ActionClasses}>
				<Button
					aria-label="save changes"
					small
					ref={saveRef}
					className="InlineEdit__save"
					primary
					disabled={errorState}
					onClick={handleSave}
					onKeyDown={keyOnSave}
					type="submit"
					iconName="done"
				/>
				<Button
					aria-label="discard changes"
					small
					ref={cancelRef}
					className="InlineEdit__cancel"
					type="reset"
					iconName="clear"
					onClick={handleCancel}
					onKeyDown={keyOnCancel}
				/>
			</ButtonGroup>
		);

		const updateStyle = {
			maxWidth:
				width !== undefined && typeof width === 'string'
					? `${width}`
					: width,
			...style,
		};

		return (
			<span
				className={InlineEditClasses}
				style={updateStyle}
				ref={ref}
				data-anvil-component="InlineEdit"
			>
				{editing ? (
					actionLayout === 'floating' ? (
						<span {...props}>
							<Popover
								trigger={Control(
									controlProps,
									internalCompProps
								)}
								portal
								direction={actionDirection}
								contentNoPadding
								padding={null}
								width="auto"
								className="InlineEdit__floating__body"
								open={editing}
								offset={{
									distance: 8,
								}}
								el={width === 'fluid' ? 'div' : 'span'}
								portalDataAttributes={portalDataAttributes}
							>
								{actions}
							</Popover>
						</span>
					) : (
						<React.Fragment>
							{showContentOnEdit && readComponent}
							{Control(controlProps, internalCompProps)}
							{actionLayout !== 'none' && actions}
						</React.Fragment>
					)
				) : (
					readComponent
				)}
			</span>
		);
	}
);
