export interface IMaskPartConfig {
	maskChar: string;
	allowedChars: RegExp;
}

const defaultConfig: IMaskPartConfig = {
	maskChar: '_',
	allowedChars: /^[\d]$/,
};

const round = (year: number, to = 100) => year - (year % to);

/**
 * Find boundaries of mask field in specified cursor position.
 *
 * Returns array with start and end index of a field.
 * End index points next character after field (useful for `String.substring`).
 * If values are the same most probably passed cursor position is not a correct value (eg. separator).
 *
 * @param mask
 * @param cursorPosition
 * @param config
 * @return [number, number]
 */
export const findMaskFieldBoundariesAtPosition = (
	mask: string,
	cursorPosition = 0,
	config: Partial<IMaskPartConfig> = {}
): [number, number] => {
	const conf: IMaskPartConfig = Object.assign(defaultConfig, config);

	const isAllowedChar = (char: string) =>
		conf.allowedChars.test(char) || char === conf.maskChar;

	let startIndex: number = cursorPosition;
	let endIndex: number = cursorPosition;

	if (isAllowedChar(mask[cursorPosition])) {
		while (
			mask[startIndex - 1] &&
			isAllowedChar(mask[startIndex - 1]) &&
			startIndex - 1 >= 0
		) {
			startIndex--;
		}

		while (
			mask[endIndex + 1] &&
			isAllowedChar(mask[endIndex + 1]) &&
			endIndex + 1 < mask.length
		) {
			endIndex++;
		}
	} else {
		return [cursorPosition, cursorPosition];
	}

	return [startIndex, endIndex + 1];
};

export const getValueAtIndex = (
	mask: string,
	index: number,
	config: Partial<IMaskPartConfig> = {}
): string | null => {
	const [startIndex, endIndex] = findMaskFieldBoundariesAtPosition(
		mask,
		index,
		config
	);

	if (startIndex === endIndex) {
		return null;
	}

	return mask
		.substring(startIndex, endIndex)
		.split('')
		.filter((c) => c !== '_')
		.join('');
};

export function setValueAtIndex(
	mask: string,
	index: number,
	value: string | number,
	config: Partial<IMaskPartConfig> = {}
): string {
	const conf: IMaskPartConfig = Object.assign(defaultConfig, config);
	const [start, end] = findMaskFieldBoundariesAtPosition(mask, index, config);

	if (start === end) {
		return mask;
	}

	const valueToSet = value === '' ? conf.maskChar.repeat(end - start) : value;
	const valueWithZeros = String(valueToSet).padStart(end - start, '0');

	return mask.substring(0, start) + valueWithZeros + mask.substring(end);
}

export function expandValueAtIndex(
	mask: string,
	index: number,
	config: Partial<IMaskPartConfig> = {}
): string {
	return setValueAtIndex(
		mask,
		index,
		getValueAtIndex(mask, index, config),
		config
	);
}

export const expandMaskValue = (
	mask: string,
	cursorPosition: number
): string => {
	let value: string = mask;

	const parsedValue = parseInt(getValueAtIndex(mask, cursorPosition), 10);
	if (!parsedValue) return value;

	const [startIndex, endIndex] = findMaskFieldBoundariesAtPosition(
		mask,
		cursorPosition
	);

	const desiredMaskLength = endIndex - startIndex;

	if (desiredMaskLength === 4) {
		// End early if full year already exists
		if (parsedValue.toString().length === 4) return value;

		/**
		 * auto fills year if value is between the beginning of century
		 * and 5 years ahead
		 *
		 * @todo: Change year calculations to use current year for all non-existant values
		 * E.g. 7 -> 2027, 20 -> 2020, 181 -> 2181, assuming current year is 2021
		 */

		const currentYear = new Date().getFullYear();

		const beginningOfCentury = round(currentYear, 100);

		const yearsFromBeginningOfCentury =
			currentYear - beginningOfCentury + 5;

		let year = parsedValue + beginningOfCentury;

		if (parsedValue > yearsFromBeginningOfCentury) {
			year -= parsedValue >= 100 ? 1000 : 100;
		}

		value = setValueAtIndex(mask, cursorPosition, year);
	} else {
		value = expandValueAtIndex(mask, cursorPosition);
	}

	return value;
};
