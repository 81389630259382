import React, { FC } from 'react';
import { FormField, FormFieldPropsStrict } from '../FormField';
import { ToggleSwitch, ToggleSwitchProps } from '../ToggleSwitch';

export interface FormToggleSwitchProps
	extends FormFieldPropsStrict,
		ToggleSwitchProps {
	label: React.ReactNode;
}

export const FormToggleSwitch: FC<FormToggleSwitchProps> = (props) => (
	<FormField control={ToggleSwitch} {...props} />
);
