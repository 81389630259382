import React from 'react';
import classnames from 'classnames';
import { Label, LabelProps } from '../Label';
import { useFocusVisible } from '../../hooks/useFocusVisible';

export interface RadioProps<T>
	extends Omit<
		React.HTMLAttributes<HTMLInputElement>,
		'onChange' | 'onClick'
	> {
	/** Whether or not the radio is chekced */
	checked?: boolean;

	/** Adds one or more classnames for an element */
	className?: string;

	/** Whether the radio is in a disabled state */
	disabled?: boolean;

	/** Visual error state for the radio */
	error?: boolean;

	/** ID for the form */
	id?: string;

	/** Text associatged with the label of a checkbox */
	label?: LabelProps['children'];

	/** Property options for the label */
	labelProps?: LabelProps;

	/** Calls when the radio changes in value */
	onChange?: (
		value: T,
		event: React.SyntheticEvent<HTMLInputElement>
	) => void;

	/** Calls when the radio is clicked  */
	onClick?: (
		event: React.MouseEvent<HTMLInputElement>,
		data: RadioProps<T>
	) => void;

	/** HTML radio name value */
	name?: string;

	/** Same as disabled state. Legacy Semantic prop. */
	readonly?: boolean;

	/** Controls the size of the radioes */
	size?: 'small' | 'medium' | 'large';

	/** HTML radio value */
	value?: T;
}

export const Radio: <T>(
	props: React.PropsWithoutRef<RadioProps<T>> &
		React.RefAttributes<HTMLDivElement>
) => React.ReactElement | null = React.forwardRef(function <T>(
	props: RadioProps<T>,
	ref: React.RefObject<HTMLDivElement>
) {
	const {
		checked,
		className,
		disabled,
		error,
		id,
		label,
		labelProps,
		name,
		onChange,
		onClick,
		readonly,
		size,
		value,
		onBlur,
		onFocus,
		...rest
	} = props;

	const newRef = React.useRef(null);
	const itemRef = ref ? ref : newRef;

	const { isFocusVisible } = useFocusVisible(itemRef);

	const onChangeHandler = (e: React.SyntheticEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(value, e);
		}
	};

	const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
		if (onClick) {
			onClick(e, props);
		}
	};

	const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
		const target = e.target as HTMLElement;
		if (target.tagName !== 'INPUT') {
			e.stopPropagation();
		}
	};

	const getLabel = () => {
		if (label === undefined || label === '') return false;

		const labelClassName = labelProps?.className
			? labelProps.className
			: null;
		const LabelClasses = classnames(labelClassName, 'Radio__label');
		return (
			<Label
				error={error}
				disabled={disabled}
				{...labelProps}
				className={LabelClasses}
			>
				{label}
			</Label>
		);
	};

	const RadioClasses = classnames('a-Radio', className, {
		'Radio--disabled': disabled || readonly,
		'Radio--checked': checked,
		'Radio--error': error,
		'Radio--fitted': label === undefined || label === '',
		'Radio--small': size === 'small',
		'Radio--large': size === 'large',
		'Radio--focus-visible': isFocusVisible,
	});

	return (
		<div
			className={RadioClasses}
			onClick={handleClickEvent}
			ref={itemRef}
			data-anvil-component="Radio"
			{...rest}
		>
			<label className="Radio__html-label">
				<input
					checked={checked}
					className="Radio__input"
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChangeHandler}
					onClick={onClickHandler}
					role="radio"
					type="radio"
				/>
				<span className="Radio__box" />
				{getLabel()}
			</label>
		</div>
	);
});
