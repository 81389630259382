import React, { FC } from 'react';
import classnames from 'classnames';
import { JustifyProps } from '../../utilities/justify-props';
import { Headline } from '../Headline';

export interface PopoverContentPropsStrict {
	/** Add class attribute */
	className?: string;

	children?: React.ReactNode;

	/** Add a specific max-height value to the popover body */
	contentScrollHeight?: string;

	/** Removes content padding. For full-width backgrounds */
	noPadding?: boolean;

	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
}

export interface PopoverContentProps extends PopoverContentPropsStrict {
	[propName: string]: any;
}

export const PopoverContent: FC<PopoverContentProps> = React.forwardRef(
	(
		{ children, className, contentScrollHeight, noPadding, onKeyDown },
		ref: React.RefObject<HTMLDivElement>
	) => {
		const PopoverContentClassNames = classnames(
			'Popover__content',
			className,
			{
				'Popover__content--nopadding': noPadding,
			}
		);
		return (
			<div
				style={{ maxHeight: contentScrollHeight }}
				className={PopoverContentClassNames}
				onKeyDown={onKeyDown}
				ref={ref}
			>
				{children}
			</div>
		);
	}
);

export interface PopoverFooterProps extends PopoverContentProps {
	align?: JustifyProps;
}

export const PopoverFooter: FC<PopoverFooterProps> = ({
	align = 'left',
	children,
	className,
	...props
}) => {
	const PopoverFooterClassNames = classnames('Popover__footer', className, {
		'justify-content-start': align === 'left',
		'justify-content-center': align === 'center',
		'justify-content-end': align === 'right',
		'justify-content-between': align === 'space-between',
	});
	return (
		<div className={PopoverFooterClassNames} {...props}>
			{children}
		</div>
	);
};

export interface PopoverHeaderProps {
	align?: JustifyProps;

	/** Add class attribute */
	className?: string;

	children?: React.ReactNode;

	/** Removes header padding. For full-width/height backgrounds */
	noPadding?: boolean;
}

export const PopoverHeader: FC<PopoverHeaderProps> = ({
	align = 'left',
	children,
	className,
	noPadding,
	...props
}) => {
	const PopoverHeaderClassNames = classnames('Popover__header', className, {
		'justify-content-start': align === 'left',
		'justify-content-center': align === 'center',
		'justify-content-end': align === 'right',
		'justify-content-between': align === 'space-between',
		'Popover__header--nopadding': noPadding,
	});
	return (
		<Headline size="small" className={PopoverHeaderClassNames} {...props}>
			{children}
		</Headline>
	);
};

export const PopoverDivider = () => <div className="Popover__divider" />;
