import classnames from 'classnames';
import React, { FC, useId } from 'react';

import {
	ModalWindow,
	ModalWindowProps,
	ModalHeader,
	ModalHeaderProps,
	ModalFooter,
	ModalFooterProps,
	ModalContent,
	ModalSizes,
} from './components';
import { UseFocusTrapProps } from '../../hooks';

export interface ModalProps extends ModalHeaderProps, ModalWindowProps {
	/** Adds one or more classnames for an element */
	className?: string;

	/** Modal Footer Content */
	footer?: React.ReactElement;

	/** Footer Alignment */
	footerAlign?: ModalFooterProps['align'];

	/** Set visibility of the Modal */
	open?: boolean;

	/** Render in a React Portal */
	portal?: boolean;

	/** Allows the Modal to fit within the browser window */
	scrollable?: boolean;

	/** Controls the width of the Modal */
	size?: ModalSizes;

	/** Options passed into useFocusTrap */
	focusTrapOptions?: UseFocusTrapProps;

	/** Adds classnames to Modal's content wrapper */
	contentClassName?: string;
}

export interface Modal extends FC<ModalProps> {
	/** Subcomponents */
	Window: typeof ModalWindow;
	Header: typeof ModalHeader;
	Content: typeof ModalContent;
	Footer: typeof ModalFooter;
	Sizes: typeof ModalSizes;
}

export const Modal: Modal = ({
	children,
	footer,
	footerAlign,
	onClose,
	open,
	portal = true,
	scrollable = true,
	size,
	title,
	focusTrapOptions,
	contentClassName,
	...rest
}) => {
	const id = useId();
	const modalLabel = `modalLabel_id_${id}`;
	const modalDescribedby = `modalDescribedby_id_${id}`;

	return (
		<ModalWindow
			onClose={onClose}
			size={size}
			focusTrapOptions={focusTrapOptions}
			open={open}
			portal={portal}
			role="alertdialog"
			data-anvil-component="Modal"
			aria-modal="true"
			aria-labelledby={modalLabel}
			aria-describedby={modalDescribedby}
			{...rest}
		>
			<ModalHeader id={modalLabel} title={title} onClose={onClose} />
			<ModalContent
				className={classnames('fs-3', contentClassName)}
				scrollable={scrollable}
				id={modalDescribedby}
			>
				{children}
			</ModalContent>
			{footer && <ModalFooter align={footerAlign}>{footer}</ModalFooter>}
		</ModalWindow>
	);
};

Modal.Window = ModalWindow;
Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
Modal.Sizes = ModalSizes;
