import { DateRangePickerProps } from '../DateRangePicker';
import { toDateFormat } from '../../../utilities/dateFormat';
import { DateTime } from 'luxon';

interface dateValue {
	start: string;
	end: string;
}

export const checkScope = (
	value: DateRangePickerProps['value'] | dateValue,
	min: Date,
	max: Date
) => {
	if (!value?.start || !value?.end) return { start: false, end: false };
	let start;
	let end;

	if (typeof value.start === 'string') {
		start = DateTime.fromFormat(value.start, 'LL/dd/yyyy')
			.startOf('day')
			.toJSDate();
	} else {
		start = toDateFormat.startOfDay(value.start);
	}
	if (typeof value.end === 'string') {
		end = DateTime.fromFormat(value.end, 'LL/dd/yyyy')
			.endOf('day')
			.toJSDate();
	} else {
		end = toDateFormat.endOfDay(value.end);
	}

	const scopeMessage = (date?: Date) => {
		const underMinDate =
			date?.getTime() < toDateFormat.startOfDay(min)?.getTime();
		const exceedsMaxDate =
			date?.getTime() > toDateFormat.endOfDay(max)?.getTime();
		if (underMinDate) {
			return `Min date is ${DateTime.fromJSDate(min).toFormat(
				'LL/dd/yyyy'
			)}`;
		}
		if (exceedsMaxDate) {
			return `Max date is ${DateTime.fromJSDate(max).toFormat(
				'LL/dd/yyyy'
			)}`;
		}
		return false;
	};

	return {
		start: scopeMessage(start),
		end: scopeMessage(end),
	};
};
