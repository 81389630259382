import React, { FC } from 'react';
interface ConditionalWrapperProps {
	condition: boolean;
	wrapper: (child: React.ReactElement) => React.ReactElement;
	children: React.ReactElement | React.ReactElement[];
}

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
	condition,
	wrapper,
	children,
}) => {
	const container = <React.Fragment>{children}</React.Fragment>;

	return condition ? wrapper(container) : container;
};
